/* eslint-disable no-console */
import { observable, action, toJS, makeObservable } from 'mobx';
import { client as apolloClient } from '../util/apolloClient';
import { isEmpty } from 'lodash';
import moment from 'moment';

import {
  matchDetailsQuery,
  editMatchMutation,
  addMatchSponsorMutation,
  editMatchSponsorMutation,
  createMatchMutation,
} from '../graphql/match';

class MatchStore {
  @observable isError = false;
  @observable isLoading = true;
  @observable isSaving = false;
  @observable unsavedMatchChanges = false;
  @observable unsavedSponsorChanges = false;
  @observable matchDetails = {};
  @observable userContext = {};
  @observable unsavedMatchDetails = {};
  @observable sponsorDetails = {};
  @observable unsavedSponsorDetails = {};
  @observable isMatchAdmin = false;
  @observable isSponsor = false;
  @observable matchExpired = false;

  @action createMatch = async ({
    eventId,
    userContext,
    matchTotal = 0,
    matchLimit = 5000,
    matchType = 'EVENT',
    endDate = moment()
      .add(1, 'weeks')
      .format(),
  }) => {
    try {
      const options = {
        variables: {
          eventId,
          endDate,
          matchTotal,
          matchLimit,
          matchType,
          userContext,
        },
        mutation: createMatchMutation,
        errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
      };
      const res = await apolloClient.mutate(options);
      return res.data.createMatch.id;
    } catch (err) {
      console.log(err);
    }
  };

  @action getMatchDetail = async ({ matchId, userContext }) => {
    this.isLoading = true;
    this.userContext = userContext;
    this.sponsorDetails = {};
    this.unsavedSponsorDetails = {};
    try {
      const options = {
        variables: { matchId, userContext },
        query: matchDetailsQuery,
        errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
        fetchPolicy: 'network-only',
      };
      const res = await apolloClient.query(options);
      this.matchDetails = res.data.matchDetails;
      if (res.data.matchDetails.matchAdmin.isSelf) {
        this.isMatchAdmin = true;
      } else {
        this.isMatchAdmin = false;
      }
      if (moment(res.data.matchDetails.endDate).isBefore()) {
        this.matchExpired = true;
      }
      if (Array.isArray(res.data.matchDetails.matchSponsors)) {
        const sponsorDetails = res.data.matchDetails.matchSponsors.find(
          sponsor => sponsor.isSelf,
        );
        if (!isEmpty(sponsorDetails)) {
          this.isSponsor = true;
          this.sponsorDetails = sponsorDetails;
        } else {
          this.isSponsor = false;
          this.sponsorDetails = {};
        }
      } else {
        this.unsavedSponsorDetails = { matchTotal: 10000 };
        this.unsavedSponsorChanges = true;
      }

      this.isLoading = false;
    } catch (err) {
      console.log(err);
    }
  };

  @action updateMatchDetail = props => {
    this.unsavedMatchDetails = { ...this.unsavedMatchDetails, ...props };
    if (!isEmpty(this.unsavedMatchDetails)) {
      this.unsavedMatchChanges = true;
    } else {
      this.unsavedMatchChanges = false;
    }
  };

  @action updateSponsorDetail = props => {
    this.unsavedSponsorDetails = { ...this.unsavedSponsorDetails, ...props };
    if (!isEmpty(this.unsavedSponsorDetails)) {
      this.unsavedSponsorChanges = true;
    } else {
      this.unsavedSponsorChanges = false;
    }
  };

  // hacky, but since if the user is creating a new company, wipe out any default context
  @action resetSponsorDetail = () => {
    this.unsavedSponsorDetails = {};
    this.sponsorDetails = {};
    this.unsavedSponsorChanges = false;
    this.isSponsor = false;
  };

  @action saveMatch = async ({ userContext } = {}) => {
    this.isSaving = true;
    try {
      if (this.unsavedSponsorChanges) {
        // check if new sponsor
        if (isEmpty(this.sponsorDetails)) {
          await apolloClient.mutate({
            variables: {
              userContext: userContext || toJS(this.userContext),
              total: this.unsavedSponsorDetails.matchTotal,
              matchId: this.matchDetails.id,
            },
            mutation: addMatchSponsorMutation,
            errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
          });

          // or update existing sponsor
        } else {
          await apolloClient.mutate({
            variables: {
              userContext: toJS(this.userContext),
              total: this.unsavedSponsorDetails.matchTotal,
              matchId: this.matchDetails.id,
            },
            mutation: editMatchSponsorMutation,
            errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
          });
        }
      }

      // if match admin changes, save
      if (this.unsavedMatchChanges) {
        await apolloClient.mutate({
          variables: {
            id: this.matchDetails.id,
            matchLimit: this.unsavedMatchDetails.matchLimit,
            matchType: this.unsavedMatchDetails.matchType,
            endDate: this.unsavedMatchDetails.endDate,
          },
          mutation: editMatchMutation,
          errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
        });
      }
      await this.getMatchDetail({
        matchId: this.matchDetails.id,
        userContext: userContext || toJS(this.userContext),
      });

      this.isSaving = false;
      this.unsavedMatchChanges = false;
      this.unsavedSponsorChanges = false;
    } catch (err) {
      this.isSaving = false;
      console.log(err);
    }
  };

  @action stopMatchSponsor = async ({ total }) => {
    this.isSaving = true;

    try {
      await apolloClient.mutate({
        variables: {
          userContext: toJS(this.userContext),
          total,
          matchId: this.matchDetails.id,
        },
        mutation: editMatchSponsorMutation,
        errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
      });

      this.isSaving = false;
      this.unsavedMatchChanges = false;
      this.unsavedSponsorChanges = false;
    } catch (err) {
      this.isSaving = false;
      console.log(err);
    }
  };

  @action toggleMatch = async ({ matchId, active }) => {
    this.isSaving = true;
    try {
      await apolloClient.mutate({
        variables: {
          id: matchId || this.matchDetails.id,
          active: active !== undefined ? active : !this.matchDetails.active,
        },
        mutation: editMatchMutation,
        errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
      });
      if (!matchId) {
        await this.getMatchDetail({
          matchId: this.matchDetails.id,
          userContext: toJS(this.userContext),
        });
      }

      this.isSaving = false;
      this.unsavedMatchChanges = false;
      this.unsavedSponsorChanges = false;
    } catch (err) {
      this.isSaving = false;
      console.log(err);
    }
  };

  @action onDismount = () => {
    this.isError = false;
    this.isLoading = true;
    this.isSaving = false;
    this.unsavedMatchChanges = false;
    this.unsavedSponsorChanges = false;
    this.matchDetails = {};
    this.userContext = {};
    this.unsavedMatchDetails = {};
    this.sponsorDetails = {};
    this.unsavedSponsorDetails = {};
    this.isMatchAdmin = false;
    this.isSponsor = false;
  };

  constructor () {
    makeObservable(this);
  }
}

const matchStore = new MatchStore();
export default matchStore;
