import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';

import Avatar from 'components/Avatar/Avatar';
import CauzeSpinner from 'components/CauzeSpinner/CauzeSpinner';
import LargeNumber from 'components/LargeNumber/LargeNumber';

const CharityListItem = ({
  charity,
  onSelect,
  onLearnMore,
  showLink = false,
  disabled = false,
}) => {
  const handleClick = (ev) => {
    if (showLink) {
      return;
    }
    ev.preventDefault();
    if (disabled) {
      return;
    }
    if (!!onSelect) {
      onSelect(charity.id);
    } else if (!!onLearnMore) {
      onLearnMore(charity.id);
    }
  };

  const hero = charity.hero?.md || charity.heroUrls?.md;
  const heroClassName = useMemo(() => {
    const baseClassName = 'charity-item-hero';

    if (hero) {
      return baseClassName;
    }

    const i = charity.name.length % 6;

    return `${baseClassName} background-gradient-${i + 1}`;
  }, [charity]);

  return (
    <a
      href={showLink ? `/charity/${charity.id}` : ''}
      className="charity-item"
      onClick={handleClick}
    >
      <div className="charity-item-hero-container">
        <div
          className={heroClassName}
          style={{
            backgroundImage: hero ? `url('${hero}')` : undefined,
          }}
        />
        <div className="charity-item-hero-backdrop" />
        <Avatar
          md
          noBorder
          className="avatar"
          type="charity"
          avatar={charity.avatar || charity.avatarUrls}
        />
      </div>
      <div className="charity-item-body">
        <div className="charity-item-name">{charity.name}</div>
        <div className="charity-item-sub">
          <span className="charity-item-city">
            {charity.city?.toLowerCase()}
          </span>
          {Boolean(charity.city && charity.state) && <span>, </span>}
          <span className="charity-item-state">
            {charity.state?.toLowerCase()}
          </span>
        </div>
        <div className="charity-item-sub">
          <LargeNumber amount={charity.followerCount} /> Follower
          {charity.followerCount !== 1 && 's'}
        </div>
      </div>
    </a>
  );
};

CharityListItem.propTypes = {
  charity: PropTypes.object,
  isSelected: PropTypes.bool,
  onLearnMore: PropTypes.func,
  onSelect: PropTypes.func,
  disabled: PropTypes.bool,
  hideShadow: PropTypes.bool,
  showLink: PropTypes.bool,
};

const CharityList = ({
  charities,
  onLearnMore,
  onSelect,
  selected = [],
  showLink,
  disabled = false,
  hideShadow,
  rounded,
  loading,
}) => {
  const [isMobile, setIsMobile] = useState(document.body.clientWidth < 425);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 425 && !isMobile) {
        setIsMobile(true);
      } else if (window.innerWidth > 425 && isMobile) {
        setIsMobile(false);
      }
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isMobile]);

  if (loading) {
    return (
      <div className="event-list">
        <CauzeSpinner />
      </div>
    );
  }

  return (
    <Swiper
      className={cx('charity-list', {
        'show-arrows': charities && charities.length > 2,
      })}
      navigation
      modules={[Navigation]}
      spaceBetween={8}
      slidesPerView={isMobile ? 2.5 : 3.5}
      slidesPerGroup={2}
    >
      {charities.map((charity) => (
        <SwiperSlide key={charity.id}>
          <CharityListItem
            charity={charity}
            onSelect={onSelect}
            isSelected={selected.includes(charity.id)}
            onLearnMore={onLearnMore}
            disabled={disabled}
            hideShadow={hideShadow}
            showLink={showLink}
            rounded={rounded}
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

CharityList.propTypes = {
  title: PropTypes.string,
  charities: PropTypes.array,
  eventId: PropTypes.string,
  onLearnMore: PropTypes.func,
  onSelect: PropTypes.func,
  selected: PropTypes.array,
  showSelectButton: PropTypes.bool,
  disabled: PropTypes.bool,
  hideShadow: PropTypes.bool,
  showLink: PropTypes.bool,
};

export default CharityList;
