import gql from 'graphql-tag';

const usersQuery = gql`
  query users {
    users {
      id
      firstName
      lastName
      username
      email
      followerCount
      followingCount
      avatar {
        sm
      }
      balance {
        total
      }
      shareLink
      zip
    }
  }
`;

const userAdminsQuery = gql`
  query userAdmins($user_id: ID!) {
    userAdmins(userId: $user_id) {
      id
      firstName
      lastName
      username
      email
      followerCount
      followingCount
      invitedDate
      redeemedDate
      avatar {
        sm
      }
      balance {
        total
        gifts {
          amount
          remaining
        }
      }
      zip
    }
  }
`;

const assignUserRoleMutation = gql`
  mutation assignUserRole(
    $role_type: UserRoleType!
    $target_id: ID
    $user_id: ID!
  ) {
    assignUserRole(
      roleType: $role_type
      targetId: $target_id
      userId: $user_id
    ) {
      active
      id
    }
  }
`;

const revokeUserRoleMutation = gql`
  mutation revokeUserRole(
    $role_type: UserRoleType!
    $target_id: ID
    $user_id: ID!
  ) {
    revokeUserRole(
      roleType: $role_type
      targetId: $target_id
      userId: $user_id
    ) {
      active
      id
    }
  }
`;

const inviteUserAdminMutation = gql`
  mutation sendGifts($user_context: UserContext, $emails: [String]) {
    sendGifts(
      userContext: $user_context
      emails: $emails
      amount: 0
      paymentType: BALANCE
      userRole: INFLUENCER_ADMIN
      giftType: USER_GIFT
    ) {
      token
    }
  }
`;

const inviteUserMutation = gql`
  mutation sendGifts($emails: [String]) {
    sendGifts(
      emails: $emails
      amount: 0
      paymentType: BALANCE
      userRole: INFLUENCER
      giftType: USER_GIFT
    ) {
      token
    }
  }
`;

const listUserAdminInvitesQuery = gql`
  query listUserAdminInvites($user_id: ID!) {
    listUserAdminInvites(userId: $user_id) {
      id
      email
      updatedAt
      pending
      revoked
      gift {
        amount
        comment
      }
    }
  }
`;

const userByUsernameQuery = gql`
  query userByUsernameQuery($username: String!) {
    userByUsername(username: $username) {
      username
    }
  }
`;

export {
  usersQuery,
  userAdminsQuery,
  assignUserRoleMutation,
  revokeUserRoleMutation,
  inviteUserMutation,
  inviteUserAdminMutation,
  listUserAdminInvitesQuery,
  userByUsernameQuery,
};
