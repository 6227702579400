/* eslint-disable no-console */
import { observable, action, toJS, makeObservable } from 'mobx';
import { client as apolloClient } from 'util/apolloClient';
import { client as api2Client } from 'util/api2Client';
import { charityByIdQuery } from 'graphql/charity';
import { followMutation, unfollowMutation } from 'graphql/follow';
import { likeMutation, unlikeMutation } from 'graphql/like';
import { trackFollow, trackUnfollow } from 'util/tracking/follow';

import { projectEventsQuery } from 'graphql/event';
import { projectFeedQuery } from 'graphql/feed';

class CharityStore {
  @observable loading = true;
  @observable isError = false;
  @observable ledgerData = [];
  @observable ledgerLoading = true;
  @observable reportRequestSuccess = false;
  @observable charities = new Map();
  @observable feeds = new Map();
  @observable events = new Map();
  @observable feedLoading = false;
  @observable feedLoadingMore = false;
  @observable eventsLoading = false;
  @observable matchSummaryByCompany;

  @action getCharity = async ({
    id = null,
    userContext,
    silent = false,
    type = 'PROJECT',
  }) => {
    if (!silent) {
      this.loading = true;
    }
    const options = {
      variables: { id, type, userContext },
      query: charityByIdQuery,
      fetchPolicy: 'no-cache',
      errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
    };
    try {
      const result = await apolloClient.query(options);
      this.charities.set(id, result.data.data);
      this.loading = false;
      this.isError = false;
    } catch (err) {
      this.loading = false;
      this.isError = true;
      this.error = err;
    }
  };

  @action clearFeed = async ({ id }) => {
    this.feedLoading = true;
    this.charities.set(id, []);
  };

  @action like = ({ like = true, id, purchaseId, charityId, userContext }) => {
    const currentFeed = toJS(this.feeds.get(charityId));

    const feedItem = currentFeed.find(
      (feedItem) => feedItem?.activityPurchase?.id === id,
    );

    if (!feedItem) return;

    feedItem.currentEntityLiked = like;
    feedItem.likeCount = like ? feedItem.likeCount + 1 : feedItem.likeCount - 1;
    this.feeds.set(charityId, currentFeed);

    const updatedUserContext = { ...userContext };

    if (updatedUserContext.influencerId) {
      updatedUserContext.userId = updatedUserContext.influencerId;
      delete updatedUserContext.influencerId;
    }

    const options = {
      variables: { id: purchaseId, userContext: updatedUserContext },
      mutation: like ? likeMutation : unlikeMutation,
      errorPolicy: global.IS_DEV ? 'all' : 'none',
    };
    apolloClient.mutate(options).catch(() => {
      feedItem.currentEntityLiked = !like;
      feedItem.likeCount = like
        ? feedItem.likeCount - 1
        : feedItem.likeCount + 1;
      this.feeds.set(charityId, currentFeed);
    });
  };

  @action getCharityFeed = async ({ id }) => {
    this.feedLoading = true;
    this.currentPage = 1;
    try {
      const variables = {
        projectId: parseInt(id),
        limit: 20,
        offset: 0,
      };

      const options = {
        variables: variables,
        query: projectFeedQuery,
        fetchPolicy: 'no-cache',
        errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
      };

      const results = await api2Client.query(options);

      this.feeds.set(id, results.data.projectFeed);
    } catch (err) {
      // pass
    }

    this.feedLoading = false;
  };

  @action getEvents = async ({ id }) => {
    this.eventsLoading = true;

    try {
      const variables = {
        projectId: parseInt(id),
        pinnedOnly: true,
      };

      const options = {
        variables: variables,
        query: projectEventsQuery,
        fetchPolicy: 'no-cache',
        errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
      };

      const results = await api2Client.query(options);
      this.events.set(id, results.data.projectEvents);
    } catch (err) {
      // pass
    }

    this.eventsLoading = false;
  };

  @action loadMore = async ({ id }) => {
    this.currentPage = this.currentPage + 1;
    const currentFeedData = toJS(this.feeds.get(id));

    if (this.currentPage > this.totalPages) {
      return;
    }

    this.feedLoadingMore = true;
    try {
      const variables = {
        projectId: parseInt(id),
        limit: 20,
        offset: (this.currentPage - 1) * 20,
      };

      const options = {
        variables: variables,
        query: projectFeedQuery,
        fetchPolicy: 'no-cache',
        errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
      };

      const results = await api2Client.query(options);
      this.feeds.set(id, currentFeedData.concat(results.data.projectFeed));
    } catch (err) {
      // pass
    }

    this.feedLoadingMore = false;
  };

  @action follow = ({ follow = true, actorContext, charityId }) => {
    const targetContext = { charityId };
    this.setFollow({ charityId, follow });

    if (follow) {
      trackFollow({ actorContext, targetContext });
    } else {
      trackUnfollow({ actorContext, targetContext });
    }

    const options = {
      variables: {
        targetContext,
        actorContext,
      },
      mutation: follow ? followMutation : unfollowMutation,
      errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
    };

    apolloClient.mutate(options).catch((err) => {
      this.setFollow({ charityId, follow: !follow });
      if (global.IS_LOCAL_OR_DEV) {
        console.log(err);
      }
    });
  };

  @action setFollow = ({ charityId, follow }) => {
    const charityData = this.charities.get(charityId);
    charityData.isSelfFollowing = follow;
    charityData.followerCount = charityData.followerCount + (follow ? 1 : -1);
    this.charities.set(charityId, charityData);
  };

  constructor() {
    makeObservable(this);
  }
}

const charityStore = new CharityStore();
export default charityStore;
