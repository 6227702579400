import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import { observer, inject } from 'mobx-react';
import { debounce } from 'lodash';
import classnames from 'classnames';
import useKeyboardEvent from '../../hooks/useKeyboardEvent.js';
import columns from './InfluencersTableColumns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';

const InfluencersTable = ({ influencersStore, goToUser }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [pageSize, setPageSize] = useState(influencersStore.influencers.length);
  const [mobileSortActive, setMobileSortActive] = useState(false);
  const [mobileSort, setMobileSort] = useState({
    id: 'name',
    desc: false,
  });

  const MAX_PAGE_SIZE = 100;

  let tableRef = useRef(null);
  let searchInputRef = useRef(null);

  const debouncedSetSearchTerm = debounce(setSearchTerm, 1000);

  useKeyboardEvent('Escape', () => {
    clearSearch();
  });

  const clearSearch = () => {
    setSearchTerm('');
  };

  const toggleMobileSort = (isOpen = true) => {
    if (isOpen) {
      setMobileSortActive(true);
      return;
    }
    setMobileSortActive(false);
  };

  const tableColumns = columns({
    balanceTotal: influencersStore.balanceTotal,
    followingCount: influencersStore.followingCount,
    followersCount: influencersStore.followersCount,
  });

  return (
    <div className="rt-container">
      <div className="action-bar flex-row">
        <div className="responsive-row">
          <div className={classnames('search-input')}>
            <div tabIndex="0" className="action-button">
              <FontAwesomeIcon
                icon={faSearch} className={classnames('', {
                  orange: searchTerm !== '',
                })}/>
            </div>
            <input
              tabIndex={0}
              className="input"
              placeholder="Search Influencers&#8230;"
              ref={searchInputRef}
              onChange={e => debouncedSetSearchTerm(e.target.value)}
            />
            <div
              alt="clear all"
              className="action-button close-button flex-column"
              onClick={clearSearch}
            >
              <FontAwesomeIcon icon={faTimes} />
            </div>
          </div>
        </div>
        <div className="responsive-row flex-expand">
          <div className="flex-row flex-expand is-hidden-tablet"></div>
          <div className="flex-row">
            <div
              className={classnames('sort-action flex-column is-hidden-tablet')}
              onClick={
                mobileSortActive
                  ? () =>
                    setMobileSort({ ...mobileSort, desc: !mobileSort.desc })
                  : () => {
                    setMobileSort({ ...mobileSort, desc: !mobileSort.desc });
                    toggleMobileSort(true);
                  }
              }
            >
              <FontAwesomeIcon
                icon={faSortUp} className={classnames('', {
                  orange: mobileSort.desc,
                })}/>
              <FontAwesomeIcon
                icon={faSortDown} className={classnames('', {
                  orange: !mobileSort.desc,
                })}/>
            </div>
          </div>
        </div>
      </div>
      <ReactTable
        filterable
        className="ledger-table"
        resizable={false}
        keyField={'id'}
        ref={tableRef}
        columns={tableColumns}
        data={influencersStore.influencers}
        pageSize={MAX_PAGE_SIZE}
        minRows={0}
        getTdProps={goToUser}
        filtered={[{ id: 'influencer', value: searchTerm }]}
        defaultSorted={[{ id: 'influencer', desc: true }]}
        defaultPageSize={MAX_PAGE_SIZE}
        showPaginationBottom={pageSize > MAX_PAGE_SIZE}
        onFilterUpdateLength={len => setPageSize(len)}
        {...(mobileSortActive > 0 && { sorted: [mobileSort] })}
      />
    </div>
  );
};

InfluencersTable.propTypes = {
  influencersStore: PropTypes.object.isRequired,
  goToUser: PropTypes.func.isRequired,
};

export default inject('influencersStore')(observer(InfluencersTable));
