import gql from 'graphql-tag';

const companyByIdQuery = gql`
  query($id: ID!) {
    companyById(id: $id) {
      invitedCount
      employeeCount
      employeeDonatedCount
      donationCountByLocation {
        count
        state
      }
      donationCountByCategory {
        category
        count
      }
    }
  }
`;

const companySupportedCharitiesQuery = gql`
  query($company_id: ID!) {
    companySupportedCharities(companyId: $company_id) {
      avatar {
        sm
        md
        lg
        xl
      }
      donationCount
      id
      zip
      donationAmount
      ein
      state
      description
      name
      city
      category
      webUrl
      street
      matchAmount
      bio
    }
  }
`;

const createDownloadTokenMutation = gql`
  mutation($handler_name: String, $user_context: UserContext) {
    createDownloadToken(
      handlerName: $handler_name
      userContext: $user_context
    ) {
      token
    }
  }
`;
export {
  companySupportedCharitiesQuery,
  companyByIdQuery,
  createDownloadTokenMutation,
};
