import React, { useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import { Navbar } from 'components/page';
import FooterNew from 'components/page/Footer/FooterNew';
import CauzeSpinner from 'components/CauzeSpinner/CauzeSpinner';

import AuthViewLogin from './AuthView/AuthViewLogin.js';

const LoginView = ({ authStore, profileStore, uiStore, _token }) => {
  useEffect(() => {
    if (
      authStore.isAuthenticated &&
      profileStore.hasFetchedInitial &&
      profileStore.availableUserEntities.length > 1 &&
      authStore.stashedPathDetails.path &&
      !authStore.stashedPathDetails.path.contains('addsponsor')
    ) {
      uiStore.openModal('CONTEXT_SELECTOR');
    }
  }, [profileStore.hasFetchedInitial, authStore.isAuthenticated]);

  if (authStore.isAuthenticated && !profileStore.hasFetchedInitial) {
    return (
      <div className="auth-view">
        <Navbar />
        <div className="form-container section flex-expand">
          <CauzeSpinner />
        </div>
      </div>
    );
  }

  return (
    <div className="signup-view-container">
      <Navbar hideLoginPrompt />
      <AuthViewLogin />
      <FooterNew />
    </div>
  );
};

export default inject(
  'authStore',
  'profileStore',
  'uiStore',
)(observer(LoginView));
