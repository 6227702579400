import React from 'react';
import { Redirect } from '@reach/router';
import withUserContext from '../../behaviors/withUserContext';

const WalletRedirectView = ({ activeEntity }) => {
  if (activeEntity) {
    return (
      <Redirect
        to={`/admin/${activeEntity.entityType.toLowerCase()}/${
          activeEntity.id
        }/wallet`}
        noThrow
      />
    );
  }
  return <Redirect to={`/login`} noThrow />;
};

export default withUserContext(WalletRedirectView);
