import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const CheckoutInputNew = ({
  value,
  onChange,
  fieldTitle,
  placeholder,
  isError,
  errorText,
  className = '',
  type,
  onBlur,
  style,
}) => (
  <label
    className={classnames(`checkout-input-new ${className}`, {
      'input-error': isError,
    })}
  >
    <input
      type={type}
      className={classnames('input', {
        'input-error-underline': isError,
      })}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      placeholder={placeholder || fieldTitle}
      style={style}
    />
    {isError ? (
      <p className="error-text">{errorText}</p>
    ) : (
      // yeah I know this is hacky
      <div className="input-margin-bottom"></div>
    )}
  </label>
);

CheckoutInputNew.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  fieldTitle: PropTypes.string,
  placeholder: PropTypes.string,
  isError: PropTypes.bool,
  errorText: PropTypes.string,
  type: PropTypes.string,
  fieldSubtitle: PropTypes.string,
  onBlur: PropTypes.func,
  className: PropTypes.string,
  style: PropTypes.object,
};

export default CheckoutInputNew;
