import { useEffect } from 'react';
import charityAdminStore from '../stores/CharityAdminStore';

const useCharityAdmin = charityId => {
  useEffect(() => {
    charityAdminStore.onUnmount();
    if (charityId) {
      charityAdminStore.getInitial(charityId);
    }
  }, [charityId]);
};

export default useCharityAdmin;
