import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';

const InviteUsers = ({ onClickInviteUsers }) => (
  <div className="dashboard-item pending-invites flex-row">
    <div className="dashboard-icon">
      <FontAwesomeIcon icon={faUserPlus} />
    </div>
    <div className="dashboard-content">
      <div className="dashboard-details flex-column">
        <div className="dashboard-item-title">Invite Followers</div>
        <div className="dashboard-item-body">
          Invite followers to join you on Cauze!
        </div>
      </div>
      <div className="dashboard-item-actions flex-row">
        <Button className="is-small" onClick={onClickInviteUsers}>
          Invite Followers
        </Button>
      </div>
    </div>
  </div>
);

InviteUsers.propTypes = {
  onClickInviteUsers: PropTypes.func.isRequired,
};

export default InviteUsers;
