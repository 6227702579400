import { getEntityUrl } from './navHelpers';
import dompurify from 'dompurify';

const sanitizer = dompurify.sanitize;
const markdownRegexGlobal = /@\[([^[]*)]\(([^(^)]*)\)\(([^(^)]*)\)/g;
const markdownRegex = /@\[([^[]*)]\(([^(^)]*)\)\(([^(^)]*)\)/;

const shorten = (string, maxLength, ellipsis = true) => {
  const showEllipsis = string.length > maxLength && ellipsis;
  return `${string.substring(0, maxLength)}${showEllipsis ? '…' : ''}`;
};

const convertMarkdownToFormatted = text =>
  text.replace(markdownRegexGlobal, str => {
    let [_full, username, id, entityType] = str.match(markdownRegex);
    return `<a href="${getEntityUrl({ entityType, id })}">@${username}</a>`;
  });

const convertSnakeToSpaces = text => text.split('_')
  .filter(x => x.length > 0)
  .join(' ');

export { shorten, convertMarkdownToFormatted, convertSnakeToSpaces, markdownRegex, sanitizer };
export const linkify = require('linkifyjs');
