import React, { useState } from 'react';
import { observer } from 'mobx-react';
import Currency from '../../../Currency/Currency';

import PropTypes from 'prop-types';

const SelectFunding = ({ store, setValidator }) => {
  const match = store.currentMatch;
  const defaultFundingAmount = match.matchLimit * match.multiplier * 5;

  setValidator(() => {
    if (match.matchTotal < defaultFundingAmount || !match.matchTotal) {
      return 'You must fund at least enough to match 5 employees giving';
    } else {
      return '';
    }
  });
  const [fundDefault, setFundDefault] = useState(
    match.matchTotal === defaultFundingAmount,
  );

  return (
    <div className="option-group modal-match-height">
      <div className="option-group-subhead is-size-5">
        <p className="match-title">Funding Your Match</p>
        <p className="fund-info">
          Employee matches require at least 5x the Max per employee amount. Once
          a month, or when your match funding goes below{' '}
          <Currency amount={match.matchLimit} /> (1x max per employeee amount),
          we will bring your match fund back up to the required{' '}
          <Currency amount={defaultFundingAmount} /> by pulling the amount from
          your balance. If you don’t have enough money in your Cauze balance,
          the pending donations won’t be granted until the match is adequately
          funded.
        </p>
      </div>
      <div
        className="flex-expand flex-column match-selection"
        style={{ marginLeft: '1rem', marginTop: '0.5rem' }}
      >
        <label className="radio" htmlFor="minFund">
          <input
            type="radio"
            value="minFund"
            id="minFund"
            name="minFnud"
            checked={fundDefault}
            onChange={_e => {
              setFundDefault(true);
              store.updateMatch({ matchTotal: defaultFundingAmount });
            }}
          />
          &nbsp; Minimum: Fund <Currency amount={defaultFundingAmount} />
        </label>
        <label className="radio custom-amount" htmlFor="customFund">
          <input
            type="radio"
            value="customFund"
            id="customFund"
            name="customFund"
            checked={!fundDefault}
            onChange={_e => {
              setFundDefault(false);
            }}
            style={{ marginRight: '10px' }}
          />
          &nbsp; Custom Amount ( Above{' '}
          <Currency amount={defaultFundingAmount} /> )
        </label>
        {!fundDefault && (
          <div
            className="name-input flex-column"
            style={{ marginLeft: '2rem', marginTop: '0rem' }}
          >
            <div className="flex-row flex-expand flex-align-center">
              $
              <input
                placeholder={defaultFundingAmount / 100}
                className="input"
                value={match.matchTotal / 100}
                onChange={e => {
                  store.updateMatch({ matchTotal: e.target.value * 100 });
                }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

SelectFunding.propTypes = {
  store: PropTypes.object.isRequired,
  setValidator: PropTypes.func,
};

export default observer(SelectFunding);
