import React from 'react';
import PropTypes from 'prop-types';

const SponsoredCard = ({ sponsor }) => (
  <div className="sponsored-card-wrapper">
    <div className="card">
      <header className="card-header">
        <img
          className="card-header-image"
          alt="Logo"
          src={`${sponsor.logo.original}`}/>
      </header>
      <div className="card-content">
        <div className="content">
          {sponsor.description}
        </div>
      </div>
    </div>
  </div>
);

SponsoredCard.propTypes = {
  sponsor: PropTypes.shape({
    logo: PropTypes.shape({
      md: PropTypes.string,
      lg: PropTypes.string,
      original: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default SponsoredCard;
