import gql from 'graphql-tag';

const listGiftsQuery = gql`
  query listGifts($user_context: UserContext) {
    listGifts(userContext: $user_context) {
      amount
      comment
      insertedAt
      id
      recipient {
        username
        firstName
        lastName
        email
        avatar {
          md
          lg
          sm
          xl
        }
      }
      remaining
      revoked
      revokedBy {
        username
      }
      type
      updatedAt
    }
  }
`;

const listUnredeemedGiftsQuery = gql`
  query listUnredeemedGifts($user_context: UserContext) {
    listUnredeemedGifts(userContext: $user_context) {
      resentAt
      insertedAt
      id
      revoked
      pending
      gift {
        amount
      }
      sentAt
      email
      type
    }
  }
`;

const revokeUserTokensMutation = gql`
  mutation revokeUserTokens($ids: [ID!]!) {
    revokeUserTokens(ids: $ids) {
      email
    }
  }
`;

const refundGiftsMutation = gql`
  mutation refundGifts($gift_ids: [ID!]!) {
    refundGifts(giftIds: $gift_ids) {
      id
    }
  }
`;

export {
  listGiftsQuery,
  listUnredeemedGiftsQuery,
  refundGiftsMutation,
  revokeUserTokensMutation,
};
