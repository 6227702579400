import React, { Fragment, useState, useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import classnames from 'classnames';

import useAddFundsGiftCheckout from 'hooks/useAddFundsGiftCheckout';
import withUserContext from 'behaviors/withUserContext';
import Button from 'components/Button/Button';
import Currency from 'components/Currency/Currency';
import CauzeSpinner from 'components/CauzeSpinner/CauzeSpinner';
import AmountSelector from 'components/AmountSelector/AmountSelector';
import ErrorModal from 'components/modals/ErrorModal';
import { DottedLine } from 'components/checkout/CheckoutConfirmationModal';
import EmailListInput from 'components/EmailListInput/EmailListInput';
import PaymentOptionSelector from 'components/checkout/PaymentOptionSelector';

const SendUserGiftsModal = ({
  isOpen,
  onToggleClose,
  userContext,
  activeEntity,
  addFundsGiftCheckoutStore,
  giftType = 'SELF_GIFT',
  charityId,
  companyId,
  userId,
  isInviteFollowerExperience = false,
  onSuccess = (_amt) => {},
  onCancel = () => {},
  defaultAmount = 0,
  existingEmails = [],
  hideGiftAmounts = false,
  baseEmailInputText,
}) => {
  const [hasValidationError, setHasValidationError] = useState(true);
  const [showComment, setShowComment] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  addFundsGiftCheckoutStore.setStripeObject(stripe);
  addFundsGiftCheckoutStore.setStripeElements(elements);

  const isGroup = companyId && activeEntity.groupType !== 'COMPANY';
  const currentCheckout = addFundsGiftCheckoutStore.currentCheckout;

  const onToggleCancel = () => {
    onCancel();
    onToggleClose();
    addFundsGiftCheckoutStore.resetCheckout();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (
      (giftType === 'USER_GIFT' || giftType === 'EMPLOYEE_GIFT') &&
      currentCheckout.emails.length === 0
    ) {
      addFundsGiftCheckoutStore.popErrorModal(
        'Please enter at least one email.',
      );
      return;
    }

    if (
      (currentCheckout.amount === 0 && giftType === 'SELF_GIFT') ||
      (!isInviteFollowerExperience &&
        giftType === 'USER_GIFT' &&
        currentCheckout.amount === 0)
    ) {
      addFundsGiftCheckoutStore.popErrorModal('Please select an amount.');
      return;
    }

    if (
      (currentCheckout.paymentType === 'ACH' && currentCheckout.cardId) ||
      (currentCheckout.paymentType === 'CARD' && currentCheckout.cardId) ||
      currentCheckout.amount === 0 ||
      currentCheckout.paymentType === 'BALANCE' ||
      !currentCheckout.paymentType
    ) {
      addFundsGiftCheckoutStore.startCheckout();
      return;
    }

    if (!stripe || !elements) {
      return;
    }

    if (currentCheckout.paymentType === 'CARD' && !currentCheckout.cardId) {
      addFundsGiftCheckoutStore.startCheckout();
    }
  };

  useAddFundsGiftCheckout({
    userId,
    charityId,
    companyId,
    giftType,
    activeEntity,
  });

  useEffect(() => {
    if (defaultAmount && defaultAmount !== 0) {
      addFundsGiftCheckoutStore.updateCheckout({
        amount: defaultAmount,
      });
    }
  }, [addFundsGiftCheckoutStore, defaultAmount]);

  if (addFundsGiftCheckoutStore.checkoutDetailsFail) {
    return (
      <div
        className={classnames('add-funds-modal modal', {
          'is-active': isOpen,
        })}
      >
        <div onClick={onToggleCancel} className="modal-background"></div>
        <div className="modal-card">
          <section className="modal-card-body">
            <div className="text-bold">Couldn't get checkout details</div>
            <Button
              style={{ marginTop: '2rem' }}
              className="is-medium"
              onClick={() =>
                addFundsGiftCheckoutStore.getCheckoutDetails({
                  userContext,
                  activeEntity,
                })
              }
            >
              Try Again
            </Button>
          </section>
        </div>
      </div>
    );
  }

  if (addFundsGiftCheckoutStore.checkoutLoading) {
    return (
      <div
        className={classnames('add-funds-modal modal', {
          'is-active': isOpen,
        })}
      >
        <div onClick={onToggleCancel} className="modal-background"></div>
        <div className="modal-card">
          <CauzeSpinner />
        </div>
      </div>
    );
  }

  return (
    <div
      className={classnames('add-funds-modal modal', {
        'is-active': isOpen,
      })}
    >
      <div onClick={onToggleCancel} className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          {addFundsGiftCheckoutStore.showConfirmationModal ? (
            <p className="modal-card-title">Checkout Summary</p>
          ) : (
            <p className="modal-card-title">
              {giftType === 'SELF_GIFT' && 'Adding Funds'}
              {giftType === 'EMPLOYEE_GIFT' &&
                `Add ${isGroup ? 'Members' : 'Employees'}`}
              {giftType === 'USER_GIFT' &&
                !isInviteFollowerExperience &&
                'Send Gifts'}
              {giftType === 'USER_GIFT' &&
                isInviteFollowerExperience &&
                'Invite Followers'}
            </p>
          )}
          <button
            className="delete"
            aria-label="close"
            onClick={onToggleCancel}
          ></button>
        </header>
        {addFundsGiftCheckoutStore.showConfirmationModal && (
          <section className="checkout-confirm-modal modal-card-body">
            <p>
              Check your summary and confirm to
              {giftType === 'SELF_GIFT'
                ? ' add funds to your account.'
                : ' send invites and gifts.'}
            </p>
            {(currentCheckout.paymentType === 'ACH' ||
              currentCheckout.paymentType === 'CARD') && (
              <div className="flex-column">
                <div className="line-item flex-row">
                  <div>Your Amount</div>
                  <DottedLine />
                  <div>
                    <Currency
                      showCents
                      amount={currentCheckout.splitAmount.forCauze}
                    />
                  </div>
                </div>
                {currentCheckout.splitAmount.prepayFee !== 0 && (
                  <div className="line-item flex-row">
                    <div>5% Grant Processing Fee</div>
                    <DottedLine />
                    <div>
                      <Currency
                        showCents
                        amount={currentCheckout.splitAmount.prepayFee}
                      />
                    </div>
                  </div>
                )}
                <div className="line-item flex-row">
                  <div>Processing Fee</div>
                  <DottedLine />
                  <div>
                    <Currency
                      showCents
                      amount={currentCheckout.splitAmount.fee}
                    />
                  </div>
                </div>
                <div className="is-size-7">
                  {currentCheckout.paymentType === 'ACH'
                    ? `Our ACH processor charges a mandatory processing fee
                  of 0.8%. 100% of your intended donation goes to the
                  nonprofit.`
                    : `Our credit card processor charges a mandatory processing fee
                  of 2.2% + .30 (3.5% for American Express). 100% of your intended donation goes to the
                  nonprofit.`}
                </div>
                <hr />
                <div className="line-item total flex-row">
                  <div>Total:</div>
                  <DottedLine />
                  <div>
                    <Currency
                      showCents
                      amount={currentCheckout.splitAmount.total}
                    />
                  </div>
                </div>
              </div>
            )}
            {currentCheckout.paymentType?.toUpperCase() === 'BALANCE' && (
              <div className="flex-column">
                <div className="line-item flex-row">
                  <div>Current Balance:</div>
                  <DottedLine />
                  <div>
                    <Currency showCents amount={currentCheckout.balance} />
                  </div>
                </div>
                <div className="line-item flex-row">
                  <div>Gift(s): </div>
                  <DottedLine />
                  <div>
                    <Currency
                      showCents
                      amount={currentCheckout.splitAmount.forCauze}
                    />
                  </div>
                </div>
                <div className="line-item flex-row">
                  <div>Remaining Balance: </div>
                  <DottedLine />
                  <div>
                    <Currency
                      showCents
                      amount={
                        currentCheckout.balance -
                        currentCheckout.splitAmount.forCauze
                      }
                    />
                  </div>
                </div>
                <hr />
                <div className="line-item total flex-row">
                  <div>Total:</div>
                  <DottedLine />
                  <div>
                    <Currency
                      showCents
                      amount={currentCheckout.splitAmount.forCauze}
                    />
                  </div>
                </div>
              </div>
            )}
            <div className="flex-row flex-center">
              <Button
                className="donate-button is-medium"
                onClick={() => {
                  addFundsGiftCheckoutStore
                    .completeCheckout()
                    .then((checkoutComplete) => {
                      if (checkoutComplete === true) {
                        onSuccess(currentCheckout.amount);
                        addFundsGiftCheckoutStore.resetCheckout();
                        onToggleClose();
                      }
                    });
                }}
              >
                Confirm Transaction
              </Button>
            </div>
          </section>
        )}
        <>
          <section
            className="modal-card-body"
            style={{
              display:
                addFundsGiftCheckoutStore.showConfirmationModal && 'none',
            }}
          >
            <form onSubmit={handleSubmit}>
              {addFundsGiftCheckoutStore.showErrorModal && (
                <ErrorModal
                  isOpen
                  errorText={addFundsGiftCheckoutStore.errorModalText}
                  buttonText={addFundsGiftCheckoutStore.errorModalButtonText}
                  onToggleClose={addFundsGiftCheckoutStore.onErrorModalClose}
                />
              )}
              {giftType === 'SELF_GIFT' ? (
                <p>
                  Adding funds to your account makes it easy to provide gifts,
                  matches or direct donations in the name of your account.
                </p>
              ) : (
                <p>
                  {giftType === 'EMPLOYEE_GIFT' ? (
                    <span>
                      Add {isGroup ? 'members' : 'employees'} to your Cauze
                      account by email address. Then, you can either choose to
                      add a gift and send them an invite to the platform now, or
                      add them to the pending invites list to invite them later.
                    </span>
                  ) : (
                    <span>
                      Send {isInviteFollowerExperience ? 'an invite' : 'a gift'}{' '}
                      to any email address
                    </span>
                  )}
                </p>
              )}

              <div className="form-container">
                {giftType !== 'SELF_GIFT' && (
                  <Fragment>
                    <div className="flex-column">
                      <EmailListInput
                        setHasValidationError={(valid) =>
                          setHasValidationError(valid)
                        }
                        existingEmails={existingEmails}
                        updateEmails={(emails) =>
                          addFundsGiftCheckoutStore.updateCheckout({ emails })
                        }
                        baseEmailInputText={baseEmailInputText}
                      />
                    </div>
                    <div className="option-group">
                      <input
                        type="checkbox"
                        checked={showComment}
                        onChange={() => setShowComment(!showComment)}
                      />
                      &nbsp; Add a custom message
                    </div>
                    {showComment && (
                      <textarea
                        placeholder="Enter a custom message"
                        className="textarea comment-field"
                        onChange={(ev) => {
                          addFundsGiftCheckoutStore.updateCheckout({
                            comment: ev.target.value,
                          });
                        }}
                        value={currentCheckout.comment}
                      />
                    )}
                  </Fragment>
                )}
                {!charityId && !hideGiftAmounts && (
                  <div className="bg-dark-gray flex-column flex-center">
                    <div className="amount-header bg-dark-gray text-bold">
                      {giftType === 'SELF_GIFT' && 'Amount you want to add:'}
                      {(giftType === 'USER_GIFT' || 'EMPLOYEE_GIFT') &&
                        isInviteFollowerExperience &&
                        'Add a Gift'}
                      {giftType === 'USER_GIFT' &&
                        !isInviteFollowerExperience &&
                        'Select Gift Amount'}
                    </div>
                    <AmountSelector
                      amounts={
                        giftType === 'SELF_GIFT' && companyId
                          ? [5000, 10000, 50000]
                          : [1000, 2500, 5000]
                      }
                      defaultAmount={defaultAmount}
                      selectedAmount={currentCheckout.amount}
                      onAmountUpdate={(amt) =>
                        addFundsGiftCheckoutStore.updateCheckout({
                          amount: amt,
                        })
                      }
                    />
                    {activeEntity.prepayFees && (
                      <div
                        style={{ marginBottom: '0.5rem' }}
                        className="amount-header bg-dark-gray text-bold"
                      >
                        Plus 5% Grant Processing Fee
                      </div>
                    )}
                    {currentCheckout.emails.length > 1 && (
                      <p className="option-group-subhead flex-row option-total">
                        <Currency amount={currentCheckout.amount} /> &nbsp;x{' '}
                        {currentCheckout.emails.length} =&nbsp;{' '}
                        <span className="text-bold">
                          <Currency
                            amount={
                              currentCheckout.amount *
                              currentCheckout.emails.length
                            }
                          />
                        </span>
                      </p>
                    )}
                  </div>
                )}
                {!(
                  giftType === 'EMPLOYEE_GIFT' && currentCheckout.amount === 0
                ) && (
                  <Fragment>
                    <PaymentOptionSelector
                      activeEntity={activeEntity}
                      allowNegativeBalance={activeEntity.balance?.allowNegative}
                      excludedPaymentTypes={[
                        giftType === 'SELF_GIFT' ? 'BALANCE' : '',
                        giftType === 'EMPLOYEE_GIFT' ? 'PAYPAL' : '',
                      ]}
                      store={addFundsGiftCheckoutStore}
                    />
                    <p className="check-description">
                      You can also send a check to us. We ask that the minimum
                      check size be $5,000. Send to:
                      <p className="address">Cauze Charitable Fund</p>
                      <p className="address">1306 West Eastman Street</p>
                      <p className="address">Boise, ID 83702</p>
                      In the memo line, please put the name of the account the
                      funds are going to. Please allow 5-7 days for the check to
                      process and the balance be add to your account.
                    </p>
                  </Fragment>
                )}
              </div>
            </form>
          </section>
          <footer
            className="modal-card-foot"
            style={{
              display:
                addFundsGiftCheckoutStore.showConfirmationModal && 'none',
            }}
          >
            <Button
              white
              className="donate-button is-small"
              onClick={onToggleCancel}
            >
              cancel
            </Button>
            {giftType === 'EMPLOYEE_GIFT' && (
              <Button
                className="donate-button is-small"
                onClick={(ev) => {
                  addFundsGiftCheckoutStore.updateCheckout({ pending: true });
                  handleSubmit(ev);
                }}
                disabled={
                  (currentCheckout.amount !== 0 &&
                    (!stripe || !currentCheckout.paymentType)) ||
                  (hasValidationError && giftType !== 'SELF_GIFT')
                }
                isSubmitting={addFundsGiftCheckoutStore.awaitTokenCreation}
              >
                Send Later
              </Button>
            )}
            {currentCheckout.paymentType !== 'PAYPAL' && (
              <Button
                className="donate-button is-small"
                onClick={(ev) => {
                  addFundsGiftCheckoutStore.updateCheckout({
                    pending: false,
                  });
                  handleSubmit(ev);
                }}
                isSubmitting={addFundsGiftCheckoutStore.awaitTokenCreation}
                disabled={
                  (currentCheckout.amount !== 0 &&
                    (!stripe || !currentCheckout.paymentType)) ||
                  (hasValidationError && giftType !== 'SELF_GIFT')
                }
              >
                {giftType === 'SELF_GIFT' ? 'Add Funds' : 'Send'}
                {giftType === 'EMPLOYEE_GIFT' && ' Now'}
              </Button>
            )}
          </footer>
        </>
      </div>
    </div>
  );
};

export default withUserContext(
  inject(
    'profileStore',
    'authStore',
    'uiStore',
    'addFundsGiftCheckoutStore',
  )(observer(SendUserGiftsModal)),
);
