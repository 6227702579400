/* eslint-disable react/prop-types */
import React from 'react';
import matchSorter from 'match-sorter';
import { TableCurrency } from '../UserTable/components';
import Charity from './Charity';
import ColumnHeader from '../ColumnHeader/ColumnHeader';

const charityColumns = ({ totalGood, totalMatchedPortion, totalDonations }) => [
  {
    id: 'name',
    Header: 'Charity Name',
    accessor: 'name',
    Cell: Charity,
    className: 'col-left',
    headerClassName: 'col-left',
    filterAll: true,
    filterMethod: (filter, rows) =>
      matchSorter(rows, filter.value, {
        keys: ['name', 'category', '_original.city', '_original.state'],
        threshold: matchSorter.rankings.CONTAINS,
      }),
  },
  {
    Header: 'id',
    accessor: 'id',
    className: 'is-hidden',
    headerClassName: 'is-hidden',
  },
  {
    id: 'category',
    Header: 'Category',
    accessor: 'category',
    minWidth: 35,
    className: 'col-left col-wrap is-hidden-mobile is-hidden-tablet-only',
    headerClassName: 'col-left is-hidden-mobile is-hidden-tablet-only',
  },
  {
    id: 'totalDonated',
    Header: () => <ColumnHeader title="Total Good" amount={totalGood} />,
    accessor: 'donationAmount',
    minWidth: 35,
    Cell: props => <TableCurrency value={props.value} showCents />,
    style: {
      textAlign: 'center',
    },
  },
  {
    id: 'matched',
    Header: () => (
      <ColumnHeader title="Matched Portion" amount={totalMatchedPortion} />
    ),
    accessor: 'matchAmount',
    className: 'is-hidden-mobile',
    headerClassName: 'is-hidden-mobile',
    minWidth: 35,
    Cell: props => <TableCurrency value={props.value} showCents />,
    style: {
      textAlign: 'center',
    },
  },
  {
    id: 'donationCount',
    Header: () => <ColumnHeader title="Donations" subtitle={totalDonations} />,
    accessor: 'donationCount',
    className: 'is-hidden-mobile',
    headerClassName: 'is-hidden-mobile',
    minWidth: 35,
    style: {
      textAlign: 'center',
    },
  },
];

export default charityColumns;
