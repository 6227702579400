import classNames from 'classnames';

import Currency from 'components/Currency/Currency';
import BankIcon from 'assets/images/icons/bank/icons8-bank-60.png';
import getCardIcon from 'util/getCardIcon';

const PaymentMeta = ({ currentCheckout, balance, containerClassName }) => {
  const cardPaymentTypes = ['stripe_cc', 'card'];
  const achPaymentTypes = ['stripe_ach', 'ach'];
  const _balance = balance || { total: currentCheckout?.balance };

  const _containerClassName = classNames(
    'payment-meta flex-row flex-expand flex-align-center flex-justify-center full-width',
    {
      [containerClassName]: Boolean(containerClassName),
    },
  );

  if (currentCheckout.paymentType === 'balance' && _balance?.total) {
    return (
      <div className={_containerClassName}>
        <p className="balance-total">
          Payment Method: Balance:{' '}
          <Currency amount={_balance.total} showCents />
        </p>
        <p className="divider">|</p>
        <p className="balance-remaining">
          Remaining:{' '}
          <Currency
            amount={_balance.total - currentCheckout.donationAmount}
            showCents
          />
        </p>
      </div>
    );
  }

  if (
    cardPaymentTypes.includes(`${currentCheckout.paymentType}`.toLowerCase()) &&
    currentCheckout.cardName
  ) {
    return (
      <div className={_containerClassName}>
        <p className="balance-total">Payment Method: Card:</p>
        <img
          className="card-image"
          src={getCardIcon(currentCheckout.cardBrand)}
        />
        <p className="card-total">
          {`**** **** **** ${currentCheckout.cardName}`}
        </p>
      </div>
    );
  }

  if (
    achPaymentTypes.includes(`${currentCheckout.paymentType}`.toLowerCase())
  ) {
    return (
      <div className={_containerClassName}>
        <p className="balance-total">Payment Method: ACH</p>
        <img className="bank-image" src={BankIcon} />
      </div>
    );
  }

  return <div className="payment-meta-empty"></div>;
};

export default PaymentMeta;
