import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useMatch from '../../hooks/useMatch';
import { observer, inject } from 'mobx-react';
import { toJS } from 'mobx';
import moment from 'moment';
import axios from 'axios';
import config from '../../config/';
import { getToken } from '../../util/storage';
import { navigate } from '@reach/router';
import withAuthRequired from '../../behaviors/withAuthRequired.js';

import Navbar from 'components/page/Navbar/Navbar';
import Footer from 'components/page/Footer/Footer';
import CauzeSpinner from 'components/CauzeSpinner/CauzeSpinner';
import Button from 'components/Button/Button';
import Currency from 'components/Currency/Currency';
import Avatar from 'components/Avatar/Avatar';

import companyPlaceholderImage from '../../assets/images/icons/placeholder/placeholder-business@3x.png';

const UPLOAD_URL = config.UPLOAD_ROOT;
const COMPANY_LOGO_FORM_KEY = `avatar`;

const submitCompanyLogo = ({ companyLogoFile, companyId }) => {
  try {
    getToken().then((token) => {
      const url = `${UPLOAD_URL}/company/${companyId}/${COMPANY_LOGO_FORM_KEY}`;
      const data = new FormData();
      data.append(COMPANY_LOGO_FORM_KEY, companyLogoFile);

      axios.put(url, data, {
        headers: {
          Authorization: token ? `Bearer ${token}` : null,
        },
      });
    });
  } catch (err) {
    console.log(err);
  }
};

const handleCompanyLogoChange = (e, setCompanyLogoFile, setCompanyLogoUrl) => {
  e.preventDefault();

  let reader = new FileReader();
  let file = e.target.files[0];

  reader.onloadend = () => {
    setCompanyLogoFile(file);
    setCompanyLogoUrl(reader.result);
  };

  reader.readAsDataURL(file);
};

const SponsorInviteView = ({
  id,
  _userContext,
  profileStore,
  matchStore,
  uiStore,
}) => {
  useMatch({ id });
  const [selectedUser, setSelectedUser] = useState(0);
  const [companyName, setCompanyName] = useState('');
  const [creatorRole, setCreatorRole] = useState('');
  const [balance, setBalance] = useState(0);
  const [activeEntityName, setActiveEntityName] = useState('Your');
  const [companyLogoFile, setCompanyLogoFile] = useState(null);
  const [companyLogoUrl, setCompanyLogoUrl] = useState(null);
  const [phone, setPhone] = useState('');

  const activeEntity = toJS(profileStore.activeEntity);
  const matchDetails = matchStore.matchDetails;
  const sponsorDetails = matchStore.sponsorDetails;
  const currentSponsorDetails = {
    ...matchStore.sponsorDetails,
    ...matchStore.unsavedSponsorDetails,
  };
  const matchDifference = matchStore.isSponsor
    ? -(matchStore.sponsorDetails.matchTotal - currentSponsorDetails.matchTotal)
    : currentSponsorDetails.matchTotal;

  useEffect(() => {
    if (selectedUser === 'COMPANY') {
      setBalance(0);
      setActiveEntityName(null);
      matchStore.getMatchDetail({ userContext: null }).then(() => {
        matchStore.resetSponsorDetail();
      });
      return;
    }
    if (selectedUser === 0) {
      setBalance(profileStore.data?.balance.total || 0);
      setActiveEntityName('Your');
      profileStore.clearActiveEntity();
      matchStore.getMatchDetail({
        matchId: id,
        userContext: { userId: profileStore.data?.id },
      });
    } else {
      setBalance(
        profileStore.availableUserEntities[selectedUser].balance.total,
      );
      setActiveEntityName(
        profileStore.availableUserEntities[selectedUser].name,
      );
      profileStore.setActiveEntity(selectedUser, false, false);
      matchStore.getMatchDetail({
        matchId: id,
        userContext:
          profileStore.availableUserEntities[selectedUser].userContext,
      });
    }
  }, [id, matchStore, profileStore, selectedUser, profileStore.data]);

  const submitForm = async () => {
    if (
      matchStore.isSponsor &&
      currentSponsorDetails.matchTotal <
        sponsorDetails.matchTotal - sponsorDetails.remaining
    ) {
      uiStore.showNotification({
        type: 'ERROR',
        body: 'Cannot set new max match to less than already matched amount',
        onDismiss: () => {},
      });
      return;
    }
    try {
      if (selectedUser === 'COMPANY') {
        if (!companyName || !phone) {
          uiStore.showNotification({
            type: 'ERROR',
            body: 'Please add company name and phone',
            onDismiss: () => {},
          });
          return;
        }
        await profileStore
          .userCreateCompany({
            name: companyName,
            phoneNumber: phone,
            creatorRole,
          })
          .then(async (companyId) => {
            try {
              if (companyId && companyLogoFile) {
                await submitCompanyLogo({ companyLogoFile, companyId });
              }
              uiStore.openModal('SEND_GIFTS', {
                giftType: 'SELF_GIFT',
                companyId,
                defaultAmount: matchDifference,
                userContext: { companyId },
                onSuccess: () =>
                  matchStore
                    .saveMatch({ userContext: { companyId } })
                    .then(() => navigate(`/match/${id}/success`)),
                onCancel: () =>
                  setSelectedUser(
                    profileStore.availableUserEntities.findIndex(
                      (entity) =>
                        entity.entityType === 'COMPANY' &&
                        entity.id === companyId,
                    ),
                  ),
              });
            } catch (err) {
              console.error(err);
              throw err;
            }
          });
        return;
      }
      if (
        balance < matchDifference &&
        !(
          activeEntity.entityType === 'COMPANY' &&
          activeEntity?.balance?.allowNegative
        )
      ) {
        uiStore.openModal('SEND_GIFTS', {
          giftType: 'SELF_GIFT',
          defaultAmount: matchDifference - balance,
          ...activeEntity.userContext,
          onSuccess: () =>
            matchStore.saveMatch().then(() => navigate(`/match/${id}/success`)),
        });
      } else {
        matchStore.saveMatch().then(() => navigate(`/match/${id}/success`));
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleChange = (ev) => {
    if (ev.target.value === 'DEFAULT' || ev.target.value === 'COMPANY') {
      setSelectedUser(ev.target.value);
      return;
    }
    setSelectedUser(parseInt(ev.target.value));
  };

  return (
    <div className="sponsor-invite flex-column">
      <Navbar />
      {matchDetails.matchAdmin && !matchStore.matchExpired && (
        <div className="invite-header flex-column">
          <div className="flex-row">
            <Avatar md avatar={matchDetails.matchAdmin.avatar} />
            <div className="flex-column">
              <h1 className="text-title">
                Offer a match to{' '}
                {matchDetails.events && matchDetails.events[0].name}
              </h1>
              <div className="text-regular flex-row">
                {matchDetails.matchAdmin.name} invited you to match this cauze
              </div>
            </div>
          </div>
        </div>
      )}
      {matchStore.matchExpired && (
        <div className="invite-header flex-column">
          <div className="flex-row">
            <Avatar md avatar={matchDetails.matchAdmin.avatar} />
            <div className="flex-column">
              <h1 className="text-title">Your Match Invite</h1>
              <div className="text-regular flex-row">
                We're sorry, the match you were invited to has expired. Please
                ask {matchDetails.matchAdmin.name} to adjust the expiration
                date.
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="sponsor-padding flex-justify-center">
        <h1 className="text-bold text-center">Match Details</h1>
        <div className="text-regular text-center">
          This match is being offered by:
        </div>
        <form
          className="matchsponsor-form flex-justify-center"
          onSubmit={(e) => {
            e.preventDefault();
            submitForm();
          }}
        >
          <div className="control user-list flex-column">
            {profileStore.availableUserEntities.map((entity, index) => {
              if (
                entity.entityType !== 'COMPANY' &&
                entity.entityType !== 'INFLUENCER'
              ) {
                return null;
              }
              return (
                <label
                  className="user-item"
                  htmlFor={
                    index === 0 ? 'userRadioDefault' : `userRadio${index}`
                  }
                >
                  <input
                    type="radio"
                    value={index}
                    id={`userRadio${index}`}
                    name="user"
                    checked={selectedUser === index}
                    onChange={handleChange}
                  />
                  {entity.name}
                </label>
              );
            })}
            <label className="radio" htmlFor="createUser">
              <input
                type="radio"
                value="COMPANY"
                name="user"
                id="createUser"
                checked={selectedUser === 'COMPANY'}
                onChange={handleChange}
              />
              New Company or Organization
            </label>
          </div>
          {selectedUser === 'COMPANY' && (
            <div className="company-inputs">
              <div className="field-title">Company or Organization Name</div>
              <input
                placeholder="Company Name"
                value={companyName}
                name="user"
                id="company-name"
                className="text-input"
                onChange={(e) => setCompanyName(e.target.value)}
              />
              <div className="field-title">Your Role</div>
              <input
                placeholder="Your Role"
                value={creatorRole}
                name="creator-role"
                id="creator-role"
                className="text-input"
                onChange={(e) => setCreatorRole(e.target.value)}
              />
              <div className="field-title">Phone Number</div>
              <input
                placeholder="Phone Number"
                value={phone}
                name="user"
                id="phone"
                className="text-input"
                onChange={(e) => setPhone(e.target.value)}
              />
              <div className="logo-text text-regular">Company Logo</div>
              <div className="field-title">
                Your profile image will display next to your match offer.
              </div>
              <div className="logo-upload flex-row">
                <img
                  className="profile-preview-image"
                  src={companyLogoUrl || companyPlaceholderImage}
                />
                <input
                  className="fileInput"
                  type="file"
                  onChange={(e) =>
                    handleCompanyLogoChange(
                      e,
                      setCompanyLogoFile,
                      setCompanyLogoUrl,
                    )
                  }
                />
              </div>
            </div>
          )}
          <hr />
          {selectedUser !== 'COMPANY' && (
            <div className="balance flex-column text-center">
              <span>
                {activeEntityName} Balance:{' '}
                <Currency amount={balance} showCents /> -{' '}
                <strong>
                  <a
                    href="#"
                    onClick={() => {
                      if (selectedUser === 'DEFAULT') {
                        profileStore.clearActiveEntity();
                      } else {
                        profileStore.setActiveEntity(selectedUser);
                      }
                      uiStore.openModal('SEND_GIFTS', {
                        giftType: 'SELF_GIFT',
                        defaultAmount: matchDifference,
                        ...activeEntity.userContext,
                        onSuccess: (amt) => setBalance(balance + amt),
                      });
                    }}
                  >
                    Add Funds
                  </a>
                </strong>
              </span>
            </div>
          )}
          {matchStore.isLoading ? (
            <CauzeSpinner fullscreen />
          ) : (
            <div className="flex-column flex-align-center">
              <h1 className="text-center">
                What is the{' '}
                {matchStore.sponsorDetails.matchTotal ? (
                  <strong>new</strong>
                ) : (
                  ''
                )}{' '}
                total amount you would like to offer as a match?{' '}
              </h1>
              {matchStore.isSponsor && (
                <div className="text-center">
                  (<Currency amount={currentSponsorDetails.remaining} /> of{' '}
                  <Currency amount={sponsorDetails.matchTotal} /> remains)
                </div>
              )}
              <div className="amount-input flex-row">
                $
                <input
                  onChange={(ev) => {
                    matchStore.updateSponsorDetail({
                      matchTotal: parseInt(ev.target.value) * 100,
                    });
                  }}
                  value={
                    currentSponsorDetails.matchTotal
                      ? currentSponsorDetails.matchTotal / 100
                      : 0
                  }
                />
              </div>
              <div className="match-detail text-center">
                <h2>Match Availability</h2>
                {matchDetails.matchType === 'FOLLOWER' ? (
                  <p>
                    {matchDetails.matchAdmin.name}'s followers can receive the
                    match.
                  </p>
                ) : (
                  <p>Anyone can receive the match.</p>
                )}
              </div>
              <div className="match-detail text-center">
                <h2>Max match per giver</h2>
                <p>
                  <Currency amount={matchDetails.userMatchLimit} />
                </p>
              </div>
              {matchDetails.endDate && (
                <div className="match-detail text-center">
                  <h2>Match Expiration</h2>
                  <p>{moment(matchDetails.endDate).format('MM/DD/YYYY')}</p>
                </div>
              )}
              <div
                style={{ fontSize: '0.75rem' }}
                className="match-detail text-center"
              >
                <p>
                  If any funds remain, they will be added to your balance where
                  you can donate, send gifts and start new matches.
                </p>
              </div>
            </div>
          )}
          <Button
            disabled={!matchDifference || matchStore.matchExpired}
            onClick={submitForm}
          >
            {currentSponsorDetails.matchTotal === 0 || matchDifference < 0
              ? 'Save Match Details'
              : 'Complete Match Checkout'}
          </Button>
        </form>
        <div className="fine-print flex-row flex-center text-light">
          Your contribution is being made to Cauze Charitable Fund, a nonprofit,
          donor advised fund, which will distribute your donation to the
          nonprofit organization(s) that you indicated. As required by the
          Internal Revenue Service (“IRS”), Cauze Charitable Fund has exclusive
          legal control over the donation. Your contribution is tax-deductible
          to the extent allowed by law. No benefit has been bestowed upon the
          donor in exchange for this contribution. The Cauze Charitable Fund EIN
          is 45-4602256.
        </div>
      </div>
      <Footer hideGetAppButton short />
    </div>
  );
};

SponsorInviteView.propTypes = {
  id: PropTypes.string,
  userContext: PropTypes.object,
  profileStore: PropTypes.object.isRequired,
  matchStore: PropTypes.object.isRequired,
  uiStore: PropTypes.object.isRequired,
};

export default withAuthRequired(
  inject('profileStore', 'matchStore', 'uiStore')(observer(SponsorInviteView)),
);
