import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import branch from 'branch-sdk';

import Navbar from 'components/page/Navbar/Navbar';
import Footer from 'components/page/Footer/Footer';
import Button from 'components/Button/Button';
import Currency from 'components/Currency/Currency';

import appIcon from '../../assets/images/brand/app-icon.png';

const sendSMS = async ({ phoneNumber, token, callback }) => {
  const url = token ? `https://cauze.app.link/token/${token}` : null;
  const linkData = {
    tags: [],
    channel: 'Website',
    feature: 'TextMeTheApp',
    data: {},
  };
  if (url) {
    linkData.data = { $ios_deeplink_path: url, $android_deeplink_path: url };
  }
  const options = {};
  branch.sendSMS(phoneNumber, linkData, options, callback);
};

const DownloadAppView = ({ uiStore }) => {
  const [phoneInputVal, setPhoneInputVal] = useState('');

  const handleSubmit = (ev) => {
    ev.preventDefault();
    sendSMS({
      phoneNumber: phoneInputVal,
      token: uiStore.savedUiState.userToken?.token,
      callback: (err, _res) => {
        if (err) {
          alert('Sorry, something went wrong.');
        } else {
          alert(
            'Success! Check your phone because we just sent you a text with a link to the app!',
          );
          setPhoneInputVal('');
        }
      },
    });
  };

  return (
    <div className="download-app flex-column">
      <Navbar cobrand={uiStore.savedUiState.userToken?.company} />
      <div className="download-background flex-column flex-align-center">
        <img className="app-icon" src={appIcon} />
        {uiStore.savedUiState.userToken ? (
          <div className="flex-column flex-align-center">
            <div className="title">Almost Done!</div>
            <p>
              To fund your account with your{' '}
              <strong>
                <Currency amount={uiStore.savedUiState.userToken.gift.amount} />{' '}
                balance
              </strong>
              , enter your phone number below, to receive a Cauze app download
              link.{' '}
            </p>
          </div>
        ) : (
          <div className="flex-column flex-align-center">
            <div className="title">Download Cauze</div>
            <p>Enter your phone number below to download Cauze.</p>
          </div>
        )}
        <form onSubmit={handleSubmit}>
          <div className="flex-row">
            <input
              className="input"
              placeholder="(555) 555-5555"
              onChange={(e) => setPhoneInputVal(e.target.value)}
              value={phoneInputVal}
            />
            <Button type="submit">Submit</Button>
          </div>
        </form>
      </div>
      <Footer cobrand={uiStore.savedUiState.userToken?.company} />
    </div>
  );
};

DownloadAppView.propTypes = {
  uiStore: PropTypes.object.isRequired,
};

export default inject('uiStore')(observer(DownloadAppView));
