const MultipleItemMessage = ({
  messageStart,
  messageItems,
  messageEnd,
}) => {
  console.log('messageItems', messageItems);
  let messageReturn = messageStart || '';
  const messageItemsLength = messageItems.length;
  messageItems.sort().forEach((item, index) => {
    if (item) {
      if (index <= messageItemsLength - 3) {
        messageReturn += (`${item}, `);
      }
      if (index === messageItemsLength - 2) {
        messageReturn += (`${item} and `);
      }
      if (index === messageItemsLength - 1) {
        messageReturn += item;
      }
    }
  });
  messageReturn += messageEnd || '';

  return messageReturn;
};

export default MultipleItemMessage;
