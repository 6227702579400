import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Button/Button';
import useKeyboardEvent from '../../../hooks/useKeyboardEvent.js';
// import { observer } from 'mobx-react';

import classnames from 'classnames';

const ThanksModal = ({ isOpen, onToggleClose, thanksCount, action }) => {
  const [commentText, setCommentText] = useState('');

  useKeyboardEvent('Escape', () => {
    onToggleClose(false);
  });

  return (
    <div
      className={classnames('modal', {
        'is-active': isOpen,
      })}
    >
      <div className="modal-background"></div>
      <div className="modal-card thanks-modal">
        <header className="modal-card-head">
          <p className="modal-card-title">Say Thanks</p>
          <button
            className="delete"
            aria-label="close"
            onClick={onToggleClose}
          ></button>
        </header>
        <section className="modal-card-body">
          <div className="option-group">
            <p className="option-info is-size-6">
              There is no strategy more effective than saying thank you. It is
              outrageously simple, but we’ve found that nonprofits that thank
              their donors are more likely to create repeat donors and intrigue
              users who are unfamiliar with your organization. The giver also
              receives a notification when you comment on their post!
            </p>
          </div>
          <div className="option-group">
            <p className="option-group-subhead is-size-6">
              You are thanking {thanksCount} giver{thanksCount > 1 ? 's' : ''}
            </p>
            <p className="option-group-subhead is-size-5">Message:</p>
            <div className="control">
              <textarea
                placeholder="Enter a thank you message"
                className="textarea comment-field"
                onChange={(ev) => {
                  setCommentText(ev.target.value);
                }}
                value={commentText}
              />
            </div>
          </div>
        </section>
        <footer className="modal-card-foot">
          <button className="button is-small" onClick={onToggleClose}>
            Cancel
          </button>
          <Button
            disabled={commentText.length === 0}
            className="button is-primary is-small"
            onClick={() => {
              action(commentText);
              onToggleClose(false);
            }}
          >
            Thank {thanksCount} giver{thanksCount > 1 ? 's' : ''}
          </Button>
        </footer>
      </div>
    </div>
  );
};

ThanksModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  thanksCount: PropTypes.number,
  onToggleClose: PropTypes.func.isRequired,
  action: PropTypes.func,
};

export default ThanksModal;
