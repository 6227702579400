import { SocialLinks } from 'social-links';
import { linkify } from './stringUtils.js';

const scrubLink = (text) => {
  const links = linkify.find(text);
  if (links.length > 0 && links[0].href) {
    return links[0].href;
  }
  return null;
};

const socialLinks = new SocialLinks({
  usePredefinedProfiles: true,
  trimInput: true,
  allowQueryParams: false,
});

const sanitizeAccounts = ({
  youtubeUrl,
  webUrl,
  facebookName,
  instagramName,
  twitterName,
}) => ({
  youtubeUrl: youtubeUrl
    ? socialLinks.sanitize('youtube', youtubeUrl.replace('/c/', '/channel/'))
    : null,
  twitterName: twitterName
    ? socialLinks.getProfileId('twitter', twitterName)
    : null,
  facebookName: facebookName
    ? socialLinks.getProfileId('facebook', facebookName)
    : null,
  instagramName: instagramName
    ? socialLinks.getProfileId('instagram', instagramName)
    : null,
  webUrl: webUrl ? scrubLink(webUrl) : null,
});

const sanitizeLink = (url, socialType) => {
  if (!url || !socialType) return null;

  if (socialType === 'website') {
    return scrubLink(url);
  }

  if (socialType === 'youtube') {
    return socialLinks.sanitize('youtube', url.replace('/c/', '/channel/'));
  }

  return socialLinks.sanitize(socialType, url);
};

const validateLink = (url, socialType) => {
  if (!url) return true;
  if (!socialType) return false;

  if (socialType === 'website') {
    return Boolean(scrubLink(url));
  }

  if (socialType === 'youtube') {
    return socialLinks.isValid('youtube', url.replace('/c/', '/channel/'));
  }

  return socialLinks.isValid(socialType, url);
};

const validateAccounts = ({
  youtubeUrl,
  webUrl,
  facebookName,
  instagramName,
  twitterName,
}) => ({
  ...(webUrl ? { webUrl: !!scrubLink(webUrl) } : {}),
  ...(youtubeUrl
    ? {
        youtubeUrl: socialLinks.isValid(
          'youtube',
          youtubeUrl.replace('/c/', '/channel/'),
        ),
      }
    : { youtubeUrl: true }),
  ...(twitterName
    ? {
        twitterName: socialLinks.isValid('twitter', twitterName),
      }
    : { twitterName: true }),
  ...(facebookName
    ? {
        facebookName: socialLinks.isValid('facebook', facebookName),
      }
    : { facebookName: true }),
  ...(instagramName
    ? {
        instagramName: socialLinks.isValid('instagram', instagramName),
      }
    : { instagramName: true }),
});

const emailValidate = (value) => {
  const isValid =
    value !== '' &&
    !(value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value));
  return isValid;
};

const zipValidate = (value) => {
  const isValid = !(value && !/^[0-9]{5}(?:-[0-9]{4})?$/i.test(value));
  return isValid;
};

const checkoutFormValidate = ({
  firstName,
  lastName,
  email,
  charityId,
  charityIds,
  onNoCharitySelected,
}) => {
  const validationObj = {
    firstName: true,
    lastName: true,
    email: true,
    isValid: true,
  };
  if (!firstName) {
    validationObj.firstName = false;
    validationObj.isValid = false;
  }
  if (!lastName) {
    validationObj.lastName = false;
    validationObj.isValid = false;
  }
  if (!email || !emailValidate(email)) {
    validationObj.email = false;
    validationObj.isValid = false;
  }
  if (charityIds.length === 0 && !charityId) {
    validationObj.isValid = false;
    onNoCharitySelected();
  }

  return validationObj;
};

const userCheckoutFormValidate = ({ firstName, lastName, email, card }) => {
  const validationObj = {
    firstName: true,
    lastName: true,
    email: true,
    isValid: true,
  };
  if (!firstName) {
    validationObj.firstName = false;
    validationObj.isValid = false;
  }
  if (!lastName) {
    validationObj.lastName = false;
    validationObj.isValid = false;
  }
  if (!email || !emailValidate(email)) {
    validationObj.email = false;
    validationObj.isValid = false;
  }
  if (card && !card._complete) {
    validationObj.card = false;
    validationObj.isValid = false;
  }

  return validationObj;
};

const signupValidate = ({ firstName, lastName, email, password }) => {
  const validationObj = {
    firstName: true,
    lastName: true,
    email: true,
    password: true,
    isValid: true,
  };
  if (!firstName) {
    validationObj.firstName = false;
    validationObj.isValid = false;
  }
  if (!lastName) {
    validationObj.lastName = false;
    validationObj.isValid = false;
  }
  if (!email || !emailValidate(email)) {
    validationObj.email = false;
    validationObj.isValid = false;
  }

  if (!password || password.length < 8) {
    validationObj.password = false;
    validationObj.isValid = false;
  }

  return validationObj;
};

export {
  scrubLink,
  sanitizeAccounts,
  validateAccounts,
  emailValidate,
  zipValidate,
  checkoutFormValidate,
  userCheckoutFormValidate,
  signupValidate,
  sanitizeLink,
  validateLink,
};
