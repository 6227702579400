import React from 'react';
import PropTypes from 'prop-types';

import CauzesView from './CauzesView';
import withAuthRequired from '../../behaviors/withAuthRequired';
import useCauzes from '../../hooks/useCauzes';

const CharityCauzesView = ({ charityId }) => {
  useCauzes({ userContext: { charityId } });

  return <CauzesView userContext={{ charityId }} />;
};

CharityCauzesView.propTypes = {
  charityId: PropTypes.string,
};

export default withAuthRequired(CharityCauzesView, {
  entityType: 'CHARITY',
});
