import cx from 'classnames';

import venmoBtn from 'assets/images/icons/venmo/venmo-logo.png';

const VenmoButton = ({ children, className, ...props }) => {
  const btnClassName = cx(
    '!p-0 h-10 bg-venmo-blue border-none rounded-md cursor-pointer flex justify-center',
    {
      [className]: className,
    },
  );

  return (
    <button className={btnClassName} {...props}>
      {children} <img className="h-6 my-auto" src={venmoBtn} />
    </button>
  );
};

export default VenmoButton;
