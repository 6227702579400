const appColors = {
  // brand - orange - #CE410D
  brand: 'rgb(207, 65, 13)',
  brandFocus: 'rgb(241, 91, 39)',
  brandFade: 'rgba(241, 91, 38, 0.3)',

  // complement - blue - #1333EA
  complement: 'rgb(19, 51, 235)',
  complementFocus: 'rgb(66, 92, 238)',
  complementFade: 'rgba(19, 51, 234, 0.2)',

  // support - deepPurple - #171643
  support: 'rgb(23, 22, 68)',
  supportFocus: 'rgb(68, 68, 105)',
  supportFade: 'rgba(19, 51, 234, 0.2)',

  lilac: 'rgb(224, 183, 214)',

  black: 'rgb(27, 32, 49)',
  black70: 'rgba(27, 32, 49, 0.7)',
  black50: 'rgba(27, 32, 49, 0.5)',
  black30: 'rgba(27, 32, 49, 0.3)',
  grey: 'rgb(104, 112, 137)',
  grey75: 'rgba(104, 112, 137, 0.75)',
  grey50: 'rgba(104, 112, 137, 0.5)',
  grey30: 'rgba(104, 112, 137, 0.3)',
  lightGrey: 'rgb(241, 241, 242)',
  offWhite2: 'rgb(245, 245, 245)',
  offWhite: 'rgb(247, 247, 247)',
  white: 'rgb(255, 255, 255)',

  // green
  success: '#038164',
  // yellow
  warning: '#FFC300',
  // red
  error: '#E60054',

  purple: 'rgb(95, 0, 199)',
  chatBackground: 'rgb(245, 245, 245)',
  eventHeaderShadow: 'rgba(80, 80, 80, .6)',
  topics: ['#E60054', '#FC6949', '#F8A963', '#3EC28F', '#4BB6D8', '#755EF2'],
};

export default appColors;
