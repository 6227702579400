/* eslint-disable no-console */
import debug from '../util/debug';
import { observable, action, makeObservable } from 'mobx';
import profileStore from './ProfileStore';
import uiStore from './UiStore';
import eventStore from './EventStore';
import donationLandingStore from './DonationLandingStore';
import mixpanel from 'mixpanel-browser';

import {
  getToken,
  setToken,
  removeToken,
  getUsername,
  setUsername,
  removeUsername,
} from '../util/storage';
import { client as apolloClient } from '../util/apolloClient';
import { client as api2Client } from '../util/api2Client';

import {
  loginMutation,
  userSignUpMutation,
  authorizedQuery,
  companyTokenDetailsQuery,
  sendPasswordReset,
  passwordResetTokenValidate,
  resetPassword,
} from '../graphql/auth';
import { giftTokenQuery } from 'graphql/gift';

class AuthStore {
  @observable isAuthenticated = false;
  @observable isFetchingToken = false;
  @observable isLoggingIn = false;
  @observable authError = false;
  @observable username = '';
  @observable createUserErrors = {
    emailTakenOrInvalid: false,
    backupEmailTakenOrInvalid: false,
  };
  @observable newAccount = false;
  @observable stashedPathDetails = {};
  @observable loadingTokenData = false;
  @observable companyInviteDetails = null;
  @observable giftTokenDetails = null;
  @observable passwordResetTokenValidateResponse = '';

  @action
  onAppLoadFetch = async () => {
    try {
      await this.fetchToken();
      await this.fetchUsername();
    } catch (error) {
      console.log('Application load failed', error);
      this.logout({ error });
    }
  };

  @action
  fetchToken = async () => {
    this.isFetchingToken = true;
    const token = await getToken();
    if (token) {
      const tokenValidResponse = await api2Client.query({
        query: authorizedQuery,
        fetchPolicy: 'network-only',
        errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
      });
      if (tokenValidResponse.data.authorized && !tokenValidResponse.errors) {
        this.isAuthenticated = true;
        setToken(tokenValidResponse.data.authorized.refreshToken);
      } else {
        this.isAuthenticated = false;
        removeToken();
      }
    } else {
      this.isAuthenticated = false;
    }
    this.isFetchingToken = false;
  };

  @action
  fetchUsername = async () => {
    const username = await getUsername();
    if (username) this.username = username;
  };

  @action
  changeAuthentication = (login) => {
    if (login) {
      this.isAuthenticated = true;
      if (
        uiStore.savedUiState?.userToken?.token &&
        !this.companyInviteDetails // those tokens get processed by creating an account
      ) {
        donationLandingStore.loadGiftData({
          token: uiStore.savedUiState.userToken.token,
        });
      }

      this.resetAuthState();
    } else {
      this.logout();
    }
  };

  @action
  resetAuthState = () => {
    this.isFetchingToken = false;
    this.isLoggingIn = false;
    this.authError = false;
    this.companyInviteDetails = null;
    this.giftTokenDetails = null;
    this.loadingTokenData = false;
    this.passwordResetTokenValidateResponse = '';
  };

  @action sendPasswordReset = async (email) => {
    mixpanel.track('Password reset', {
      email,
    });

    const variables = {
      email,
    };

    const options = {
      variables,
      mutation: sendPasswordReset,
      fetchPolicy: 'no-cache',
      errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
    };

    try {
      await api2Client.mutate(options);
    } catch (err) {
      if (global.IS_LOCAL_OR_DEV) {
        console.log(err);
      }
    }
  };

  @action signup = async ({
    email,
    backupEmail,
    firstName,
    lastName,
    password,
    zip,
  }) => {
    this.isLoggingIn = true;
    try {
      const options = {
        variables: {
          email: email.trim().toLowerCase(),
          firstName,
          lastName,
          password,
          zip,
          backupEmail,
        },
        mutation: userSignUpMutation,
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      };

      const loginResult = await apolloClient.mutate(options);
      if (loginResult.errors && loginResult.errors.length > 0) {
        throw loginResult.errors;
      }
      setToken(loginResult.data.userSignUp.token);
      setUsername(loginResult.data.userSignUp.me.username);

      this.changeAuthentication(true);
      await profileStore.getProfile();

      mixpanel.identify(profileStore.data.email);
      mixpanel.track('Signed Up', {
        email: profileStore.data.email,
        username: loginResult.data.userSignUp.me.username,
        firstName,
        lastName,
        zip,
      });

      mixpanel.people.set({
        $name: `${firstName} ${lastName}`,
        $email: profileStore.data.email,
        'First Name': firstName,
        'Last Name': lastName,
        Email: profileStore.data.email,
        Username: loginResult.data.userSignUp.me.username,
        Zip: zip,
        'Signup Date': new Date(),
      });

      this.username = loginResult.data.userSignUp.me.username;
      this.newAccount = true;
    } catch (errors) {
      mixpanel.track('Signup Fail', {
        email,
        firstName,
        lastName,
        zip,
      });

      if (global.IS_LOCAL_OR_DEV) {
        console.log(errors);
      }
      this.isLoggingIn = false;
      if (Array.isArray(errors)) {
        errors.forEach((err) => {
          if (err.message === 'has already been taken') {
            if (err.key === 'email') {
              this.createUserErrors.emailTakenOrInvalid = true;
            }
          }
          if (err.message === 'has invalid format') {
            if (err.key === 'email') {
              this.createUserErrors.emailTakenOrInvalid = true;
            }
          }
          if (err.message === 'has already been taken') {
            if (err.key === 'backup_email') {
              this.createUserErrors.backupEmailTakenOrInvalid = true;
            }
          }
          if (err.message === 'has invalid format') {
            if (err.key === 'backup_email') {
              this.createUserErrors.backupEmailTakenOrInvalid = true;
            }
          }
        });
      }
      return false;
    }
  };

  @action
  login = async (username, password) => {
    this.resetAuthState();
    this.isLoggingIn = true;

    const options = {
      variables: { identifier: username, password },
      mutation: loginMutation,
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    };

    // Doesn't need to subscribe unlike client.watchQuery
    let loginResult = await api2Client.mutate(options);
    if (loginResult.errors?.length > 0) {
      mixpanel.track('Login Fail', {
        username,
        error: loginResult.errors[0].message,
      });

      this.authError = true;
      this.isLoggingIn = false;
      debug(loginResult.errors[0].message);
      throw loginResult.errors[0].message;
    } else {
      loginResult = loginResult.data.authenticate;
      debug(loginResult);

      setToken(loginResult.token);
      setUsername(loginResult.me.username);
      this.username = loginResult.me.username;
      this.changeAuthentication(true);
    }

    this.isLoggingIn = false;
  };

  @action loadTokenData = async ({ token }) => {
    this.loadingTokenData = true;

    const options = {
      variables: {
        token,
      },
      query: companyTokenDetailsQuery,
      fetchPolicy: 'no-cache',
      errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
    };

    let error = '';
    try {
      this.companyInviteDetails = await apolloClient
        .query(options)
        .then((res) => res.data.userToken);
    } catch (err) {
      error = err;
      console.log('loadTokenData failed', error);
    }

    mixpanel.track('Token Loaded', {
      token,
      error,
    });
    this.loadingTokenData = false;
  };

  @action loadGiftTokenData = async ({ token }) => {
    this.loadingTokenData = true;

    const options = {
      variables: {
        token,
      },
      query: giftTokenQuery,
      fetchPolicy: 'no-cache',
      errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
    };

    let error = '';
    try {
      this.giftTokenDetails = await api2Client
        .query(options)
        .then((res) => res.data.giftToken);
    } catch (err) {
      error = err;
    }

    mixpanel.track('Token Loaded', {
      token,
      error,
    });
    this.loadingTokenData = false;
  };

  @action
  stashPathDetails = (stashedPathDetails) => {
    this.stashedPathDetails = stashedPathDetails;
  };

  @action unstashPathDetails = () => {
    let unstashedPath = '/';
    if (this.stashedPathDetails.path) {
      unstashedPath = this.stashedPathDetails.path;
    }
    this.stashedPathDetails = {};
    return unstashedPath;
  };

  @action logout = (logoutParams) => {
    mixpanel.track('Logged Out', {
      error: logoutParams?.error,
    });
    mixpanel.reset();

    uiStore.saveUiState({ giftAmount: null });
    this.isAuthenticated = false;

    profileStore.onUnmount();
    eventStore.onUnmount();

    removeToken();
    removeUsername();

    this.resetAuthState();
    this.isAuthenticated = false;
  };

  constructor() {
    makeObservable(this);
  }

  @action passwordResetTokenValidate = async (token) => {
    mixpanel.track('Reset Password Token Validate');
    mixpanel.reset();

    const variables = {
      token,
    };

    const options = {
      variables,
      query: passwordResetTokenValidate,
      fetchPolicy: 'no-cache',
      errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
    };

    try {
      const result = await api2Client.query(options);
      this.passwordResetTokenValidateResponse =
        result.data.passwordResetTokenValidate.status;
    } catch (err) {
      if (global.IS_LOCAL_OR_DEV) {
        console.log(err);
      }
    }
  };

  @action resetPassword = async (password, token) => {
    mixpanel.track('Reset Password');
    mixpanel.reset();

    const variables = {
      password,
      token,
    };

    const options = {
      variables,
      mutation: resetPassword,
      fetchPolicy: 'no-cache',
      errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
    };

    try {
      const result = await api2Client.mutate(options);
      const email = result?.data?.resetPassword?.email;
      await this.login(email, password);
    } catch (err) {
      if (global.IS_LOCAL_OR_DEV) {
        console.log(err);
      }
    }
  };
}

const authStore = new AuthStore();
export default authStore;
