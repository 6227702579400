import React, { useState, Fragment } from 'react'; // Fragment
import PropTypes from 'prop-types';
import { Link } from '@reach/router';
import { getAppLink } from '../../../util/userAgent';
import { headerDownloadLinkPress } from '../../../util/events/nav';
import { observer, inject } from 'mobx-react';
import withUserContext from '../../../behaviors/withUserContext';

import CauzeButton from 'components/CauzeButton/CauzeButton';
import UserDropDown from './UserDropDown';
import LoginModal from '../../modals/LoginModal/LoginModal';
import Currency from '../../Currency/Currency';
import logo from '../../../assets/images/brand/cauze-logotype-light.svg';

import giftIcon from '../../../assets/images/icons/gift/gift-box-color.png';

const GiftCard = ({ amount }) => (
  <Link className="gift-card" to="/donate">
    <img className="gift-icon" src={giftIcon} />
    {amount && (
      <Currency amount={amount} superCents className="text-title gift-amount" />
    )}
  </Link>
);

GiftCard.propTypes = {
  amount: PropTypes.number,
};

const Navbar = ({
  authStore,
  hideLoginPrompt,
  uiStore,
  cobrand, // this is a company object
  activeEntity,
  hideContextSelector = false,
  redirectAfterContextSelect = true,
}) => {
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [loginTab, setLoginTab] = useState(1);
  // cauze is id: 1, hide if cauze
  const isCobrand = cobrand && cobrand.id !== '1';
  const isAuthenticated = authStore.isAuthenticated;

  return (
    <nav
      className="navbar is-fixed-top shadow-deep flex-row"
      role="navigation"
      aria-label="main navigation"
    >
      {global.IS_DEV ? <div className="env-indicator dev">DEV</div> : null}
      {global.IS_LOCAL ? (
        <div className="env-indicator local">LOCAL</div>
      ) : null}
      <LoginModal
        isOpen={showLoginModal}
        onToggleClose={() => setShowLoginModal(false)}
        defaultTab={loginTab}
      />
      <div className="navbar-content">
        <div className="navbar-brand">
          <a
            onClick={headerDownloadLinkPress}
            href={'/donate'}
            className="navbar-item cauze-gradient"
          >
            {isCobrand && cobrand.logo ? (
              <div className="cobrand">
                <img
                  src={cobrand.logo.original}
                  className="app-logo app-logo-cobrand"
                  alt={cobrand.name}
                />
                <div className="powered-by">
                  Powered by
                  <img src={logo} className="app-logo" alt="Cauze" />
                </div>
              </div>
            ) : (
              <img src={logo} className="app-logo" alt="Cauze" />
            )}
          </a>
          {isAuthenticated ? (
            <UserDropDown
              id="user-dropdown-mobile"
              redirectAfterContextSelect={redirectAfterContextSelect}
              hideContextSelector={hideContextSelector}
              className="navbar-item is-hidden-desktop"
              activeEntity={activeEntity}
            />
          ) : (
            <Fragment>
              <div className="navbar-flex" />
              {uiStore.savedUiState.userToken?.gift && (
                <div className="navbar-item gift-container is-hidden-desktop">
                  <GiftCard
                    amount={
                      !uiStore.savedUiState.userToken.gift.hideAmount &&
                      uiStore.savedUiState.userToken.gift?.amount
                    }
                  />
                </div>
              )}
              <div className="navbar-item get-cauze is-hidden-desktop">
                {!hideLoginPrompt ? (
                  <div className="flex-row flex-center">
                    <CauzeButton
                      id="nav-login-button-mobile"
                      support
                      style={{ marginRight: '0.5rem' }}
                      onClick={() => {
                        setLoginTab(1);
                        setShowLoginModal(true);
                      }}
                    >
                      Login
                    </CauzeButton>
                    <CauzeButton
                      onClick={() => {
                        setLoginTab(0);
                        setShowLoginModal(true);
                      }}
                    >
                      Signup
                    </CauzeButton>
                  </div>
                ) : (
                  <CauzeButton
                    onClick={headerDownloadLinkPress}
                    href={getAppLink()}
                  >
                    {uiStore.hasApp ? 'Open in Cauze' : 'Download App'}
                  </CauzeButton>
                )}
              </div>
            </Fragment>
          )}
        </div>
        {isAuthenticated ? (
          <div className="navbar-menu">
            <UserDropDown
              id="user-dropdown-desktop"
              activeEntity={activeEntity}
            />
          </div>
        ) : (
          <div className="navbar-menu">
            <div className="navbar-end">
              {uiStore.savedUiState.userToken?.gift && (
                <div className="navbar-item gift-container">
                  <GiftCard
                    amount={
                      !uiStore.savedUiState.userToken.gift.hideAmount &&
                      uiStore.savedUiState.userToken.gift?.amount
                    }
                  />
                </div>
              )}
              <div className="navbar-item">
                {!hideLoginPrompt ? (
                  <div className="flex-row flex-center">
                    {/* <div className="login-prompt">
                    Already have an account?&nbsp;
                  </div>
                  */}
                    <CauzeButton
                      id="nav-login-button-desktop"
                      support
                      style={{ marginRight: '0.5rem' }}
                      onClick={() => {
                        setLoginTab(1);
                        setShowLoginModal(true);
                      }}
                    >
                      Login
                    </CauzeButton>
                    <CauzeButton
                      onClick={() => {
                        setLoginTab(0);
                        setShowLoginModal(true);
                      }}
                    >
                      Signup
                    </CauzeButton>
                  </div>
                ) : (
                  <CauzeButton
                    onClick={headerDownloadLinkPress}
                    href={getAppLink()}
                  >
                    {uiStore.hasApp ? 'Open in Cauze' : 'Download App'}
                  </CauzeButton>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

Navbar.defaultProps = {
  buttonLink: 'http://www.cauze.com',
  activeEntity: {},
};

Navbar.propTypes = {
  hideLoginPrompt: PropTypes.bool,
  buttonLink: PropTypes.string,
  profileStore: PropTypes.shape({
    activeEntity: PropTypes.object,
  }).isRequired,
  authStore: PropTypes.shape({
    isAuthenticated: PropTypes.bool.isRequired,
    username: PropTypes.string.isRequired,
  }).isRequired,
  uiStore: PropTypes.object.isRequired,
  activeEntity: PropTypes.object,
  cobrand: PropTypes.object,
  hideContextSelector: PropTypes.bool,
  redirectAfterContextSelect: PropTypes.bool,
};

export default withUserContext(
  inject(
    'uiStore',
    'authStore',
    'eventStore',
    'profileStore',
  )(observer(Navbar)),
);
