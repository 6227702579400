import { useState, useEffect } from 'react';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Redirect } from '@reach/router';
import { observer, inject } from 'mobx-react';

import Avatar from 'components/Avatar/Avatar';

import { ReactComponent as CauzeLogo } from 'assets/images/brand/cauze-logo.svg';
import { ReactComponent as GiftIcon } from 'assets/images/icons/gift/gift-box.svg';
import { ReactComponent as SurpriseIcon } from 'assets/images/icons/svgs/surprise.svg';
import Currency from 'components/Currency/Currency';
import CreateAccountForm from './CreateAccountForm';
import CauzeButton from 'components/CauzeButton/CauzeButton';
import CauzeSpinner from 'components/CauzeSpinner/CauzeSpinner';

import { emailValidate, zipValidate } from 'util/formFieldValidators';

const validateCreateUser = ({ user, _fieldIsValid, setFieldIsValid }) => {
  if (!user) {
    return false;
  }
  const fieldValidators = {
    firstName: user.firstName.length !== 0,
    lastName: user.lastName.length !== 0,
    email: emailValidate(user.email),
    zip: zipValidate(user.zip),
    password: user.password.length > 7,
    hasAcceptedTerms: Boolean(user.hasAcceptedTerms),
  };

  let userValid =
    typeof Object.keys(fieldValidators).find(
      (key) => fieldValidators[key] === false,
    ) === 'undefined';
  setFieldIsValid(fieldValidators);
  return userValid;
};

const onSignUpClick = async ({ user, authStore }) => {
  await authStore.signup(user);
};

const AuthViewSignup = ({
  firstName = '',
  lastName = '',
  zip = '',
  email = '',
  authStore,
  profileStore,
  uiStore,
}) => {
  useEffect(() => {
    if (
      authStore.isAuthenticated &&
      profileStore.hasFetchedInitial &&
      profileStore.availableUserEntities.length > 1 &&
      authStore.stashedPathDetails.path &&
      !authStore.stashedPathDetails.path.contains('addsponsor')
    ) {
      uiStore.openModal('CONTEXT_SELECTOR');
    }
  }, [profileStore.hasFetchedInitial, authStore.isAuthenticated]);

  const loadingSignInBanner =
    !authStore.isAuthenticated && authStore.loadingTokenData;

  const showSignInBanner =
    !authStore.isAuthenticated &&
    !authStore.loadingTokenData &&
    authStore.companyInviteDetails &&
    !authStore.companyInviteDetails.redeemed;
  const showGift = Boolean(authStore.companyInviteDetails?.gift?.amount);
  const companyName = authStore.companyInviteDetails?.company?.name;

  const [user, setUser] = useState({
    firstName,
    lastName,
    zip,
    email,
    password: '',
    hasAcceptedTerms: false,
  });

  const [fieldIsValid, setFieldIsValid] = useState({
    firstName: true,
    lastName: true,
    zip: true,
    password: true,
    email: true,
    hasAcceptedTerms: true,
  });

  const redeemerEmail = authStore.companyInviteDetails?.email;
  useEffect(() => {
    if (redeemerEmail) {
      setUser({
        ...user,
        email: redeemerEmail,
      });
    }
  }, [redeemerEmail]);

  if (authStore.isAuthenticated && profileStore.hasFetchedInitial) {
    if (authStore.stashedPathDetails.path) {
      return <Redirect to={`${authStore.unstashPathDetails()}`} noThrow />;
    } else {
      return <Redirect to="/donate" noThrow />;
    }
  }

  if (
    (authStore.isAuthenticated && !profileStore.hasFetchedInitial) ||
    loadingSignInBanner
  ) {
    return <CauzeSpinner />;
  }

  return (
    <div className="signup-view">
      <div className="background-image" />
      <div className="auth-form-body">
        <div className="signup-view-logos">
          <CauzeLogo />
          {showSignInBanner && (
            <>
              <FontAwesomeIcon icon={faTimes} size="lg" />
              <Avatar
                noBorder
                avatar={authStore.companyInviteDetails?.company?.avatar}
              />
            </>
          )}
        </div>
        {showSignInBanner && (
          <div className="signup-view-gift">
            <div className="gift-icon">
              {showGift ? <GiftIcon /> : <SurpriseIcon />}
            </div>
            {showGift && (
              <div className="gift-amount">
                <Currency
                  showCents
                  showDollarSign
                  amount={authStore.companyInviteDetails?.gift?.amount}
                />
              </div>
            )}
            <div className="gift-desc">
              <div className="gift-desc-bold">
                {showGift
                  ? `${companyName} has given you a
                gift!`
                  : `You’ve been invited to set up your 
                  Cauze personal giving account.`}
              </div>
              <div className="gift-desc-text">
                {showGift
                  ? `To use your gift and select a charity of your choice for giving,
                join the ${companyName}
                employee giving program, and the Cauze community.`
                  : `${companyName} will match all your giving, so you need to create an account to utilize the match.`}
              </div>
            </div>
          </div>
        )}
        <h1 className="signup-view-title">create an account</h1>
        <form
          className="signup-view-form"
          onSubmit={(e) => {
            e.preventDefault();
            if (validateCreateUser({ user, fieldIsValid, setFieldIsValid })) {
              onSignUpClick({ user, authStore });
            }
          }}
        >
          <CreateAccountForm
            showTerms
            user={user}
            setUser={setUser}
            onChange={setUser}
            fieldIsValid={fieldIsValid}
            setFieldIsValid={setFieldIsValid}
            uiStore={uiStore}
          />
          <CauzeButton type="submit">SIGN UP</CauzeButton>
        </form>
        <div className="login-button-container">
          <a href="/login" className="login-button">
            LOGIN
          </a>
        </div>
      </div>
    </div>
  );
};

export default inject(
  'authStore',
  'profileStore',
  'uiStore',
)(observer(AuthViewSignup));
