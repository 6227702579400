import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { isEmpty } from 'lodash';

import * as events from '../../util/events/checkout';
import withUserContext from '../../behaviors/withUserContext';
import { getAppLink } from '../../util/userAgent';

import Navbar from 'components/page/Navbar/Navbar';
import Footer from 'components/page/Footer/Footer';
import Button from 'components/Button/Button';
import Currency from 'components/Currency/Currency';
import ShareModal from 'components/modals/ShareModal/ShareModal';
import shareIcon from '../../assets/images/icons/share/share-orange.svg';
import background from '../../assets/images/illustrations/ntee-backgrounds/human-services.jpg';
import CauzeSpinner from 'components/CauzeSpinner/CauzeSpinner';

const MatchSuccessView = ({ profileStore, matchStore, id }) => {
  const [showShareModal, setShowShareModal] = useState(false);

  const matchDetails = matchStore.matchDetails;
  const sponsorDetails = matchStore.sponsorDetails;

  if (isEmpty(matchDetails)) {
    matchStore.getMatchDetail({
      matchId: id,
      userContext: profileStore.activeEntity.userContext,
    });
    return (
      <div className="checkout-success flex-column">
        <Navbar />
        <CauzeSpinner />
      </div>
    );
  }
  return (
    <div className="checkout-success flex-column">
      <Navbar />
      <div
        className="success-background"
        style={{ backgroundImage: `url(${background})` }}
      >
        <ShareModal
          isOpen={showShareModal}
          onToggleClose={() => setShowShareModal(false)}
          shareUrl={
            matchDetails.events && matchDetails.events[0].shareLink
              ? matchDetails.events[0].shareLink
              : 'https://www.cauze.com'
          }
          shareTitle={`Match for ${matchDetails.events[0].name}`}
        />
        <div className="success-message-spacer" />
        <div className="success-message-container">
          {sponsorDetails.matchTotal === 0 ? (
            <div style={{ padding: '2rem' }}>
              <h1 className="title">
                Matching discontinued. The remainder has been refunded to your
                cauze balance.
              </h1>
            </div>
          ) : (
            <div className="flex-column flex-center">
              {matchDetails.events[0]?.image?.md && (
                <img
                  className="cauze-image"
                  src={matchDetails.events[0].image.md}
                />
              )}
              <div
                onClick={() => {
                  events.openShareModal();
                  setShowShareModal(true);
                }}
                className="share"
              >
                <img className="share-logo" src={shareIcon} />
                share
              </div>
              <div style={{ paddingTop: '1rem' }}>
                <h1 className="title">
                  THANKS FOR YOUR{' '}
                  <Currency amount={sponsorDetails.matchTotal} /> MATCH
                </h1>
              </div>
              {matchDetails.events && matchDetails.events[0].name && (
                <div className="charity-name">
                  To {matchDetails.events[0].name}
                </div>
              )}
              <div className="thanks-message">
                {`Way to unleash your good! We will let ${matchDetails.matchAdmin.name}`}{' '}
                {`know about your match offer to ${matchDetails.events[0].name}.`}
              </div>
            </div>
          )}
          <div className="button-row">
            <Button
              className="is-medium"
              href={`/event/${matchDetails.events[0].id}`}
            >
              Return to Giving Page
            </Button>
            <Button className="is-medium" href={getAppLink()}>
              Download Cauze App
            </Button>
          </div>
        </div>
      </div>
      <Footer short hideGetAppButton />
    </div>
  );
};

MatchSuccessView.propTypes = {
  id: PropTypes.string,
  profileStore: PropTypes.shape({
    data: PropTypes.shape({
      avatar: PropTypes.object.isRequired,
    }),
  }),
  matchStore: PropTypes.shape({
    matchDetails: PropTypes.object.isRequired,
  }),
};

export default withUserContext(
  inject('profileStore', 'matchStore')(observer(MatchSuccessView)),
);
