import React from 'react';
import { PaymentRequestButtonElement } from '@stripe/react-stripe-js';

const PaymentRequestButton = ({ store, paymentRequest: pr }) => {
  const paymentRequest = pr || store.paymentRequest;

  if (!store?.paymentRequest) return <></>;

  return (
    Boolean(paymentRequest) && (
      <div className="apple-pay-container">
        <PaymentRequestButtonElement options={{ paymentRequest }} />
      </div>
    )
  );
};

export default PaymentRequestButton;
