import React, { useMemo, useState, useEffect } from 'react';
import { navigate } from '@reach/router';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { ReactComponent as EllipsisIcon } from 'assets/images/icons/dots/three-dots-hollow.svg';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';

import Avatar from 'components/Avatar/Avatar';

import { shorten } from 'util/stringUtils';
import LargeNumber from 'components/LargeNumber/LargeNumber';
import CauzeSpinner from 'components/CauzeSpinner/CauzeSpinner';
import CauzePinButton from 'components/CauzePinButton/CauzePinButton';

const EventListItem = ({ event, onPin, isSelf, uiStore }) => {
  const [pinned, setPinned] = useState(true);

  const eventItemImageClassName = useMemo(() => {
    const baseClasssName = 'event-item-image';

    if (event.image?.md) {
      return baseClasssName;
    }

    const i = event.name.length % 6;
    return `${baseClasssName} background-gradient-${i + 1}`;
  }, [event]);

  const giverCount = useMemo(() => {
    return Math.max(
      event.purchaserCount || 0,
      event.topPurchasers?.length || 0,
    );
  }, [event]);

  const handlePin = (e) => {
    e.stopPropagation();
    e.preventDefault();

    if (!pinned) {
      setPinned(!pinned);
      onPin(e);
    } else {
      // show confirmation modal on unpin
      uiStore.openModal('CONFIRM', {
        message: 'Unpin cauze?',
        action: () => {
          setPinned(!pinned);
          onPin(e);
        },
        confirmText: 'Confirm',
        bodyMessage:
          'Unpinning this cauze will cause it no longer appear on your profile.',
      });
    }
  };

  if (!pinned) return <></>;

  return (
    <div
      className="event-item"
      key={`${event.name}-${event.id}`}
      onClick={() => navigate(`/event/${event.id}`)}
    >
      <div className="event-item-header">
        {isSelf && <CauzePinButton pinned={pinned} onPin={handlePin} />}
        <div className="event-item-overlay" />
        <div
          className={eventItemImageClassName}
          style={{
            backgroundImage: event.image?.md
              ? `url('${event.image?.md}')`
              : undefined,
          }}
        />
      </div>
      <div className="event-item-body">
        <div className="event-item-name">{shorten(event.name, 60)}</div>
        <div className="event-item-host">
          <Avatar avatarUrls={event.hosts?.[0]?.avatarUrls} />
          <div className="event-item-host-text">
            <div>by: </div>
            <div className="event-item-host-name">{event.hosts?.[0]?.name}</div>
          </div>
        </div>
        <div className="event-item-givers">
          {Boolean(giverCount) && Boolean(event.topPurchasers?.length) && (
            <div className="event-item-giver-count">
              <LargeNumber amount={giverCount} /> Giver
              {giverCount !== 1 && 's'}:
            </div>
          )}
          <div className="event-item-avatar-group">
            {event.topPurchasers?.slice(0, 5).map((topPurchaser) => (
              <Avatar xsm avatarUrls={topPurchaser.actor.avatarUrls} />
            ))}
            {giverCount >= 5 && (
              <EllipsisIcon className="event-item-ellipsis" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

EventListItem.propTypes = {
  event: PropTypes.object,
};

const EventList = ({ events, loading, onPin, isSelf, uiStore }) => {
  const [listEvents, setListEvents] = useState(events);
  const [isMobile, setIsMobile] = useState(document.body.clientWidth < 425);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 425 && !isMobile) {
        setIsMobile(true);
      } else if (window.innerWidth > 425 && isMobile) {
        setIsMobile(false);
      }
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isMobile]);

  useEffect(() => {
    setListEvents(events);
  }, [events]);

  if (loading) {
    return (
      <div className="event-list">
        <CauzeSpinner />
      </div>
    );
  }

  return (
    <Swiper
      className={cx('event-list', {
        'show-arrows': listEvents && listEvents.length > 2,
      })}
      navigation
      modules={[Navigation]}
      spaceBetween={8}
      slidesPerView={isMobile ? 1.5 : 2.3}
    >
      {listEvents.map((event) => (
        <SwiperSlide key={event.id}>
          <EventListItem
            event={event}
            isSelf={isSelf}
            uiStore={uiStore}
            onPin={(e) => {
              e.stopPropagation();
              e.preventDefault();

              if (event.currentEntityHasPinned) {
                setListEvents(listEvents.filter((evt) => evt.id !== event.id));
              }

              onPin?.({
                eventId: event.id,
                pinned: !event.currentEntityHasPinned,
              });
            }}
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

EventList.propTypes = {
  events: PropTypes.array.isRequired,
  loading: PropTypes.bool,
};

export default EventList;
