import gql from 'graphql-tag';

const createMatchMutation = gql`
  mutation createMatch(
    $charity_ids: [ID]
    $end_date: Date
    $event_id: ID
    $match_limit: Int!
    $match_return_type: MatchReturnType
    $match_total: Int
    $match_type: MatchType
    $name: String
    $start_date: Date
    $user_context: UserContext
    $multipler: Int
  ) {
    createMatch(
      charityIds: $charity_ids
      endDate: $end_date
      eventId: $event_id
      matchLimit: $match_limit
      matchReturnType: $match_return_type
      matchTotal: $match_total
      matchType: $match_type
      name: $name
      startDate: $start_date
      userContext: $user_context
      multiplier: $multipler
    ) {
      id
    }
  }
`;

const matchDetailsQuery = gql`
  query matchDetails($match_id: ID!, $user_context: UserContext) {
    matchDetails(matchId: $match_id, userContext: $user_context) {
      id
      active
      company {
        name
        subtitle
        avatar {
          sm
          md
        }
      }
      matchAdmin {
        id
        isSelf
        isSelfFollowing
        entityType
        name
        avatar {
          sm
          md
        }
      }
      events {
        id
        name
        shareLink
        image {
          md
        }
      }
      description
      startDate
      endDate
      matchLimit
      matchTotal
      totalRaised
      totalRemaining
      userMatchLimit
      matchType
      matchSponsors {
        matchTotal
        remaining
        name
        id
        isSelfFollowing
        isSelf
        subtitle
        entityType
        avatar {
          sm
          md
        }
      }
    }
  }
`;

const editMatchMutation = gql`
  mutation editMatch(
    $id: ID!
    $end_date: Date
    $active: Boolean
    $match_limit: Int
    $match_total: Int
    $match_type: MatchType
    $multiplier: Int
  ) {
    editMatch(
      id: $id
      endDate: $end_date
      active: $active
      matchLimit: $match_limit
      matchTotal: $match_total
      matchType: $match_type
      multiplier: $multiplier
    ) {
      id
    }
  }
`;

const addMatchSponsorMutation = gql`
  mutation addMatchSponsor(
    $match_id: ID!
    $total: Int!
    $user_context: UserContext
  ) {
    addMatchSponsor(
      matchId: $match_id
      total: $total
      userContext: $user_context
    ) {
      id
    }
  }
`;

const editMatchSponsorMutation = gql`
  mutation editMatchSponsor(
    $match_id: ID!
    $total: Int!
    $user_context: UserContext
  ) {
    editMatchSponsor(
      matchId: $match_id
      total: $total
      userContext: $user_context
    ) {
      id
    }
  }
`;

export {
  matchDetailsQuery,
  addMatchSponsorMutation,
  editMatchSponsorMutation,
  editMatchMutation,
  createMatchMutation,
};
