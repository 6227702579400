import { observer, inject } from 'mobx-react';

import withUserContext from '../../behaviors/withUserContext';
import useEvent from '../../hooks/useEvent';
import CauzeSpinner from 'components/CauzeSpinner/CauzeSpinner';
import CauzeFundraiserEventView from './CauzeFundraiserEventView';
import FuturePhilanthropyView from './FuturePhilanthropyView';

const EventView = (props) => {
  let parsedEventId =
    decodeURIComponent(props.eventId).split('?').length > 1 &&
    decodeURIComponent(props.eventId).split('?')[0];

  const sanitizedEventId = parsedEventId || props.eventId;
  useEvent({
    eventId: sanitizedEventId,
    purchaseId: props.purchaseId,
    userContext: props.userContext,
  });

  if (props.eventStore.loading || !props.eventStore.eventData) {
    return (
      <div className="event-view background-grey flex-center flex-column">
        <CauzeSpinner fullscreen style={{ margin: 'auto' }} />
      </div>
    );
  }

  const eventConfig = props.eventStore.eventData.config || {
    donationAmountPresets: [2000, 2500, 5000],
    minimumDonation: 10000,
    template: 'default',
  };

  const templates = {
    default: FuturePhilanthropyView,
    cauze_fundraise: CauzeFundraiserEventView,
    future_philanthropy: FuturePhilanthropyView,
  };

  const Template = templates[eventConfig.template] || templates['default'];
  return <Template {...props} />;
};

export default withUserContext(
  inject(
    'eventStore',
    'profileStore',
    'uiStore',
    'matchStore',
    'authStore',
  )(observer(EventView)),
);
