import { useEffect } from 'react';
import cauzeStore from '../stores/CauzeStore';

const useCauzes = ({ userContext }) => {
  useEffect(() => {
    cauzeStore.onUnmount();
    cauzeStore.onCreateUnmount();
    cauzeStore.getInitial({ userContext });
  }, [userContext]);
};

export default useCauzes;
