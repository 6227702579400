/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import CauzeButton from 'components/CauzeButton/CauzeButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

const CharityDonateSearch = ({
  searchStore,
  uiStore,
  onSearch,
  useShadows = false,
}) => {
  useEffect(() => {
    searchStore.getCountryList();
  }, []);

  const openCountryModal = () =>
    uiStore.openModal('COUNTRY_SELECTOR', {
      onConfirm: (country) => {
        if (
          searchStore.currentSearch.query ||
          searchStore.currentSearch.country
        ) {
          searchStore.updateAndRunSearch({ country, location: '' });
          return;
        }
        searchStore.updateSearch({ country, location: '' });
      },
      countryWhitelist: searchStore.countryWhitelist,
    });

  return (
    <div className="charity-donate-search position-relative">
      <form
        action="."
        className="charity-search-form"
        onSubmit={(e) => {
          e.target[0].blur();
          e.target[1]?.blur();
          e.preventDefault();
          onSearch();
        }}
      >
        <input
          className="search-input column"
          type="search"
          name="name"
          autoComplete="off"
          value={searchStore.currentSearch.query}
          placeholder={`Foodbank`}
          onChange={(e) => searchStore.updateSearch({ query: e.target.value })}
        />
        {(searchStore.currentSearch.country === 'United States' ||
          searchStore.currentSearch.country === '') && (
          <>
            <span className="charity-search-separation"> | </span>
            <FontAwesomeIcon
              icon={faMapMarkerAlt}
              aria-hidden="true"
              className="map-marker"
            />
            <input
              className="search-input-location column "
              type="search"
              name="location"
              value={searchStore.currentSearch.location}
              autoComplete="off"
              placeholder={'City/Zip Code'}
              onChange={(e) =>
                searchStore.updateSearch({ location: e.target.value })
              }
            />
          </>
        )}
        <div
          className={classnames(
            'flex-row flex-align-center margin-bottom-responsive',
            {
              'input-shadow': useShadows,
            },
          )}
        ></div>
        <div className="search-country" onClick={openCountryModal}>
          <span className="current-country">
            {!searchStore.currentSearch.country
              ? 'United States'
              : `${searchStore.currentSearch.country}`}
          </span>
          {' | '}
          <span className="change-country">Change</span>
        </div>
        <CauzeButton
          className="charity-search-button"
          style={{ marginLeft: '0.5rem', marginRight: '0.8rem' }}
          type="submit"
        >
          Search
        </CauzeButton>
      </form>
    </div>
  );
};

CharityDonateSearch.propTypes = {
  searchStore: PropTypes.object.isRequired,
  uiStore: PropTypes.object.isRequired,
  onSearch: PropTypes.func.isRequired,
  useShadows: PropTypes.bool,
};

export default observer(CharityDonateSearch);
