import React, { useEffect, useState, useRef } from 'react';
import scriptLoader from 'react-async-script-loader';
import { PaymentRequestButtonElement } from '@stripe/react-stripe-js';

const WrappedApplePayButton = ({
  isScriptLoaded,
  isScriptLoadSucceed,
  store,
}) => {
  const [show, setShow] = useState(false);
  const [paymentRequest, setPaymentRequest] = useState();
  const currentAmount = useRef({ amount: 0, complete: false });

  useEffect(() => {
    const stripe = store.stripe;
    if (!stripe || !window.ApplePaySession || currentAmount.current.complete) {
      return;
    }

    const handle = async () => {
      if (
        (store.currentCheckout.donationAmount ??
          store.currentCheckout.amount) === currentAmount.current.amount
      ) {
        return;
      }

      currentAmount.current.amount =
        store.currentCheckout.donationAmount ?? store.currentCheckout.amount;

      const orderDetails = await store.onApplePayOrderCreate();

      let pr = paymentRequest;
      if (!pr) {
        pr = stripe.paymentRequest({
          ...orderDetails,
          requestPayerName: true,
          requestPayerEmail: true,
        });

        pr.on('paymentmethod', async (ev) => {
          // Confirm the PaymentIntent without handling potential next actions (yet).
          const { paymentIntent, error: confirmError } =
            await stripe.confirmCardPayment(
              store.currentCheckout.clientSecret,
              { payment_method: ev.paymentMethod.id },
              { handleActions: false },
            );

          let firstName = '';
          let lastName = '';
          let email = ev.payerEmail || '';

          try {
            const payerName = ev.payerName || '';
            if (payerName) {
              firstName = payerName.split(' ')[0];
              lastName = payerName.split(' ').slice(1).join(' ');
            }
          } catch (err) {
            // dont set values
          }

          if (confirmError) {
            store.popErrorModal(
              'Unable to verify card payment. Try again later.',
            );
            ev.complete('fail');
          } else {
            ev.complete('success');
            if (paymentIntent.status === 'requires_action') {
              // Let Stripe.js handle the rest of the payment flow.
              const { error } = await stripe.confirmCardPayment(
                store.currentCheckout.clientSecret,
              );
              if (error) {
                store.popErrorModal(
                  'The payment failed. Try a different payment method.',
                );
              } else {
                store.updateCheckout({
                  paymentIntentId: paymentIntent.id,
                  paymentMethodId: ev.paymentMethod.id,
                  firstName,
                  lastName,
                  email,
                  paymentMethod: 'apple_pay',
                });
                currentAmount.current.complete = true;
                if (!(await store.onApplePayOrderComplete(paymentIntent))) {
                  currentAmount.current.complete = false;
                }
              }
            } else {
              store.updateCheckout({
                paymentIntentId: paymentIntent.id,
                paymentMethodId: ev.paymentMethod.id,
                firstName,
                lastName,
                email,
              });
              currentAmount.current.complete = true;
              if (!(await store.onApplePayOrderComplete(paymentIntent))) {
                currentAmount.current.complete = false;
              }
            }
          }
        });
      } else {
        pr.update(orderDetails);
      }

      // Check the availability of the Payment Request API.
      const result = await pr?.canMakePayment();
      setPaymentRequest(pr);

      if (!show && result?.applePay) {
        setShow(true);
      }
    };

    handle();
  }, [isScriptLoaded, isScriptLoadSucceed, store, store.currentCheckout]);

  if (!isScriptLoaded || !isScriptLoadSucceed || !show) return <></>;

  return (
    Boolean(paymentRequest) && (
      <div className="apple-pay-container">
        <PaymentRequestButtonElement options={{ paymentRequest }} />
      </div>
    )
  );
};

export default scriptLoader(
  'https://applepay.cdn-apple.com/jsapi/v1.1.0/apple-pay-sdk.js',
)(WrappedApplePayButton);
