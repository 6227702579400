import { observable, action, makeObservable, toJS } from 'mobx';

import donationLandingStore from './DonationLandingStore';
import { getUiState, setUiState } from '../util/storage';
import { convertSnakeToSpaces } from '../util/stringUtils';
import mixpanel from 'mixpanel-browser';

class UiStore {
  @observable savedUiState = {};
  @observable awaitingFetchUiState = true;
  @observable modal = '';
  @observable hasApp = false;
  @observable modalProps = {};
  @observable notification = null;

  @action
  loadUiState = async () => {
    this.awaitingFetchUiState = true;
    try {
      const uiState = await getUiState();
      if (uiState) {
        this.savedUiState = uiState;
        if (uiState.userToken) {
          donationLandingStore.loadGiftData({ token: uiState.userToken.token });
        }
        this.awaitingFetchUiState = false;
      }
    } catch {
      setUiState(this.savedUiState);
    }
  };

  @action
  saveUiState = async (uiState) => {
    const newUiState = { ...this.savedUiState, ...uiState };
    await setUiState(newUiState);
    this.savedUiState = newUiState;
  };

  @action setHasApp = (hasApp) => (this.hasApp = hasApp);

  @action
  deleteSavedUiState = async () => {
    await setUiState({});
    this.loadUiState();
  };

  @action openModal = (modal, props) => {
    mixpanel.track('Modal Opened', {
      modal: convertSnakeToSpaces(modal),
    });

    if (!modal) {
      this.modal = '';
      this.modalProps = {};
    }
    this.modal = modal;
    this.modalProps = props;
  };

  @action closeModal = () => {
    mixpanel.track('Modal Closed', {
      modal: convertSnakeToSpaces(toJS(this.modal)),
    });

    this.modal = '';
    this.modalProps = {};
  };

  @action showNotification = ({
    body,
    type = 'NORMAL',
    autoDismiss = true,
    onDismiss,
  } = {}) => {
    this.notification = { body, type, onDismiss };
    if (autoDismiss) {
      setTimeout(this.closeNotification, 5000);
    }
  };

  @action closeNotification = () => {
    if (this.notification && this.notification.onDismiss) {
      this.notification.onDismiss();
    }
    this.notification = null;
  };

  constructor() {
    makeObservable(this);
  }
}

const uiStore = new UiStore();
export default uiStore;
