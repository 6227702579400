import React, {
  createContext,
  useContext,
  useReducer,
  useCallback,
} from 'react';
import { navigate } from '@reach/router';

export const CauzeRoutingCtx = createContext(null);

const SET_CONFIRM_PROPS = 'SET_CONFIRM_PROPS';

const CauzeRoutingReducer = (state, action) => {
  switch (action.type) {
    case SET_CONFIRM_PROPS:
      return {
        ...state,
        confirmProps: action.payload.confirmProps,
      };
    default:
      return state;
  }
};

const CauzeRoutingProvider = ({ children, uiStore }) => {
  const [state, dispatch] = useReducer(CauzeRoutingReducer, {
    confirmProps: null,
    uiStore,
  });

  const setConfirmProps = useCallback((confirmProps) => {
    dispatch({
      type: SET_CONFIRM_PROPS,
      payload: {
        confirmProps,
      },
    });
  }, []);

  return (
    <CauzeRoutingCtx.Provider value={{ ...state, setConfirmProps }}>
      {children}
    </CauzeRoutingCtx.Provider>
  );
};

export default CauzeRoutingProvider;

export const useCauzeRoutingContext = () => {
  const { confirmProps, setConfirmProps, uiStore } =
    useContext(CauzeRoutingCtx);

  const navigateWithConfirm = useCallback(
    async (route, options, callback) => {
      if (confirmProps) {
        uiStore.openModal('CONFIRM', {
          message: 'Do you want to save your changes?',
          action: () => {
            if (callback) {
              callback();
            } else {
              navigate(route, options);
            }
            setConfirmProps(null);
          },
          ...confirmProps,
        });
      } else if (callback) {
        callback();
      } else {
        navigate(route, options);
      }
    },
    [confirmProps, uiStore],
  );

  return {
    setConfirmProps,
    navigateWithConfirm,
  };
};
