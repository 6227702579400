import { useEffect } from 'react';
import matchStore from '../stores/MatchStore';

const useMatch = ({ id, userContext }) => {
  useEffect(() => {
    matchStore.onDismount();
    matchStore.getMatchDetail({ matchId: id, userContext });
  }, [id, userContext]);
};

export default useMatch;
