import React, { useState } from 'react';
import { observer, inject } from 'mobx-react';
import { navigate } from '@reach/router';
import CauzeSpinner from 'components/CauzeSpinner/CauzeSpinner';
import Navbar from 'components/page/Navbar/Navbar';
import FooterNew from 'components/page/Footer/FooterNew';
import CharityDetailsModal from 'components/modals/CharityDetailsModal';
import LoginModal from 'components/modals/LoginModal/LoginModal';
import Hero from 'components/Hero/Hero';
import TopicPill from 'components/TopicPill/TopicPill';
import useSearch from '../../hooks/useSearch';
import SearchItem from 'components/SearchItem/SearchItem';
import withUserContext from '../../behaviors/withUserContext';
import SectionHeader from 'components/SectionHeader/SectionHeader';
import mixpanel from 'mixpanel-browser';
import CauzeButton from 'components/CauzeButton/CauzeButton';
import useGrabSlider from '../../util/useGrabSlider';

const PAGE_SIZE = 12;
const INTERSTITIAL_INDEX = 3;

const getSearchPills = (searchStore) => {
  let pills = [];
  searchStore.topics.forEach((topic, index) => {
    pills.push(
      <TopicPill
        key={`${topic}-${index}`}
        index={index}
        topic={topic}
        onClick={() => {
          searchStore.updateSearch({ query: '' });
          searchStore.setActiveTopic(topic);
        }}
        isSelected={searchStore.activeTopic === topic}
      />,
    );
  });

  searchStore.categories.forEach((category, index) => {
    pills.push(
      <TopicPill
        key={`${category}-${index}`}
        index={index}
        topic={category}
        onClick={() => {
          searchStore.updateSearch({ query: '' });
          searchStore.setActiveCategory(category);
        }}
        isSelected={searchStore.activeCategory === category}
      />,
    );
  });

  return pills;
};

const SearchView = ({ activeEntity, searchStore, uiStore, authStore }) => {
  useSearch();
  useGrabSlider('search-pill-container');

  const [detailCharity, setDetailCharity] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const showResults = searchStore.results.length > 0 && !searchStore.loading;
  const showEmpty = searchStore.results.length === 0 && !searchStore.loading;
  const canLoadMore = searchStore.page * PAGE_SIZE < searchStore.results.length;

  const onDonate = (charityId) => {
    mixpanel.track('Search Result Click', {
      channel: 'web',
      type: 'charity',
      id: charityId,
      withGift: !!uiStore.savedUiState.userToken,
    });

    if (uiStore.savedUiState.userToken) {
      uiStore.openModal('CHECKOUT_WITH_GIFT', {
        giftToken: uiStore.savedUiState?.userToken?.token,
        hideAmount: uiStore.savedUiState?.userToken?.gift?.hideAmount,
        giftAmount: uiStore.savedUiState?.userToken?.gift?.amount,
        email: uiStore.savedUiState?.userToken?.email,
        initiatorId: uiStore.savedUiState.userToken?.gift?.initiatorCompany?.id,
        charityId,
      });
    } else {
      navigate(`/checkout/charity/${charityId}`);
    }
  };

  const showUnauthedPrompt = () => {
    uiStore.openModal('CONFIRM', {
      message: 'Please Login or Signup to follow givers',
      action: () => {
        setShowLoginModal(true);
      },
      confirmText: 'Login / Signup',
      cancelText: 'Not Now',
    });
  };

  return (
    <div className="search-view flex-column">
      <Navbar
        cobrand={uiStore.savedUiState.userToken?.company}
        redirectAfterContextSelect={false}
      />
      <div className="">
        {showDetails && (
          <CharityDetailsModal
            isOpen={showDetails}
            mini
            onToggleClose={() => {
              setShowDetails(false);
              setDetailCharity(null);
            }}
            charity={detailCharity}
          />
        )}
        <LoginModal
          isOpen={showLoginModal}
          onToggleClose={() => setShowLoginModal(false)}
        />
        <div className="search-view-hero-wrapper">
          <Hero
            activeEntity={activeEntity}
            searchStore={searchStore}
            uiStore={uiStore}
            isShort={true}
          />
        </div>
        <div className="container">
          <div className="results-container">
            <div className="flex-expand flex-row flex-justify-start full-width">
              <SectionHeader
                title="Your Search Results"
                lineColor="Pink"
                style={{ marginBottom: '1rem' }}
              />
            </div>
            <div className={`trending-header ${showEmpty && 'no-results'}`}>
              Trending
            </div>
            <div
              className={`search-pill-container ${showEmpty && 'no-results'}`}
            >
              {getSearchPills(searchStore)}
            </div>
            {searchStore.loading && <CauzeSpinner fullscreen />}
            {showEmpty && (
              <div className="empty-item flex-column flex-align-center">
                <div className="flex-row">
                  <div className="flex-column">
                    <p className="text-bold">Sorry, We Couldn't Find a Match</p>
                    <p className="text-normal">
                      Try a different search item or check out
                      <br />
                      some of our suggestions below
                    </p>
                  </div>
                </div>
                <div className="flex-row flex-justify-end flex-align-center"></div>
              </div>
            )}
            {showResults &&
              searchStore.results
                .slice(0, INTERSTITIAL_INDEX)
                .map((charity, index) => (
                  <SearchItem
                    charity={charity}
                    userContext={activeEntity.userContext}
                    onDonate={() => onDonate(charity.id)}
                    onLearnMore={() => {
                      setDetailCharity(charity);
                      setShowDetails(true);
                    }}
                    onFollowCharity={searchStore.followCharity}
                    onFollowGiver={searchStore.onFollowGiver}
                    key={`${index}-${charity.name}`}
                    index={index}
                    isAuthenticated={authStore.isAuthenticated}
                    setShowLoginModal={setShowLoginModal}
                    onShowUnauthedPrompt={showUnauthedPrompt}
                    authStore={authStore}
                  />
                ))}
          </div>
        </div>
        <div className="container">
          <div className="results-container">
            {showResults &&
              searchStore.results
                .slice(INTERSTITIAL_INDEX, searchStore.page * PAGE_SIZE)
                .map((charity, index) => (
                  <SearchItem
                    charity={charity}
                    userContext={activeEntity.userContext}
                    onDonate={() => onDonate(charity.id)}
                    onLearnMore={() => {
                      setDetailCharity(charity);
                      setShowDetails(true);
                    }}
                    onFollowFollower={searchStore.followCharity}
                    onFollowCharity={searchStore.followCharity}
                    onFollowGiver={searchStore.onFollowGiver}
                    key={`${index + INTERSTITIAL_INDEX}-${charity.name}`}
                    index={index + INTERSTITIAL_INDEX}
                    isAuthenticated={authStore.isAuthenticated}
                    onShowUnauthedPrompt={showUnauthedPrompt}
                  />
                ))}
            {canLoadMore && (
              <CauzeButton
                white
                large
                onClick={searchStore.loadMoreSearchItems}
                style={{ marginTop: '5rem' }}
              >
                Load More Results
              </CauzeButton>
            )}
          </div>
        </div>
      </div>
      <FooterNew cobrand={uiStore.savedUiState.userToken?.company} />
    </div>
  );
};

export default withUserContext(
  inject(
    'searchStore',
    'donationLandingStore',
    'uiStore',
    'authStore',
  )(observer(SearchView)),
);
