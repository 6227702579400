import cx from 'classnames';

import CauzeSpinner from 'components/CauzeSpinner/CauzeSpinner';

const Button = ({
  children,
  variant = 'primary',
  className,
  loading = false,
  ...buttonProps
}) => {
  return (
    <button
      disabled={loading}
      {...buttonProps}
      className={cx('button-new', {
        [variant]: Boolean(variant),
        [className]: Boolean(className),
      })}
    >
      {loading && (
        <CauzeSpinner
          marginTop="auto"
          style={{
            margin: 'auto',
            width: '20px',
          }}
        />
      )}
      {!loading && children}
    </button>
  );
};

export default Button;
