import React from 'react';
import Avatar from '../Avatar/Avatar';
import PropTypes from 'prop-types';

const Charity = ({ value, original }) => (
  <div className="flex-row col-charity-container">
    <div className="flex-column flex-expand min-width-reset">
      <div className="flex-row">
        <Avatar type="charity" avatar={original.avatar} />
        <div className="flex-column flex-expand col-charity-details">
          <div>
            <div className="col-charity-name">
              <button>{value}</button>
            </div>
            <div className="col-charity-place">
              {original.city}, {original.state}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

Charity.propTypes = {
  value: PropTypes.string,
  original: PropTypes.shape({
    name: PropTypes.string.isRequired,
    city: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    state: PropTypes.string,
  }),
};

export default Charity;
