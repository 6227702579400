import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import * as events from '../../util/events/checkout';
import withUserContext from '../../behaviors/withUserContext';

import Navbar from 'components/page/Navbar/Navbar';
import FooterNew from 'components/page/Footer/FooterNew';
import Button from 'components/Button/Button';
import Currency from 'components/Currency/Currency';
import LoginModal from 'components/modals/LoginModal/LoginModal';
import CauzeSpinner from 'components/CauzeSpinner/CauzeSpinner';

import mobileAppGraphic from '../../assets/images/illustrations/iphone-hand-cauzeapp.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const DonationWithGiftSuccessView = ({
  donationCheckoutStore,
  donationUnauthedCheckoutStore,
}) => {
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [checkoutStore, setCheckoutStore] = useState();

  const onCreateAccountClick = () => {
    events.downloadFromCheckoutConfirm();
    setShowLoginModal(true);
  };

  useEffect(() => {
    if (!donationCheckoutStore.checkoutLoading) {
      setCheckoutStore(donationCheckoutStore);
      return;
    }

    if (!donationUnauthedCheckoutStore.checkoutLoading) {
      setCheckoutStore(donationUnauthedCheckoutStore);
    }
  }, [donationCheckoutStore, donationUnauthedCheckoutStore]);

  const checkoutSuccess = useMemo(
    () => checkoutStore?.checkoutSuccess,
    [checkoutStore],
  );

  return (
    <div className="checkout-success gift-success flex-column">
      <Navbar />
      <LoginModal
        isOpen={showLoginModal}
        onToggleClose={() => setShowLoginModal(false)}
        defaultTab={0}
      />
      {!checkoutStore || !checkoutSuccess ? (
        <div className="checkout loading-checkout flex-column">
          <Navbar redirectAfterContextSelect={false} />
          <CauzeSpinner id="loading-checkout-spinner" fullscreen />
          <FooterNew short hideGetAppButton />
        </div>
      ) : (
        <div>
          <div className="success-message-container">
            <div className="success-check-icon">
              <FontAwesomeIcon icon={faCheck} aria-hidden="true" />
            </div>
            <h1 className="title">Donation Successful</h1>
            <div className="thanks-message">
              You made today count. Despite all the noise and news that can
              drown out good intentions, you took the meaningful step toward
              building the communities we each yearn for. Well done!
            </div>
            <div className="thanks-detail">
              We've sent a confirmation to your email.
            </div>
            {checkoutSuccess.rewardAmount && (
              <div className="columns">
                <div className="column donate-again">
                  <div className="flex-row">
                    <div className="flex-column">
                      <div className="donate-again-title">
                        Earn <Currency amount={checkoutSuccess.rewardAmount} />{' '}
                        to Give Again
                      </div>
                      <div>
                        Create an Account to track your good and receive a $10
                        to continue your giving.
                      </div>
                    </div>
                    <div className="flex-column">
                      <img src={mobileAppGraphic} />
                      <Button
                        className="is-medium"
                        onClick={onCreateAccountClick}
                      >
                        Create Account
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      <FooterNew grey />
    </div>
  );
};

DonationWithGiftSuccessView.propTypes = {
  donationCheckoutStore: PropTypes.object.isRequired,
  profileStore: PropTypes.shape({
    data: PropTypes.shape({
      avatar: PropTypes.object.isRequired,
    }),
  }),
};

export default withUserContext(
  inject(
    'donationCheckoutStore',
    'donationUnauthedCheckoutStore',
    'profileStore',
  )(observer(DonationWithGiftSuccessView)),
);
