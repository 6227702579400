import React, { useState } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import Avatar from '../Avatar/Avatar';
import classnames from 'classnames';
import ImageUploadModal from '../modals/ImageUploadModal/ImageUploadModal';
import ErrorModal from 'components/modals/ErrorModal/';
import CauzeSpinner from 'components/CauzeSpinner/CauzeSpinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera } from '@fortawesome/free-solid-svg-icons';

const LoadingImage = () => (
  <div className="image-loading-area">
    <CauzeSpinner size="4rem" />
  </div>
);

const CommentInput = ({ avatar, userContext, eventId, purchaseId, store }) => {
  const [previewImageUrl, setPreviewImageUrl] = useState('');
  const [showImageUploadModal, setShowImageUploadModal] = useState(false);

  const showImagePreview =
    previewImageUrl !== '' && !store.uploadingCommentImage;

  return (
    <div className="support-block comment-input flex-column flex-align-center">
      <ImageUploadModal
        updateImageUrl={setPreviewImageUrl}
        store={store}
        isActive={showImageUploadModal}
        onToggleClose={() => setShowImageUploadModal(false)}
        cropRatio={[1, 1]}
        src=""
        noPlaceholder
      />
      <ErrorModal
        onToggleClose={store.resetImage}
        errorText="There was an issue uploading the image. Support has been notified."
        isOpen={store.imageUploadError}
      />
      <p className="text-bold comment-title">
        Keep your Cauze followers informed
      </p>
      <p className="comment-subtitle">
        Share stories, goals and updates with your followers
      </p>
      <div style={{ width: '100%' }} className="flex-column flex-space-between">
        <div className="comment-area-container">
          {store.uploadingCommentImage && <LoadingImage />}
          {showImagePreview && (
            <div className="image-container">
              <div className="remove-container">
                <img className="comment-image" src={previewImageUrl} />
                <p
                  onClick={(ev) => {
                    ev.preventDefault();
                    store.resetImage();
                    setPreviewImageUrl('');
                  }}
                  className="image-remove-icon"
                >
                  x
                </p>
              </div>
            </div>
          )}
          <textarea
            className="comment-area"
            value={store.commentText}
            onChange={(ev) => store.setCommentText(ev.target.value)}
            rows="5"
            cols="100"
          />
        </div>
        <div className="flex-row flex-align-center icon-container flex-space-between">
          <div className="flex-row flex-align-center">
            <>
              <Avatar className="comment-avatar" avatar={avatar} />
              <FontAwesomeIcon
                icon={faCamera}
                className="camera-icon"
                onClick={(ev) => {
                  ev.preventDefault();
                  setShowImageUploadModal(true);
                }}
              />
            </>
          </div>
          <p
            onClick={(ev) => {
              if (store.commentText && store.uploadingCommentImage === false) {
                ev.preventDefault();
                store
                  .createComment({
                    body: store.commentText,
                    eventId,
                    purchaseId,
                    userContext,
                    ...store.commentImageData,
                  })
                  .then(() => setPreviewImageUrl(''));
              }
            }}
            className={classnames('button post-button is-primary text-bold', {
              'post-text': !!store.commentText,
              'post-text-disabled': !store.commentText,
            })}
          >
            Post
          </p>
        </div>
      </div>
    </div>
  );
};

CommentInput.propTypes = {
  avatar: PropTypes.object,
  userContext: PropTypes.object.isRequired,
  eventId: PropTypes.string,
  purchaseId: PropTypes.string,
  store: PropTypes.object.isRequired,
};

export default observer(CommentInput);
