import React, { useState, useEffect } from 'react';
import classnames from 'classnames';

import Currency from '../Currency/Currency';
import ErrorCard from 'components/ErrorCard/ErrorCard';

const AmountSelector = ({
  onAmountUpdate,
  amounts = [1000, 2500, 5000, 10000],
  defaultAmount,
  sendDefaultUpdate = true,
  placeholder = '&nbsp;custom',
  minimumAmount,
  onEnter,
}) => {
  const [amount, setAmount] = useState(
    defaultAmount !== 0 ? defaultAmount : null,
  );
  const [customAmount, setCustomAmount] = useState(
    amount !== null && !amounts.includes(amount) ? amount / 100 : null,
  );
  const [customAmountFocused, setCustomAmountFocused] = useState(
    customAmount !== null,
  );
  const [showZeroAmountError, setShowZeroAmountError] = useState(false);
  const [showMinimumAmountError, setShowMinimumAmountError] = useState(false);

  useEffect(() => {
    if (defaultAmount && sendDefaultUpdate) {
      onAmountUpdate(defaultAmount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="amount-selector gap-2.5">
        <div className="flex gap-2.5">
          {amounts.map((amt) => (
            <button
              type="button"
              key={`${amt}`}
              className={classnames('amount-option flex-1', {
                'is-active': amount === amt,
              })}
              onMouseDown={() => {
                setAmount(amt);
                onAmountUpdate(amt);
                setCustomAmountFocused(false);
                setCustomAmount(null);
                setShowZeroAmountError(false);
                setShowMinimumAmountError(false);
              }}
            >
              <Currency amount={amt} />
            </button>
          ))}
        </div>
        <button
          onClick={(ev) => ev.preventDefault()}
          className={classnames('amount-custom flex-1 w-full', {
            '!bg-black !text-white': customAmountFocused || customAmount,
          })}
        >
          <input
            onFocus={(ev) => {
              ev.preventDefault();
              setShowZeroAmountError(false);
              setShowMinimumAmountError(false);
              setCustomAmountFocused(true);
              setAmount(0);
            }}
            inputMode="numeric"
            placeholder={placeholder}
            type="text"
            value={customAmount === null ? '' : `$ ${customAmount}`}
            onChange={(ev) => {
              ev.preventDefault();

              const value = ev.target.value.replace('$', '').trim();
              setCustomAmount(isNaN(parseInt(value)) ? '' : parseInt(value));
              setAmount(0);
            }}
            onKeyPress={(ev) => {
              if (ev.key === 'Enter') {
                ev.preventDefault();

                if (customAmount && onEnter) {
                  onAmountUpdate(customAmount * 100);
                  onEnter(ev);
                }
              }
            }}
            onBlur={(ev) => {
              ev.preventDefault();

              if (!amount && !customAmount) {
                setShowZeroAmountError(true);
              } else if (minimumAmount && minimumAmount > customAmount * 100) {
                // don't show both
                setShowMinimumAmountError(true);
              }

              onAmountUpdate(customAmount * 100);

              setCustomAmountFocused(false);
            }}
          />
        </button>

        {showZeroAmountError && (
          <>
            <ErrorCard message="Please enter a positive donation amount" />
          </>
        )}
      </div>

      {minimumAmount && showMinimumAmountError && (
        <>
          <div className="py-2.5" />
          <ErrorCard
            message={`Please enter an amount of $${
              minimumAmount / 100
            } or more`}
          />
        </>
      )}
    </>
  );
};

export default AmountSelector;
