import React from 'react';
import { useStripe, useElements } from '@stripe/react-stripe-js';

import AddCard from 'components/SavedPaymentMethods/AddCard';

const AddNewCardContent = ({ onSubmit }) => {
  const stripe = useStripe();
  const elements = useElements();

  return (
    <div className="flex-1 w-full px-5 flex flex-col justify-between">
      <div>
        <div className="flex justify-center w-full">
          <div className="font-agenda-bold text-[20px] mx-auto py-2">
            Add New Card
          </div>
        </div>
      </div>
      <div>
        <AddCard showAddCard defaultOpen isCheckout />
        <button
          className="cz-btn w-full"
          onClick={() => {
            onSubmit({ stripe, elements });
          }}
        >
          OK
        </button>
      </div>
    </div>
  );
};

export default AddNewCardContent;
