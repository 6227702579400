import gql from 'graphql-tag';

const feedQuery = gql`
  query feedQuery(
    $id: ID
    $page: Int!
    $page_size: Int!
    $purchase_id: ID
    $type: FeedType!
    $user_context: UserContext
    $sort_order: FeedSortType
  ) {
    feed(
      id: $id
      page: $page
      pageSize: $page_size
      purchaseId: $purchase_id
      type: $type
      userContext: $user_context
      sortOrder: $sort_order
    ) {
      pageNumber
      pageSize
      total
      totalPages
      items {
        actor {
          id
          isSelf
          avatar {
            sm
            md
          }
          name
          body
          previewUrl
          image {
            md
            lg
            xl
            full
            __typename
          }
          entityType
          unclaimed
          isActive
        }
        initiator {
          id
          avatar {
            sm
            md
          }
          name
          entityType
        }
        matchActors {
          id
          avatar {
            sm
            md
          }
          name
          entityType
        }
        id
        eventId
        eventType
        purchaseId
        hasLiked
        shareLink
        donationAmount
        commentsCount
        hasCommented
        likes
        date
        subjects {
          id
          name
          subjectType
          avatar {
            sm
            md
            __typename
          }
        }
        type
        purchaseCount
        hasJoined
      }
    }
  }
`;

const lightweightFeedQuery = gql`
  query feedQuery(
    $id: ID
    $page: Int!
    $page_size: Int!
    $type: FeedType!
    $user_context: UserContext
  ) {
    feed(
      id: $id
      page: $page
      pageSize: $page_size
      type: $type
      userContext: $user_context
    ) {
      pageNumber
      pageSize
      total
      totalPages
      items {
        actor {
          id
          isSelf
          purchaseId
          avatar {
            sm
            md
          }
          name
          body
          previewUrl
          image {
            md
            lg
            xl
            full
            __typename
          }
          entityType
          isActive
          unclaimed
        }
        initiator {
          id
          avatar {
            sm
            md
          }
          name
          entityType
        }
        matchActors {
          id
          avatar {
            sm
            md
          }
          name
          entityType
        }
        id
        eventId
        eventDescription
        eventType
        purchaseId
        date
        subjects {
          id
          name
          subjectType
          avatar {
            sm
            md
            __typename
          }
        }
        type
        purchaseCount
        hasJoined
      }
    }
  }
`;

const purchaseFeedQuery = gql`
  query purchaseFeedQuery($eventId: Int!) {
    purchaseFeed(eventId: $eventId) {
      cauzePurchase {
        id
        commentBody # Body
        insertedAt # (derived) Days ago
        currentEntityLiked # Liked state
        actor {
          avatarUrls {
            md # Oval avatar image
          }
          id
          name # Investor name
        }
      }
      purchaseLikeCount # Total Likes
    }
  }
`;

const feedQueryFragment = `
  itemType
  totalActorCount
  commentCount
  likeCount
  currentEntityLiked
  purchaseComment
  projects {
    id
    name
  }
  activityRoot {
    activityEntity {
      id
      type
    }
  }
  joinedEntity {
    id
    name
    type
  }
  activityPurchase {
    insertedAt
    id
    itemType
    matchPurchases {
      matchSponsor {
        id
        name
        type
      }
      match {
        multiplier
        donorCount
      }
    }
  }
  activityEntity {
    id
    type
    name
    isCurrentEntity
    avatarUrls {
      md
    }
    user {
      isActive
      unclaimed
    }
  }
  event {
    id
    name
    eventType
    description
    giverCount
    purchaserCount
    current
    previewUrl
    image {
      lg
    }
    projects {
      id
      avatarUrls {
        md
        sm
      }
    }
    topPurchasers {
      actor {
        name
        avatarUrls {
          md
          sm
        }
      }
    }
  }
`;

const projectFeedQuery = gql`
  query projectFeedQuery($projectId: Int!, $limit: Int, $offset: Int) {
    projectFeed(projectId: $projectId, limit: $limit, offset: $offset) {
      ${feedQueryFragment}
    }
  }
`;

const userFeedQuery = gql`
  query userFeedQuery($userId: Int!, $limit: Int, $offset: Int) {
    userFeed(userId: $userId, limit: $limit, offset: $offset) {
      ${feedQueryFragment}
    }
  }
`;

const companyFeedQuery = gql`
  query companyFeedQuery($companyId: Int!, $offset: Int, $limit: Int) {
    companyFeed(companyId: $companyId, offset: $offset, limit: $limit) {
      ${feedQueryFragment}
    }
  }
`;

const globalFeedQuery = gql`
  query globalFeedQuery($offset: Int, $limit: Int) {
    globalFeed(offset: $offset, limit: $limit) {
      ${feedQueryFragment}
    }
  }
`;

const eventFeedQuery = gql`
  query eventFeedQuery($eventId: Int!, $limit: Int, $offset: Int) {
    cauzeFeed(eventId: $eventId, limit: $limit, offset: $offset) {
      ${feedQueryFragment}
    }
  }
`;

export {
  feedQuery,
  lightweightFeedQuery,
  purchaseFeedQuery,
  projectFeedQuery,
  userFeedQuery,
  companyFeedQuery,
  globalFeedQuery,
  eventFeedQuery,
};
