import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { navigate } from '@reach/router';

import useCompanyAdmin from '../../hooks/useCompanyAdmin';
import withUserContext from '../../behaviors/withUserContext';
import withAuthRequired from '../../behaviors/withAuthRequired';

import UpdateGiftsModal from 'components/modals/UpdateGiftsModal/UpdateGiftsModal';
import UserTable from 'components/UserTable/UserTable';
import Navbar from 'components/page/Navbar/Navbar';
import Footer from 'components/page/Footer/Footer';
import Button from 'components/Button/Button';
import CauzeSpinner from 'components/CauzeSpinner/CauzeSpinner';
import Back from 'components/page/Back/Back';

const PendingInvitesView = ({
  profileStore,
  companyAdminStore,
  companyId,
  uiStore,
}) => {
  const [showSendGiftModal, setShowSendGiftModal] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);

  useCompanyAdmin(companyId);
  const activeEntity = profileStore.getActiveEntity();
  const pendingInviteActions = [
    {
      name: 'Remove Invite',
      action: (ids) => {
        let unredeemedIds = ids.map((id) => id.replace('unredeemed-', ''));
        if (unredeemedIds.length > 0) {
          companyAdminStore.revokeUserTokens({ ids: unredeemedIds });
        }
      },
    },
    {
      name: 'Send Invite',
      action: (ids) => {
        let unredeemedIds = ids.map((id) => id.replace('unredeemed-', ''));
        if (unredeemedIds.length > 0) {
          companyAdminStore.resendCompanyInvites({
            ids: unredeemedIds,
            companyId,
          });
        }
      },
    },
    {
      name: 'Update Gift',
      action: (ids) => {
        setSelectedIds(ids);
        setShowSendGiftModal(true);
      },
    },
  ];

  return (
    <div className="company-admin pending-invites-view flex-column">
      <Navbar />
      {companyAdminStore.loading ? (
        <CauzeSpinner fullscreen />
      ) : (
        <div>
          {showSendGiftModal && (
            <UpdateGiftsModal
              selectedIds={selectedIds}
              isOpen
              activeEntity={activeEntity}
              updateGiftAmount
              companyAdminStore={companyAdminStore}
              companyId={companyId}
              onToggleClose={() => setShowSendGiftModal(false)}
              uiStore={uiStore}
            />
          )}
          <section>
            <div className="page-header container flex-row">
              <Back route="/admin" />
              <h1 className="title">Pending Invites</h1>
              {companyAdminStore.pending.length > 0 && (
                <div className="flex-row">
                  <Button
                    className="is-small invite-button"
                    onClick={() => {
                      companyAdminStore.resendCompanyInvites({
                        ids: companyAdminStore.pending.map(
                          (user) => user.tokenId,
                        ),
                        companyId,
                      });
                      navigate('/admin');
                    }}
                  >
                    Send All
                  </Button>
                  <Button
                    className="is-small"
                    onClick={() =>
                      uiStore.openModal('SEND_GIFTS', {
                        giftType: 'EMPLOYEE_GIFT',
                        isInviteFollowerExperience: true,
                        companyId,
                      })
                    }
                  >
                    Add
                  </Button>
                </div>
              )}
            </div>
          </section>
          <section>
            <div className="container">
              {companyAdminStore.pending.length > 0 ? (
                <UserTable
                  totalUsersBalance={companyAdminStore.totalUsersBalance}
                  companyEligibleMatch={companyAdminStore.companyEligibleMatch}
                  companyRemainingMatch={
                    companyAdminStore.companyRemainingMatch
                  }
                  actions={pendingInviteActions}
                  users={companyAdminStore.pending}
                  tableType="pending"
                  noFilter
                  store={companyAdminStore}
                />
              ) : (
                <div className="container flex-column flex-center flex-expand">
                  <p>You have no pending invites.</p>
                </div>
              )}
            </div>
          </section>
        </div>
      )}
      <Footer />
    </div>
  );
};

PendingInvitesView.propTypes = {
  profileStore: PropTypes.object.isRequired,
  companyAdminStore: PropTypes.object.isRequired,
  companyId: PropTypes.string.isRequired,
  uiStore: PropTypes.object.isRequired,
};

export default withAuthRequired(
  withUserContext(
    inject(
      'profileStore',
      'companyAdminStore',
      'uiStore',
    )(observer(PendingInvitesView)),
  ),
  { entityType: 'COMPANY' },
);
