import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import CauzeSpinner from '../CauzeSpinner/CauzeSpinner';

const CauzeButton = ({
  children,
  childrenOnHover,
  onClick,
  grey,
  white,
  black,
  support,
  narrow,
  small,
  large,
  tall,
  blackBorder,
  blackBorderThin,
  greyBorder,
  noBorder,
  className,
  disabled,
  isSubmitting,
  style,
  href,
  type,
  id,
  btnId,
  baseClass = 'cauze-button',
}) => {
  if (href) {
    return (
      <div id={id} className="cauze-button-container">
        <a
          style={style}
          className={classnames(
            baseClass,
            {
              'grey-theme': grey,
              'white-theme': white,
              'black-theme': black,
              'support-theme': support,
              narrow: narrow,
              small: small,
              large: large,
              'no-border': noBorder,
              'black-border': blackBorder,
              'grey-border': greyBorder,
              tall: tall,
            },
            className,
          )}
          href={href}
        >
          {isSubmitting && (
            <CauzeSpinner
              style={{
                margin: 'auto',
                marginTop: '-1.2rem',
                width: '20px',
              }}
              grey={white}
            />
          )}
          <span className={classnames({ 'is-submitting': isSubmitting })}>
            {children}
          </span>
        </a>
      </div>
    );
  } else {
    return (
      <div id={id} className="cauze-button-container">
        <button
          id={btnId}
          style={style}
          className={classnames(
            baseClass,
            {
              'grey-theme': grey,
              'white-theme': white,
              'support-theme': support,
              narrow: narrow,
              small: small,
              large: large,
              'no-border': noBorder,
              disabled: disabled,
              'black-border': blackBorder,
              'black-border-thin': blackBorderThin,
              'grey-border': greyBorder,
              tall: tall,
            },
            className,
          )}
          disabled={disabled}
          onClick={onClick}
          type={type}
          href={href}
        >
          {isSubmitting && (
            <CauzeSpinner
              style={{
                margin: 'auto',
                marginTop: '-1.2rem',
                width: '20px',
              }}
              grey={white}
            />
          )}
          <span
            className={classnames(
              { 'is-submitting': isSubmitting },
              { 'non-hover-child': childrenOnHover },
            )}
          >
            {children}
          </span>
          <span
            className={classnames(
              { 'is-submitting': isSubmitting },
              { 'hover-child': childrenOnHover },
            )}
          >
            {childrenOnHover}
          </span>
        </button>
      </div>
    );
  }
};

CauzeButton.defaultProps = {
  onClick: () => {},
  type: 'button',
  grey: false,
  white: false,
  support: false,
  narrow: false,
  noBorder: false,
  blackBorder: false,
  greyBorder: false,
  tall: false,
};

CauzeButton.propTypes = {
  onClick: PropTypes.func,
  support: PropTypes.bool,
  grey: PropTypes.bool,
  white: PropTypes.bool,
  children: PropTypes.node.isRequired,
  childrenOnHover: PropTypes.string,
  noBorder: PropTypes.bool,
  blackBorder: PropTypes.bool,
  greyBorder: PropTypes.bool,
  tall: PropTypes.bool,
  disabled: PropTypes.bool,
  style: PropTypes.object,
  isSubmitting: PropTypes.bool,
  type: PropTypes.string,
  href: PropTypes.string,
};

export default CauzeButton;
