import { useEffect } from 'react';
import userProfileStore from '../stores/UserProfileStore';

const useProfileById = ({ id, userContext }) => {
  useEffect(() => {
    userProfileStore.getUser({ id, userContext });
    userProfileStore.getFeed({ id, userContext });
    userProfileStore.getEvents({ id });
    userProfileStore.setLedgerFilters({});
  }, [id, userContext]);
};

export default useProfileById;
