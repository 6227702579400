import CheckMark from '../../assets/images/icons/checkMark/checkMarkThick.svg';

const CauzeCheckbox = ({ isSelected, onClick, id, disabled }) => (
  <div
    id={id}
    className={`cauze-checkbox ${isSelected && 'is-selected'}`}
    onClick={disabled ? undefined : onClick}
  >
    {isSelected && <img src={CheckMark} className="checkbox-checkmark" />}
  </div>
);

export default CauzeCheckbox;
