import mixpanel from 'mixpanel-browser';
import { convertIdToEntityType } from '../contextUtils';

const trackFollow = ({ actorContext, pageContext, targetContext, props }) => {
  mixpanel.track('Follow', getTrackingProps({ actorContext, pageContext, targetContext, props }));
};

const trackUnfollow = ({ actorContext, targetContext, pageContext, props }) => {
  mixpanel.track('Unfollow', getTrackingProps({ actorContext, pageContext, targetContext, props }));
};

const getTrackingProps = ({ actorContext, pageContext, targetContext, props }) => {
  const { id: actorId, entityType: actorEntityType } = convertIdToEntityType(actorContext || {});
  const { id: targetId, entityType: targetEntityType } = convertIdToEntityType(targetContext);

  const trackingProps = {
    actorId,
    actorEntityType,
    targetId,
    targetEntityType,
    ...props,
  };

  if (pageContext) {
    const { id: pageId, entityType: pageEntityType } = convertIdToEntityType(pageContext);
    trackingProps.pageId = pageId;
    trackingProps.pageEntityType = pageEntityType;
  }

  return trackingProps;
};

export {
  trackFollow, trackUnfollow,
};
