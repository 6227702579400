import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import classnames from 'classnames';
import useKeyboardEvent from '../../hooks/useKeyboardEvent.js';
import columns from './CompaniesTableColumns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';

const CompaniesTable = ({ companies, onAddToBalance, onGotoCompany }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [pageSize, setPageSize] = useState(companies.length);
  const [searchActive, setSearchActive] = useState(false);
  const [mobileSortActive, setMobileSortActive] = useState(false);
  const [mobileSort, setMobileSort] = useState({
    id: 'name',
    desc: false,
  });

  const MAX_PAGE_SIZE = 100;

  const tableColumns = columns(onAddToBalance);

  let tableRef = useRef(null);
  let searchInputRef = useRef(null);

  useEffect(() => {
    if (searchActive) {
      searchInputRef.current.focus();
    }
  }, [searchActive]);

  useKeyboardEvent('Escape', () => {
    toggleSearch(false);
  });

  const clearSearch = () => {
    setSearchTerm('');
    searchInputRef.current.value = '';
  };

  const toggleSearch = (isOpen = true) => {
    if (isOpen) {
      setMobileSortActive(false);
      setSearchActive(true);
      searchInputRef.current.focus();
      return;
    }
    setSearchActive(false);
    searchInputRef.current.blur();
    clearSearch();
  };

  const toggleMobileSort = (isOpen = true) => {
    if (isOpen) {
      setMobileSortActive(true);
      return;
    }
    setMobileSortActive(false);
  };

  return (
    <div className="rt-container">
      <div className="action-bar flex-row">
        <div className="responsive-row">
          <div className={classnames('search-input')}>
            <div
              tabIndex="0"
              onFocus={() => toggleSearch(true)}
              onClick={searchTerm !== '' ? clearSearch : toggleSearch}
              className="action-button"
            >
              <FontAwesomeIcon
                icon={faSearch} className={classnames('', {
                  orange: searchTerm !== '',
                })}/>
            </div>
            <input
              tabIndex={0}
              autoFocus={searchActive}
              className="input"
              placeholder="Search Companies&#8230;"
              ref={searchInputRef}
              onChange={e => setSearchTerm(e.target.value)}
              onFocus={toggleSearch}
              onBlur={() => setSearchActive(false)}
            />
            <div
              alt="clear all"
              className="action-button close-button flex-column"
              disabled={!(searchActive || searchTerm !== '')}
              onClick={() => {
                toggleSearch(false);
                toggleMobileSort(false);
              }}
            >
              <FontAwesomeIcon icon={faTimes} />
            </div>
          </div>
        </div>
        <div className="responsive-row flex-expand">
          <div className="flex-row flex-expand is-hidden-tablet"></div>
          <div className="flex-row">
            <div
              className={classnames('sort-action flex-column is-hidden-tablet')}
              onClick={
                mobileSortActive
                  ? () =>
                    setMobileSort({ ...mobileSort, desc: !mobileSort.desc })
                  : () => {
                    setMobileSort({ ...mobileSort, desc: !mobileSort.desc });
                    toggleMobileSort(true);
                  }
              }
            >
              <FontAwesomeIcon
                icon={faSortUp} className={classnames('', {
                  orange: mobileSort.desc,
                })}/>
              <FontAwesomeIcon
                icon={faSortDown} className={classnames('', {
                  orange: !mobileSort.desc,
                })}/>
            </div>
          </div>
        </div>
      </div>
      <ReactTable
        filterable
        className="ledger-table"
        resizable={false}
        keyField={'id'}
        ref={tableRef}
        columns={tableColumns}
        data={companies}
        pageSize={MAX_PAGE_SIZE}
        minRows={0}
        getTdProps={onGotoCompany}
        filtered={[{ id: 'company', value: searchTerm }]}
        defaultSorted={[{ id: 'company', desc: false }]}
        defaultPageSize={MAX_PAGE_SIZE}
        showPaginationBottom={pageSize > MAX_PAGE_SIZE}
        onFilterUpdateLength={len => setPageSize(len)}
        {...(mobileSortActive > 0 && { sorted: [mobileSort] })}
      />
    </div>
  );
};

CompaniesTable.propTypes = {
  companies: PropTypes.array,
  onAddToBalance: PropTypes.func.isRequired,
  onGotoCompany: PropTypes.func.isRequired,
};

export default CompaniesTable;
