import gql from 'graphql-tag';

const companiesQuery = gql`
  query companies {
    companies {
      id
      name
      url
      description
      shareLink
      avatar {
        sm
      }
      balance {
        total
      }
      emailDomains
      employeeCount
      invitedCount
    }
  }
`;

const addToCompanyBalanceMutation = gql`
  mutation addToCompanyBalanceMutation(
    $id: ID!
    $amount: Int
    $description: String
  ) {
    addToCompanyBalance(id: $id, amount: $amount, description: $description) {
      id
      total
    }
  }
`;

export { companiesQuery, addToCompanyBalanceMutation };
