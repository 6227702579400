import React from 'react';
import cx from 'classnames';

import Currency from 'components/Currency/Currency';
import useCheckoutStore from 'stores/CheckoutStore';
import WrappedPaypalButton from 'components/WrappedPaypalButton';
import PaymentRequestButton from 'components/PaymentRequestButton/PaymentRequestButton';

const SubmitButton = ({ isMobile, onSubmit, loading }) => {
  const addFundsGiftCheckoutStore = useCheckoutStore();
  const currentCheckout = addFundsGiftCheckoutStore.currentCheckout;
  const paymentType = addFundsGiftCheckoutStore.getPaymentType().toLowerCase();

  if (['apple_pay', 'google_pay'].includes(paymentType)) {
    return (
      <div className="w-full mt-2">
        <PaymentRequestButton store={addFundsGiftCheckoutStore} />
      </div>
    );
  }

  if (paymentType === 'paypal') {
    return (
      <WrappedPaypalButton {...addFundsGiftCheckoutStore.getPaypalVars()} />
    );
  }

  return (
    <button
      disabled={loading}
      className={cx('cz-btn w-full mt-2.5 md:mt-8', {
        'bg-lightgray-999': loading,
      })}
      onClick={onSubmit}
    >
      {isMobile ? (
        'OK'
      ) : (
        <>
          CONFIRM ADD FUNDS{' '}
          <Currency showCents amount={currentCheckout.splitAmount?.total} />
        </>
      )}
    </button>
  );
};

export default SubmitButton;
