import gql from 'graphql-tag';

const profileQuery = gql`
  query MyProfileQuery {
    data: me {
      id
      name
      firstName
      lastName
      username
      email
      backupEmail
      isVerified
      isInfluencer
      hasRecurringDeposit
      emailIsPrivate
      sysAdmin
      bio
      zip
      roles {
        balance {
          allowNegative
          total
        }
        company {
          modules
          prepayFees
          groupType
        }
        charity {
          canDonate
        }
        entity {
          avatar {
            sm
            md
          }
          entityType
          id
          name
          shareLink
          type
        }
        name
      }
      balance {
        total
      }
      avatar {
        sm
        md
      }
      hero {
        md
      }
    }
  }
`;

const profileFeedQuery = gql`
  query profileFeed($page: Int!, $page_size: Int!) {
    data: profileFeedV2(page: $page, pageSize: $page_size) {
      pageNumber
      pageSize
      total
      totalPages
      items {
        actors {
          id
          avatar {
            sm
            md
          }
          name
          body
          previewUrl
          image {
            md
            full
            __typename
          }
          type
        }
        id
        hasLiked
        donationAmount
        commentsCount
        hasCommented
        likes
        date
        subjects {
          id
          name
          type
          avatar {
            sm
            md
            __typename
          }
        }
        type
        purchaseCount
        hasJoined
      }
    }
  }
`;

const balanceQuery = gql`
  query MyBalanceQuery {
    myBalance {
      updatedAt
      total
      userBalanceCredits {
        id
        amount
        description
        endingBalance
        type
        createdAt
      }
      userBalanceDebits {
        id
        amount
        description
        endingBalance
        type
        createdAt
        giftType
      }
    }
  }
`;

const mySupportedCharitiesQuery = gql`
  query mySupportedCharities {
    data: mySupportedCharities {
      id
      name
      city
      state
      avatar {
        sm
        md
      }
      donationAmount
    }
  }
`;

const updateProfileMutation = gql`
  mutation UpdateUser(
    $id: ID!
    $first_name: String
    $last_name: String
    $email: String
    $backup_email: String
    $username: String
    $bio: String
    $zip: String
  ) {
    updateUser(
      id: $id
      firstName: $first_name
      lastName: $last_name
      email: $email
      backupEmail: $backup_email
      username: $username
      bio: $bio
      zip: $zip
    ) {
      id
      firstName
      lastName
      username
      email
      backupEmail
      bio
      zip
    }
  }
`;

const updateProfileMutationV2 = gql`
  mutation UpdateUser($update: UserUpdateInput!) {
    updateUser(update: $update) {
      id
      firstName
      lastName
      username
      foundationName
      zip
      webUrl
      shareLink
      userSocials {
        socialType
        url
      }
    }
  }
`;

const setMonthlyGoalMutation = gql`
  mutation setMonthlyGoal($goal: Int!) {
    setMonthlyGoal(goal: $goal)
  }
`;

const companyEntity = gql`
  query companyById($id: ID!) {
    data: companyById(id: $id) {
      id
      name
      prepayFees
      balance {
        allowNegative
        total
      }
      avatar {
        sm
        md
        lg
      }
      groupType
      modules
      restrictMatchAfterBalance
    }
  }
`;

const charityEntity = gql`
  query companyById($id: ID!) {
    data: charityById(id: $id) {
      id
      name
      balance {
        total
      }
      canDonate
      avatar {
        sm
        md
        lg
      }
    }
  }
`;

const influencerEntity = gql`
  query userById($id: ID!) {
    data: userById(id: $id) {
      id
      name
      balance {
        total
      }
      avatar {
        sm
        md
        lg
      }
    }
  }
`;

const emailUsubscribeMutation = gql`
  mutation emailUnsubscribe($email: String!) {
    emailUnsubscribe(email: $email) {
      status
    }
  }
`;

const emailAnnualReceiptMutation = gql`
  mutation emailAnnualReceiptMutation($year: Int!) {
    emailAnnualReceipt(year: $year) {
      status
    }
  }
`;

export {
  profileFeedQuery,
  profileQuery,
  mySupportedCharitiesQuery,
  balanceQuery,
  updateProfileMutation,
  updateProfileMutationV2,
  setMonthlyGoalMutation,
  companyEntity,
  charityEntity,
  influencerEntity,
  emailUsubscribeMutation,
  emailAnnualReceiptMutation,
};
