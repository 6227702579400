const getUserNameDefault = user => {
  if (!user) return;
  if (user.firstName) return user.firstName;

  if (user.username) return user.username;

  if (user.name) return user.name;
};

export {
  getUserNameDefault,
};
