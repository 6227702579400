import React from 'react';
import PropTypes from 'prop-types';

const SectionHeader = ({ title, subtitle, style }) => {
  return (
    <div className="section-header-wrapper" style={style}>
      <h3 className="section-header-title">{title}</h3>
      <p className="section-header-subtitle">{subtitle}</p>
    </div>
  );
};

SectionHeader.propTypes = {
  title: PropTypes.string.isRequired,
  lineColor: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  style: PropTypes.object,
};

export default SectionHeader;
