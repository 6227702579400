import gql from 'graphql-tag';

const likeMutation = gql`
mutation likePurchaseItem($id: ID!, $user_context: UserContext) {
  like(purchaseId: $id, userContext: $user_context) {
    purchaseId
    likes
  }
}
`;

const unlikeMutation = gql`
mutation unlikePurchaseItem($id: ID!, $user_context: UserContext) {
  unlike(purchaseId: $id, userContext: $user_context) {
    purchaseId
    likes
  }
}
`;

export {
  likeMutation,
  unlikeMutation,
};
