/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { Navbar } from 'components/page';
import FooterNew from 'components/page/Footer/FooterNew';

const EmailUnsubscribeView = ({ profileStore, email }) => (
  <div className="email-unsubscribe-view-container">
    <Navbar hideLoginPrompt />
    {profileStore.emailUnsubscribeResponse !== 'OK' && (
      <div className="unsubscribe-action-container">
        <div className="unsubscribe-confirmation-text-container">
          <p className="unsubscribe-confirmation-title-text">
            We're sad to see you go...
          </p>
          <p classname="unsubscribe-confirmation-text">
            You are unsubscribing from all Cauze email messages. (Including
            payment confirmation emails when you donate on Cauze). Are you sure?
          </p>
        </div>
        <div className="unsubscribe-confirmation-button-container">
          <p
            className="unsubscribe-confirmation-button"
            onClick={() => profileStore.setEmailUnsubscribe(email)}
          >
            {`YES, UNSUBSCRIBE ME`}
          </p>
        </div>
      </div>
    )}
    {profileStore.emailUnsubscribeResponse === 'OK' && (
      <div className="unsubscribe-action-container">
        <div className="unsubscribe-confirmation-text-container">
          <p className="unsubscribe-confirmation-title-text">All done!</p>
          <p classname="unsubscribe-confirmation-text">
            You will no longer receive Cauze email messages.
          </p>
        </div>
      </div>
    )}
    <FooterNew />
  </div>
);

EmailUnsubscribeView.propTypes = {
  profileStore: PropTypes.object.isRequired,
  email: PropTypes.string.isRequired,
};

export default inject('profileStore')(observer(EmailUnsubscribeView));
