import { useEffect } from 'react';
import searchStore from '../stores/SearchStore';
import donationLandingStore from '../stores/DonationLandingStore';

const useSearch = () => {
  useEffect(() => {
    searchStore.getTopics();
    searchStore.getCategories();
    searchStore.getCharities();
    if (donationLandingStore.topCauzes.length === 0) {
      donationLandingStore.getAssociatedEvents();
    }
  }, []);
};

export default useSearch;
