import { useEffect } from 'react';
import companyStore from '../stores/CompanyStore';
import { normalizeContext } from '../util/contextUtils';

const useCompanyProfileById = ({ id, userContext }) => {
  useEffect(() => {
    companyStore.getCompany({ id, userContext: normalizeContext(userContext) });
    companyStore.getCompanyFeed({
      id,
      userContext: normalizeContext(userContext),
    });
    companyStore.getCompanyMembers({
      id,
      userContext: normalizeContext(userContext),
    });
    companyStore.getCompanyEvents({ id });
  }, [id, userContext]);
};

export default useCompanyProfileById;
