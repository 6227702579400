import React from 'react';
import CauzeButton from 'components/CauzeButton/CauzeButton';
import useKeyboardEvent from '../../../hooks/useKeyboardEvent.js';
import classnames from 'classnames';

const PrivacyPolicyModal = ({ onToggleClose, action, isOpen }) => {
  useKeyboardEvent('Escape', () => {
    onToggleClose(false);
  });

  return (
    <div
      className={classnames('modal terms-of-use-modal', {
        'is-active': isOpen,
      })}
    >
      <div
        className="modal-background"
        onClick={() => {
          onToggleClose(false);
        }}
      ></div>
      <div className="modal-card ">
        <header className="modal-card-head">
          <p className="modal-card-title">PRIVACY POLICY</p>
        </header>
        <section className="modal-card-body">
          <div>(Last updated:March 18, 2018))</div>
          <div>
            This website (the "Site") is owned and operated by Cauze, Inc.
            ("we," "us", etc. or the "Company"). The purpose of this Privacy
            Policy is to inform our users ("you") about our online collection
            and use of information via this Site. By using this Site, you
            understand and agree to the terms of this Privacy Policy.
          </div>
          <div className="subheader">COLLECTION OF INFORMATION</div>
          <div>
            When you use the Site, we collect certain kinds of information as
            part of the Site's general operation, such as the website that
            referred you to us, your IP address, browser type and language, and
            access times. We may also collect navigational information,
            including information about the pages you view, the links you click,
            and other actions taken in connection with theSite. To the extent
            that we share this type of information externally, we do so only on
            an anonymous basis. We do not collect personally-identifiable
            information from you unless you knowingly provide it to us. If you
            personalize your profile, create an account, or provide grants
            preferences via your One4All DAF account, for example, we may
            collect personally-identifiable information such as your full name,
            address, telephone number, email address, and subject of inquiry. We
            may combine your visit and navigational information with the
            personal information that you provide for our internal purposes. It
            is always your choice whether to provide us with
            personally-identifiable information that we request, but the use of
            certain features (such as registering an account, making a donation,
            or submitting requests for on-line assistance to our "contact us"
            address) will not be available without such information from you.
          </div>
          <div className="subheader">
            USE OF COOKIES AND SIMILAR TECHNOLOGIES
          </div>
          <div>
            We may use cookies, small text files that are sent to your web
            browser and stored on your computer's hard drive, to improve and
            personalize your experience on our Site. Cookies contain information
            that can later be read by a web server in the domain that issued the
            cookie to you. The information that cookies collect may include the
            date and time of your visit, your registration information, and your
            navigational and funding or purchase history. In some cases, our
            third-party service providers may use cookies on the Site. We have
            no access to or control over these cookies. This Privacy Policy
            covers only our use of cookies; it does not cover the use of cookies
            by third parties. You have the ability to accept or decline cookies.
            Most browsers automatically accept cookies, but you can usually
            modify your browser setting to decline cookies. If you choose to
            decline cookies, you may not be able to sign in or use other
            interactive features of the Site and services that depend on
            cookies. In addition to cookies, we may use other technologies,
            including single-pixel gifs (also known as web beacons) on the Site
            and in promotional e-mail messages or newsletters. These tiny
            electronic images assist us in determining how many users have
            visited certain pages or opened messages or newsletters. We do not
            use these images to collect personal information.
          </div>
          <div className="subheader">USE OF INFORMATION</div>
          <div>
            We may use your personal information to operate and improve the
            Site, to process your transactions, to provide customer service, to
            perform analysis aimed at improving the Services, and to display
            content that is customized to your preferences. We also use your
            personal information to communicate with you. We may send
            transaction-related communications such as welcome notices and
            donation confirmations. We may also send you surveys or other
            marketing communications (such as newsletters) to inform you of new
            events, programs, or services or other information that may be of
            interest. If you do not wish to receive marketing communications,
            you may: (i) adjust your "Personal Information Preferences" within
            your profile settings,(ii) contact us at feedback@cauze.com, or
            (iii) follow the "unsubscribe" instructions included within each
            email communication.
          </div>
          <div className="subheader">SHARING OF INFORMATION</div>
          <div>
            Except as disclosed in this Privacy Policy, we do not share your
            personal information with any outside parties. We may share your
            personal information with service providers who perform services on
            our behalf. For example, we may hire other companies to handle the
            processing of payments, to provide data storage, to host websites,
            to assist in direct marketing, to conduct audits, etc. Those
            companies will be provided only the personal information they need
            to provide the service, may not use it for any other purpose, and
            must maintain its confidentiality. We may share your personal
            information within our corporate group, including with our
            subsidiaries and divisions and withOne4All Charitable Fund, a
            501(c)(3) public charity affiliated with us, all of whom may use
            your information for the purposes disclosed herein. In addition,
            Information about our customers, including personal information, may
            be disclosed as part of any merger, acquisition, or sale of the
            company and/or its assets, as well as in the unlikely event of
            insolvency, bankruptcy, or receivership, in which personal
            information would be transferred as one of the business assets of
            the company. We will notify you of such an occurrence as described
            in the "Changes to This Privacy Policy" section below. We reserve
            the right to disclose your personal information if required to do so
            by law, or in the good-faith belief that such action is reasonably
            necessary to comply with legal process, respond to claims, or
            protect the rights, property or safety of our company, employees,
            customers, or the public.
          </div>
          <div className="subheader">ACCESSING YOUR INFORMATION</div>
          <div>
            The security of your personal information is important to us. We
            follow generally accepted industry standards to help protect your
            personal information. For instance, when you enter sensitive
            information(such as a credit card number) for the purposes of making
            a donation, we (or our payment processor(s))encrypt that information
            using secure socket layer (SSL) technology. No method of
            transmission over theInternet, or method of electronic storage, is
            100% secure. Therefore, while we strive to protect your personal
            information, we cannot guarantee its absolute security. If a
            password is used to protect your account and personal information,
            it is your responsibility to keep your password confidential.
          </div>
          <div className="subheader">CHILDREN</div>
          <div>
            We do not intend to solicit or collect personal information from
            anyone under the age of 18. If you are under 18, you should not use
            or enter information on the Site, including without limitation, any
            interactive areas of the Site.
          </div>
          <div className="subheader">LINKS</div>
          <div>
            We may link to third-party websites, which have different privacy
            policies and practices from ours. We assume no responsibility for
            the policies or practices of such linked sites, and encourage you to
            become acquainted with them prior to use.
          </div>
          <div className="subheader">CHANGES TO THIS PRIVACY POLICY</div>
          <div>
            We reserve the right to change the terms of this Privacy Policy at
            any time.When we make changes, we will revise the "last updated"
            date at the top of the policy. If there are material changes to this
            statement orin how the Company may use your personal information, we
            will notify you by prominently by posting a notice of such changes
            here or on our home page, or by sending you an email. We encourage
            you to review this policy whenever you visit the Site If you have
            any questions about these terms, please direct them to
            feedback@cauze.com.
          </div>
        </section>
        <footer className="modal-card-foot">
          <CauzeButton
            className="button is-primary is-small"
            onClick={() => {
              action && action();
              onToggleClose(false);
            }}
          >
            Close
          </CauzeButton>
        </footer>
      </div>
    </div>
  );
};

export default PrivacyPolicyModal;
