import gql from 'graphql-tag';

const loginMutation = gql`
  mutation Authentication($identifier: String!, $password: String!) {
    authenticate(identifier: $identifier, password: $password) {
      token
      me {
        id
        firstName
        lastName
        username
        email
        balance {
          total
        }
      }
    }
  }
`;

const sendPasswordReset = gql`
  mutation sendPasswordReset($email: String!) {
    sendPasswordReset(email: $email) {
      status
    }
  }
`;

const passwordResetTokenValidate = gql`
  query passwordResetTokenValidateQuery($token: String!) {
    passwordResetTokenValidate(token: $token) {
      status
    }
  }
`;

const resetPassword = gql`
  mutation resetPassword($token: String!, $password: String!) {
    resetPassword(token: $token, password: $password) {
      email
    }
  }
`;

const getTokenMutation = gql`
  mutation getTokenMutation($email: String, $password: String) {
    getToken(email: $email, password: $password) {
      token
    }
  }
`;

const usernameValidationQuery = gql`
  mutation CheckUsername($username: String!) {
    checkUsername(username: $username) {
      available
      valid
    }
  }
`;

const emailValidationQuery = gql`
  mutation CheckEmail($email: String!) {
    checkEmail(email: $email) {
      available
      valid
    }
  }
`;

const userSignUpMutation = gql`
  mutation userSignUpMutation(
    $email: String!
    $backup_email: String
    $first_name: String!
    $last_name: String!
    $password: String!
    $zip: String
  ) {
    userSignUp(
      email: $email
      backupEmail: $backup_email
      firstName: $first_name
      lastName: $last_name
      password: $password
      zip: $zip
    ) {
      token
      me {
        username
      }
    }
  }
`;

const authorizedQuery = gql`
  query authorized {
    authorized {
      refreshToken
    }
  }
`;

const companyTokenDetailsQuery = gql`
  query userToken($token: String!) {
    userToken(token: $token) {
      company {
        name
        avatar {
          md
        }
      }
      gift {
        amount
      }
      redeemed
      email
    }
  }
`;

export {
  loginMutation,
  getTokenMutation,
  emailValidationQuery,
  usernameValidationQuery,
  userSignUpMutation,
  authorizedQuery,
  companyTokenDetailsQuery,
  sendPasswordReset,
  passwordResetTokenValidate,
  resetPassword,
};
