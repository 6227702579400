import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { navigate } from '@reach/router';

import useCompanyAdmin from '../../hooks/useCompanyAdmin';
import withAuthRequired from '../../behaviors/withAuthRequired';

import UserTable from 'components/UserTable/UserTable';
import UpdateGiftsModal from 'components/modals/UpdateGiftsModal/UpdateGiftsModal';
import ConfirmModal from 'components/modals/ConfirmModal/ConfirmModal';
import CreateMatch from 'components/dashboard/CreateMatch';
import AddEmployees from 'components/dashboard/AddEmployees';
import AddFunds from 'components/dashboard/AddFunds';
import Navbar from 'components/page/Navbar/Navbar';
import Footer from 'components/page/Footer/Footer';
import Button from 'components/Button/Button';
import CauzeSpinner from 'components/CauzeSpinner/CauzeSpinner';
import { convertEntityTypeToId } from '../../util/contextUtils';

import MultiStepModal from 'components/modals/MultiStepModal';

import {
  SelectAnnualMatchDetails,
  Summary,
  Advanced,
} from 'components/modals/CreateEditMatchModal/Steps';

const CompanyEmployeesView = ({
  profileStore,
  companyAdminStore,
  createMatchStore,
  uiStore,
  companyId,
}) => {
  const [showDashboard] = useState(true);
  const [showMatchModal, setShowMatchModal] = useState(false);
  const [showSendGiftModal, setShowSendGiftModal] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [confirmModalAction, setConfirmModalAction] = useState(() => {});
  const [creatingMatch, setCreatingMatch] = useState(false);
  useCompanyAdmin(companyId);

  const activeEntity = profileStore.getActiveEntity();

  // If the company type is a limited version, don't show employees and redirect to gifts
  if (
    companyId &&
    activeEntity &&
    activeEntity.modules &&
    !activeEntity.modules.includes('EMPLOYEE')
  ) {
    navigate(`/admin/company/${companyId}/gifts`);
  }

  const isGroup = activeEntity.groupType !== 'COMPANY';

  const onAddEmployee = () =>
    uiStore.openModal('SEND_GIFTS', {
      giftType: 'EMPLOYEE_GIFT',
      isInviteFollowerExperience: true,
      companyId: companyId,
      hideGiftAmounts: isGroup,
      existingEmails: companyAdminStore.users
        .map((user) => user.email)
        .concat(companyAdminStore.pending.map((user) => user.email)),
      onSuccess: () => companyAdminStore.getInitial(companyId),
    });

  const companyAdminActions = [
    {
      name: isGroup ? 'Remove Member' : 'Remove Employee',
      action: (ids) => {
        setConfirmModalAction(() => () => {
          let unredeemedIds = [];
          let userIds = [];
          ids.forEach((id) => {
            if (id.includes('unredeemed')) {
              unredeemedIds.push(id.replace('unredeemed-', ''));
            } else {
              userIds.push(id);
            }
          });
          if (unredeemedIds.length > 0) {
            companyAdminStore.revokeUserTokens({
              ids: unredeemedIds,
              revokeAll: true,
            });
          }
          if (userIds.length > 0) {
            companyAdminStore.removeCompanyUsers({
              userIds,
              companyId,
            });
          }
        });
        setShowConfirmModal(true);
      },
    },
    {
      name: 'Resend Invite',
      action: (ids) => {
        let unredeemedIds = ids
          .filter((id) => id.startsWith('unredeemed-'))
          .map((id) => id.replace('unredeemed-', ''));

        if (unredeemedIds.length > 0) {
          companyAdminStore.resendCompanyInvites({
            ids: unredeemedIds,
            companyId,
          });
        }
      },
    },
    {
      name: 'Send a Gift',
      action: (ids) => {
        setSelectedIds(ids);
        setShowSendGiftModal(true);
      },
    },
  ];

  const groupAdminActions = [
    {
      name: 'Send Donation Reminder',
      action: (ids) => {
        let userIds = ids.filter((id) => !id.startsWith('unredeemed-'));

        if (userIds.length > 0) {
          companyAdminStore.sendGroupDonationReminders({
            userIds,
            companyId,
          });
        }
      },
    },
    {
      name: 'Remove Member',
      action: (ids) => {
        setConfirmModalAction(() => () => {
          let unredeemedIds = [];
          let userIds = [];
          ids.forEach((id) => {
            if (id.includes('unredeemed')) {
              unredeemedIds.push(id.replace('unredeemed-', ''));
            } else {
              userIds.push(id);
            }
          });
          if (unredeemedIds.length > 0) {
            companyAdminStore.revokeUserTokens({
              ids: unredeemedIds,
              revokeAll: true,
            });
          }
          if (userIds.length > 0) {
            companyAdminStore.removeCompanyUsers({
              userIds,
              companyId,
            });
          }
        });
        setShowConfirmModal(true);
      },
    },
    {
      name: 'Resend Invite',
      action: (ids) => {
        let unredeemedIds = ids
          .filter((id) => id.startsWith('unredeemed-'))
          .map((id) => id.replace('unredeemed-', ''));

        if (unredeemedIds.length > 0) {
          companyAdminStore.resendCompanyInvites({
            ids: unredeemedIds,
            companyId,
          });
        }
      },
    },
  ];

  const onCreateMatch = () => {
    const isEditing = createMatchStore.editingMatchId;
    const isAnnual = createMatchStore.currentMatch.matchCharities?.length === 0;
    let balanceDifference = 0;

    if (isEditing && !isAnnual) {
      balanceDifference =
        createMatchStore.currentMatch.matchTotal -
        createMatchStore.previousMatchTotal -
        profileStore.activeEntity.balance.total;
    } else if (!isEditing) {
      balanceDifference =
        createMatchStore.currentMatch.matchTotal -
        profileStore.activeEntity.balance.total;
    }

    const matchMutation = createMatchStore.editingMatchId
      ? () => createMatchStore.editMatch(createMatchStore.editingMatchId)
      : () =>
          createMatchStore.createCompanyMatch({
            companyId,
          });

    if (
      (balanceDifference > 0 &&
        !profileStore.activeEntity.balance.allowNegative &&
        !isEditing) ||
      (isEditing && !isAnnual)
    ) {
      setShowMatchModal(false);

      uiStore.openModal('SEND_GIFTS', {
        defaultAmount: balanceDifference,
        giftType: 'SELF_GIFT',
        ...convertEntityTypeToId({
          entityType: profileStore.activeEntity.entityType,
          id: profileStore.activeEntity.id,
        }),
        onSuccess: matchMutation,
        onCancel: createMatchStore.onUnmount,
      });
    } else {
      setCreatingMatch(true);
      matchMutation().then(() => {
        companyAdminStore.getCompanyMatchDetails({
          id: companyId,
        });
        setCreatingMatch(false);
        setShowMatchModal(false);
      });
    }
  };

  const onLoadAnnualMatchModal = async () => {
    await createMatchStore.createAnnualMatchInit({
      restrictMatchAfterBalance:
        profileStore.activeEntity?.restrictMatchAfterBalance,
    });
    setShowMatchModal(true);
  };

  const actions = isGroup ? groupAdminActions : companyAdminActions;

  return (
    <div className="company-admin employees-view flex-column">
      <Navbar />
      {companyAdminStore.loading ? (
        <CauzeSpinner fullscreen />
      ) : (
        <div>
          {showMatchModal && (
            <MultiStepModal
              isOpen
              setIsOpen={setShowMatchModal}
              onToggleClose={setShowMatchModal}
              onCancel={createMatchStore.onUnmount}
              titles={[
                createMatchStore.editingMatchId
                  ? 'Edit Employee Match'
                  : 'Create an Employee Match',
                'Match Summary',
              ]}
              onConfirm={onCreateMatch}
              confirmButtonText={
                createMatchStore.editingMatchId ? 'Save Match' : 'Confirm Match'
              }
              isConfirming={creatingMatch}
              advancedView={<Advanced store={createMatchStore} />}
            >
              <SelectAnnualMatchDetails store={createMatchStore} />
              <Summary
                store={createMatchStore}
                balance={profileStore.activeEntity?.balance}
              />
            </MultiStepModal>
          )}
          {showSendGiftModal && (
            <UpdateGiftsModal
              activeEntity={activeEntity}
              selectedIds={selectedIds}
              isOpen
              companyAdminStore={companyAdminStore}
              companyId={companyId}
              onToggleClose={() => setShowSendGiftModal(false)}
              isGroup={isGroup}
            />
          )}
          {showConfirmModal && (
            <ConfirmModal
              isOpen
              message="Are you sure you want to delete selected users?"
              action={confirmModalAction}
              onToggleClose={() => setShowConfirmModal(false)}
            />
          )}
          {showDashboard && (
            <section>
              <div className="container">
                <div className="dashboard-container">
                  <h1 className="subtitle">Dashboard</h1>
                  <div className="dashboard flex-row">
                    <AddFunds
                      onClickAddFunds={() =>
                        uiStore.openModal('SEND_GIFTS', {
                          giftType: 'SELF_GIFT',
                          companyId,
                          onSuccess: () =>
                            companyAdminStore.getInitial(companyId),
                        })
                      }
                    />
                    <AddEmployees
                      onAddEmployee={onAddEmployee}
                      pendingCount={companyAdminStore.pending.length}
                      companyAdminStore={companyAdminStore}
                      companyId={companyId}
                      isGroup={isGroup}
                    />
                    {companyAdminStore.showMatchReminder && (
                      <CreateMatch
                        companyAdminStore={companyAdminStore}
                        onCreateMatch={onLoadAnnualMatchModal}
                        isGroup={isGroup}
                      />
                    )}
                  </div>
                </div>
              </div>
            </section>
          )}
          <section>
            <div className="page-header container flex-row">
              <h1 className="title">{isGroup ? 'Members' : 'Employees'}</h1>
              <Button
                style={{ marginLeft: 200 }}
                className="is-small"
                onClick={onAddEmployee}
              >
                Add
              </Button>
            </div>
          </section>
          <section>
            <div className="container">
              <UserTable
                totalUsersBalance={companyAdminStore.totalUsersBalance}
                companyEligibleMatch={companyAdminStore.companyEligibleMatch}
                companyRemainingMatch={companyAdminStore.companyRemainingMatch}
                companyUserMatchLimit={companyAdminStore.companyUserMatchLimit}
                totalGroupDonationCount={
                  companyAdminStore.totalGroupDonationCount
                }
                totalGroupDonationAmount={
                  companyAdminStore.totalGroupDonationAmount
                }
                totalGiftBalance={companyAdminStore.totalGiftBalance}
                actions={actions}
                users={companyAdminStore.users}
                totalDonationCount={companyAdminStore.totalDonationCount}
                isGroup={isGroup}
                store={companyAdminStore}
              />
            </div>
          </section>
        </div>
      )}
      <Footer />
    </div>
  );
};

CompanyEmployeesView.propTypes = {
  profileStore: PropTypes.object.isRequired,
  uiStore: PropTypes.object.isRequired,
  companyAdminStore: PropTypes.object.isRequired,
  createMatchStore: PropTypes.object.isRequired,
  companyId: PropTypes.string,
};

export default withAuthRequired(
  inject(
    'uiStore',
    'profileStore',
    'companyAdminStore',
    'createMatchStore',
  )(observer(CompanyEmployeesView)),
  {
    entityType: 'COMPANY',
  },
);
