import React from 'react';
import Button from 'components/Button/Button';
import { getAppLink } from '../../../util/userAgent';
import classnames from 'classnames';
import * as events from '../../../util/events/nav';
import facebookIcon from '../../../assets/images/icons/social/facebook.svg';
import instagramIcon from '../../../assets/images/icons/social/instagram.svg';
import logo from '../../../assets/images/brand/cauze-logotype-accent.svg';

const Footer = ({ short = false, hideGetAppButton = false, cobrand } = {}) => {
  const isCobrand = cobrand && cobrand.id !== '1';
  return (
    <footer
      className={classnames('footer max-vw', { 'footer-cobrand': isCobrand })}
    >
      <div className={classnames('content', { 'no-border': short })}>
        {isCobrand && (
          <div className="cobrand-detail">
            <div className="cobrand-title-container">
              <div className="cobrand-title">Brought to you by</div>
            </div>
            <div className="columns">
              <div className="column">
                {cobrand.logo?.original ? (
                  <img
                    src={cobrand.logo.original}
                    className="cobrand-logo"
                    alt={cobrand.name}
                  />
                ) : (
                  <div className="cobrand-name">{cobrand.name}</div>
                )}
                <p className="description">{cobrand.description}</p>
              </div>
              <div className="column">
                <img src={logo} className="cobrand-logo" alt="Cauze" />
                <p className="description">
                  An app where you can give and share all of your donations in
                  one place. Unleash your good!
                </p>
              </div>
            </div>
          </div>
        )}
        {!short && (
          <div className="footer-links">
            <div className="footer-title">Cauze</div>
            <div className="flex-row">
              <a
                className="footer-link"
                onClick={events.footerHomeLinkPress}
                href="https://www.cauze.com/home"
              >
                Home
              </a>
              <div className="divider">|</div>
              <a
                className="footer-link"
                onClick={events.footerEmployersLinkPress}
                href="https://www.cauze.com/employers"
              >
                Employers
              </a>
              <div className="divider">|</div>
              <a
                className="footer-link"
                onClick={events.footerNonprofitsLinkPress}
                href="https://www.cauze.com/nonprofits"
              >
                Nonprofits
              </a>
              <div className="divider">|</div>
              <a
                className="footer-link"
                onClick={events.footerAboutLinkPress}
                href="https://www.cauze.com/our-story"
              >
                About
              </a>
              <div className="divider">|</div>
              <a
                className="footer-link"
                onClick={events.footerHelpLinkPress}
                href="https://www.cauze.com/help"
              >
                Help
              </a>
            </div>
            <div className="button-container flex-row">
              <Button
                className="donate-button is-medium"
                onClick={events.footerDownloadLinkPress}
                href={getAppLink()}
              >
                Download The App
              </Button>
            </div>
            <div className="button-container flex-row">
              <a
                onClick={events.footerFacebookPress}
                href="https://www.facebook.com/cauzeapp/"
              >
                <img className="icon" src={facebookIcon} />
              </a>
              <a
                onClick={events.footerInstragramPress}
                href="https://www.instagram.com/cauzeapp/"
              >
                <img className="icon" src={instagramIcon} />
              </a>
            </div>
            <div className="disclaimer">
              App Store®, the Apple logo, and iPhone® are registered trademarks
              of Apple Inc. Google Play and the Google Play logo are trademarks
              of Google LLC.
            </div>
          </div>
        )}
        {short && !hideGetAppButton && (
          <div className="mobile-button flex-row flex-center">
            <Button
              onClick={events.footerDownloadLinkPress}
              className="donate-button is-small"
              href={getAppLink()}
            >
              Download The App
            </Button>
          </div>
        )}
        <div className="legal-links flex-row">
          <a
            target="_blank"
            onClick={events.footerTermsLinkPress}
            href="https://www.cauze.com/terms-of-service"
            className="legal-link"
          >
            Terms
          </a>
          <a
            target="_blank"
            onClick={events.footerPrivacyLinkPress}
            href="https://www.cauze.com/privacy-policy"
            className="legal-link"
          >
            Privacy
          </a>
          <a
            target="_blank"
            onClick={events.footerDonorAgreementPress}
            href="https://www.cauze.com/donor-agreement"
            className="legal-link"
          >
            Donor Agreement
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
