import React from 'react';
import PropTypes from 'prop-types';

import CauzesView from './CauzesView';
import withUserContext from '../../behaviors/withUserContext';
import withAuthRequired from '../../behaviors/withAuthRequired';
import useCauzes from '../../hooks/useCauzes';

const CompanyCauzesView = ({ companyId }) => {
  useCauzes({ userContext: { companyId } });

  return <CauzesView userContext={{ companyId }} />;
};

CompanyCauzesView.propTypes = {
  companyId: PropTypes.string,
};

export default withAuthRequired(withUserContext(CompanyCauzesView), {
  entityType: 'COMPANY',
});
