const Section = ({ children, title }) => {
  return (
    <section className="full-width">
      <h2>{title}</h2>
      <div>{children}</div>
    </section>
  );
};

export default Section;
