import { useEffect } from 'react';
import companyGiftsStore from '../stores/CompanyGiftsStore';

const useCompanyGifts = companyId => {
  useEffect(() => {
    companyGiftsStore.onUnmount();
    if (companyId) {
      companyGiftsStore.getInitial(companyId);
    }
  }, [companyId]);
};

export default useCompanyGifts;
