/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import entityGiftsStore from '../stores/EntityGiftsStore';

const useEntityGifts = userContext => {
  useEffect(() => {
    entityGiftsStore.onUnmount();
    if (userContext) {
      entityGiftsStore.getInitial(userContext);
    }
  }, []);
};

export default useEntityGifts;
