import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';

import withUserContext from '../../behaviors/withUserContext';
import withAuthRequired from '../../behaviors/withAuthRequired';

import Navbar from 'components/page/Navbar/Navbar';
import Footer from 'components/page/Footer/Footer';

import CharitySearch from 'components/CharitySearch/CharitySearch';

const CharitySearchView = () => (
  <div className="company-admin employees-view flex-column">
    <Navbar />
    <section>
      <div className="page-header container flex-row">
        <h1 className="title">Charity Search</h1>
      </div>
      <div style={{ margin: '2rem' }}>
        <CharitySearch />
      </div>
    </section>
    <Footer />
  </div>
);

CharitySearchView.propTypes = {
  profileStore: PropTypes.object.isRequired,
};

export default withAuthRequired(
  withUserContext(inject('profileStore')(observer(CharitySearchView))),
  { entityType: 'CHARITY' },
);
