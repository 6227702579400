import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import classnames from 'classnames';
import withUserContext from '../../../behaviors/withUserContext';
import CauzeButton from 'components/CauzeButton/CauzeButton';
import Select from 'components/Select/Select';

const CountrySelectorModal = ({
  title = 'Choose Country',
  buttonText = 'ok',
  isOpen,
  onToggleClose,
  onConfirm,
  countryWhitelist = [{ name: 'United States', code: 'us' }],
}) => {
  const [selectedCountry, setSelectedCountry] = useState(null);
  const updateSelectedCountry = (country) => {
    setSelectedCountry(country);
  };

  const onClose = () => {
    onToggleClose();
  };
  return (
    <div
      className={classnames('user-search-modal modal', {
        'is-active': isOpen,
      })}
    >
      <div onClick={onClose} className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">{title}</p>
          <button
            className="delete"
            aria-label="close"
            onClick={onClose}
          ></button>
        </header>
        <section className="modal-card-body flex-column">
          <Select
            options={countryWhitelist}
            stateChanger={updateSelectedCountry}
          />
        </section>
        <footer className="modal-card-foot">
          <CauzeButton
            className="button"
            narrow
            onClick={() => {
              onConfirm(selectedCountry);
              onClose();
            }}
          >
            {buttonText}
          </CauzeButton>
          <CauzeButton white noBorder className="button" onClick={onClose}>
            Cancel
          </CauzeButton>
        </footer>
      </div>
    </div>
  );
};

CountrySelectorModal.propTypes = {
  profileStore: PropTypes.object.isRequired,
  userContext: PropTypes.object,
  authStore: PropTypes.object.isRequired,
  isOpen: PropTypes.bool,
  onToggleClose: PropTypes.func.isRequired,
  uiStore: PropTypes.object,
  title: PropTypes.string,
  buttonText: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  countryWhitelist: PropTypes.array.isRequired,
};

export default withUserContext(
  inject(
    'profileStore',
    'authStore',
    'uiStore',
  )(observer(CountrySelectorModal)),
);
