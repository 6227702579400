import { useEffect } from 'react';

import addFundsGiftCheckoutStore from 'stores/AddFundsGiftCheckoutStore';

const useAddFundsGiftCheckout = ({
  userId,
  companyId,
  charityId,
  giftType,
  activeEntity,
}) => {
  useEffect(() => {
    addFundsGiftCheckoutStore.resetCheckout();
    addFundsGiftCheckoutStore.generatePlaidLinkToken();
    addFundsGiftCheckoutStore.getCheckoutDetails({
      userContext: { companyId, charityId, userId },
      giftType,
      activeEntity,
    });
  }, [userId, companyId, charityId, giftType]);
};

export default useAddFundsGiftCheckout;
