import React, { useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import classnames from 'classnames';

import useAddFundsGiftCheckout from 'hooks/useAddFundsGiftCheckout';
import withUserContext from 'behaviors/withUserContext';

import Button from 'components/Button/Button';
import Currency from 'components/Currency/Currency';
import CauzeSpinner from 'components/CauzeSpinner/CauzeSpinner';
import AmountSelector from 'components/AmountSelector/AmountSelector';
import ErrorModal from 'components/modals/ErrorModal';
import { DottedLine } from 'components/checkout/CheckoutConfirmationModal';
import PaymentOptionSelector from 'components/checkout/PaymentOptionSelector';

const FundGroupModal = ({
  isOpen,
  onToggleClose,
  activeEntity,
  addFundsGiftCheckoutStore,
  giftType = 'USER_GIFT',
  onSuccess = (_amt) => {},
  onCancel = () => {},
  defaultAmount = 1000,
  groupId,
}) => {
  useAddFundsGiftCheckout({
    ...activeEntity.userContext,
    giftType,
    activeEntity,
  });
  const stripe = useStripe();
  const elements = useElements();

  addFundsGiftCheckoutStore.setStripeObject(stripe);
  addFundsGiftCheckoutStore.setStripeElements(elements);

  useEffect(() => {
    if (defaultAmount && defaultAmount !== 0) {
      addFundsGiftCheckoutStore.updateCheckout({
        amount: defaultAmount,
        companyIds: [groupId],
      });
    }
  }, [addFundsGiftCheckoutStore, defaultAmount, groupId]);

  const onToggleCancel = () => {
    onCancel();
    onToggleClose();
    addFundsGiftCheckoutStore.resetCheckout();
  };

  const currentCheckout = addFundsGiftCheckoutStore.currentCheckout;

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (currentCheckout.amount === 0) {
      addFundsGiftCheckoutStore.popErrorModal('Please select an amount.');
      return;
    }

    if (!stripe || !elements) {
      return;
    }

    addFundsGiftCheckoutStore.startCheckout();
  };

  if (addFundsGiftCheckoutStore.checkoutDetailsFail) {
    return (
      <div
        className={classnames('add-funds-modal modal', {
          'is-active': isOpen,
        })}
      >
        <div onClick={onToggleCancel} className="modal-background"></div>
        <div className="modal-card">
          <section className="modal-card-body">
            <div className="text-bold">Couldn't get checkout Details</div>
            <Button
              style={{ marginTop: '2rem' }}
              className="is-medium"
              onClick={() =>
                addFundsGiftCheckoutStore.getCheckoutDetails({
                  userContext: activeEntity.userContext,
                })
              }
            >
              Try Again
            </Button>
          </section>
        </div>
      </div>
    );
  }

  if (addFundsGiftCheckoutStore.checkoutLoading) {
    return (
      <div
        className={classnames('add-funds-modal modal', {
          'is-active': isOpen,
        })}
      >
        <div onClick={onToggleCancel} className="modal-background"></div>
        <div className="modal-card">
          <CauzeSpinner />
        </div>
      </div>
    );
  }

  return (
    <div
      className={classnames('add-funds-modal modal', {
        'is-active': isOpen,
      })}
    >
      <div onClick={onToggleCancel} className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          {addFundsGiftCheckoutStore.showConfirmationModal ? (
            <p className="modal-card-title">Checkout Summary</p>
          ) : (
            <p className="modal-card-title">Add Funds to Group</p>
          )}
          <button
            className="delete"
            aria-label="close"
            onClick={onToggleCancel}
          ></button>
        </header>
        {addFundsGiftCheckoutStore.showConfirmationModal && (
          <section className="checkout-confirm-modal modal-card-body">
            <p>Check your summary and confirm to add funds.</p>
            {(currentCheckout.paymentType === 'ACH' ||
              currentCheckout.paymentType === 'CARD') && (
              <div className="flex-column">
                <div className="line-item flex-row">
                  <div>Your Amount</div>
                  <DottedLine />
                  <div>
                    <Currency
                      showCents
                      amount={currentCheckout.splitAmount.forCauze}
                    />
                  </div>
                </div>
                {currentCheckout.splitAmount.prepayFee !== 0 && (
                  <div className="line-item flex-row">
                    <div>5% Grant Processing Fee</div>
                    <DottedLine />
                    <div>
                      <Currency
                        showCents
                        amount={currentCheckout.splitAmount.prepayFee}
                      />
                    </div>
                  </div>
                )}
                <div className="line-item flex-row">
                  <div>Processing Fee</div>
                  <DottedLine />
                  <div>
                    <Currency
                      showCents
                      amount={currentCheckout.splitAmount.fee}
                    />
                  </div>
                </div>
                <div className="is-size-7">
                  {currentCheckout.paymentType === 'ACH'
                    ? `Our ACH processor charges a mandatory processing fee
                  of 0.8%. 100% of your intended donation goes to the
                  nonprofit.`
                    : `Our credit card processor charges a mandatory processing fee
                  of 2.2% + .30. 100% of your intended donation goes to the
                  nonprofit.`}
                </div>
                <hr />
                <div className="line-item total flex-row">
                  <div>Total:</div>
                  <DottedLine />
                  <div>
                    <Currency
                      showCents
                      amount={currentCheckout.splitAmount.total}
                    />
                  </div>
                </div>
              </div>
            )}
            {currentCheckout.paymentType === 'BALANCE' && (
              <div className="flex-column">
                <div className="line-item flex-row">
                  <div>Current Balance:</div>
                  <DottedLine />
                  <div>
                    <Currency showCents amount={currentCheckout.balance} />
                  </div>
                </div>
                <div className="line-item flex-row">
                  <div>Your Donation: </div>
                  <DottedLine />
                  <div>
                    <Currency
                      showCents
                      amount={currentCheckout.splitAmount.forCauze}
                    />
                  </div>
                </div>
                <div className="line-item flex-row">
                  <div>Remaining Balance: </div>
                  <DottedLine />
                  <div>
                    <Currency
                      showCents
                      amount={
                        currentCheckout.balance -
                        currentCheckout.splitAmount.forCauze
                      }
                    />
                  </div>
                </div>
                <hr />
                <div className="line-item total flex-row">
                  <div>Total:</div>
                  <DottedLine />
                  <div>
                    <Currency
                      showCents
                      amount={currentCheckout.splitAmount.forCauze}
                    />
                  </div>
                </div>
              </div>
            )}
            <div className="flex-row flex-center">
              <Button
                className="donate-button is-medium"
                onClick={() => {
                  addFundsGiftCheckoutStore
                    .completeCheckout()
                    .then((checkoutComplete) => {
                      if (checkoutComplete === true) {
                        onSuccess(currentCheckout.amount);
                        addFundsGiftCheckoutStore.resetCheckout();
                        onToggleClose();
                      }
                    });
                }}
              >
                Confirm Transaction
              </Button>
            </div>
          </section>
        )}
        <>
          <section
            className="modal-card-body"
            style={{
              display:
                addFundsGiftCheckoutStore.showConfirmationModal && 'none',
            }}
          >
            {addFundsGiftCheckoutStore.showErrorModal && (
              <ErrorModal
                isOpen
                errorText={addFundsGiftCheckoutStore.errorModalText}
                buttonText={addFundsGiftCheckoutStore.errorModalButtonText}
                onToggleClose={addFundsGiftCheckoutStore.onErrorModalClose}
              />
            )}
            <div className="form-container">
              <div className="bg-dark-gray flex-column flex-center">
                <div className="amount-header bg-dark-gray text-bold">
                  Amount you want to add:
                </div>
                <AmountSelector
                  amounts={[1000, 2500, 5000]}
                  defaultAmount={defaultAmount}
                  selectedAmount={currentCheckout.amount}
                  onAmountUpdate={(amt) =>
                    addFundsGiftCheckoutStore.updateCheckout({
                      amount: amt,
                    })
                  }
                />
                {currentCheckout.emails.length > 1 && (
                  <p className="option-group-subhead flex-row option-total">
                    <Currency amount={currentCheckout.amount} /> &nbsp;x{' '}
                    {currentCheckout.emails.length} =&nbsp;{' '}
                    <span className="text-bold">
                      <Currency
                        amount={
                          currentCheckout.amount * currentCheckout.emails.length
                        }
                      />
                    </span>
                  </p>
                )}
              </div>
              {currentCheckout.amount !== 0 && (
                <PaymentOptionSelector
                  activeEntity={activeEntity}
                  allowNegativeBalance={activeEntity.balance?.allowNegative}
                  excludedPaymentTypes={[]}
                  store={addFundsGiftCheckoutStore}
                />
              )}
            </div>
          </section>
          <footer
            className="modal-card-foot"
            style={{
              display:
                addFundsGiftCheckoutStore.showConfirmationModal && 'none',
            }}
          >
            <Button
              white
              className="donate-button is-small"
              onClick={onToggleCancel}
            >
              cancel
            </Button>
            {currentCheckout.paymentType !== 'PAYPAL' && (
              <Button
                className="donate-button is-small"
                onClick={handleSubmit}
                isSubmitting={addFundsGiftCheckoutStore.awaitTokenCreation}
                disabled={
                  (currentCheckout.amount !== 0 && !stripe) ||
                  !currentCheckout.paymentType
                }
              >
                Add Funds
              </Button>
            )}
          </footer>
        </>
      </div>
    </div>
  );
};

export default withUserContext(
  inject(
    'profileStore',
    'authStore',
    'uiStore',
    'addFundsGiftCheckoutStore',
  )(observer(FundGroupModal)),
);
