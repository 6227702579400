/* eslint-disable no-console */
import { observable, action, toJS, makeObservable } from 'mobx';
import { client as apolloClient } from '../util/apolloClient';
import uiStore from './UiStore';
import profileStore from './ProfileStore';
import {
  createCompanyMatchMutation,
  getMatchDetailsQuery,
  editCompanyMatchMutation,
} from '../graphql/companyMatching';

import moment from 'moment';

const MATCH_LIMIT_DEFAULT = 10000;

class CreateMatchStore {
  @observable currentMatch = {
    name: null,
    matchLimit: MATCH_LIMIT_DEFAULT,
    matchTotal: MATCH_LIMIT_DEFAULT * 5,
    startDate: null,
    endDate: null,
    multiplier: 1,
    restrictMatchAfterBalance: true,
    matchType: 'COMPANY',
  };

  @observable matchDates = [];

  @observable editingMatchId = null;
  @observable isEditingMatchAdmin = false;
  @observable previousMatchTotal = null;

  // For the modal closing to add funds before creating a match
  @observable persistThroughUnmount = false;
  @observable isAdvanced = false;
  @observable showAdvancedButton = true;

  @observable createError = false;
  @observable editError = false;

  @action updateMatch = props => {
    this.currentMatch = { ...this.currentMatch, ...props };
    global.IS_LOCAL_OR_DEV && console.log(toJS(this.currentMatch));
  };

  getCurrentMatch = () => toJS(this.currentMatch);

  createAnnualMatchInit = async ({ restrictMatchAfterBalance }) => {
    this.updateMatch({ restrictMatchAfterBalance });
    await this.getMatchDates();
  };

  getMatchDates = async () => {
    if (this.matchDates.length > 0) {
      return;
    }

    const currentQuarter = moment().quarter();

    // Dont need to calculate end of quarter 4, that's just end of the year
    if (currentQuarter !== 4) {
      const endOfQuarter = moment()
        .endOf('quarter')
        .subtract(1, 'day');

      this.matchDates.push({
        utc: endOfQuarter.utc().format(),
        name: endOfQuarter.format('LL'),
        date: endOfQuarter,
      });

      // 3 because max we add to the quarter is 3,
      // e.g. if we are in quarter 1 we only go to quarter 3
      for (let i = currentQuarter; i < 2; i++) {
        const dateToPush = moment()
          .add(i, 'quarter')
          .endOf('quarter')
          .subtract(1, 'day');

        this.matchDates.push({
          utc: dateToPush.utc().format(),
          name: dateToPush.format('MMMM, DD YYYY'),
          date: dateToPush,
        });
      }
    }

    const endOfYear = moment()
      .endOf('year')
      .subtract(1, 'day')
      .utc();

    this.matchDates.push({
      utc: endOfYear.format(),
      name: endOfYear.format('MMMM, DD YYYY'),
      date: endOfYear,
    });

    // no dates are loaded in for a new match, so set it to the first date we suggest
    if (!this.currentMatch.startDate && !this.currentMatch.endDate) {
      this.updateMatch({
        startDate: moment().utc(),
        endDate: this.matchDates[0].date,
      });
    }

    global.IS_LOCAL_OR_DEV && console.log('matchDates', toJS(this.matchDates));
  };

  @action createCompanyMatch = async ({ companyId }) => {
    try {
      const res = await apolloClient.mutate({
        variables: this.createMatchVariables({ companyId }),
        mutation: createCompanyMatchMutation,
        errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
      });
      if (res.errors) {
        throw res.errors;
      }

      profileStore.updateActiveEntityTotal(this.currentMatch.matchTotal * -1);

      this.onUnmount();
      uiStore.showNotification({
        body: `Your Match Has Been Created`,
        type: 'SUCCESS',
      });
    } catch (err) {
      this.error = true;
      if (err[0].message.includes('Insufficient')) {
        uiStore.showNotification({
          body: 'Insufficient funds. Please add funds before creating a match.',
          type: 'ERROR',
        });
      } else {
        uiStore.showNotification({
          body:
            'There was an error creating your match. Support has been notified.',
          type: 'ERROR',
        });
      }
      console.log(err);
    }
  };

  @action loadExistingMatch = async matchId => {
    this.editingMatchId = matchId;

    try {
      const result = await apolloClient
        .query({
          variables: { matchId },
          query: getMatchDetailsQuery,
          fetchPolicy: 'network-only',
          errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
        })
        .then(result => result.data.matchDetails);

      this.updateMatch({
        name: result.name,
        startDate: result.startDate ? moment(result.startDate).utc() : null,
        endDate: result.endDate ? moment(result.endDate).utc() : null,
        matchLimit: result.matchLimit,
        matchTotal: result.matchTotal,
        restrictMatchAfterBalance: result.restrictMatchAfterBalance,
        multiplier: result.multiplier,
        matchCharities: result.matchCharities,
        matchType: result.matchType,
      });

      this.previousMatchTotal = result.matchTotal;
      if (result.matchAdmin?.id === profileStore.activeEntity?.id) { this.isEditingMatchAdmin = true; }
    } catch (err) {
      this.editError = true;
      console.log(err);
    }
  };

  @action editMatch = async matchId => {
    try {
      const res = await apolloClient.mutate({
        variables: this.createMatchVariables({ matchId }),
        mutation: editCompanyMatchMutation,
        errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
      });

      if (this.previousMatchTotal < this.currentMatch.matchTotal) {
        profileStore.updateActiveEntityTotal(
          (this.currentMatch.matchTotal - this.previousMatchTotal) * -1,
        );
      }

      this.onUnmount();
      uiStore.showNotification({
        body: `Your Match Has Been Edited`,
        type: 'SUCCESS',
      });

      if (res.errors) {
        throw res.errors;
      }
    } catch (err) {
      if (err[0].message.includes('Insufficient')) {
        uiStore.showNotification({
          body: 'Insufficient funds. Please add funds before creating a match.',
          type: 'ERROR',
        });
      } else {
        uiStore.showNotification({
          body:
            'There was an error creating your match. Support has been notified.',
          type: 'ERROR',
        });
      }
      this.createError = true;
      console.log(err);
    }
  };

  createMatchVariables = ({ companyId, matchId }) => {
    let variables = {};

    variables.matchTotal = this.currentMatch.matchTotal;
    variables.matchLimit = this.currentMatch.matchLimit;
    variables.name = this.currentMatch.name;
    variables.restrictMatchAfterBalance = this.currentMatch.restrictMatchAfterBalance;
    variables.matchType = this.currentMatch.matchType;

    if (this.currentMatch.startDate) {
      variables.startDate = moment(this.currentMatch.startDate).format();
    }

    if (this.currentMatch.endDate) {
      variables.endDate = moment(this.currentMatch.endDate).format();
    }

    if (companyId) variables.userContext = { companyId };
    if (matchId) variables.id = matchId;
    if (this.currentMatch.multiplier) { variables.multiplier = this.currentMatch.multiplier; }

    if (
      this.previousMatchTotal &&
      this.previousMatchTotal > variables.matchTotal
    ) {
      // if the company is funding less than they used to, don't send it
      delete variables['matchTotal'];
    }
    return variables;
  };

  @action setPersistThroughUnmount = () => {
    this.persistThroughUnmount = true;
  };

  @action setIsAdvanced = isAdvanced => {
    this.isAdvanced = isAdvanced;
  };

  @action onUnmount = () => {
    if (this.persistThroughUnmount) {
      this.persistThroughUnmount = false;
      return;
    }

    this.currentMatch = {
      name: null,
      matchLimit: MATCH_LIMIT_DEFAULT,
      matchTotal: MATCH_LIMIT_DEFAULT * 5,
      startDate: null,
      endDate: null,
      multiplier: 1,
      restrictMatchAfterBalance: true,
      matchType: 'COMPANY',
    };

    this.editingMatchId = null;
    this.isEditingMatchAdmin = false;
    this.previousMatchTotal = null;
    this.matchDates = [];
    this.createError = false;
    this.editError = false;
    this.persistThroughUnmount = false;
    this.isAdvanced = false;
  };

  constructor () {
    makeObservable(this);
  }
}

const createMatchStore = new CreateMatchStore();
export default createMatchStore;
