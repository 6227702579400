import CheckoutSection from './CheckoutSection';
import Currency from 'components/Currency/Currency';
import CauzeAmountSelector from 'components/CauzeAmountSelector/CauzeAmountSelector';
import CauzeMatchList from 'components/CauzeMatchList/CauzeMatchList';
import CauzeCharityList from 'components/CauzeCharityList/CauzeCharityList';

const ChooseAmountSection = ({
  minimumDonationAmount,
  currentCheckout,
  checkoutDetails,
  authedOrUnauthedDonationStore,
  checkoutTitleEntity,
  location,
  onAmountUpdate,
}) => {
  const showMinDonation = minimumDonationAmount && minimumDonationAmount > 100;
  const customDonationAmounts = location?.state?.donationAmountPresets;

  const onMatchSelect = (matchId) => {
    if (currentCheckout.matchIds.includes(matchId)) {
      authedOrUnauthedDonationStore.updateCheckout({
        matchIds: currentCheckout.matchIds.filter((match) => match !== matchId),
      });
    } else {
      authedOrUnauthedDonationStore.updateCheckout({
        matchIds: [...currentCheckout.matchIds, matchId],
      });
    }
  };

  const onCharitySelect = (charityId) => {
    if (currentCheckout.charityIds.includes(charityId)) {
      authedOrUnauthedDonationStore.updateCheckout({
        charityIds: currentCheckout.charityIds.filter(
          (charity) => charity !== charityId,
        ),
      });
    } else {
      authedOrUnauthedDonationStore.updateCheckout({
        charityIds: [...currentCheckout.charityIds, charityId],
      });
    }
  };

  const filteredMatches = Array.isArray(checkoutDetails?.matches)
    ? checkoutDetails?.matches.filter((match) => {
        if (
          !match?.active ||
          Number.parseInt(match?.totalRemaining) <= 0 ||
          Number.parseInt(match?.userMatchLimit) <= 0
        ) {
          return false;
        }

        if (match?.currentEntityRemaining === undefined) {
          return true;
        }

        return Number.parseInt(match?.currentEntityRemaining) > 0;
      })
    : [];

  return (
    <CheckoutSection title="1. Choose Amount">
      {showMinDonation && (
        <h4>
          Minimum <Currency amount={minimumDonationAmount} />
        </h4>
      )}

      <CauzeAmountSelector
        defaultAmount={
          customDonationAmounts?.[0] || currentCheckout.donationAmount
        }
        amounts={customDonationAmounts || checkoutDetails.donationAmounts}
        selectedAmount={currentCheckout.donationAmount}
        minimumAmount={minimumDonationAmount}
        onAmountUpdate={(donationAmount) => {
          authedOrUnauthedDonationStore.updateCheckout({
            donationAmount,
          });

          // remove payment type if balance is selected and amount is greater than balance
          if (
            currentCheckout.paymentType === 'balance' &&
            donationAmount > currentCheckout.balance
          ) {
            authedOrUnauthedDonationStore.updateCheckout({
              paymentType: null,
              cardId: null,
              cardBrand: null,
              cardName: null,
              cardNickname: null,
            });
          }

          onAmountUpdate?.();
        }}
      />

      <div className="message-container">
        <div>
          <p>What inspired you to give today?</p>
          <textarea
            onInput={(input) =>
              authedOrUnauthedDonationStore.updateCheckout({
                comment: input.currentTarget.value,
              })
            }
            className="message-input"
            id="message"
            placeholder={`I'm supporting ${checkoutTitleEntity} because...`}
            rows="7"
            cols="50"
          />
        </div>
      </div>

      {checkoutDetails.charities?.length > 1 && (
        <div className="matching mb-8">
          <h3 className="uppercase">Charities</h3>
          <CauzeCharityList
            charities={checkoutDetails.charities}
            onSelect={onCharitySelect}
            selected={currentCheckout.charityIds}
            noCheckBox={checkoutDetails.charities.length === 1}
          />
        </div>
      )}

      {filteredMatches.length > 0 && (
        <div className="matching">
          <h3 className="uppercase">Donation Match</h3>
          <CauzeMatchList
            matches={filteredMatches}
            currentPaymentType={currentCheckout.paymentType}
            donationAmount={currentCheckout.donationAmount}
            selected={currentCheckout.matchIds}
            onSelect={onMatchSelect}
          />
        </div>
      )}
    </CheckoutSection>
  );
};

export default ChooseAmountSection;
