import React, { useState } from 'react';
import mixpanel from 'mixpanel-browser';

import Avatar from 'components/Avatar/Avatar';
import Currency from 'components/Currency/Currency';
import PropTypes from 'prop-types';
import ConfirmModal from 'components/modals/ConfirmModal/ConfirmModal';
import CauzeSwitch from 'components/CauzeSwitch/CauzeSwitch';
import classNames from 'classnames';

// get match amount if under max
const matchAmount = ({ donationAmount, match }) => {
  let maxMatch;
  let matchAmount = donationAmount * match.multiplier;

  if (match.userMatchLimit < match.totalRemaining) {
    maxMatch = match.userMatchLimit;
  } else {
    maxMatch = match.totalRemaining;
  }

  if (matchAmount > maxMatch) {
    return maxMatch;
  } else {
    return matchAmount;
  }
};

matchAmount.propTypes = {
  donationAmount: PropTypes.number.isRequired,
  match: PropTypes.object.isRequired,
};

// Show max amount matched if donation over limit
const maxText = ({ donationAmount, match }) => {
  if (
    donationAmount >= match.userMatchLimit ||
    donationAmount >= match.totalRemaining
  ) {
    return <span className="match-max"> (max)</span>;
  }
};

maxText.propTypes = {
  donationAmount: PropTypes.number.isRequired,
  match: PropTypes.object.isRequired,
};

// determine if one or more match sponsors
const matchSponsorName = (match) =>
  match.matchSponsors.length === 1
    ? match.matchSponsors[0].name
    : 'Event Sponsors';

matchSponsorName.propTypes = {
  match: PropTypes.object.isRequired,
};

// Match List Component
const CauzeMatchListItem = ({
  donationAmount,
  match,
  isSelected = true,
  onSelect,
  index,
  currentPaymentType,
}) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  let isMatchActive = match.paymentTypes.includes(
    currentPaymentType?.toUpperCase(),
  );

  const currentUserMatchRemaining =
    (match.currentEntityRemaining || match.userMatchLimit) * match.multiplier;

  const remainingMatchToShow =
    currentUserMatchRemaining < match.totalRemaining
      ? currentUserMatchRemaining
      : match.totalRemaining;
  const avatarObj =
    Array.isArray(match?.matchSponsors) && match?.matchSponsors?.length > 0
      ? match.matchSponsors.find(
          (sponsor) => Boolean(sponsor?.avatarUrls) || Boolean(sponsor?.avatar),
        )
      : null;

  return (
    <>
      <ConfirmModal
        isOpen={showConfirmModal}
        action={() => setShowConfirmModal(false)}
        onToggleClose={() => setShowConfirmModal(false)}
        message="Employer Set a Condition"
        bodyMessage={`${match.matchSponsors[0].name} will match your donation after spending your employer gift balance`}
        confirmOnly={true}
      />
      <div
        className={`cauze-match-item-container ${
          !isMatchActive && 'not-active'
        }`}
        onClick={(ev) => {
          ev.preventDefault();
          if (isMatchActive) {
            if ((match?.matchType || '').toUpperCase() !== 'EVENT') {
              if (isSelected) {
                mixpanel.track('User Unchecked Match', { matchId: match.id });
              } else {
                mixpanel.track('User Checked Match', { matchId: match.id });
              }

              // treat event matches as "disabled"
              onSelect(match.id);
            }
          } else {
            setShowConfirmModal(true);
          }
        }}
      >
        <Avatar
          md
          className={classNames('avatar', {
            'filter grayscale opacity-50': !isSelected,
          })}
          type="charity"
          avatar={avatarObj?.avatar}
          avatarUrls={avatarObj?.avatarUrls}
          index={index}
        />
        <div className="match-details">
          <div className="match-amount">
            +
            <Currency
              amount={matchAmount({ donationAmount, match })}
              showDollarSign={false}
            />
            {maxText({ donationAmount, match })}
          </div>
          <div className="max-match-amount">
            up to&nbsp;
            <Currency amount={remainingMatchToShow} />
            &nbsp;from
          </div>
          <div className="match-sponsor">{matchSponsorName(match)}</div>
        </div>
        <div className="match-checkbox-container">
          {isMatchActive && (
            <CauzeSwitch isSelected={isSelected} id={match.id} />
          )}
        </div>
      </div>
    </>
  );
};
CauzeMatchListItem.propTypes = {
  onSelect: PropTypes.func.isRequired,
  donationAmount: PropTypes.number.isRequired,
  match: PropTypes.object.isRequired,
  isSelected: PropTypes.bool.isRequired,
  index: PropTypes.number,
  currentPaymentType: PropTypes.string.isRequired,
};

export default CauzeMatchListItem;
