import { useEffect } from 'react';
import companyAdminStore from '../stores/CompanyAdminStore';

const useCompanyAdmin = companyId => {
  useEffect(() => {
    companyAdminStore.onUnmount();
    if (companyId) {
      companyAdminStore.getInitial(companyId);
    }
  }, [companyId]);
};

export default useCompanyAdmin;
