/* eslint-disable max-len */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Currency from '../Currency/Currency';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExclamationCircle,
  faCheck,
} from '@fortawesome/free-solid-svg-icons';

const EMAIL_RE =
  /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;

const PayrollLog = ({ entries = [], companyAdminStore }) => {
  let uploadSet = null;
  let displaySection = false;

  const getEntryUserDisplay = (entry) => {
    if (entry?.user?.firstName || entry?.user?.lastName) {
      return `${entry.user?.firstName || ''} ${
        entry.user?.lastName || ''
      }`.trim();
    }

    const entryEmail = entry.fileRow.match(EMAIL_RE)?.[0];
    const foundUser = companyAdminStore.users.find(
      (user) => user.email === entryEmail,
    );

    if (foundUser?.fullName) {
      return foundUser?.fullName;
    } else if (entryEmail) {
      return entryEmail;
    }

    return 'Pending User';
  };

  useEffect(() => {
    if (entries?.length) {
      Promise.all(
        entries.map(async (entry) => {
          if (entry.user) return;

          const entryEmail = entry.fileRow.match(EMAIL_RE)?.[0];
          const foundUser = companyAdminStore.users.find(
            (user) => user.email === entryEmail,
          );

          if (foundUser && parseInt(foundUser.id)) {
            await companyAdminStore.updatePayrollRow({
              payrollId: entry.id,
              userId: foundUser.id,
            });
          }
        }),
      );
    }
  }, [entries]);

  return (
    <div>
      {entries.map((entry, index) => {
        if (entry.uploadedAt !== uploadSet) {
          uploadSet = entry.uploadedAt;
          displaySection = true;
        } else {
          displaySection = false;
        }
        return (
          <div key={entry.id}>
            {displaySection && (
              <div
                className="log-section"
                style={{ marginTop: index !== 0 ? 20 : 0 }}
              >
                {entry.filename} - uploaded{' '}
                {moment(entry.uploadedAt).format('MM/DD/YYYY')}
              </div>
            )}
            <div className="log-entry">
              {entry.error ? (
                <div>
                  <FontAwesomeIcon
                    icon={faExclamationCircle}
                    className="error"
                    aria-hidden="true"
                  />
                  {entry.error}
                </div>
              ) : (
                <div>
                  <FontAwesomeIcon
                    icon={faCheck}
                    className="success"
                    aria-hidden="true"
                  />
                  {moment(entry.payrollDate).format('MM/DD/YYYY')} -{' '}
                  {getEntryUserDisplay(entry)} -{' '}
                  <Currency amount={entry.amount} />
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

PayrollLog.propTypes = {
  entries: PropTypes.array,
  companyAdminStore: PropTypes.object.isRequired,
};

export default PayrollLog;
