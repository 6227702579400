import { useEffect } from 'react';
import cauzeStore from '../stores/CauzeStore';

const useCreateCauze = ({ userContext, eventId }) => {
  useEffect(() => {
    cauzeStore.onCreateUnmount();
    if (eventId) {
      cauzeStore.loadCauzeToEdit({ userContext, eventId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId]);
};

export default useCreateCauze;
