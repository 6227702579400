import React, { Component } from 'react';
import PropTypes from 'prop-types';

import url from 'url';
import youTubeIcon from '../../assets/images/icons/youtube/play.png';
import CauzeSpinner from 'components/CauzeSpinner/CauzeSpinner';

import { getLinkPreview } from 'link-preview-js';

import config from '../../config/index';

const checkHttps = (uri) => {
  if (uri.startsWith('https')) {
    return uri;
  } else {
    return `${uri.slice(0, 4)}s${uri.slice(4)}`;
  }
};

const proxyUri = (uri) =>
  `${config.API_ROOT}/opengraph/${encodeURIComponent(uri)}`;

class Preview extends Component {
  static propTypes = {
    uri: PropTypes.string.isRequired,
    onRemovePreview: PropTypes.func,
    onPreviewLoaded: PropTypes.func,
    smallPreview: PropTypes.bool,
    hideUntilLoaded: PropTypes.bool,
    style: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      title: null,
      image_url: null,
      link: props.uri,
      link_type: null,
      has_preview: false,
      width: 0,
      loading: true,
    };
  }

  fetchPreview = async (uri) => {
    if (this.mounted) {
      this.setState({ loading: true });
      try {
        const { title, images, url, mediaType } = await getLinkPreview(
          proxyUri(checkHttps(uri)),
        );
        if (this.props.onPreviewLoaded) {
          this.props.onPreviewLoaded({
            previewImageUrl:
              images.length > 0 ? checkHttps(images[0]) : undefined,
            title,
            url,
            mediaType,
          });
        }
        if (this.mounted) {
          this.setState({
            title: title,
            image_url: images.length > 0 ? checkHttps(images[0]) : undefined,
            link: url,
            link_type: mediaType,
            has_preview: true,
            loading: false,
          });
        }
      } catch (e) {
        console.log('Error loading link preview: ', e); // eslint-disable-line no-console
        if (this.mounted) {
          this.setState({
            has_preview: false,
            loading: false,
          });
        }
      }
    }
  };

  componentDidMount() {
    this.mounted = true;
    this.fetchPreview(this.props.uri);
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidUpdate(prevProps) {
    if (this.props.uri !== prevProps.uri) {
      this.fetchPreview(this.props.uri);
    }
  }

  render() {
    const { image_url, title, has_preview } = this.state;
    const hostname = url.parse(this.props.uri).hostname; // eslint-disable-line node/no-deprecated-api
    const isYouTube = this.props.uri.includes('youtu');
    if (this.state.loading) {
      if (this.props.hideUntilLoaded) {
        return null;
      }
      return (
        <div>
          <CauzeSpinner size="50px" />
        </div>
      );
    }

    if (!this.state.has_preview) {
      console.log('no preview');
      return null;
    }

    return (
      <div style={this.props.style} className="link-preview">
        {has_preview && this.props.smallPreview && (
          <div className="small-preview">
            <img className="preview-image" src={image_url} />
            <div className="flex-column flex-expand">
              <div className="host-name">
                {hostname.startsWith('www.') ? hostname.substring(4) : hostname}
              </div>
              <div className="title">{title}</div>
            </div>
            {this.props.onRemovePreview && (
              <div
                className="delete"
                onClick={this.props.onRemovePreview}
              ></div>
            )}
          </div>
        )}
        {has_preview && !this.props.smallPreview && (
          <a
            className="large-preview"
            target="_blank"
            href={this.props.uri}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {this.props.onRemovePreview && (
              <div className="delete" onClick={this.props.onRemovePreview}>
                x
              </div>
            )}
            {image_url && <img className="preview-image" src={image_url} />}
            {isYouTube && image_url && (
              <img className="youtube" src={youTubeIcon} />
            )}
            <div className="content">
              <div className="title">{title}</div>
            </div>
          </a>
        )}
      </div>
    );
  }
}

export default Preview;
export { checkHttps };
