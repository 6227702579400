import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { observer } from 'mobx-react';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import EntityList from './EntityList/EntityList';
import CauzeSpinner from 'components/CauzeSpinner/CauzeSpinner';

import useKeyboardEvent from 'hooks/useKeyboardEvent.js';
import { normalizeContext } from 'util/contextUtils';

const EntityListModal = ({
  title,
  entities,
  store,
  userContext,
  isOpen,
  showFollow,
  buttonLabel,
  onToggleClose,
  loadingKey,
  entitiesKey,
  isAuthenticated,
  hasSearch,
  confirmMessage,
}) => {
  const [searchValue, setSearchValue] = useState('');

  useKeyboardEvent('Escape', () => {
    onToggleClose(false);
  });

  const _userContext = normalizeContext(userContext);

  const setUnfollow = ({ onfollowCallback }) => {
    onfollowCallback();
  };

  const isLoading = loadingKey && store[loadingKey] === true;
  const _entities = entitiesKey ? store[entitiesKey] : entities;

  const filteredEntites = useMemo(() => {
    if (Array.isArray(_entities) && hasSearch) {
      return _entities.filter((entity) =>
        entity.name.toLowerCase().includes(searchValue.toLowerCase()),
      );
    }

    return _entities;
  }, [_entities, searchValue, hasSearch]);

  return (
    <div
      className={classnames('modal entity-list-modal', {
        'is-active': isOpen,
      })}
    >
      <div
        className="modal-background"
        onClick={() => {
          onToggleClose(false);
        }}
      ></div>
      <div className="modal-card">
        <section className="modal-card-body">
          <div className="entity-list-modal-title">
            <div className="entity-list-modal-close" onClick={onToggleClose}>
              <FontAwesomeIcon icon={faArrowLeft} />
            </div>
            <div className="entity-list-modal-title-text">{title}</div>
          </div>
          {Boolean(hasSearch) && (
            <div className="entity-list-modal-search">
              <input
                className="search-input"
                type="search"
                name="name"
                autoComplete="off"
                placeholder="Search users..."
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
            </div>
          )}
          <div className="entity-list-modal-content">
            {isLoading ? (
              <CauzeSpinner style={{ marginTop: '5rem' }} large />
            ) : (
              <EntityList
                entities={filteredEntites}
                showFollow={showFollow}
                buttonLabel={buttonLabel}
                userContext={_userContext}
                store={store}
                setUnfollow={setUnfollow}
                isAuthenticated={isAuthenticated}
                confirmMessage={confirmMessage}
              />
            )}
          </div>
        </section>
      </div>
    </div>
  );
};

EntityListModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onToggleClose: PropTypes.func.isRequired,
  entities: PropTypes.array,
  showFollow: PropTypes.bool,
  buttonLabel: PropTypes.string,
  loadingKey: PropTypes.string,
  entitiesKey: PropTypes.string,
};
export default observer(EntityListModal);
