import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
// import { navigate } from '@reach/router';

import useInfluencerDashboard from '../../hooks/useInfluencerDashboard';
import withAuthRequired from '../../behaviors/withAuthRequired';

import Navbar from 'components/page/Navbar/Navbar';
import Footer from 'components/page/Footer/Footer';
import Button from 'components/Button/Button';
import CauzeSpinner from 'components/CauzeSpinner/CauzeSpinner';
import InviteUsers from 'components/dashboard/InviteUsers';
import Share from 'components/dashboard/Share';
import AddFunds from 'components/dashboard/AddFunds';
import ConfirmModal from 'components/modals/ConfirmModal/ConfirmModal';

import InfluencerDashboardTable from 'components/Influencer/InfluencerDashboardTable';

const InfluencerDashboardView = ({
  influencerDashboardStore,
  profileStore,
  influencerId,
  uiStore,
}) => {
  const userContext = { userId: influencerId };
  const [showDashboard] = useState(true);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showCantRemoveModal, setShowCantRemoveModal] = useState(false);
  const [confirmModalAction, setConfirmModalAction] = useState(() => {});

  useInfluencerDashboard(influencerId);

  const influencerAdminActions = [
    {
      name: 'Remove Admin',
      action: (ids) => {
        if (ids.includes(influencerId)) {
          setShowCantRemoveModal(true);
        } else {
          setConfirmModalAction(() => () => {
            let unredeemedIds = [];
            let adminIds = [];
            ids.forEach((id) => {
              if (id.includes('unredeemed')) {
                unredeemedIds.push(id.replace('unredeemed-', ''));
              } else {
                adminIds.push(id);
              }
            });

            if (unredeemedIds.length > 0) {
              influencerDashboardStore.revokeAdminInvites({
                ids: unredeemedIds,
              });
            }
            if (adminIds.length > 0) {
              adminIds.map((id) =>
                influencerDashboardStore.revokeInfluencerAdmin({
                  userId: id,
                  targetId: influencerId,
                }),
              );
            }
          });

          setShowConfirmModal(true);
        }
      },
    },
  ];

  const shareLink = profileStore.activeEntity?.shareLink;

  return (
    <div className="influencer-admin flex-column">
      <Navbar />
      {influencerDashboardStore.loading ? (
        <CauzeSpinner fullscreen />
      ) : (
        <div>
          {showConfirmModal && (
            <ConfirmModal
              isOpen
              message="Are you sure you want to remove selected admins?"
              action={confirmModalAction}
              onToggleClose={() => setShowConfirmModal(false)}
            />
          )}
          {showCantRemoveModal && (
            <ConfirmModal
              isOpen
              message="Only influencer admins can be removed."
              action={() => {}}
              onToggleClose={() => setShowCantRemoveModal(false)}
              confirmOnly
            />
          )}
          {showDashboard && (
            <section>
              <div className="container">
                <div
                  style={{ marginTop: '1rem' }}
                  className="dashboard-container"
                >
                  <h1 className="subtitle">Dashboard</h1>
                  <div className="dashboard flex-row">
                    <InviteUsers
                      onClickInviteUsers={() =>
                        uiStore.openModal('SEND_GIFTS', {
                          giftType: 'USER_GIFT',
                          isInviteFollowerExperience: true,
                          userId: influencerId,
                        })
                      }
                    />
                    {shareLink && <Share shareUrl={shareLink} />}
                    <AddFunds
                      onClickAddFunds={() =>
                        uiStore.openModal('SEND_GIFTS', {
                          giftType: 'SELF_GIFT',
                          userId: influencerId,
                          isSendGiftExperience: true,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
            </section>
          )}
          <section>
            <div className="page-header container flex-row">
              <h1 className="title">Account Admins</h1>

              <Button
                style={{ marginLeft: 200 }}
                className="is-small"
                onClick={() =>
                  uiStore.openModal('USER_SEARCH', {
                    title: 'Add an Account Admin',
                    buttonText: 'Add Account Admin',
                    onConfirm: ({ email, userId }) =>
                      influencerDashboardStore.addInfluencerAdmin({
                        email,
                        userId,
                        targetId: userContext.userId,
                      }),
                  })
                }
              >
                Add Account Admin
              </Button>
            </div>
          </section>
          <section>
            <div className="container flex-justify-center">
              {influencerDashboardStore.influencerAdmins.length > 0 && (
                <InfluencerDashboardTable actions={influencerAdminActions} />
              )}
            </div>
          </section>
        </div>
      )}
      <Footer />
    </div>
  );
};

InfluencerDashboardView.propTypes = {
  influencerDashboardStore: PropTypes.object.isRequired,
  profileStore: PropTypes.object.isRequired,
  uiStore: PropTypes.object.isRequired,
  influencerId: PropTypes.string.isRequired,
};

export default withAuthRequired(
  inject(
    'profileStore',
    'uiStore',
    'influencerDashboardStore',
  )(observer(InfluencerDashboardView)),
  { entityType: 'INFLUENCER' },
);
