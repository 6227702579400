import React from 'react';
import PropTypes from 'prop-types';

import giftIcon from 'assets/images/icons/svgs/gift-new.svg';
import checkedPersonIcon from 'assets/images/icons/svgs/checked-person.svg';
import addPersonIcon from 'assets/images/icons/svgs/add-person.svg';
import shareIcon from 'assets/images/icons/share/share-grey-thick.svg';

const ProfileAction = ({ variant, onClick }) => {
  let icon = giftIcon;

  if (variant === 'gift') {
    icon = giftIcon;
  }

  if (variant === 'follow') {
    icon = addPersonIcon;
  }

  if (variant === 'unfollow') {
    icon = checkedPersonIcon;
  }

  if (variant === 'share') {
    icon = shareIcon;
  }

  if (variant === 'edit') {
    return (
      <button className="profile-action-edit" onClick={onClick}>
        <span>EDIT PROFILE</span>
      </button>
    );
  }

  if (variant === 'give') {
    return (
      <button className="profile-action-give" onClick={onClick}>
        <span>GIVE</span>
      </button>
    );
  }

  if (variant === 'unfollow') {
    return (
      <button className="profile-action unfollow" onClick={onClick}>
        <img src={icon} />
      </button>
    );
  }

  return (
    <button className="profile-action" onClick={onClick}>
      <img src={icon} />
    </button>
  );
};

ProfileAction.propTypes = {
  variant: PropTypes.oneOf(['follow', 'gift', 'unfollow', 'edit', 'share']),
  onClick: PropTypes.func,
};

export default ProfileAction;
