import React, { useState } from 'react';
import { toJS } from 'mobx';
import PropTypes from 'prop-types';
import Button from 'components/Button/Button';
import AmountSelector from '../../AmountSelector/AmountSelector';
import Currency from '../../Currency/Currency';
import useKeyboardEvent from '../../../hooks/useKeyboardEvent.js';

import classnames from 'classnames';

const sendGifts = ({
  companyId,
  selectedIds,
  amount,
  commentText,
  companyAdminStore,
}) => {
  let unredeemedIds = [];
  let userIds = [];
  selectedIds.forEach((id) => {
    if (id.includes('unredeemed')) {
      unredeemedIds.push(id);
    } else {
      userIds.push(id);
    }
  });
  if (unredeemedIds.length > 0) {
    let unredeemedEmails = toJS(
      companyAdminStore.users
        .filter((user) => unredeemedIds.includes(user.id))
        .map((user) => user.email),
    );
    if (unredeemedEmails.length > 0) {
      companyAdminStore.sendCompanyInvites({
        emails: unredeemedEmails,
        pending: false,
        comment: commentText,
        companyId,
        amount,
      });
    }
    let pendingInvites = toJS(
      companyAdminStore.pending
        .filter((user) => unredeemedIds.includes(user.id))
        .map((user) => user.tokenId),
    );
    if (pendingInvites.length > 0) {
      companyAdminStore.updateCompanyInvites({
        ids: pendingInvites,
        comment: commentText,
        amount,
      });
    }
  }
  if (userIds.length > 0) {
    companyAdminStore.sendCompanyGifts({
      userIds,
      companyId,
      amount,
      comment: commentText,
    });
  }
};

const UpdateGiftsModal = ({
  isOpen,
  onToggleClose,
  companyAdminStore,
  companyId,
  selectedIds = [],
  isGroup,
  updateGiftAmount = false,
  activeEntity,
  uiStore,
}) => {
  const [amount, setAmount] = useState();
  const [commentText, setCommentText] = useState();
  useKeyboardEvent('Escape', () => {
    onToggleClose(false);
  });

  return (
    <div
      className={classnames('modal', {
        'is-active': isOpen,
      })}
    >
      <div className="modal-background"></div>
      <div className="modal-card send-gift-modal">
        <header className="modal-card-head">
          <p className="modal-card-title">
            {updateGiftAmount ? 'Update Gift Amount' : 'Send a Gift'}
          </p>
          <button
            className="delete"
            aria-label="close"
            onClick={onToggleClose}
          ></button>
        </header>
        <section className="modal-card-body">
          <div className="option-group">
            <p className="option-group-subhead is-size-5">
              Select a Gift Amount to give to {selectedIds.length}{' '}
              {isGroup ? 'member' : 'employee'}
              {selectedIds.length > 1 && 's'}:
            </p>
            <AmountSelector onAmountUpdate={(amt) => setAmount(amt)} />
            <p className="option-group-subhead flex-row option-total">
              Available Balance: &nbsp;
              <Currency amount={activeEntity.balance.total} />
            </p>
            <p className="option-group-subhead flex-row option-total">
              {typeof amount === 'number' && (
                <span>
                  <Currency amount={amount} /> &nbsp;x {selectedIds.length}{' '}
                  =&nbsp;{' '}
                  <span className="text-bold">
                    <Currency amount={amount * selectedIds.length} />
                  </span>
                </span>
              )}
              {!activeEntity?.balance?.allowNegative &&
                activeEntity.balance?.total < amount * selectedIds.length && (
                  <p>
                    &nbsp;Exceeds balance. Use{' '}
                    <a
                      onClick={() =>
                        uiStore.openModal('SEND_GIFTS', {
                          defaultAmount:
                            amount * selectedIds.length -
                            activeEntity?.balance?.total,
                          companyId,
                        })
                      }
                    >
                      Add Funds
                    </a>{' '}
                    to top up.
                  </p>
                )}
            </p>
          </div>
          <div className="option-group">
            <p className="option-group-subhead is-size-5">
              {updateGiftAmount
                ? 'Update Gift Message:'
                : 'Add a Message (Optional):'}
            </p>
            <div className="control">
              <textarea
                placeholder="Enter an invite message"
                className="textarea comment-field"
                onChange={(ev) => {
                  setCommentText(ev.target.value);
                }}
                value={commentText}
              />
            </div>
          </div>
        </section>
        <footer className="modal-card-foot">
          <Button
            disabled={
              typeof amount !== 'number' ||
              (!activeEntity?.balance?.allowNegative &&
                activeEntity?.balance?.total < amount * selectedIds.length)
            }
            className="button is-primary is-small"
            onClick={() => {
              sendGifts({
                companyId,
                selectedIds,
                amount,
                commentText,
                companyAdminStore,
              });
              onToggleClose(false);
            }}
          >
            {updateGiftAmount ? 'Update' : 'Send Now'}
          </Button>
          <button className="button is-small" onClick={onToggleClose}>
            cancel
          </button>
        </footer>
      </div>
    </div>
  );
};

UpdateGiftsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onToggleClose: PropTypes.func.isRequired,
  companyAdminStore: PropTypes.object,
  companyId: PropTypes.string.isRequired,
  selectedIds: PropTypes.array,
  updateGiftAmount: PropTypes.bool,
  isGroup: PropTypes.bool,
  activeEntity: PropTypes.object.isRequired,
  uiStore: PropTypes.object.isRequired,
};

export default UpdateGiftsModal;
