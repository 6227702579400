/* eslint-disable no-console */
import { observable, action, makeObservable } from 'mobx';
import { client as apolloClient } from '../util/apolloClient';
import uiStore from './UiStore';

import {
  influencersQuery,
  // revokeUserRoleMutation,
  assignUserRoleMutation,
  inviteInfluencerMutation,
} from '../graphql/influencers';

class InfluencersStore {
  @observable loading = true;
  @observable isError = false;

  @observable balanceTotal = 0;
  @observable followersCount = 0;
  @observable followingCount = 0;

  @observable influencers = [];

  getInitial = () => {
    this.getInfluencers();
  };

  @action getInfluencers = async () => {
    this.loading = true;

    const options = {
      variables: {},
      query: influencersQuery,
      fetchPolicy: 'no-cache',
      errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
    };

    let _balanceTotal = 0;
    let _followersCount = 0;
    let _followingCount = 0;

    try {
      this.influencers = await apolloClient.query(options).then(res => {
        if (res?.data?.influencers) {
          return res.data.influencers.map(influencer => {
            _balanceTotal += influencer.balance?.total;
            _followersCount += influencer.followerCount;
            _followingCount += influencer.followingCount;
            return {
              ...influencer,
              balance: influencer.balance?.total,
              entityType: 'INFLUENCER',
              shareLink: influencer.shareLink,
              name: `${influencer.firstName} ${influencer.lastName}`,
            };
          });
        }

        return [];
      });

      this.balanceTotal = _balanceTotal;
      this.followersCount = _followersCount;
      this.followingCount = _followingCount;

      this.loading = false;
      this.isError = false;
    } catch (err) {
      console.log(err);
      this.loading = false;
      this.isError = true;
      this.error = err;
    }
  };

  @action addInfluencer = async ({ userId, email }) => {
    try {
      if (email) {
        const options = {
          variables: {
            emails: [email],
          },
          query: inviteInfluencerMutation,
          fetchPolicy: 'no-cache',
          errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
        };
        const res = await apolloClient.query(options);
        this.influencers.push({
          id: res.data.sendGifts.token,
          firstName: email,
          lastName: '',
        });
      } else {
        const options = {
          variables: {
            roleType: 'INFLUENCER',
            userId,
          },
          query: assignUserRoleMutation,
          fetchPolicy: 'no-cache',
          errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
        };
        await apolloClient.query(options);
        this.getInfluencers();
      }
      uiStore.showNotification({ body: 'Influencer added.', type: 'SUCCESS' });
    } catch (err) {
      console.log(err);
    }
  };

  @action onUnmount = () => {
    this.isError = false;
    this.influencers = [];
    this.balanceTotal = 0;
    this.followersCount = 0;
    this.followingCount = 0;
  };

  constructor () {
    makeObservable(this);
  }
}

const influencersStore = new InfluencersStore();

export default influencersStore;
