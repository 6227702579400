import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import classnames from 'classnames';
import withUserContext from '../../../behaviors/withUserContext';
import { emailValidate } from '../../../util/formFieldValidators';
import Button from 'components/Button/Button';
import UserSearch from '../../UserSearch/UserSearch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

const UserSearchModal = ({
  title = 'User Search',
  buttonText = 'Add',
  isOpen,
  onToggleClose,
  onConfirm,
}) => {
  const [selectedUser, setSelectedUser] = useState(null);
  const [email, setEmail] = useState('');
  const [emailValid, setEmailValid] = useState(null);
  const onClose = () => {
    onToggleClose();
  };

  return (
    <div
      className={classnames('user-search-modal modal', {
        'is-active': isOpen,
      })}
    >
      <div onClick={onClose} className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">{title}</p>
          <button
            className="delete"
            aria-label="close"
            onClick={onClose}
          ></button>
        </header>
        <section className="modal-card-body flex-column">
          <div className="flex-column flex-center">
            Find an existing account:
          </div>
          <UserSearch onChange={(user) => setSelectedUser(user.id)} />
          <div
            style={{ margin: '1rem 0' }}
            className="flex-column flex-center text-bold"
          >
            {' '}
            or{' '}
          </div>
          <div className="flex-column flex-center">Invite a new user:</div>
          <div className="flex-row email-input">
            <FontAwesomeIcon icon={faEnvelope} className="envelope-icon" />
            <input
              type="text"
              placeholder="Enter email addresses"
              className="input"
              onChange={(ev) => {
                setEmail(ev.target.value);
                setEmailValid(emailValidate(ev.target.value));
              }}
              value={email}
            />
          </div>
        </section>
        <footer className="modal-card-foot">
          <Button white className="button" onClick={onClose}>
            Cancel
          </Button>
          <Button
            disabled={!emailValid && !selectedUser}
            className="button"
            onClick={() => {
              onConfirm({
                email: email !== '' ? email : null,
                userId: selectedUser,
              });
              onClose();
            }}
          >
            {buttonText}
          </Button>
        </footer>
      </div>
    </div>
  );
};

UserSearchModal.propTypes = {
  profileStore: PropTypes.object.isRequired,
  userContext: PropTypes.object,
  authStore: PropTypes.object.isRequired,
  isOpen: PropTypes.bool,
  onToggleClose: PropTypes.func.isRequired,
  uiStore: PropTypes.object,
  title: PropTypes.string,
  buttonText: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
};

export default withUserContext(
  inject('profileStore', 'authStore', 'uiStore')(observer(UserSearchModal)),
);
