import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';

import useCompanyAdmin from 'hooks/useCompanyAdmin';
import withUserContext from 'behaviors/withUserContext';
import withAuthRequired from 'behaviors/withAuthRequired';

import Navbar from 'components/page/Navbar/Navbar';
import Footer from 'components/page/Footer/Footer';
import Button from 'components/Button/Button';
import CauzeSpinner from 'components/CauzeSpinner/CauzeSpinner';
import PayrollLog from 'components/PayrollLog/PayrollLog';

const PayrollReportView = ({ companyAdminStore, uiStore, companyId }) => {
  const fileUploadRef = useRef(null);
  useCompanyAdmin(companyId);

  useEffect(() => {
    companyAdminStore.getPayrollReport();
  }, [companyAdminStore]);

  return (
    <div className="company-admin payroll-report flex-column">
      <Navbar />
      {companyAdminStore.loading ? (
        <CauzeSpinner fullscreen />
      ) : (
        <div>
          <section>
            <div className="page-header container flex-row">
              <h1 className="title">Payroll Report</h1>
              <Button
                isSubmitting={companyAdminStore.isUploadingCSV}
                className="is-small"
                onClick={() => fileUploadRef.current.click()}
              >
                Upload Payroll CSV
              </Button>
              <input
                ref={fileUploadRef}
                className="is-hidden"
                type="file"
                accept="text/csv"
                onChange={(e) => {
                  e.preventDefault();

                  const file = e.target.files[0];

                  if (file.type !== 'text/csv') {
                    uiStore.showNotification({
                      type: 'ERROR',
                      body: 'File must be a CSV file',
                    });
                  } else {
                    companyAdminStore.uploadPayrollCSV(file);
                  }
                }}
              />
            </div>
          </section>
          <section className="container">
            <div className="payroll-log">
              {companyAdminStore.csvUploaded && (
                <div className="file-upload-success">
                  {companyAdminStore.csvFilename} uploaded
                </div>
              )}
              <PayrollLog
                entries={companyAdminStore.payrollStatusReport}
                companyAdminStore={companyAdminStore}
              />
            </div>
          </section>
        </div>
      )}
      <Footer />
    </div>
  );
};

PayrollReportView.propTypes = {
  profileStore: PropTypes.object.isRequired,
  companyAdminStore: PropTypes.object.isRequired,
  companyId: PropTypes.string.isRequired,
};

export default withAuthRequired(
  withUserContext(
    inject(
      'profileStore',
      'uiStore',
      'companyAdminStore',
    )(observer(PayrollReportView)),
  ),
  {
    entityType: 'COMPANY',
  },
);
