import React, { useState, useRef, useEffect } from 'react';
import ReactTable from 'react-table';
import selectTableHOC from 'react-table/lib/hoc/selectTable';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSearch,
  faTimes,
  faSortUp,
  faSortDown,
  faFilter,
  faCaretRight,
} from '@fortawesome/free-solid-svg-icons';

import useKeyboardEvent from '../../hooks/useKeyboardEvent.js';
import Button from 'components/Button/Button';
import { companyColumns, groupColumns } from './UserTableColumns';

import {
  toggleSelection,
  toggleAll,
  useMultiSelect,
} from '../../util/tableUtils';

import { ReactComponent as DownloadIcon } from 'assets/images/icons/download/download.svg';

const SelectTable = selectTableHOC(ReactTable);

const UserTable = ({
  users,
  keyField = 'id',
  actions,
  companyEligibleMatch,
  companyRemainingMatch,
  companyUserMatchLimit,
  totalDonationCount,
  totalGiftBalance,
  totalGroupDonationCount,
  totalGroupDonationAmount,
  tableType = 'employees',
  noFilter = false,
  isGroup = false,
  store,
}) => {
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedAction, setSelectedAction] = useState('Actions');
  const [searchTerm, setSearchTerm] = useState('');
  const [searchActive, setSearchActive] = useState(false);
  const [statusFilter, setStatusFilter] = useState('all');
  const [giftsFilter, setGiftsFilter] = useState('all');
  const [matchFilter, setMatchFilter] = useState('all');
  const [pageSize, setPageSize] = useState(users.length);
  const [showOptions, setShowOptions] = useState(false);
  const [mobileSortActive, setMobileSortActive] = useState(false);
  const [mobileSort, setMobileSort] = useState({ id: 'email', desc: false });

  const MAX_PAGE_SIZE = 200;
  let userTableRef = useRef(null);
  let searchInputRef = useRef(null);
  let selectAllMobileRef = useRef(null);
  let selectAllRef = useRef(null);
  let userTableColumns;

  if (isGroup) {
    userTableColumns = groupColumns({
      totalGroupDonationCount,
      totalGroupDonationAmount,
      actions,
    });
  } else {
    userTableColumns = companyColumns({
      tableType,
      // totalUsersBalance,
      companyEligibleMatch,
      companyRemainingMatch,
      companyUserMatchLimit,
      totalDonationCount,
      totalGiftBalance,
      actions,
    });
  }

  useEffect(() => {
    if (searchActive) {
      searchInputRef.current.focus();
    }
  }, [searchActive]);

  useMultiSelect(selectAllRef, selectAllMobileRef, selectedIds, users);

  useKeyboardEvent('Escape', () => {
    toggleSearch(false);
  });

  const toggleOptionsModal = (isOpen) => {
    if (!isOpen) {
      document.documentElement.classList.remove('is-clipped');
      setShowOptions(false);
      return;
    }
    document.documentElement.classList.add('is-clipped');
    setShowOptions(true);
  };

  const clearAllFilters = () => {
    setStatusFilter('all');
    setGiftsFilter('all');
    setMatchFilter('all');
  };

  const clearSearch = () => {
    setSearchTerm('');
    searchInputRef.current.value = '';
  };

  const toggleSearch = (isOpen = true) => {
    if (isOpen) {
      setMobileSortActive(false);
      setSearchActive(true);
      searchInputRef.current.focus();
      return;
    }
    setSearchActive(false);
    searchInputRef.current.blur();
    clearSearch();
  };

  const toggleMobileSort = (isOpen = true) => {
    if (isOpen) {
      setMobileSortActive(true);
      return;
    }
    setMobileSortActive(false);
  };

  const toggleUserSelection = (key, shift, row) => {
    toggleSelection(key, shift, row, selectedIds, setSelectedIds);
  };

  const toggleAllUsers = () => {
    toggleAll(users, userTableRef, selectedIds, setSelectedIds, keyField);
  };

  const rowFn = (state, rowInfo, column, instance) => ({
    onClick: (e, handleOriginal) => {
      const { expanded } = state;
      const path = rowInfo.nestingPath[0];
      const diff = { [path]: !expanded[path] };

      instance.setState({
        expanded: { ...expanded, ...diff },
      });
      if (handleOriginal) {
        handleOriginal();
      }
    },
    className:
      rowInfo &&
      selectedIds.includes(`select-${rowInfo.original.id}`) &&
      'row-selected',
  });

  const isSelected = (key) => selectedIds.includes(`select-${key}`);

  const filters = isGroup
    ? [
        { id: 'email', value: searchTerm },
        { id: 'accountCreated', value: statusFilter },
      ]
    : [
        { id: 'email', value: searchTerm },
        { id: 'accountCreated', value: statusFilter },
        { id: 'gift', value: giftsFilter },
        { id: 'remainingMatch', value: matchFilter },
      ];

  return (
    <div className="rt-container">
      <div
        className={classnames('modal', {
          'is-active': showOptions,
        })}
      >
        <div className="modal-background"></div>
        <div className="modal-card search-options-modal">
          <header className="modal-card-head">
            <p className="modal-card-title">
              <FontAwesomeIcon icon={faFilter} />
              Filters
            </p>
            <button
              className="delete"
              aria-label="close"
              onClick={() => toggleOptionsModal(false)}
            ></button>
          </header>
          <section className="modal-card-body">
            <div className="option-group">
              <p className="option-group-subhead is-size-5">Status</p>
              <div className="control flex-column">
                <label className="radio" htmlFor="statusRadio1">
                  <input
                    type="radio"
                    value="all"
                    name="status"
                    id="statusRadio1"
                    checked={statusFilter === 'all'}
                    onChange={(e) => setStatusFilter(e.target.value)}
                  />
                  All
                </label>
                <label className="radio" htmlFor="statusRadio2">
                  <input
                    type="radio"
                    value="Account Created"
                    id="statusRadio2"
                    name="status"
                    checked={statusFilter === 'Account Created'}
                    onChange={(e) => setStatusFilter(e.target.value)}
                  />
                  Account Created
                </label>
                <label className="radio" htmlFor="statusRadio3">
                  <input
                    type="radio"
                    value="Waiting"
                    id="statusRadio3"
                    name="status"
                    checked={statusFilter === 'Waiting'}
                    onChange={(e) => setStatusFilter(e.target.value)}
                  />
                  Waiting
                </label>
                <label className="radio" htmlFor="statusRadio4">
                  <input
                    type="radio"
                    value="Cancelled"
                    id="statusRadio4"
                    name="status"
                    checked={statusFilter === 'Cancelled'}
                    onChange={(e) => setStatusFilter(e.target.value)}
                  />
                  Cancelled
                </label>
              </div>
            </div>
            {!isGroup && (
              <div className="option-group">
                <p className="option-group-subhead is-size-5">Match</p>
                <div className="control flex-column">
                  <label className="radio" htmlFor="matchRadio1">
                    <input
                      type="radio"
                      value="all"
                      name="match"
                      id="matchRadio1"
                      checked={matchFilter === 'all'}
                      onChange={(e) => setMatchFilter(e.target.value)}
                    />
                    All
                  </label>
                  <label className="radio" htmlFor="matchRadio2">
                    <input
                      type="radio"
                      value="match"
                      name="match"
                      id="matchRadio2"
                      checked={matchFilter === 'match'}
                      onChange={(e) => setMatchFilter(e.target.value)}
                    />
                    Remaining Match
                  </label>
                  <label className="radio" htmlFor="matchRadio3">
                    <input
                      type="radio"
                      value="no match"
                      name="match"
                      id="matchRadio3"
                      checked={matchFilter === 'no match'}
                      onChange={(e) => setMatchFilter(e.target.value)}
                    />
                    No Remaining Match
                  </label>
                </div>
              </div>
            )}
            {!isGroup && (
              <div className="option-group">
                <p className="option-group-subhead is-size-5">Company Gifts</p>
                <div className="control flex-column">
                  <label className="radio" htmlFor="giftsRadio1">
                    <input
                      type="radio"
                      value="all"
                      name="gifts"
                      id="giftsRadio1"
                      checked={giftsFilter === 'all'}
                      onChange={(e) => setGiftsFilter(e.target.value)}
                    />
                    All
                  </label>
                  <label className="radio" htmlFor="giftsRadio2">
                    <input
                      type="radio"
                      value="redeemed"
                      name="gifts"
                      id="giftsRadio2"
                      checked={giftsFilter === 'redeemed'}
                      onChange={(e) => setGiftsFilter(e.target.value)}
                    />
                    Has Redeemed Gifts
                  </label>
                  <label className="radio" htmlFor="giftsRadio3">
                    <input
                      type="radio"
                      value="unredeemed"
                      name="gifts"
                      id="giftsRadio3"
                      checked={giftsFilter === 'unredeemed'}
                      onChange={(e) => setGiftsFilter(e.target.value)}
                    />
                    Has Unredeemed Gifts
                  </label>
                </div>
              </div>
            )}
          </section>
          <footer className="modal-card-foot">
            <Button
              className="button is-primary"
              onClick={() => toggleOptionsModal(false)}
            >
              Apply
            </Button>
            <Button
              white
              className="button"
              onClick={() => {
                clearAllFilters();
                toggleOptionsModal(false);
              }}
            >
              Clear All
            </Button>
          </footer>
        </div>
      </div>
      <div className="action-bar flex-row">
        <div className="responsive-row">
          <div className={classnames('search-input')}>
            <div
              tabIndex="0"
              onFocus={() => toggleSearch(true)}
              onClick={searchTerm !== '' ? clearSearch : toggleSearch}
              className="action-button"
            >
              <FontAwesomeIcon
                icon={faSearch}
                className={classnames('', {
                  orange: searchTerm !== '',
                })}
              />
            </div>
            <div className="input-container">
              <input
                tabIndex={0}
                autoFocus={searchActive}
                className="input"
                placeholder={
                  isGroup ? 'search members...' : 'search employees...'
                }
                ref={searchInputRef}
                onChange={(e) => setSearchTerm(e.target.value)}
                onFocus={toggleSearch}
                onBlur={() => setSearchActive(false)}
              />
              <div
                alt="clear all"
                className="action-button close-button flex-column"
                disabled={
                  !(
                    mobileSortActive ||
                    searchActive ||
                    searchTerm !== '' ||
                    matchFilter !== 'all' ||
                    giftsFilter !== 'all' ||
                    statusFilter !== 'all'
                  )
                }
                onClick={() => {
                  toggleSearch(false);
                  toggleMobileSort(false);
                  setStatusFilter('all');
                  setMatchFilter('all');
                  setGiftsFilter('all');
                }}
              >
                <FontAwesomeIcon icon={faTimes} />
              </div>
            </div>
            {Boolean(store.exportToCSV) && (
              <div className="download-icon" onClick={store.exportToCSV}>
                <span>
                  {store.isDownloading ? 'Downloading...' : 'Download'}
                </span>
                {!store.isDownloading && (
                  <DownloadIcon height={15} width={15} />
                )}
              </div>
            )}
          </div>
        </div>
        <div className="responsive-row flex-expand">
          <div className="select-all-check is-hidden-tablet">
            <input
              type="checkbox"
              checked={users.length === selectedIds.length}
              onChange={() => toggleAll()}
              ref={selectAllMobileRef}
            />
          </div>
          {actions && (
            <div
              className={classnames('actions-container flex-row flex-expand')}
            >
              <select
                value={selectedAction}
                onChange={(e) => setSelectedAction(e.target.value)}
              >
                <option value="Actions">Actions</option>
                {actions.map((action, index) => (
                  <option key={`${action.name}`} value={index}>
                    {action.name}
                  </option>
                ))}
              </select>
              <button
                className="button is-hidden-mobile is-primary is-small apply-button"
                disabled={
                  selectedAction === 'Actions' || selectedIds.length === 0
                }
                onClick={() => {
                  actions[selectedAction].action(
                    selectedIds.map((idString) =>
                      idString.replace('select-', ''),
                    ),
                  );
                  setSelectedIds([]);
                }}
              >
                Apply
              </button>
              <button
                className={classnames('is-hidden-tablet go-action', {
                  'is-disabled': selectedAction === 'Actions',
                })}
                disabled={
                  selectedAction === 'Actions' || selectedIds.length === 0
                }
                onClick={() =>
                  actions[selectedAction].action(
                    selectedIds.map((idString) =>
                      idString.replace('select-', ''),
                    ),
                  )
                }
              >
                <FontAwesomeIcon icon={faCaretRight} />
              </button>
            </div>
          )}
          <div
            className={classnames('flex-row flex-expand is-hidden-tablet', {
              'is-hidden-mobile': searchActive,
            })}
          />
          <div className="flex-row">
            <div
              className={classnames(
                'action-button sort-action flex-column is-hidden-tablet',
              )}
              onClick={
                mobileSortActive
                  ? () =>
                      setMobileSort({ ...mobileSort, desc: !mobileSort.desc })
                  : () => {
                      setMobileSort({ ...mobileSort, desc: !mobileSort.desc });
                      toggleMobileSort(true);
                    }
              }
            >
              <FontAwesomeIcon
                icon={faSortUp}
                className={classnames('', {
                  orange: mobileSort.desc,
                })}
              />
              <FontAwesomeIcon
                icon={faSortDown}
                className={classnames('', {
                  orange: !mobileSort.desc,
                })}
              />
            </div>
            {!noFilter && (
              <div
                className={classnames('action-button')}
                onClick={() => toggleOptionsModal(!showOptions)}
              >
                <FontAwesomeIcon
                  icon={faFilter}
                  className={classnames('', {
                    orange:
                      statusFilter !== 'all' ||
                      giftsFilter !== 'all' ||
                      matchFilter !== 'all',
                  })}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {selectedIds.length > 0 && (
        <div className="status-bar">
          {selectedIds.length} selected
          <button
            onClick={() => {
              setSelectedIds([]);
            }}
          >
            Deselect All
          </button>
        </div>
      )}
      <SelectTable
        filterable
        SelectAllInputComponent={() => (
          <input
            type="checkbox"
            aria-label="Select row with id:select-all"
            id="select-all"
            checked={users.length === selectedIds.length}
            ref={selectAllRef}
            onChange={toggleAllUsers}
          />
        )}
        resizable={false}
        className="user-table select-table"
        keyField={keyField}
        ref={userTableRef}
        toggleSelection={toggleUserSelection}
        selectAll={users.length === selectedIds.length}
        toggleAll={toggleAllUsers}
        selectType="checkbox"
        columns={userTableColumns}
        data={users}
        isSelected={isSelected}
        getTrProps={rowFn}
        getTheadProps={() => ({ className: 'is-hidden-mobile' })}
        pageSize={MAX_PAGE_SIZE}
        minRows={0}
        filtered={filters}
        defaultSorted={
          isGroup
            ? [{ id: 'lastDonationDate', desc: true }]
            : [{ id: 'email', desc: false }]
        }
        defaultPageSize={MAX_PAGE_SIZE}
        showPaginationBottom={pageSize > MAX_PAGE_SIZE}
        onFilterUpdateLength={(len) => setPageSize(len)}
        {...(mobileSortActive > 0 && { sorted: [mobileSort] })}
      />
    </div>
  );
};

export default UserTable;
