import React from 'react';
import PropTypes from 'prop-types';

const DonationLegalInfo = ({ uiStore }) => (
  <p className="legal-description">
    THE LEGAL STUFF: Your contribution is being made to Cauze Charitable Fund, a
    nonprofit, donor advised fund, which will distribute your donation to the
    nonprofit organization(s) that you indicated.{' '}
    <span
      onClick={() => {
        uiStore.openModal('TERMS');
      }}
    >
      MORE
    </span>
  </p>
);

DonationLegalInfo.propTypes = {
  uiStore: PropTypes.object.isRequired,
};

export default DonationLegalInfo;
