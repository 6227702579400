import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';

import useDonationLanding from 'hooks/useDonationLanding';
import withUserContext from 'behaviors/withUserContext';
import Navbar from 'components/page/Navbar/Navbar';
import FooterNew from 'components/page/Footer/FooterNew';
import FeedItem from 'components/FeedItem/FeedItem';
import BrandedBanner from 'components/BrandedBanner/BrandedBanner';
import Hero from 'components/Hero/Hero';
import SponsoredCards from 'components/SponsoredCards/SponsoredCards';
import CauzeSpinner from 'components/CauzeSpinner/CauzeSpinner';
import SectionHeader from 'components/SectionHeader/SectionHeader';
import CauzeButton from 'components/CauzeButton/CauzeButton';
import { getThnksCompanyId } from 'util/cobrandUtils';
import convertFeedToV1 from 'util/feed/convertFeedToV1';
import EventList from 'components/EventList/EventList';
import mergeFeedItems from 'util/feed/mergeFeedItems';
import LoginModal from 'components/modals/LoginModal/LoginModal';

const DonationLandingView = ({
  donationLandingStore,
  searchStore,
  uiStore,
  authStore,
  profileStore,
  activeEntity,
}) => {
  const [showLoginModal, setShowLoginModal] = useState(false);
  useDonationLanding();
  const userContext = profileStore.getUserContext()?.userContext;

  useEffect(() => {
    if (donationLandingStore.lastScrollPosition) {
      setTimeout(() => {
        window.scrollTo(0, donationLandingStore.lastScrollPosition);
      }, 1);
    }

    return () => donationLandingStore.updateScrollPosition();
  }, []);

  const onLike = ({ feedId }) => {
    if (!authStore.isAuthenticated) {
      uiStore.openModal('CONFIRM', {
        message: 'Please Login or Signup to like this post.',
        action: () => {
          setShowLoginModal(true);
        },
        confirmText: 'Login/Signup',
        cancelText: 'Not Now',
        bodyMessage:
          'Create your own Cauze account and join our community of givers.',
      });
      return;
    }

    const feed = donationLandingStore.recentFeedItems;
    const feedItem = feed?.find(
      (feedItem) => feedItem?.activityPurchase?.id === feedId,
    );

    if (feedItem) {
      donationLandingStore.like({
        like: !feedItem.currentEntityLiked,
        id: feedId,
        purchaseId: feedItem.activityPurchase?.id,
        userContext: userContext,
      });
    }
  };

  if (!authStore.isAuthenticated && donationLandingStore.giftRedeemed) {
    uiStore.showNotification({
      body: 'This gift has already been donated.',
      type: 'ERROR',
      onDismiss: () => {},
      autoDismiss: true,
    });
  }

  let cobrandCompany = null;
  // Only thnks is a cobrand company for now
  if (uiStore.savedUiState.userToken?.company?.id === getThnksCompanyId()) {
    cobrandCompany = uiStore.savedUiState.userToken.company;
  }

  let hero = null;
  let feed = null;

  // These conditionals will have the cauzeSpinner swapped out for skeleton components,
  // for now they're structured this way to make the change easily

  if (donationLandingStore.loadingGiftData) {
    hero = (
      <div className="loading-hero">
        <CauzeSpinner />
      </div>
    );
  } else {
    hero = (
      <Hero
        activeEntity={activeEntity}
        userToken={uiStore?.savedUiState?.userToken}
        searchStore={searchStore}
        uiStore={uiStore}
      />
    );
  }

  if (donationLandingStore.loadingFeedItems) {
    feed = (
      <div className="loading-feed-items">
        <CauzeSpinner />
      </div>
    );
  } else if (donationLandingStore.recentFeedItems.length > 0) {
    feed = (
      <div className="feed-wrapper">
        <div className="flex-expand flex-row flex-justify-start full-width">
          <SectionHeader
            title="Recent Giving Activity"
            lineColor="Orange"
            style={{ marginBottom: '1rem' }}
          />
        </div>
        <div className="feed">
          {mergeFeedItems(donationLandingStore.recentFeedItems).map(
            (item, index) => (
              <FeedItem
                item={convertFeedToV1(item)}
                key={index}
                index={index}
                onLike={onLike}
              />
            ),
          )}
        </div>
        <CauzeButton
          isSubmitting={donationLandingStore.loadingMore}
          white
          onClick={donationLandingStore.loadMoreRecentFeedItems}
          style={{ marginTop: '5rem' }}
        >
          Load More Activity
        </CauzeButton>
      </div>
    );
  }

  return (
    <div className="donation-landing">
      <LoginModal
        isOpen={showLoginModal}
        onToggleClose={() => setShowLoginModal(false)}
      />
      <Navbar redirectAfterContextSelect={false} cobrand={cobrandCompany} />
      {hero}
      <div className="content-container flex-column flex-align-center">
        <div className="cauzes-wrapper">
          <div className="top-cauzes flex-column">
            <SectionHeader
              title="Join Popular Cauzes"
              lineColor="Pink"
              subtitle="Cauzes are collective giving experiences"
            />
            <EventList
              events={donationLandingStore.topCauzes}
              loading={donationLandingStore.loadingCauzes}
              uiStore={uiStore}
            />
          </div>
        </div>
        {feed}
        {cobrandCompany && <SponsoredCards sponsor={cobrandCompany} />}
      </div>
      {!cobrandCompany && <BrandedBanner />}
      <FooterNew grey={cobrandCompany} />
    </div>
  );
};

DonationLandingView.propTypes = {
  donationLandingStore: PropTypes.object.isRequired,
  searchStore: PropTypes.object.isRequired,
  uiStore: PropTypes.object.isRequired,
  authStore: PropTypes.object.isRequired,
};

export default withUserContext(
  inject(
    'donationLandingStore',
    'searchStore',
    'uiStore',
    'authStore',
    'profileStore',
  )(observer(DonationLandingView)),
);
