/* eslint-disable no-console */
import {
  observable, // toJS
  action,
  makeObservable,
} from 'mobx';
import { client as apolloClient } from '../util/apolloClient';
import moment from 'moment';
import {
  listGiftsQuery,
  listUnredeemedGiftsQuery,
  refundGiftsMutation,
  revokeUserTokensMutation,
} from '../graphql/listGifts';

class EntityGiftsStore {
  @observable loading = true;
  @observable ledgerLoading = true;
  @observable isError = false;
  @observable ledgerData = [];
  @observable totalGifted = 0;
  @observable remainingGifted = 0;

  getInitial = userContext => {
    this.getLedger({ userContext });
  };

  @action getLedger = async ({ userContext }) => {
    this.ledgerLoading = true;

    try {
      let [listGifts, unredeemedGifts] = await Promise.all([
        this.getRedeemedGifts({ userContext }),
        this.getUnredeemedGifts({ userContext }),
      ]);

      this.ledgerData = listGifts
        .map(gift => {
          if (!gift.revoked) {
            this.totalGifted = this.totalGifted + gift.amount;
            this.remainingGifted = this.remainingGifted + gift.remaining;
          }
          return {
            ...gift,
            status: gift.revoked ? 'Revoked' : 'Redeemed',
            type: gift.type === 'USER_GIFT' ? 'Customer Gift' : 'Employee Gift',
            invite: false,
          };
        })
        // unredeemed gifts are actually user tokens coming back from the server
        .concat(
          unredeemedGifts.map(user => {
            if (!user.revoked) { this.totalGifted = this.totalGifted + user?.gift?.amount; }
            return {
              ...user,
              insertedAt: user.sentAt,
              amount: user.gift && user.gift.amount > 0 ? user.gift.amount : 0,
              // options are 'GIFT' for user and 'COMPANY_INVITE_WITH_GIFT' for employee invite gift
              type: user.type === 'GIFT' ? 'Customer Gift' : 'Invite & Gift',
              status: user.revoked ? 'Revoked' : 'Waiting',
              invite: true,
              id: `unredeemed-${user.id}`,
            };
          }),
        )
        // yeah, I know this is inefficient
        .sort((a, b) => {
          if (moment(a.insertedAt).isAfter(b.insertedAt)) {
            return 1;
          } else {
            return -1;
          }
        });

      this.ledgerLoading = false;
    } catch (err) {
      this.ledgerLoading = false;
      this.isError = true;
      this.error = err;
      if (global.IS_LOCAL_OR_DEV) {
        console.log(err);
      }
    }
  };

  getRedeemedGifts = ({ userContext }) => {
    const options = {
      variables: { userContext },
      query: listGiftsQuery,
      fetchPolicy: 'no-cache',
      errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
    };

    return apolloClient.query(options).then(res => res.data.listGifts);
  };

  getUnredeemedGifts = ({ userContext }) => {
    const options = {
      variables: { userContext },
      query: listUnredeemedGiftsQuery,
      fetchPolicy: 'no-cache',
      errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
    };

    return apolloClient
      .query(options)
      .then(res => res.data.listUnredeemedGifts);
  };

  @action revokeUserTokens = async ({ ids }) => {
    try {
      await apolloClient.mutate({
        variables: { ids },
        mutation: revokeUserTokensMutation,
        errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
      });

      this.ledgerData = this.ledgerData.map(gift => {
        if (ids.includes(gift.tokenId)) {
          return { ...gift, remaining: 0, status: 'Revoked' };
        } else {
          return gift;
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  @action refundGifts = ({ giftIds }) => {
    try {
      apolloClient.mutate({
        variables: { giftIds },
        mutation: refundGiftsMutation,
        errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
      });

      this.ledgerData = this.ledgerData.map(gift => {
        if (giftIds.includes(gift.id)) { return { ...gift, remaining: 0, status: 'Revoked' }; }
        return gift;
      });
    } catch (err) {
      console.log(err);
    }
  };

  constructor () {
    makeObservable(this);
  }

  @action onUnmount () {
    this.loading = true;
    this.ledgerLoading = true;
    this.isError = false;
    this.ledgerData = [];
    this.totalGifted = 0;
    this.remainingGifted = 0;
  }
}

const entityGiftsStore = new EntityGiftsStore();

export default entityGiftsStore;
