import { useEffect } from 'react';
import charitiesStore from '../stores/CharitiesStore';

const useCharities = () => {
  useEffect(() => {
    charitiesStore.onUnmount();
    charitiesStore.getInitial();
  }, []);
};

export default useCharities;
