import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import selectTableHOC from 'react-table/lib/hoc/selectTable';
import { observer, inject } from 'mobx-react';
import { debounce } from 'lodash';
import classnames from 'classnames';
import useKeyboardEvent from '../../hooks/useKeyboardEvent.js';
import {
  toggleSelection,
  toggleAll,
  useMultiSelect,
} from '../../util/tableUtils';
import columns from './InfluencerDashboardColumns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes, faSortUp, faSortDown, faCaretRight } from '@fortawesome/free-solid-svg-icons';

const SelectTable = selectTableHOC(ReactTable);

const InfluencersTable = ({
  influencerDashboardStore,
  actions,
  keyField = 'id',
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedAction, setSelectedAction] = useState(0);
  const [pageSize, setPageSize] = useState(
    influencerDashboardStore.influencerAdmins.length,
  );
  const [selectedAdmins, setSelectedAdmins] = useState([]);
  const [searchActive, setSearchActive] = useState(false);
  const [mobileSortActive, setMobileSortActive] = useState(false);
  const [mobileSort, setMobileSort] = useState({
    id: 'name',
    desc: true,
  });

  const MAX_PAGE_SIZE = 100;

  let tableRef = useRef(null);
  let searchInputRef = useRef(null);
  let selectAllRef = useRef(null);
  let selectAllMobileRef = useRef(null);

  const debouncedSetSearchTerm = debounce(setSearchTerm, 1000);

  useEffect(() => {
    if (searchActive) {
      searchInputRef.current.focus();
    }
  }, [searchActive]);

  useMultiSelect(
    selectAllRef,
    selectAllMobileRef,
    selectedAdmins,
    setSelectedAdmins,
  );

  useKeyboardEvent('Escape', () => {
    clearSearch();
  });

  const clearSearch = () => {
    setSearchTerm('');
  };

  const toggleSearch = (isOpen = true) => {
    if (isOpen) {
      setMobileSortActive(false);
      setSearchActive(true);
      searchInputRef.current.focus();
      return;
    }
    setSearchActive(false);
    searchInputRef.current.blur();
    clearSearch();
  };

  const toggleMobileSort = (isOpen = true) => {
    if (isOpen) {
      setMobileSortActive(true);
      return;
    }
    setMobileSortActive(false);
  };

  const toggleAdminSelection = (key, shift, row) => {
    toggleSelection(key, shift, row, selectedAdmins, setSelectedAdmins);
  };

  const toggleAllAdmins = () => {
    toggleAll(
      influencerDashboardStore.influencerAdmins,
      tableRef,
      selectedAdmins,
      setSelectedAdmins,
      keyField,
    );
  };

  const isSelected = key => selectedAdmins.includes(`select-${key}`);

  const adminColumns = columns();

  return (
    <div className="rt-container">
      <div className="action-bar flex-row">
        <div className="responsive-row">
          <div className={classnames('search-input')}>
            <div
              tabIndex="0"
              onFocus={() => toggleSearch(true)}
              onClick={searchTerm !== '' ? clearSearch : toggleSearch}
              className="action-button"
            >
              <FontAwesomeIcon
                icon={faSearch} className={classnames('', {
                  orange: searchTerm !== '',
                })}/>
            </div>
            <input
              tabIndex={0}
              autoFocus={searchActive}
              className="input"
              placeholder="Search Admins&#8230;"
              ref={searchInputRef}
              onChange={e => debouncedSetSearchTerm(e.target.value)}
              onFocus={toggleSearch}
              onBlur={() => setSearchActive(false)}
            />
            <div
              alt="clear all"
              className="action-button close-button flex-column"
              disabled={!(searchActive || searchTerm !== '')}
              onClick={() => {
                toggleSearch(false);
                toggleMobileSort(false);
              }}
            >
              <FontAwesomeIcon icon={faTimes} />
            </div>
          </div>
        </div>
        <div className="responsive-row flex-expand">
          <div className="select-all-check is-hidden-tablet">
            <input
              type="checkbox"
              checked={
                influencerDashboardStore.influencerAdmins ===
                selectedAdmins.length
              }
              onChange={() => toggleAll()}
              ref={selectAllMobileRef}
            />
          </div>
          <div className={classnames('actions-container flex-row flex-expand')}>
            <select
              value={selectedAction}
              onChange={e => setSelectedAction(e.target.value)}
            >
              {actions.map((action, index) => (
                <option key={`${action.name}`} value={index}>
                  {action.name}
                </option>
              ))}
            </select>
            <button
              className="button is-hidden-mobile is-primary is-small apply-button"
              disabled={selectedAdmins.length === 0}
              onClick={() => {
                actions[selectedAction].action(
                  selectedAdmins.map(idString =>
                    idString.replace('select-', ''),
                  ),
                );
                setSelectedAdmins([]);
              }}
            >
              Apply
            </button>
            <button
              className={classnames('is-hidden-tablet go-action', {
                'is-disabled': selectedAction === 'Actions',
              })}
              disabled={selectedAdmins.length === 0}
              onClick={() =>
                actions[selectedAction].action(
                  selectedAdmins.map(idString =>
                    idString.replace('select-', ''),
                  ),
                )
              }
            >

              <FontAwesomeIcon icon={faCaretRight} />
            </button>
          </div>
          {/* <div className="table-info">Influencer Admins</div> */}
          <div className="flex-row flex-expand is-hidden-tablet"></div>
          <div className="flex-row">
            <div
              className={classnames(
                'action-button sort-action flex-column is-hidden-tablet',
              )}
              onClick={
                mobileSortActive
                  ? () =>
                    setMobileSort({ ...mobileSort, desc: !mobileSort.desc })
                  : () => {
                    setMobileSort({ ...mobileSort, desc: !mobileSort.desc });
                    toggleMobileSort(true);
                  }
              }
            >
              <FontAwesomeIcon
                icon={faSortUp} className={classnames('', {
                  orange: mobileSort.desc,
                })}/>
              <FontAwesomeIcon
                icon={faSortDown} className={classnames('', {
                  orange: !mobileSort.desc,
                })}/>
            </div>
          </div>
        </div>
      </div>
      <SelectTable
        filterable
        SelectAllInputComponent={() => (
          <input
            type="checkbox"
            aria-label="Select row with id:select-all"
            id="select-all"
            checked={
              influencerDashboardStore.influencerAdmins.length ===
              selectedAdmins.length
            }
            ref={selectAllRef}
            onChange={toggleAllAdmins}
          />
        )}
        selectAll={
          influencerDashboardStore.influencerAdmins.length ===
          selectedAdmins.length
        }
        className="select-table influencer-admin-table"
        resizable={false}
        keyField={keyField}
        ref={tableRef}
        selectType="checkbox"
        columns={adminColumns}
        data={influencerDashboardStore.influencerAdmins}
        toggleAll={toggleAllAdmins}
        isSelected={isSelected}
        toggleSelection={toggleAdminSelection}
        pageSize={MAX_PAGE_SIZE}
        minRows={0}
        filtered={[{ id: 'name', value: searchTerm }]}
        defaultSorted={[{ id: 'name', desc: false }]}
        defaultPageSize={MAX_PAGE_SIZE}
        showPaginationBottom={pageSize > MAX_PAGE_SIZE}
        onFilterUpdateLength={len => setPageSize(len)}
        {...(mobileSortActive > 0 && { sorted: [mobileSort] })}
      />
    </div>
  );
};

InfluencersTable.propTypes = {
  influencerDashboardStore: PropTypes.object.isRequired,
  keyField: PropTypes.string,
  actions: PropTypes.array.isRequired,
};

export default inject('influencerDashboardStore')(observer(InfluencersTable));
