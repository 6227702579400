import React from 'react';
import PropTypes from 'prop-types';

import CreateCauzeView from './CreateCauzeView';
import useCreateCauze from '../../hooks/useCreateCauze';
import withAuthRequired from '../../behaviors/withAuthRequired';

const CharityCreateCauzeView = ({ charityId, eventId }) => {
  useCreateCauze({ userContext: { charityId }, eventId });
  return <CreateCauzeView userContext={{ charityId }} eventId={eventId} />;
};

CharityCreateCauzeView.propTypes = {
  charityId: PropTypes.string,
  eventId: PropTypes.string,
};

export default withAuthRequired(CharityCreateCauzeView, {
  entityType: 'CHARITY',
});
