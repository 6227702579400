import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { navigate } from '@reach/router';
import config from '../../config';

import useCompanies from '../../hooks/useCompanies';
import withAuthRequired from '../../behaviors/withAuthRequired';

import Navbar from 'components/page/Navbar/Navbar';
import Footer from 'components/page/Footer/Footer';
import CauzeSpinner from 'components/CauzeSpinner/CauzeSpinner';
import Button from 'components/Button/Button';
import AddToBalanceModal from 'components/modals/Company/AddToBalanceModal';

import CompaniesTable from 'components/CompaniesTable/CompaniesTable';

const CompaniesView = ({ companiesStore }) => {
  const [showAddToBalanceModal, setAddToBalanceModal] = useState(false);

  const addToBalance = async (companyId) => {
    companiesStore.setActiveCompany(companyId);
    setAddToBalanceModal(true);
  };

  const gotoCompany = (_state, rowInfo, column, _instance) => ({
    onClick: (_e, handleOriginal) => {
      if (column.id === 'addToBalance') {
        return handleOriginal && handleOriginal();
      }
      if (column.id === 'shareLink') {
        return handleOriginal && handleOriginal();
      }
      navigate(`/admin/company/${rowInfo.original.id}`);
    },
  });

  useCompanies();
  return (
    <div className="company-reporting flex-column">
      <Navbar />
      {companiesStore.loading ? (
        <CauzeSpinner fullscreen />
      ) : (
        [
          showAddToBalanceModal && (
            <AddToBalanceModal
              isOpen
              companiesStore={companiesStore}
              onToggleClose={() => setAddToBalanceModal(false)}
              key="1"
            />
          ),
          <div>
            <section>
              <div className="page-header container flex-row">
                <h1 className="title">Companies</h1>

                <Button
                  style={{ paddingLeft: '.5em', paddingRight: '.5em' }}
                  className="is-small"
                  onClick={() => {
                    companiesStore
                      .getAdminDownloadToken('grants/pending_prepays')
                      .then((token) =>
                        navigate(
                          `${config.API_ROOT}/grants/pending_prepays/${token}`,
                        ),
                      );
                  }}
                >
                  Download Pending Fee Report
                </Button>
              </div>
            </section>
            <section key="2">
              <div className="container flex-justify-center">
                {companiesStore.companies.length > 0 && (
                  <CompaniesTable
                    companies={companiesStore.companies}
                    onAddToBalance={addToBalance}
                    onGotoCompany={gotoCompany}
                  />
                )}
              </div>
            </section>
          </div>,
        ]
      )}
      <Footer />
    </div>
  );
};

CompaniesView.propTypes = {
  companiesStore: PropTypes.object.isRequired,
  profileStore: PropTypes.object.isRequired,
};

export default withAuthRequired(
  inject('profileStore', 'companiesStore')(observer(CompaniesView)),
  { entityType: 'ADMIN' },
);
