import cx from 'classnames';

const SliderModal = ({ children, isOpen, isActive = true, onToggleClose }) => {
  return (
    <div className={cx('modal', { 'is-active': isActive })}>
      {isOpen && (
        <div onClick={onToggleClose} className="modal-background"></div>
      )}
      {children}
    </div>
  );
};

export default SliderModal;
