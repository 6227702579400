import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';

const CreateMatch = ({ onCreateMatch, isGroup }) => (
  <div className="dashboard-item create-match flex-row">
    <div className="dashboard-icon">
      <FontAwesomeIcon icon={faHeart} aria-hidden="true" />
    </div>
    <div className="dashboard-content flex-column flex-expand">
      <div className="dashboard-details flex-column">
        <div className="dashboard-item-title">
          Create {isGroup ? 'A Member' : 'An Employee'} Match
        </div>
        <div className="dashboard-item-body">
          Create a {isGroup ? 'group' : 'company'} sponsored match for your{' '}
          {isGroup ? 'members' : 'employees'}
        </div>
      </div>
      <div className="dashboard-item-actions flex-row">
        <Button className="button is-small" onClick={onCreateMatch}>
          Create Match
        </Button>
      </div>
    </div>
  </div>
);

CreateMatch.propTypes = {
  companyAdminStore: PropTypes.object.isRequired,
  onCreateMatch: PropTypes.func.isRequired,
  isGroup: PropTypes.bool.isRequired,
};

export default CreateMatch;
