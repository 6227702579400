import { useState } from 'react';
import { useElements, useStripe } from '@stripe/react-stripe-js';

import getCardIcon from 'util/getCardIcon';

import ConfirmModal from 'components/modals/ConfirmModal/ConfirmModal';
import WrappedApplePayButton from 'components/WrappedApplePayButton';
import WrappedGooglePayButton from 'components/WrappedGooglePayButton';
import AddCard from './AddCard';
import AddACH from './AddACH';

const SavedPaymentMethods = ({
  walletStore,
  addFundsGiftCheckoutStore,
  userId,
  cards = [],
  isCheckout,
}) => {
  const [showAddCard, setShowAddCard] = useState(false);
  const [cardForRemoval, setCardForRemoval] = useState(null);
  const elements = useElements();
  const stripe = useStripe();

  const handleRemoveCard = () => {
    walletStore.removeCard({
      userContext: { userId },
      cardId: cardForRemoval.id,
    });
  };

  return (
    <>
      <ConfirmModal
        isOpen={Boolean(cardForRemoval)}
        action={handleRemoveCard}
        onToggleClose={() => setCardForRemoval(null)}
        message="Delete Payment Method"
        bodyMessage={`Are you sure want to delete ${
          cardForRemoval?.brand || 'card'
        } ending in ${cardForRemoval?.last4}?`}
        confirmOnly={true}
      />
      <div className="saved-payment-methods">
        {cards.map((card) => (
          <div className="saved-payment-method">
            <div className="uapm-name">{card.name || card.brand}</div>
            <div className="uapm-brand-container">
              <div className="uapm-brand">
                <img src={getCardIcon(card.brand)} />
              </div>
              <div className="uapm-last4">*{card.last4}</div>
              {card.isDefault ? (
                <button disabled className="uapm-default-btn is-default">
                  DEFAULT
                </button>
              ) : (
                <button
                  className="uapm-default-btn"
                  onClick={() => {
                    walletStore.updateDefaultCard({
                      cardId: card.id,
                      userContext: { userId },
                    });
                  }}
                >
                  SET AS DEFAULT
                </button>
              )}
              <button
                className="delete uapm-x-btn"
                onClick={() => {
                  setCardForRemoval(card);
                }}
              >
                X
              </button>
            </div>
          </div>
        ))}
        <div className="saved-payment-button-container">
          <AddCard
            isDetails
            isCheckout={isCheckout}
            showAddCard={showAddCard}
            setShowAddCard={setShowAddCard}
            onAddCard={() => {
              walletStore
                .registerNewCard({
                  elements: elements,
                  stripe: stripe,
                  userContext: {
                    userId,
                  },
                })
                .then((success) => success && setShowAddCard(false));
            }}
          />
          <AddACH
            userId={userId}
            store={isCheckout ? addFundsGiftCheckoutStore : walletStore}
          />
          {isCheckout && (
            <>
              {Boolean(window.ApplePaySession) ? (
                <WrappedApplePayButton store={addFundsGiftCheckoutStore} />
              ) : (
                <WrappedGooglePayButton store={addFundsGiftCheckoutStore} />
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default SavedPaymentMethods;
