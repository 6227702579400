import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from '@reach/router';
import mixpanel from 'mixpanel-browser';
import cx from 'classnames';

import Avatar from 'components/Avatar/Avatar';
import FeedCardMatch from './FeedCardMatch';
import ActorVerbSubjectText from 'components/ActorVerbSubjectText/ActorVerbSubjectText';
import { getEntityUrl } from 'util/navHelpers';

import { ReactComponent as HeartOutline } from 'assets/images/icons/heart/heart-outline.svg';
import { ReactComponent as HeartFilled } from 'assets/images/icons/heart/heart-red.svg';
import { ReactComponent as CommentIcon } from 'assets/images/icons/svgs/comment.svg';
import { ReactComponent as PeopleIcon } from 'assets/images/icons/svgs/people.svg';
import FeedCardCauze from './FeedCardCauze';

const FeedItemAction = ({ Icon, text, onClick }) => {
  return (
    <div className="feed-item-flex" onClick={onClick}>
      <span className="feed-item-icon">
        <Icon />
      </span>
      <div className="feed-item-number">
        <span>{text}</span>
      </div>
    </div>
  );
};

const FeedItem = ({ item, onLike, eventTitle = '', entity }) => {
  let imageHref;

  const purchaseId = item.purchaseId;
  const eventId = item.eventId;
  const entityType = item.actor?.entityType;
  const userId = item.actor?.id;
  const companyId = item.actor?.id;
  const subject = item.subjects?.[0];
  const event = item.event;
  const showEvent = event && event.eventType?.toLowerCase() === 'planned';
  const isSelf = item.actor?.isSelf;
  const hasComment = Boolean(item?.purchaseComment);

  if (eventId) {
    imageHref = `/event/${eventId}${
      purchaseId ? `?joined_purchase_id=${purchaseId}` : ''
    }`;
  } else if (purchaseId) {
    imageHref = `/event/purchase/${purchaseId}${
      purchaseId ? `?joined_purchase_id=${purchaseId}` : ''
    }`;
  } else if (item.subjects?.[0]?.id) {
    imageHref = `/event/${item.subjects[0].id}${
      purchaseId ? `?joined_purchase_id=${purchaseId}` : ''
    }`;
  }

  const onJoinClick = () => {
    mixpanel.track('Feed Item Join Click', {
      ...item,
    });

    if (!eventId && purchaseId) {
      if (entityType === 'USER') {
        navigate(
          `/event/purchase/${purchaseId}?referrer_user_id=${userId}${
            purchaseId ? `&joined_purchase_id=${purchaseId}` : ''
          }`,
        );
      } else if (entityType === 'COMPANY') {
        navigate(
          `/event/purchase/${purchaseId}?referrer_company_id=${companyId}${
            purchaseId ? `&joined_purchase_id=${purchaseId}` : ''
          }`,
        );
      } else {
        navigate(
          `/event/purchase/${purchaseId}${
            purchaseId ? `&joined_purchase_id=${purchaseId}` : ''
          }`,
        );
      }
    } else if (eventId) {
      if (entityType === 'USER') {
        navigate(
          `/event/${eventId}?referrer_user_id=${userId}${
            purchaseId ? `&joined_purchase_id=${purchaseId}` : ''
          }`,
        );
      } else if (entityType === 'COMPANY') {
        navigate(
          `/event/${eventId}?referrer_company_id=${companyId}${
            purchaseId ? `&joined_purchase_id=${purchaseId}` : ''
          }`,
        );
      } else {
        navigate(
          `/event/${eventId}${
            purchaseId ? `&joined_purchase_id=${purchaseId}` : ''
          }`,
        );
      }
    }
  };

  const isInactiveUser =
    entityType === 'USER' &&
    (!item.actor?.user?.isActive || item.actor?.user?.unclaimed);
  const AvatarContainer = isInactiveUser ? 'div' : 'a';
  const isMatchSponsor = Boolean(
    Array.isArray(item?.matchActors) &&
      item.matchActors.find(
        (matchActor) =>
          `${matchActor.id}` === `${entity?.id}` &&
          matchActor.entityType === `${entity?.groupType}`.toUpperCase(),
      ),
  );

  const hasMatch = Boolean(item.matchActors?.length) && !isMatchSponsor;

  let avatarEntity = {
    id: item.actor?.id,
    entityType: entityType,
    avatar: item.actor?.avatar,
  };

  if (isMatchSponsor) {
    avatarEntity = {
      id: entity.id,
      entityType: `${entity?.groupType}`.toUpperCase(),
      avatar: entity.avatar,
    };
  }

  return (
    <div className="feed-item">
      <div
        className={cx('feed-item-title', {
          'no-padding': !hasComment && !event,
        })}
      >
        <AvatarContainer
          className="avatar-container"
          href={isInactiveUser ? undefined : getEntityUrl(avatarEntity)}
        >
          <Avatar
            avatar={avatarEntity.avatar}
            entityType={avatarEntity.entityType}
            id={avatarEntity.id}
            onClick={null}
            noBorder
          />
        </AvatarContainer>
        <div>
          <ActorVerbSubjectText
            {...item}
            eventTitle={eventTitle}
            entity={entity}
            isMatchSponsor={isMatchSponsor}
          />
        </div>
      </div>
      {showEvent && (
        <div className="feed-card-cauze-container">
          <div className="feed-card-cauze-lines" />
          <FeedCardCauze
            href={imageHref}
            event={{
              ...subject,
            }}
            hasComment={hasComment || hasMatch}
          />
        </div>
      )}
      {hasMatch && (
        <div
          className={cx('feed-item-matches', {
            'has-comment': hasComment && showEvent,
          })}
        >
          <div
            className={cx('feed-item-actions-lines', {
              'has-comment': !showEvent,
            })}
          />
          <FeedCardMatch matches={item.matchActors} />
        </div>
      )}
      {hasComment ? (
        <div
          className={cx('feed-item-comment-container', {
            'no-event': !showEvent,
          })}
        >
          <div
            className={cx('feed-item-actions-lines', {
              'has-comment': !showEvent,
            })}
          />
          <div className="feed-item-comment">{item?.purchaseComment}</div>
        </div>
      ) : (
        <></>
      )}
      <div className="feed-item-actions">
        <div
          className={cx('feed-item-actions-lines', {
            'has-comment': hasComment || !showEvent || hasMatch,
          })}
        />
        <FeedItemAction
          Icon={item?.currentEntityLiked ? HeartFilled : HeartOutline}
          text={item?.likeCount || 0}
          onClick={() => onLike?.({ feedId: item?.id })}
        />
        {/* TODO: implement in future ticket */}
        {false && (
          <FeedItemAction Icon={CommentIcon} text={item?.commentCount || 0} />
        )}
        {!isSelf && (
          <FeedItemAction Icon={PeopleIcon} text="JOIN" onClick={onJoinClick} />
        )}
      </div>
    </div>
  );
};

FeedItem.propTypes = {
  item: PropTypes.object.isRequired,
  eventTitle: PropTypes.string,
  entity: PropTypes.object,
};

export default FeedItem;
