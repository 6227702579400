import { useEffect } from 'react';
import donationCheckoutStore from '../stores/DonationCheckoutStore';
import donationUnauthedCheckoutStore from '../stores/DonationUnauthedCheckoutStore';
import { normalizeContext } from '../util/contextUtils';

const useDonationCheckout = ({
  eventId,
  charityIds,
  joinPurchaseId,
  emailIsPrivate,
  isAuthed,
  tokenId,
  giftToken,
  giftAmount,
  activeEntity = {},
  email,
}) => {
  const store = isAuthed
    ? donationCheckoutStore
    : donationUnauthedCheckoutStore;
  useEffect(() => {
    store.resetCheckout();
    store.generatePlaidLinkToken();
    store.getCheckoutDetails({
      eventId,
      charityIds,
      emailIsPrivate,
      tokenId,
      giftToken,
      giftAmount,
      joinPurchaseId,
      userContext: normalizeContext(activeEntity.userContext),
      email,
    });
  }, [
    eventId,
    // charityIds,
    emailIsPrivate,
    tokenId,
    giftToken,
    giftAmount,
    activeEntity,
    joinPurchaseId,
  ]);
};

export default useDonationCheckout;
