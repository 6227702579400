import { PaymentElement } from '@stripe/react-stripe-js';
import cx from 'classnames';

import stripeOptions from 'config/stripe';

const AddCard = ({
  onAddCard,
  showAddCard,
  setShowAddCard,
  isCheckout,
  defaultOpen,
  isDetails,
}) => {
  return (
    <div className="saved-add-card">
      {!defaultOpen && (
        <button
          className={cx(
            'py-3 mb-2 text-center w-full bg-white cursor-pointer font-agenda-bold text-sm text-cauzeorange hover:bg-lightgray-e8e rounded-[10px]',
            {
              'border-none': !isDetails,
              'border border-black': isDetails,
            },
          )}
          type="button"
          onClick={() => setShowAddCard(!showAddCard)}
        >
          ADD NEW CARD
        </button>
      )}
      {(showAddCard || defaultOpen) && (
        <>
          <PaymentElement options={stripeOptions.cardElement} />
          {!isCheckout && (
            <button
              style={{ marginTop: '0.5rem', marginBottom: '2rem' }}
              onClick={onAddCard}
              className="saved-add-card-btn"
            >
              ADD CARD
            </button>
          )}
        </>
      )}
    </div>
  );
};

export default AddCard;
