const mergeFeedItems = (feedItems) => {
  if (!Array.isArray(feedItems)) return [];

  const feedMap = {};
  const feedKeys = [];

  feedItems.forEach((feedItem) => {
    let event = feedItem.event;
    const projects = feedItem.projects || event?.projects;

    if (Array.isArray(projects) && projects.length === 1) {
      event = projects[0];
    }

    if (!event) return;

    const feedKey = `${feedItem.activityEntity?.id}-${event?.id}-${feedItem.joinedEntity?.id}`;
    const existingFeed = feedMap[feedKey];

    if (!existingFeed) {
      feedMap[feedKey] = feedItem;
      feedKeys.push(feedKey);
    } else {
      const betterFeed =
        existingFeed.likeCount > feedItem.likeCount ? existingFeed : feedItem;
      feedMap[feedKey] = betterFeed;
    }
  });

  return feedKeys.map((feedKey) => feedMap[feedKey]);
};

export default mergeFeedItems;
