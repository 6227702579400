import authStore from './AuthStore';
import cauzeStore from './CauzeStore';
import charitiesStore from './CharitiesStore';
import charityAdminStore from './CharityAdminStore';
import companiesStore from './CompaniesStore';
import companyAdminStore from './CompanyAdminStore';
import companyReportingStore from './CompanyReportingStore';
import companyGiftsStore from './CompanyGiftsStore';
import createMatchStore from './CreateMatchStore';
import donationCheckoutStore from './DonationCheckoutStore';
import donationUnauthedCheckoutStore from './DonationUnauthedCheckoutStore';
import influencersStore from './InfluencersStore';
import influencerDashboardStore from './InfluencerDashboardStore';
import usersStore from './UsersStore';
import userDashboardStore from './UserDashboardStore';
import eventStore from './EventStore';
import profileStore from './ProfileStore';
import addFundsGiftCheckoutStore from './AddFundsGiftCheckoutStore';
import uiStore from './UiStore';
import userProfileStore from './UserProfileStore';
import companyStore from './CompanyStore';
import charityStore from './CharityStore';
import entityGiftsStore from './EntityGiftsStore';
import donationLandingStore from './DonationLandingStore';
import searchStore from './SearchStore';
import userDetailsStore from './UserDetailsStore';
import matchStore from './MatchStore';
import walletStore from './WalletStore';
import followFollowingStore from './FollowFollowingStore';
import userGiftStore from './UserGiftStore';

const stores = {
  authStore,
  uiStore,
  userProfileStore,
  cauzeStore,
  charitiesStore,
  charityAdminStore,
  companiesStore,
  companyAdminStore,
  companyReportingStore,
  companyGiftsStore,
  createMatchStore,
  donationCheckoutStore,
  donationUnauthedCheckoutStore,
  eventStore,
  profileStore,
  addFundsGiftCheckoutStore,
  influencersStore,
  influencerDashboardStore,
  usersStore,
  userDashboardStore,
  companyStore,
  charityStore,
  entityGiftsStore,
  donationLandingStore,
  searchStore,
  userDetailsStore,
  matchStore,
  walletStore,
  followFollowingStore,
  userGiftStore,
};

export default stores;
