import React from 'react';
import PropTypes from 'prop-types';

import leftNav from '../../../assets/images/icons/nav/left.svg';
import { navigate } from '@reach/router';

const Back = ({ route }) => (
  <button className="back" onClick={() => navigate(route)}>
    <img src={leftNav} />
  </button>
);

Back.propTypes = {
  route: PropTypes.string.isRequired,
};

export default Back;
