import VisaIcon from '../assets/images/cards/Visa.svg';
import MastercardIcon from '../assets/images/cards/Mastercard.svg';
import AmericanExpressIcon from '../assets/images/cards/American-Express.svg';
import DiscoverIcon from '../assets/images/cards/Discover.svg';

const getCardIcon = (cardBrand) => {
  if (cardBrand?.toLowerCase() === 'discover') {
    return DiscoverIcon;
  }
  if (cardBrand?.toLowerCase() === 'american express') {
    return AmericanExpressIcon;
  }
  if (cardBrand?.toLowerCase() === 'mastercard') {
    return MastercardIcon;
  }
  if (cardBrand?.toLowerCase() === 'visa') {
    return VisaIcon;
  }
  return null;
};

export default getCardIcon;
