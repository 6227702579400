import React from 'react';
import moment from 'moment';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const DateWithNullText = ({ value: { date, nullText } }) => (
  <span
    className={classnames({
      'col-date-highlight': !date,
    })}
  >
    {date ? moment(date).format('M/D/Y') : nullText}
  </span>
);

DateWithNullText.propTypes = {
  value: PropTypes.shape({
    date: PropTypes.string,
    nullText: PropTypes.string,
  }),
};

export { DateWithNullText };
