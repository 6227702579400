import { useEffect } from 'react';

import { normalizeContext } from '../util/contextUtils';
import userGiftStore from 'stores/UserGiftStore';

const useUserDonationCheckout = ({
  userId,
  emailIsPrivate,
  tokenId,
  giftToken,
  giftAmount,
  activeEntity = {},
}) => {
  const store = userGiftStore;
  useEffect(() => {
    store.resetCheckout();
    store.generatePlaidLinkToken();
    store.updateCheckout({ userId });
    store.getCheckoutDetails({
      userId,
      emailIsPrivate,
      tokenId,
      giftToken,
      giftAmount,
      userContext: normalizeContext(activeEntity.userContext),
    });
  }, [userId, emailIsPrivate, tokenId, giftToken, giftAmount, activeEntity]);
};

export default useUserDonationCheckout;
