import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { navigate } from '@reach/router';
import config from '../../../config';

import useCompanyReporting from '../../../hooks/useCompanyReporting';
import withUserContext from '../../../behaviors/withUserContext';
import withAuthRequired from '../../../behaviors/withAuthRequired';

import Charts from './Charts';

import Navbar from 'components/page/Navbar/Navbar';
import Footer from 'components/page/Footer/Footer';
import Button from 'components/Button/Button';
import CauzeSpinner from 'components/CauzeSpinner/CauzeSpinner';
import SupportedCharitiesTable from 'components/CharityTable/SupportedCharitiesTable';

const CompanyReportingView = ({
  companyReportingStore,
  profileStore,
  companyId,
}) => {
  useCompanyReporting(companyId);

  return (
    <div className="company-reporting flex-column">
      <Navbar />
      <div style={{ minHeight: '100vmax' }}>
        <section className="container">
          <div className="page-header flex-row">
            <h1 className="title">Reporting</h1>

            <Button
              className="is-small"
              onClick={() => {
                companyReportingStore
                  .getDownloadToken(companyId, 'company/donations')
                  .then((token) =>
                    navigate(
                      `${config.API_ROOT}/company/${companyId}/donations/${token}`,
                    ),
                  );
              }}
            >
              Download Donation Report
            </Button>
          </div>
          {companyReportingStore.chartsLoading ? (
            <CauzeSpinner />
          ) : (
            <div className="dashboard-container">
              <div className="dashboard flex-row flex-space-around">
                <Charts
                  engagementData={companyReportingStore.engagementData}
                  categoryData={companyReportingStore.categoryData}
                  isGroup={profileStore.activeEntity.groupType !== 'COMPANY'}
                />
              </div>
            </div>
          )}
          <div className="dashboard flex-row flex-space-around"></div>
        </section>
        <section>
          {companyReportingStore.supportedCharitiesLoading ? (
            <CauzeSpinner />
          ) : (
            <div className="container">
              {companyReportingStore.supportedCharities.length > 0 ? (
                <SupportedCharitiesTable
                  charities={companyReportingStore.supportedCharities}
                  totalGood={companyReportingStore.totalGood}
                  totalMatchedPortion={
                    companyReportingStore.totalMatchedPortion
                  }
                  totalDonations={companyReportingStore.totalDonations}
                />
              ) : (
                <p>No Donations</p>
              )}
            </div>
          )}
        </section>
      </div>
      <Footer />
    </div>
  );
};

CompanyReportingView.propTypes = {
  companyReportingStore: PropTypes.object.isRequired,
  companyId: PropTypes.string.isRequired,
  profileStore: PropTypes.object.isRequired,
};

export default withAuthRequired(
  withUserContext(
    inject(
      'profileStore',
      'companyReportingStore',
    )(observer(CompanyReportingView)),
  ),
  { entityType: 'COMPANY' },
);
