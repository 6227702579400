import React from 'react';
import PropTypes from 'prop-types';
import { getIosLink, getAndroidLink } from '../../../util/userAgent';
import classnames from 'classnames';
import * as events from '../../../util/events/nav';
import facebookIcon from '../../../assets/images/icons/social/facebook.svg';
import instagramIcon from '../../../assets/images/icons/social/instagram.svg';
import twitterIcon from '../../../assets/images/icons/social/twitter.svg';
import appStoreLogo from '../../../assets/images/icons/appStores/appStore.svg';
import playStoreLogo from '../../../assets/images/icons/appStores/googlePlay.svg';

import logo from '../../../assets/images/brand/cauze-logo-no-background.svg';

const FooterNew = ({ grey = false }) => (
  <footer
    className={classnames('footer-new', {
      'background-grey': grey,
      'background-white': !grey,
    })}
  >
    <div className="content flex-row">
      <div className="flex-column content-left">
        <a
          className="footer-link"
          onClick={events.footerHomeLinkPress}
          href="https://www.cauze.com/home"
        >
          Home
        </a>
        <a
          className="footer-link"
          onClick={events.footerEmployersLinkPress}
          href="https://www.cauze.com/employers"
        >
          Employers
        </a>
        <a
          className="footer-link"
          onClick={events.footerNonprofitsLinkPress}
          href="https://www.cauze.com/nonprofits"
        >
          Nonprofits
        </a>
        <a
          className="footer-link"
          onClick={events.footerAboutLinkPress}
          href="https://www.cauze.com/our-story"
        >
          About
        </a>
        <a
          className="footer-link"
          onClick={events.footerHelpLinkPress}
          href="https://www.cauze.com/help"
        >
          Help
        </a>
        <div className="disclaimer">
          App Store®, the Apple logo, and iPhone® are registered trademarks of
          Apple Inc. Google Play and the Google Play logo are trademarks of
          Google LLC.
        </div>
      </div>
      <div className="flex-expand flex-center logo-container">
        <img src={logo} className="logo" alt="Cauze" />
      </div>
      <div className="flex-column content-right">
        <div className="app-store-links flex-column">
          <a
            className="link"
            onClick={events.playStoreDownloadPress}
            href={getAndroidLink()}
          >
            <img className="icon" src={playStoreLogo} />
          </a>
          <a
            className="link"
            onClick={events.appStoreDownloadPress}
            href={getIosLink()}
          >
            <img className="icon" src={appStoreLogo} />
          </a>
        </div>
        <div className="social-container flex-row">
          <a
            className="social-link"
            onClick={events.footerTwitterPress}
            href="https://twitter.com/cauzeapp/"
          >
            <img className="icon" src={twitterIcon} />
          </a>
          <a
            className="social-link"
            onClick={events.footerFacebookPress}
            href="https://www.facebook.com/cauzeapp/"
          >
            <img className="icon" src={facebookIcon} />
          </a>
          <a
            className="social-link"
            onClick={events.footerInstragramPress}
            href="https://www.instagram.com/cauzeapp/"
          >
            <img className="icon" src={instagramIcon} />
          </a>
        </div>
        <div className="legal-links">
          <a
            target="_blank"
            onClick={events.footerTermsLinkPress}
            href="https://www.cauze.com/terms-of-service"
            className="legal-link"
          >
            Terms
          </a>
          <a
            target="_blank"
            onClick={events.footerPrivacyLinkPress}
            href="https://www.cauze.com/privacy-policy"
            className="legal-link"
          >
            Privacy
          </a>
          <a
            target="_blank"
            onClick={events.footerDonorAgreementPress}
            href="https://www.cauze.com/donor-agreement"
            className="legal-link"
          >
            Donor Agreement
          </a>
        </div>
      </div>
    </div>
  </footer>
);

FooterNew.propTypes = {
  hideGetAppButtons: PropTypes.bool,
  grey: PropTypes.bool,
};

export default FooterNew;
