import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { Redirect } from '@reach/router';
import { emailValidate, zipValidate } from '../../../util/formFieldValidators';
import classnames from 'classnames';
import CreateAccountForm from './CreateAccountForm';
import LoginForm from './LoginForm';
import CauzeSpinner from 'components/CauzeSpinner/CauzeSpinner';
import mixpanel from 'mixpanel-browser';
import CauzeButton from 'components/CauzeButton/CauzeButton';

const validateCreateUser = ({ user, _fieldIsValid, setFieldIsValid }) => {
  if (!user) {
    return false;
  }
  const fieldValidators = {
    firstName: user.firstName.length !== 0,
    lastName: user.lastName.length !== 0,
    email: emailValidate(user.email),
    zip: zipValidate(user.zip),
    password: user.password.length > 7,
  };

  let userValid =
    typeof Object.keys(fieldValidators).find(
      (key) => fieldValidators[key] === false,
    ) === 'undefined';
  setFieldIsValid(fieldValidators);
  return userValid;
};

const onSignUpClick = async ({ user, authStore }) => {
  await authStore.signup(user);
};

const onLoginClick = async (authStore, profileStore, username, password) => {
  await authStore.login(username, password);
  if (!authStore.authError) {
    await profileStore.getProfile(authStore.stashedPathDetails);
    mixpanel.identify(profileStore.data.email.toLowerCase());
    mixpanel.track('Logged In', {
      email: profileStore.data.email.toLowerCase(),
    });
  }
};

const onLogoutClick = async (authStore) => {
  await authStore.changeAuthentication();
};

const AuthView = ({
  authStore,
  profileStore,
  defaultTab = 1,
  firstName = '',
  lastName = '',
  zip = '',
  email = '',
  margin = false,
}) => {
  const [tab, setTab] = useState(defaultTab);
  useEffect(() => setTab(defaultTab), [defaultTab]);
  const [user, setUser] = useState({
    firstName,
    lastName,
    zip,
    email,
    password: '',
  });

  const [fieldIsValid, setFieldIsValid] = useState({
    firstName: true,
    lastName: true,
    zip: true,
    password: true,
    email: true,
  });

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const redeemerEmail = authStore.companyInviteDetails?.email;
  useEffect(() => {
    if (redeemerEmail) {
      setUser({
        ...user,
        email: redeemerEmail,
      });
    }
  }, [redeemerEmail]);

  if (authStore.isAuthenticated && profileStore.hasFetchedInitial) {
    if (authStore.stashedPathDetails.path) {
      return <Redirect to={`${authStore.unstashPathDetails()}`} noThrow />;
    } else {
      return <Redirect to="/donate" noThrow />;
    }
  }

  if (authStore.isAuthenticated) {
    return (
      <div className="auth-view">
        <div className="form-container section flex-expand">
          <div className="login-button flex-expand">
            <CauzeButton
              support
              large
              onClick={(_e) => onLogoutClick(authStore)}
            >
              Log Out
            </CauzeButton>
          </div>
        </div>
      </div>
    );
  }

  let userValid =
    typeof Object.keys(fieldIsValid).find(
      (key) => fieldIsValid[key] === false,
    ) === 'undefined';

  if (authStore.isAuthenticated && !profileStore.hasFetchedInitial) {
    return <CauzeSpinner />;
  }

  return (
    <div className="auth-view" style={margin ? { marginBottom: '2rem' } : {}}>
      <div className="form-container flex-expand">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            if (tab === 0) {
              if (validateCreateUser({ user, fieldIsValid, setFieldIsValid })) {
                onSignUpClick({ user, authStore });
              }
            }
            if (tab === 1) {
              onLoginClick(authStore, profileStore, username, password);
            }
          }}
        >
          {authStore.createUserErrors.emailTakenOrInvalid && (
            <div className="notification">
              Invalid email or account already exists.
            </div>
          )}
          {authStore.authError && (
            <div className="notification">Invalid username or password</div>
          )}

          <div className="flex-center flex-column background-white">
            <div className="login-tabs flex-row">
              <div
                className={classnames('tab text-bold', { selected: tab === 0 })}
                onClick={(e) => {
                  e.preventDefault();
                  setTab(0);
                }}
              >
                Sign Up
              </div>
              <div
                className={classnames('tab text-bold', { selected: tab === 1 })}
                onClick={(e) => {
                  e.preventDefault();
                  setTab(1);
                }}
              >
                Login
              </div>
            </div>
            {tab === 0 && (
              <CreateAccountForm
                user={user}
                setUser={setUser}
                onChange={setUser}
                fieldIsValid={fieldIsValid}
                setFieldIsValid={setFieldIsValid}
              />
            )}
            {tab === 1 && (
              <LoginForm
                onChange={setUser}
                username={username}
                setUsername={setUsername}
                password={password}
                setPassword={setPassword}
              />
            )}
            <CauzeButton
              large
              type="submit"
              disabled={tab === 0 ? !userValid : false}
              isSubmitting={authStore.isLoggingIn}
              className="auth-button-confirm"
            >
              {tab === 0 ? 'Sign Up' : 'Login'}
            </CauzeButton>
          </div>
        </form>
      </div>
    </div>
  );
};

AuthView.propTypes = {
  authStore: PropTypes.object.isRequired,
  profileStore: PropTypes.object.isRequired,
  defaultTab: PropTypes.number,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  zip: PropTypes.string,
  email: PropTypes.string,
  margin: PropTypes.bool,
};

export default inject('authStore', 'profileStore')(observer(AuthView));
