import React from 'react';
import PropTypes from 'prop-types';
import starOutline from '../../assets/images/icons/star/star-outline.svg';
import starOrange from '../../assets/images/icons/star/star-orange.svg';
import classnames from 'classnames';

const FollowButton = ({ following = false, style, className, onFollow }) => (
  <div onClick={onFollow} className={`follow-button flex-column flex-align-center ${className}`} style={ style } >
    {following
      ? <img src={starOrange} />
      : <img src={starOutline} />}
    <p
      className={classnames({
        'following-text': following,
        'follow-text': !following,
      })}>
      {following ? 'Following' : 'Follow'}
    </p>
  </div>
);

FollowButton.propTypes = {
  following: PropTypes.bool,
  style: PropTypes.object,
  className: PropTypes.string,
  onFollow: PropTypes.func,
};

export default FollowButton;
