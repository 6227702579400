/* eslint-disable no-console */
import { observable, action, toJS, makeObservable } from 'mobx';
import { navigate } from '@reach/router';
import { client as apolloClient } from '../util/apolloClient';
import {
  getCauzesQuery,
  createPlannedEventMutation,
  updatePlannedEventMutation,
} from '../graphql/cauze';
import { isEmpty } from 'lodash';
import { createMatchMutation, editMatchMutation } from '../graphql/match';
import { eventQuery } from '../graphql/event';

import moment from 'moment';
import axios from 'axios';
import uiStore from './UiStore';

import config from '../config';
import { getToken } from '../util/storage';
import profileStore from '../stores/ProfileStore';

const UPLOAD_URL = `${config.UPLOAD_ROOT}/event/image`;
const CAUZE_IMAGE_FORM_KEY = `image`;
const DESCRIPTION_DEFAULT =
  'Let’s make an impact together! Consider donating with me to show your support, even if it’s just a dollar. Collectively we can make a difference.';
const EVENT_DEFAULT_DAY_RANGE = 14;

class CauzeStore {
  @observable cauzes = [];
  @observable cauzesJoinTotal = 0;
  @observable cauzesAmountTotal = 0;
  @observable cauzesLoading = true;
  @observable isError = false;
  @observable isUploadingCauzeImage = false;
  @observable imageUploadError = false;
  @observable menuActiveForCharityId = null;
  @observable matchHasChanged = false;

  @action getInitial = async ({ userContext }) => {
    this.cauzesLoading = true;
    const options = {
      variables: { userContext },
      query: getCauzesQuery,
      fetchPolicy: 'no-cache',
      errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
    };

    try {
      const res = await apolloClient.query(options);

      this.cauzes = res.data.eventsForContext
        .slice()
        .sort(
          (a, b) => moment(b.insertedAt).unix() - moment(a.insertedAt).unix(),
        );

      this.cauzesAmountTotal = res.data.eventsForContext.reduce(
        (acc, { current }) => acc + current,
        0,
      );
      this.cauzesJoinTotal = res.data.eventsForContext.reduce(
        (acc, { purchases }) => acc + purchases.length,
        0,
      );

      this.cauzesLoading = false;
    } catch (err) {
      this.cauzesLoading = false;
      this.isError = true;
      this.error = err;
      if (global.IS_LOCAL_OR_DEV) {
        console.log(err);
      }
    }
  };

  getCauzeUrl = (userContext) => {
    if (userContext.companyId) {
      return `/admin/company/${userContext.companyId}/cauzes`;
    } else if (userContext.charityId) {
      return `/admin/charity/${userContext.charityId}/cauzes`;
    } else if (userContext.influencerId) {
      return `/admin/influencer/${userContext.influencerId}/cauzes`;
    } else if (userContext.userId) {
      return `/admin/influencer/${userContext.userId}/cauzes`;
    } else {
      return `/admin/cauzes`;
    }
  };

  @action navToCreateCauze = (userContext) => {
    const url = this.getCauzeUrl(userContext);
    navigate(`${url}/create`);
  };

  @action navToEditCauze = (userContext, cauzeId) => {
    const url = this.getCauzeUrl(userContext);
    navigate(`${url}/${cauzeId}/edit`);
  };

  @action navToCauzes = (userContext) => {
    navigate(this.getCauzeUrl(userContext));
  };

  @action updatePlannedEventState = async ({ eventId, eventState, index }) => {
    try {
      this.cauzes[index].eventState = eventState;
      await apolloClient.mutate({
        variables: {
          eventId,
          eventState,
        },
        mutation: updatePlannedEventMutation,
        errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
      });
    } catch (err) {
      this.error = true;
      console.log(err);
    }

    this.savingCauze = false;
  };

  // Create a Cauze

  @observable loadingCauze = false;
  @observable loadingCauzeError = false;
  @observable savingCauze = false;
  @observable createCauzeError = false;
  @observable currentCauze = {
    name: '',
    charities: [],
    eventState: 'UNPUBLISHED',
    description: DESCRIPTION_DEFAULT,
    startDate: '',
    endDate: '',
    imageId: '',
    previewImageUrl: '',
    hasDates: false,
    userMatchLimit: 5000,
    matchTotal: 50000,
    hasMatch: false,
    matchType: 'EVENT',
    matchMultiplier: 1,
  };

  @action updateCauze = (props) => {
    this.currentCauze = { ...this.currentCauze, ...props };
    global.IS_LOCAL_OR_DEV && console.log(toJS(this.currentCauze));
  };

  @action setDateDefaults = (hasDates) => {
    this.updateCauze({
      hasDates: hasDates,
      startDate: hasDates ? moment().utc() : '',
      endDate: hasDates ? moment().add(EVENT_DEFAULT_DAY_RANGE, 'd') : '',
    });
  };

  @action toggleHasDates = () => {
    this.setDateDefaults(!this.currentCauze.hasDates);
  };

  @action onToggleMenu = (charityId) => {
    this.menuActiveForCharityId = charityId;
  };

  @action uploadImage = async (imageFile) => {
    if (imageFile) {
      try {
        this.isUploadingCauzeImage = true;

        getToken().then(async (token) => {
          const url = UPLOAD_URL;
          const data = new FormData();
          data.append(CAUZE_IMAGE_FORM_KEY, imageFile);
          const res = await axios.put(url, data, {
            headers: {
              Authorization: token ? `Bearer ${token}` : null,
            },
          });

          this.updateCauze({
            imageId: res.data.id,
            previewImageUrl: res.data.urls.xl,
          });

          // This is because the upload put is so fast the user can't see
          // the progress spinner, made them think it didn't work sometimes
          setTimeout(() => {
            this.isUploadingCauzeImage = false;
          }, 2000);
        });
      } catch (err) {
        this.isUploadingCauzeImage = false;
        this.imageUploadError = true;
      }
    }
  };

  @action resetImage = () => {
    this.imageUploadError = false;
    this.updateCauze({ imageId: null, previewImageUrl: '' });
  };

  @action createPlannedEvent = async ({
    userContext,
    eventState = 'ACTIVE',
  }) => {
    this.savingCauze = true;
    const hasMatch =
      this.currentCauze.hasMatch &&
      this.currentCauze.userMatchLimit !== 0 &&
      this.currentCauze.matchTotal !== 0;

    const variables = {
      name: this.currentCauze.name.trim(),
      charityIds: this.currentCauze.charities.map((charity) => charity.id),
      description: this.currentCauze.description,
      previewUrl: this.currentCauze.previewUrl,
      externalImageUrl: this.currentCauze.externalImageUrl,
      imageID: this.currentCauze.imageId,
      hostContexts: userContext,
      eventState,
    };

    if (this.currentCauze.startDate && this.currentCauze.endDate) {
      variables.startDate = moment(this.currentCauze.startDate).format();
      variables.endDate = moment(this.currentCauze.endDate).format();
    }

    if (hasMatch) {
      variables.userMatchLimit =
        this.currentCauze.userMatchLimit * this.currentCauze.matchMultiplier;
      variables.matchTotal = this.currentCauze.matchTotal;
      variables.matchSponsorContexts = userContext;
      variables.matchType = this.currentCauze.matchType;
      variables.multiplier = this.currentCauze.matchMultiplier;
    }

    try {
      const res = await apolloClient.mutate({
        variables,
        mutation: createPlannedEventMutation,
        errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
      });

      if (hasMatch) {
        profileStore.updateActiveEntityTotal(this.currentCauze.matchTotal * -1);
      }

      this.navToCauzes(userContext);
      this.onCreateUnmount();
      if (res.errors) {
        throw res.errors;
      }
    } catch (err) {
      err.map(
        (err) =>
          err.message.includes('Insufficient funds') &&
          uiStore.showNotification({
            body: err.message,
            type: 'ERROR',
          }),
      );
      this.createCauzeError = true;
      this.savingCauze = false;
      console.log(err);
    }

    this.savingCauze = false;
  };

  @action updatePlannedEvent = async ({
    eventState = 'ACTIVE',
    userContext,
  }) => {
    this.savingCauze = true;

    let startDate = this.currentCauze.startDate;
    let endDate = this.currentCauze.endDate;
    if (moment.isMoment(startDate) && !startDate.isValid()) startDate = null;
    if (moment.isMoment(endDate) && !endDate.isValid()) endDate = null;

    const variables = {
      name: this.currentCauze.name,
      charityIds: this.currentCauze.charities.map((charity) => charity.id),
      description: this.currentCauze.description,
      previewUrl: this.currentCauze.previewUrl,
      externalImageUrl: this.currentCauze.externalImageUrl,
      eventState,
      eventId: this.currentCauze.id,
    };

    if (startDate && endDate) {
      variables.startDate = moment(startDate).format();
      variables.endDate = moment(endDate).format();
    } else {
      variables.startDate = null;
      variables.endDate = null;
    }

    // Only set the imageId for update if a new image has been uploaded, don't need to send if same
    if (this.currentCauze.imageId) {
      variables.imageId = this.currentCauze.imageId;
    }

    try {
      await apolloClient.mutate({
        variables,
        mutation: updatePlannedEventMutation,
        errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
      });

      if (this.currentCauze.hasMatch) {
        if (this.currentCauze.hadPreviousMatch) {
          const variables = {
            id: this.currentCauze.matchId,
            matchLimit: this.currentCauze.userMatchLimit,
            matchTotal: this.currentCauze.matchTotal,
            matchType: this.currentCauze.matchType,
            multiplier: this.currentCauze.matchMultiplier,
          };

          const matchTotalDifference = this.getMatchTotalDifference();
          if (matchTotalDifference > 0) {
            variables.matchTotal = this.currentCauze.matchTotal;
          }

          await apolloClient.mutate({
            variables,
            mutation: editMatchMutation,
            errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
          });

          if (matchTotalDifference > 0) {
            profileStore.updateActiveEntityTotal(matchTotalDifference * -1);
          }
        } else {
          await apolloClient.mutate({
            variables: {
              userContext,
              eventId: this.currentCauze.id,
              matchLimit: this.currentCauze.userMatchLimit,
              matchTotal: this.currentCauze.matchTotal,
              matchType: this.currentCauze.matchType,
              multiplier: this.currentCauze.matchMultiplier,
            },
            mutation: createMatchMutation,
            errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
          });
          profileStore.updateActiveEntityTotal(
            this.currentCauze.matchTotal * -1,
          );
        }
      }

      this.navToCauzes(userContext);
    } catch (err) {
      this.createCauzeError = true;
      this.savingCauze = false;
      console.log(err);
    }

    this.savingCauze = false;
  };

  @action loadCauzeToEdit = async ({ userContext, eventId }) => {
    this.loadingCauze = true;

    const options = {
      variables: {
        userContext,
        id: eventId,
      },
      query: eventQuery,
      fetchPolicy: 'no-cache',
      errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
    };

    const result = await apolloClient.query(options);
    let cauzeToEdit = result.data.event;

    if (!cauzeToEdit) {
      this.loadingCauzeError = true;
    } else {
      let matchToEdit = {};

      if (cauzeToEdit.matchSummaries && !userContext.charityId) {
        matchToEdit = cauzeToEdit.matchSummaries[0];
        matchToEdit.userMatchLimit = matchToEdit.matchLimit;
        matchToEdit.matchId = matchToEdit.id;
        matchToEdit.matchMultiplier = matchToEdit.multiplier;

        const sponsorMatch = matchToEdit.matchSponsors.filter(
          (matchSponsor) =>
            matchSponsor.id === userContext.companyId ||
            matchSponsor.id === userContext.userId,
        )[0];

        matchToEdit.matchTotal =
          sponsorMatch?.matchTotal || matchToEdit.matchTotal;

        delete matchToEdit['matchLimit'];
        delete matchToEdit['id'];
      }

      let cauzeDetails = {
        eventState: cauzeToEdit.eventState,
        name: cauzeToEdit.name,
        charities: cauzeToEdit.charities.map((charity) => ({
          ...charity,
          label: charity.name, // for the async select charity search component defaults
          key: charity.name, // ||
          location: `${charity.city}, ${charity.state}`,
          value: charity.id,
        })),
        previewUrl: cauzeToEdit.previewUrl,
        description: cauzeToEdit.description,
        startDate: moment(cauzeToEdit.startDate).utc(),
        endDate: moment(cauzeToEdit.endDate).utc(),
        previewImageUrl:
          cauzeToEdit.image && cauzeToEdit.image.lg ? cauzeToEdit.image.lg : '',
        id: eventId,
      };

      if (!isEmpty(matchToEdit)) {
        cauzeDetails = {
          ...matchToEdit,
          ...cauzeDetails,
          hadPreviousMatch: true,
          previousMatchTotal: matchToEdit.matchTotal,
          hasMatch: true,
        };
      }

      this.updateCauze(cauzeDetails);

      this.currentCauze.hasDates =
        !!cauzeToEdit.startDate || cauzeToEdit.endDate;
      this.loadingCauze = false;
    }
  };

  getMatchTotalDifference = () => {
    if (!this.currentCauze.previousMatchTotal) {
      return this.currentCauze.matchTotal;
    }

    return this.currentCauze.matchTotal - this.currentCauze.previousMatchTotal;
  };

  @action setMatchHasChanged = (matchHasChanged) => {
    this.matchHasChanged = matchHasChanged;
  };

  @action onUnmount = () => {
    this.isError = false;
    this.cauzes = [];
    this.cauzesJoinTotal = 0;
    this.cauzesAmountTotal = 0;
    this.cauzesLoading = false;
    this.isUploadingCauzeImage = false;
    this.imageUploadError = false;
    this.menuActiveForCharityId = null;
  };

  @action clearCauzeErrors = () => {
    this.loadingCauzeError = false;
    this.createCauzeError = false;
  };

  @action onCreateUnmount = () => {
    this.currentCauze = {
      name: '',
      charities: [],
      eventState: 'UNPUBLISHED',
      description: DESCRIPTION_DEFAULT,
      startDate: '',
      endDate: '',
      imageId: '',
      previewImageUrl: '',
      hasDates: false,
      userMatchLimit: 5000,
      matchTotal: 50000,
      hasMatch: false,
      hadPreviousMatch: false,
      previousMatchTotal: null,
      previewUrl: '',
      matchType: 'EVENT',
      matchMultiplier: 1,
      matchId: '',
    };
    this.matchHasChanged = false;
    this.createCauzeError = false;
    this.loadingCauze = false;
    this.loadingCauzeError = false;
  };

  constructor() {
    makeObservable(this);
  }
}

const cauzeStore = new CauzeStore();
export default cauzeStore;
