import React from 'react';
import PropTypes from 'prop-types';
import useClipboard from 'react-use-clipboard';
import classnames from 'classnames';

import copyIcon from 'assets/images/icons/svgs/copy-squares.svg';
import useKeyboardEvent from 'hooks/useKeyboardEvent.js';

const ShareModal = ({ onToggleClose, isOpen, shareUrl, isSelf }) => {
  useKeyboardEvent('Escape', () => {
    onToggleClose(false);
  });

  const [isCopied, setCopied] = useClipboard(shareUrl, {
    successDuration: 2000,
  });

  return (
    <div
      className={classnames('modal share-modal', {
        'is-active': isOpen,
      })}
    >
      <div className="modal-background" onClick={() => onToggleClose(false)} />
      <div className="modal-card">
        <section className="modal-card-body">
          <div className="share-link-x-container flex-row flex-justify-end">
            <button
              className="delete"
              aria-label="close"
              onClick={onToggleClose}
            />
          </div>
          <div>
            <div className="share-link-title">
              Share {isSelf ? 'your' : 'this'} Cauze Profile
            </div>
          </div>
          <div className="share-link-container">
            <img src={copyIcon} onClick={setCopied} />
            <input readOnly className="share-link" value={shareUrl} />
            <div className="share-link-copied">{isCopied && 'Copied!'}</div>
          </div>
        </section>
      </div>
    </div>
  );
};

ShareModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onToggleClose: PropTypes.func.isRequired,
  shareUrl: PropTypes.string.isRequired,
  shareTitle: PropTypes.string,
};

export default ShareModal;
