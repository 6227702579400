import React from 'react';

import Avatar from '../../Avatar/Avatar';
import CauzeCheckbox from 'components/CauzeCheckbox/CauzeCheckbox';

const CauzeCharityListItem = ({
  charity,
  isSelected = true,
  onSelect,
  onLearnMore,
  showLink = false,
  hideShadow = false,
  disabled = false,
  noCheckBox = false,
  notSelectable = false,
  index,
}) => (
  <a
    href={showLink ? `/charity/${charity.id}` : ''}
    className={`cauze-charity-item ${notSelectable && 'not-selectable'}`}
    key={`${charity.name}-${charity.id}`}
    onClick={(ev) => {
      if (showLink) {
        return;
      }
      ev.preventDefault();
      if (disabled) {
        return;
      }
      if (!!onSelect) {
        onSelect(charity.id);
      } else if (!!onLearnMore) {
        onLearnMore(charity.id);
      }
    }}
  >
    <Avatar
      md
      className="avatar"
      type="charity"
      dropShadow={!hideShadow}
      avatar={charity.avatar}
      avatarUrls={charity.avatarUrls}
      index={index}
    />
    <div className="flex-column flex-expand flex-align-center">
      <div className="charity-info">
        <div alt="test" className="charity-name">
          {charity.name}
        </div>
      </div>
    </div>
    <div className="charity-checkbox-container">
      {!!onSelect && !noCheckBox && (
        <CauzeCheckbox id={charity.name} isSelected={isSelected} />
      )}
    </div>
  </a>
);

export default CauzeCharityListItem;
