import AuthViewSignup from './AuthView/AuthViewSignup';
import { Navbar } from 'components/page';
import FooterNew from 'components/page/Footer/FooterNew';

const SignupView = () => {
  return (
    <div className="signup-view-container">
      <Navbar hideLoginPrompt />
      <AuthViewSignup />
      <FooterNew />
    </div>
  );
};

export default SignupView;
