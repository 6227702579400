import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import selectTableHOC from 'react-table/lib/hoc/selectTable';
import classnames from 'classnames';
import Button from 'components/Button/Button';
import { debounce } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSearch,
  faTimes,
  faSortUp,
  faSortDown,
  faFilter,
  faCaretRight,
} from '@fortawesome/free-solid-svg-icons';

import useKeyboardEvent from '../../hooks/useKeyboardEvent.js';
import {
  toggleSelection,
  toggleAll,
  useMultiSelect,
  getWrappedFilteredRows,
  getGiftTotals,
} from '../../util/tableUtils';
import columns from './GiftsTableColumns';

const SelectTable = selectTableHOC(ReactTable);

const GiftsTable = ({
  giftsList,
  keyField = 'id',
  actions,
  noFilter = false,
  totalGifted,
  remainingGifted,
  isGroup = false,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchActive, setSearchActive] = useState(false);
  const [pageSize, setPageSize] = useState(giftsList.length);
  const [showOptions, setShowOptions] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedAction, setSelectedAction] = useState('Actions');

  const [statusFilter, setStatusFilter] = useState('all');
  const [typeFilter, setTypeFilter] = useState('all');
  const [mobileSortActive, setMobileSortActive] = useState(false);
  const [mobileSort, setMobileSort] = useState({
    id: 'status',
    desc: true,
  });

  const MAX_PAGE_SIZE = 200;

  let giftsTableRef = useRef(null);
  let searchInputRef = useRef(null);
  let selectAllMobileRef = useRef(null);
  let selectAllRef = useRef(null);

  const debouncedSetSearchTerm = debounce(setSearchTerm, 1000);

  useMultiSelect(selectAllRef, selectAllMobileRef, selectedIds, giftsList);

  useEffect(() => {
    if (searchActive) {
      searchInputRef.current.focus();
    }
  }, [searchActive]);

  useKeyboardEvent('Escape', () => {
    toggleSearch(false);
  });

  const clearSearch = () => {
    setSearchTerm('');
    searchInputRef.current.value = '';
  };

  const clearAllFilters = () => {
    setTypeFilter('all');
    setStatusFilter('all');
  };

  const toggleOptionsModal = (isOpen) => {
    if (!isOpen) {
      document.documentElement.classList.remove('is-clipped');
      setShowOptions(false);
      return;
    }
    document.documentElement.classList.add('is-clipped');
    setShowOptions(true);
  };

  const toggleGiftSelection = (key, shift, row) => {
    toggleSelection(key, shift, row, selectedIds, setSelectedIds);
  };

  const toggleAllUsers = () => {
    toggleAll(giftsList, giftsTableRef, selectedIds, setSelectedIds, keyField);
  };

  const toggleSearch = (isOpen = true) => {
    if (isOpen) {
      setMobileSortActive(false);
      setSearchActive(true);
      searchInputRef.current.focus();
      return;
    }
    setSearchActive(false);
    searchInputRef.current.blur();
    clearSearch();
  };

  const toggleMobileSort = (isOpen = true) => {
    if (isOpen) {
      setMobileSortActive(true);
      return;
    }
    setMobileSortActive(false);
  };

  const rowFn = (state, rowInfo, column, instance) => ({
    onClick: (e, handleOriginal) => {
      const { expanded } = state;
      const path = rowInfo.nestingPath[0];
      const diff = { [path]: !expanded[path] };

      instance.setState({
        expanded: { ...expanded, ...diff },
      });
      if (handleOriginal) {
        handleOriginal();
      }
    },
  });

  const isSelected = (key) => selectedIds.includes(`select-${key}`);
  const { filteredTotalGifted, filteredRemainingGifted } = getGiftTotals(
    getWrappedFilteredRows(giftsTableRef),
  );

  const giftsColumns = columns({
    actions,
    totalGifted: filteredTotalGifted || totalGifted,
    remainingGifted: filteredRemainingGifted || remainingGifted,
    isGroup,
  });

  return (
    <div className="rt-container">
      <div
        className={classnames('modal', {
          'is-active': showOptions,
        })}
      >
        <div className="modal-background"></div>
        <div className="modal-card search-options-modal">
          <header className="modal-card-head">
            <p className="modal-card-title">
              <FontAwesomeIcon icon={faFilter} />
              Filters
            </p>
            <button
              className="delete"
              aria-label="close"
              onClick={() => toggleOptionsModal(false)}
            ></button>
          </header>
          <section className="modal-card-body">
            <div className="option-group">
              <p className="option-group-subhead is-size-5">Status</p>
              <div className="control flex-column">
                <label className="radio" htmlFor="statusRadio1">
                  <input
                    type="radio"
                    value="all"
                    name="status"
                    id="statusRadio1"
                    checked={statusFilter === 'all'}
                    onChange={(e) => setStatusFilter(e.target.value)}
                  />
                  All
                </label>
                <label className="radio" htmlFor="statusRadio2">
                  <input
                    type="radio"
                    value="Redeemed"
                    id="statusRadio2"
                    name="status"
                    checked={statusFilter === 'Redeemed'}
                    onChange={(e) => setStatusFilter(e.target.value)}
                  />
                  Redeemed
                </label>
                <label className="radio" htmlFor="statusRadio3">
                  <input
                    type="radio"
                    value="Waiting"
                    id="statusRadio5"
                    name="status"
                    checked={statusFilter === 'Waiting'}
                    onChange={(e) => setStatusFilter(e.target.value)}
                  />
                  Waiting
                </label>
                <label className="radio" htmlFor="statusRadio4">
                  <input
                    type="radio"
                    value="Revoked"
                    id="statusRadio4"
                    name="status"
                    checked={statusFilter === 'Revoked'}
                    onChange={(e) => setStatusFilter(e.target.value)}
                  />
                  Revoked
                </label>
              </div>
            </div>
            <div className="option-group">
              <p className="option-group-subhead is-size-5">Type</p>
              <div className="control flex-column">
                <label className="radio" htmlFor="eligibleGiversRadio1">
                  <input
                    type="radio"
                    value="all"
                    name="eligibleGivers"
                    id="typeRadio1"
                    checked={typeFilter === 'all'}
                    onChange={(e) => setTypeFilter(e.target.value)}
                  />
                  All
                </label>
                <label className="radio" htmlFor="eligibleGiversRadio2">
                  <input
                    type="radio"
                    value="Invite & Gift"
                    name="type"
                    id="typeRadio2"
                    checked={typeFilter === 'Invite & Gift'}
                    onChange={(e) => setTypeFilter(e.target.value)}
                  />
                  Invite & Gift
                </label>
                <label className="radio" htmlFor="eligibleGiversRadio3">
                  <input
                    type="radio"
                    value="Employee Gift"
                    name="type"
                    id="typeRadio3"
                    checked={typeFilter === 'Employee Gift'}
                    onChange={(e) => setTypeFilter(e.target.value)}
                  />
                  {isGroup ? 'Member' : 'Employee'} Gift
                </label>
                <label className="radio" htmlFor="eligibleGiversRadio3">
                  <input
                    type="radio"
                    value="Customer Gift"
                    name="type"
                    id="typesRadio3"
                    checked={typeFilter === 'Customer Gift'}
                    onChange={(e) => setTypeFilter(e.target.value)}
                  />
                  Customer Gift
                </label>
              </div>
            </div>
          </section>
          <footer className="modal-card-foot">
            <Button
              className="button is-primary"
              onClick={() => toggleOptionsModal(false)}
            >
              Apply
            </Button>
            <Button
              white
              className="button"
              onClick={() => {
                clearAllFilters();
                toggleOptionsModal(false);
              }}
            >
              Clear All
            </Button>
          </footer>
        </div>
      </div>
      <div className="action-bar flex-row">
        <div className="responsive-row">
          <div className={classnames('search-input')}>
            <div
              tabIndex="0"
              onFocus={() => toggleSearch(true)}
              onClick={searchTerm !== '' ? clearSearch : toggleSearch}
              className="action-button"
            >
              <FontAwesomeIcon
                icon={faSearch}
                className={classnames('', {
                  orange: searchTerm !== '',
                })}
              />
            </div>
            <input
              tabIndex={0}
              autoFocus={searchActive}
              className="input"
              placeholder="search gifts&#8230;"
              ref={searchInputRef}
              onChange={(e) => debouncedSetSearchTerm(e.target.value)}
              onFocus={toggleSearch}
              onBlur={() => setSearchActive(false)}
            />
            <div
              alt="clear all"
              className="action-button close-button flex-column"
              disabled={
                !(
                  mobileSortActive ||
                  searchActive ||
                  searchTerm !== '' ||
                  typeFilter !== 'all' ||
                  statusFilter !== 'all'
                )
              }
              onClick={() => {
                toggleSearch(false);
                toggleMobileSort(false);
                setStatusFilter('all');
                setTypeFilter('all');
              }}
            >
              <FontAwesomeIcon icon={faTimes} />
            </div>
          </div>
        </div>
        <div className="responsive-row flex-expand">
          <div className="select-all-check is-hidden-tablet">
            <input
              type="checkbox"
              checked={giftsList.length === selectedIds.length}
              onChange={() => toggleAll()}
              ref={selectAllMobileRef}
            />
          </div>
          {actions && (
            <div
              className={classnames('actions-container flex-row flex-expand')}
            >
              <select
                value={selectedAction}
                onChange={(e) => setSelectedAction(e.target.value)}
              >
                <option value="Actions">Actions</option>
                {actions.map((action, index) => (
                  <option key={`${action.name}`} value={index}>
                    {action.name}
                  </option>
                ))}
              </select>
              <button
                className="button is-hidden-mobile is-primary is-small apply-button"
                disabled={
                  selectedAction === 'Actions' || selectedIds.length === 0
                }
                onClick={() => {
                  actions[selectedAction].action(
                    selectedIds.map((idString) =>
                      idString.replace('select-', ''),
                    ),
                  );
                  setSelectedIds([]);
                }}
              >
                Apply
              </button>
              <button
                className={classnames('is-hidden-tablet go-action', {
                  'is-disabled': selectedAction === 'Actions',
                })}
                disabled={
                  selectedAction === 'Actions' || selectedIds.length === 0
                }
                onClick={() =>
                  actions[selectedAction].action(
                    selectedIds.map((idString) =>
                      idString.replace('select-', ''),
                    ),
                  )
                }
              >
                <FontAwesomeIcon icon={faCaretRight} />
              </button>
            </div>
          )}
          <div
            className={classnames('flex-row flex-expand is-hidden-tablet', {
              'is-hidden-mobile': searchActive,
            })}
          />
          <div className="flex-row">
            <div
              className={classnames(
                'action-button sort-action flex-column is-hidden-tablet',
              )}
              onClick={
                mobileSortActive
                  ? () =>
                      setMobileSort({ ...mobileSort, desc: !mobileSort.desc })
                  : () => {
                      setMobileSort({ ...mobileSort, desc: !mobileSort.desc });
                      toggleMobileSort(true);
                    }
              }
            >
              <FontAwesomeIcon
                icon={faSortUp}
                className={classnames('', {
                  orange: mobileSort.desc,
                })}
              />
              <FontAwesomeIcon
                icon={faSortDown}
                className={classnames('', {
                  orange: !mobileSort.desc,
                })}
              />
            </div>
            {!noFilter && (
              <div
                className={classnames('action-button')}
                onClick={() => toggleOptionsModal(!showOptions)}
              >
                <FontAwesomeIcon
                  icon={faFilter}
                  className={classnames('', {
                    orange: statusFilter !== 'all',
                  })}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <SelectTable
        filterable
        SelectAllInputComponent={() => (
          <input
            type="checkbox"
            aria-label="Select row with id:select-all"
            id="select-all"
            checked={giftsList.length === selectedIds.length}
            ref={selectAllRef}
            onChange={toggleAllUsers}
          />
        )}
        className="gifts-table"
        resizable={false}
        keyField={keyField}
        ref={giftsTableRef}
        toggleSelection={toggleGiftSelection}
        selectType="checkbox"
        selectAll={giftsList.length === selectedIds.length}
        toggleAll={toggleAllUsers}
        columns={giftsColumns}
        data={giftsList}
        isSelected={isSelected}
        getTrProps={rowFn}
        getTheadProps={() => ({ className: 'is-hidden-mobile' })}
        pageSize={MAX_PAGE_SIZE}
        minRows={0}
        filtered={[
          { id: 'recipient', value: searchTerm },
          { id: 'status', value: statusFilter },
          { id: 'type', value: typeFilter },
        ]}
        defaultSorted={[{ id: 'date', desc: true }]}
        showPaginationBottom={pageSize > MAX_PAGE_SIZE}
        defaultPageSize={MAX_PAGE_SIZE}
        onFilterUpdateLength={(len) => setPageSize(len)}
      />
    </div>
  );
};

GiftsTable.propTypes = {
  giftsList: PropTypes.array,
  keyField: PropTypes.string,
  actions: PropTypes.array,
  noFilter: PropTypes.bool,
  totalGifted: PropTypes.number,
  remainingGifted: PropTypes.number,
  isGroup: PropTypes.bool,
};

export default GiftsTable;
