import React from 'react';

const UserInfoInputField = ({
  placeholder,
  type = 'text',
  name,
  className,
  required = true,
  autoComplete = false,
  title,
  onUpdate,
  value,
  onBlur,
  id,
}) => (
  <div className="user-info-input-field-container">
    <div className="user-info-input-field-title">{title}</div>
    <input
      id={id}
      className={`user-info-input-field ${className && className}`}
      placeholder={placeholder}
      type={type}
      value={value}
      name={name}
      required={required}
      autoComplete={autoComplete.toString()}
      onChange={(ev) => {
        onUpdate(ev);
      }}
      onBlur={onBlur}
    />
  </div>
);

export default UserInfoInputField;
