import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import colors from '../../styles/colors';

import AsyncSelect from 'react-select/async';
import { components } from 'react-select';
import { client as apolloClient } from '../../util/apolloClient';
import { usersSearchQuery } from '../../graphql/search';
import makeAnimated from 'react-select/animated';
import Avatar from '../Avatar/Avatar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

const fetchResults = async (inputValue, callback) => {
  if (inputValue) {
    try {
      const result = await apolloClient
        .query({
          query: usersSearchQuery,
          variables: { query: inputValue },
          fetchPolicy: 'network-only',
          errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
        })
        .then(res =>
          res.data.usersSearch.map(user => ({
            ...user,
            value: user.id,
            label: `${user.firstName} ${user.lastName}`,
          })),
        );
      callback(result);
    } catch (err) {
      console.error(err);
    }
  }
};

const animatedComponents = makeAnimated();

const MultiValueContainer = props => (
  <components.MultiValueContainer {...props}>
    <div className="flex-row">
      {props.data.avatar && (
        <div
          style={{
            justifySelf: 'stretch',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Avatar
            xsm
            style={{ marginLeft: '0.25rem' }}
            avatar={props.data.avatar}
          />
        </div>
      )}
      {props.children}
    </div>
  </components.MultiValueContainer>
);

const Option = props => (
  <components.Option {...props}>
    <div className="flex-row flex-align-center">
      <Avatar
        sm
        style={{ marginRight: '0.5rem' }}
        avatar={props.data.avatar}
        type="charity"
      />
      <div className="flex-column">
        {props.data.label}
        <div style={{ fontSize: '0.8rem', color: 'grey' }}>
          {props.data.username}
        </div>
      </div>
    </div>
  </components.Option>
);

Option.propTypes = {
  children: PropTypes.node,
  data: PropTypes.object,
};

const debouncedFetchResults = debounce(fetchResults, 300);

const loadOptions = (inputValue, callback) => {
  debouncedFetchResults(inputValue, callback);
};

export default class UserSearch extends Component {
  constructor (props) {
    super(props);
    this.state = { value: this.props.defaultValue };
  }

  removeValue = index => {
    let newValue = this.state.value.slice();
    newValue.splice(index, 1);
    this.setState({ value: newValue });
  };

  handleInputChange = newValue => {
    this.setState({ inputValue: newValue });
    return newValue;
  };

  render () {
    return (
      <div className="search-container user-search-container">
        <FontAwesomeIcon icon={faSearch} className="search-icon"/>
        <div style={{ flex: 1 }}>
          <AsyncSelect
            value={this.state.value}
            placeholder="Search for a user"
            loadOptions={loadOptions}
            defaultOptions={false}
            isMulti={this.props.isMulti}
            theme={theme => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: colors.brand,
                primary75: colors.grey,
                primary50: colors.grey50,
                primary25: colors.grey25,
                danger: colors.baseColor,
                neutral10: colors.grey,
              },
            })}
            onInputChange={this.handleInputChange}
            components={{
              ...animatedComponents,
              Option,
              MultiValueContainer,
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
            onChange={value => {
              this.props.onChange(value);
              this.setState({ value: value });
            }}
            autoLoad={false}
          />
        </div>
        {this.props.showSelectionList && this.state.value && (
          <div className="search-result-container">
            {this.state.value.map((charity, index) => (
              <div
                key={`${charity.id}`}
                className="flex-row flex-align-center search-result-row"
              >
                <Avatar avatar={charity.avatar} entityType={'CHARITY'} />
                <div className="flex-expand flex-column">
                  <div className="flex-row text-bold">{charity.name}</div>
                  <div className="flex-row result-detail">
                    {charity.location} | EIN: {charity.ein}
                  </div>
                </div>
                <div
                  className="delete"
                  onClick={() => this.removeValue(index)}
                ></div>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }
}

UserSearch.propTypes = {
  onChange: PropTypes.func,
  defaultValue: PropTypes.array,
  showSelectionList: PropTypes.bool,
  isMulti: PropTypes.bool,
};
