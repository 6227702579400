import { useEffect } from 'react';
import companiesStore from '../stores/CompaniesStore';

const useCompanies = () => {
  useEffect(() => {
    companiesStore.onUnmount();
    companiesStore.getInitial();
  }, []);
};

export default useCompanies;
