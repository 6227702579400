import gql from 'graphql-tag';

const giftsLedgerQuery = gql`
  query (
    $companyId: Int
    $limit: Int
    $offset: Int
    $searchTerm: String
    $sort: String
    $sortDirection: String
    $filters: GiftLedgerFilters
  ) {
    giftsLedger(
      companyId: $companyId
      limit: $limit
      offset: $offset
      query: $searchTerm
      sort: $sort
      sortDirection: $sortDirection
      filters: $filters
    ) {
      ledgerItems {
        userTokenId
        updatedAt
        type
        status
        revokedById
        revoked
        remaining
        projectName
        recipientId
        recipient {
          username
          firstName
          lastName
          email
          avatarUrls {
            md
            lg
            sm
            xl
          }
        }
        amount
        email
        comment
        feePercent
        giftId
        giftTokenId
        hideAmount
        initiatorCompanyId
        initiatorId
        insertedAt
        invite
      }
      totalGifted
      remainingGifted
      totalRows
    }
  }
`;

const companyUnredeemedGiftsQuery = gql`
  query ($id: ID!) {
    listCompanyUnredeemedGifts(id: $id) {
      resentAt
      insertedAt
      id
      revoked
      pending
      gift {
        amount
      }
      sentAt
      email
      phoneNumber
      type
    }
  }
`;

const refundCompanyGiftsMutation = gql`
  mutation ($gift_ids: [ID!]!) {
    refundCompanyGifts(giftIds: $gift_ids) {
      id
    }
  }
`;

export {
  giftsLedgerQuery,
  companyUnredeemedGiftsQuery,
  refundCompanyGiftsMutation,
};
