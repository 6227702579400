import React, { useState } from 'react';
import PropTypes from 'prop-types';

import DatePicker from '../../../DatePicker';
import AmountSelector from '../../../AmountSelector/AmountSelector';
import Currency from '../../../Currency/Currency';

import moment from 'moment';

import { observer } from 'mobx-react';

const SelectAnnualMatchDetails = ({ store, setValidator, isGroup = false }) => {
  const [customDate, setCustomDate] = useState(!!store.editingMatchId);
  const match = store.currentMatch;

  setValidator(() => {
    if (!match.name) {
      return 'Enter a match name to proceed';
    } else if (!match.startDate || !match.endDate) {
      return 'Select a starting and ending date to proceed';
    } else if (match.matchLimit <= 0) {
      return `${
        isGroup ? 'Member' : 'Employee'
      } Match Limit must be more than 0`;
    } else {
      return '';
    }
  });

  const now = moment().utc();

  return (
    <div className="modal-match-height">
      <div className="option-group">
        <div className="name-input flex-column">
          <p className="input-title">Match Name</p>
          <input
            type="text"
            maxLength={127}
            placeholder={
              match.name || `Your ${isGroup ? 'Member' : 'Employee'} Match`
            }
            value={match.name || ''}
            onChange={e => {
              store.updateMatch({ name: e.target.value });
            }}
          />
        </div>
      </div>
      <div className="option-group" style={{ marginTop: '2rem' }}>
        <p className="match-title">Maximum Per Giver</p>
        <AmountSelector
          amounts={[5000, 10000, 25000, 50000]}
          onAmountUpdate={matchLimit =>
            store.updateMatch({
              matchLimit,
              matchTotal: matchLimit * 5 * match.multiplier,
            })
          }
          defaultAmount={match.matchLimit}
        />
        <p
          className="match-title flex-row"
          style={{ marginTop: '1rem', marginRight: '1rem', marginLeft: '1rem' }}
        >
          Match up to&nbsp;
          <span className="text-bold">
            <Currency amount={match.matchLimit} />
          </span>
          &nbsp;per Giver
        </p>
      </div>
      <div className="option-group" style={{ marginTop: '1rem' }}>
        <p className="match-title">Select Dates</p>
        <div className="flex-expand flex-column match-selection">
          {store.matchDates?.length > 0 &&
            store.matchDates.map((date, index) => (
              <label key={index} className="radio" htmlFor={`date${index}`}>
                <input
                  type="radio"
                  value={index}
                  id={`date${index}`}
                  name={`date${index}`}
                  checked={match.endDate === date.date && !customDate}
                  onChange={_e => {
                    store.updateMatch({
                      startDate: now,
                      endDate: date.date,
                    });
                    setCustomDate(false);
                  }}
                />
                Now through {date.name}
              </label>
            ))}
          <label className="radio" htmlFor="dateCustom">
            <input
              type="radio"
              value="dateCustom"
              id="dateCustom"
              name="dateCustom"
              checked={customDate}
              onChange={_e => setCustomDate(true)}
            />
            Select Date Range
          </label>
          {customDate && (
            <div
              className="option-group-subhead flex-row flex-start option-total"
              style={{ marginTop: '2rem' }}
            >
              <DatePicker
                startDate={match.startDate}
                endDate={match.endDate}
                onDatesChange={({ startDate, endDate }) => {
                  store.updateMatch({ startDate, endDate });
                }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

SelectAnnualMatchDetails.propTypes = {
  store: PropTypes.object.isRequired,
  setValidator: PropTypes.func,
  isGroup: PropTypes.bool,
};

export default observer(SelectAnnualMatchDetails);
