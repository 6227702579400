const convertFeedToV1 = (feed) => {
  return {
    ...feed,
    id: feed?.activityPurchase?.id,
    date: feed.activityPurchase?.insertedAt,
    actor: {
      id: feed?.activityEntity?.id,
      name: feed?.activityEntity?.name,
      entityType: feed?.activityEntity?.type,
      isSelf: feed?.activityEntity?.isCurrentEntity,
      avatar: feed?.activityEntity?.avatarUrls,
      user: feed?.activityEntity?.user,
    },
    initiator: {
      id: feed?.activityRoot?.activityEntity?.id,
      entityType: feed?.activityRoot?.activityEntity?.type,
      isSelf: feed?.activityRoot?.activityEntity?.isCurrentEntity,
    },
    eventId: feed?.event?.id,
    eventType: feed?.event?.eventType,
    purchaseId: feed?.activityPurchase?.id,
    type: feed?.itemType,
    subjects: [feed?.event, ...(feed?.projects || [])].filter((s) => s),
    purchaseCount: feed?.totalActorCount,
    matchActors:
      feed?.activityPurchase?.matchPurchases
        ?.map((matchPurchase) =>
          matchPurchase?.matchSponsor
            ? {
                ...matchPurchase.matchSponsor,
                entityType: matchPurchase.matchSponsor.type,
                multiplier: matchPurchase.match?.multiplier,
              }
            : null,
        )
        ?.filter((mp) => mp)
        ?.slice()
        ?.reverse()
        ?.slice(0, 1) || [],
  };
};

export default convertFeedToV1;
