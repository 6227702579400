import React from 'react';
import Avatar from 'components/Avatar/Avatar';
import Currency from 'components/Currency/Currency';
import PropTypes from 'prop-types';
import moment from 'moment';
import classnames from 'classnames';

const ExpandedCurrency = ({ text, userValue, unredeemed = false }) => (
  <div className="flex-row flex-expand user-expanded-text">
    <div className="flex-expand">{text}</div>
    <div
      className={classnames('flex-justify-end', {
        'gift gift-unredeemed': unredeemed,
      })}
    >
      {<Currency amount={userValue ? Math.abs(userValue) : 0} />}
      {unredeemed && (
        <span className="gift-unredeemed gift-unredeemed-text">
          &nbsp;(unredeemed)
        </span>
      )}
    </div>
  </div>
);
ExpandedCurrency.propTypes = {
  text: PropTypes.string.isRequired,
  totalValue: PropTypes.number,
  userValue: PropTypes.number,
  unredeemed: PropTypes.bool,
};

const ExpandedDate = ({ text, date }) => (
  <div className="flex-row flex-expand is-hidden-tablet">
    <div className="user-expanded-text flex-expand">{text}</div>
    <div className="user-expanded-text flex-justify-end">
      <span>{date ? moment(date).format('M/D/Y') : 'Waiting'}</span>
    </div>
  </div>
);
ExpandedDate.propTypes = {
  text: PropTypes.string.isRequired,
  date: PropTypes.string,
};

const User = ({ original, isExpanded }) => (
  <div className="flex-row col-user-container">
    <div className="flex-column flex-expand min-width-reset">
      <div className="flex-row">
        <Avatar avatar={original.avatar} />
        <div className="flex-column flex-expand col-user-details">
          {original.firstName !== null && original.lastName !== null ? (
            <div>
              <div className="col-user-name">{`${original.firstName} ${
                original.lastName
              }${
                original.companyRoles?.includes('admin') ? ' - Admin' : ''
              }`}</div>
              <div className="col-user-email">{original.email}</div>
            </div>
          ) : (
            <div className="col-user-name">{original.email}</div>
          )}
        </div>
      </div>
      {isExpanded && (
        <div className="is-hidden-desktop is-expanded flex-column flex-expand">
          <ExpandedCurrency
            text="Gifts"
            userValue={original.giftAmount}
            unredeemed={!original.companyGiftTotal}
          />
          <ExpandedCurrency
            text="Eligible Match"
            userValue={original.eligibleMatch}
          />
          <ExpandedDate text="Redeemed" date={original.redeemedDate} />
          <ExpandedDate text="Invited Date" date={original.invitedDate} />
        </div>
      )}
    </div>
  </div>
);

User.propTypes = {
  value: PropTypes.string,
  original: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    redeemed: PropTypes.bool,
    balance: PropTypes.shape({
      total: PropTypes.number,
    }),
  }).isRequired,
  isExpanded: PropTypes.bool,
  totalUsersBalance: PropTypes.number,
  companyEligibleMatch: PropTypes.number,
};

export default User;
