import { useState, useEffect, useMemo } from 'react';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Redirect } from '@reach/router';
import { observer, inject } from 'mobx-react';
import mixpanel from 'mixpanel-browser';

import Avatar from 'components/Avatar/Avatar';

import { ReactComponent as CauzeLogo } from 'assets/images/brand/cauze-logo.svg';
import { ReactComponent as GiftIcon } from 'assets/images/icons/gift/gift-box.svg';
import { ReactComponent as SurpriseIcon } from 'assets/images/icons/svgs/surprise.svg';
import Currency from 'components/Currency/Currency';
import CauzeButton from 'components/CauzeButton/CauzeButton';
import CauzeSpinner from 'components/CauzeSpinner/CauzeSpinner';

import LoginForm from './LoginForm';

const onLoginClick = async ({
  authStore,
  profileStore,
  uiStore,
  username,
  password,
}) => {
  try {
    await authStore.login(username, password);
  } catch (err) {
    uiStore.showNotification({
      body: 'Invalid credentials. Please check your username and password.',
      type: 'ERROR',
    });
  }

  if (!authStore.authError) {
    await profileStore.getProfile(authStore.stashedPathDetails);
    mixpanel.identify(profileStore.data.email.toLowerCase());
    mixpanel.track('Logged In', {
      email: profileStore.data.email.toLowerCase(),
    });
  }
};

const GiftBanner = ({ showGift, amount, authStore, companyName }) => {
  const giftDescTitle = useMemo(() => {
    const name = companyName || authStore.giftTokenDetails?.user?.name;

    return showGift && name
      ? `${name} has given you a
  gift!`
      : `Log in to your 
    Cauze personal giving account.`;
  }, [showGift, companyName, authStore.giftTokenDetails]);

  const giftDescText = useMemo(() => {
    if (showGift && !companyName) {
      return 'To use your gift and select a charity of your choice for giving, login in to your giving account.';
    }

    return showGift
      ? `To use your gift and select a charity of your choice for giving,
log in to your ${companyName}
employee giving account.`
      : `${companyName} will match all your giving, so you need to login to your account to utilize the match.`;
  }, [companyName, showGift]);

  return (
    <div className="signup-view-gift">
      <div className="gift-icon">
        {showGift ? <GiftIcon /> : <SurpriseIcon />}
      </div>
      {showGift && Boolean(amount) && (
        <div className="gift-amount">
          <Currency showCents showDollarSign amount={amount} />
        </div>
      )}
      <div className="gift-desc">
        <div className="gift-desc-bold">{giftDescTitle}</div>
        <div className="gift-desc-text">{giftDescText}</div>
      </div>
    </div>
  );
};

const AuthViewLogin = ({ authStore, profileStore, uiStore, email }) => {
  const [username, setUsername] = useState(email || '');
  const [password, setPassword] = useState('');

  useEffect(() => {
    if (
      authStore.isAuthenticated &&
      profileStore.hasFetchedInitial &&
      profileStore.availableUserEntities.length > 1 &&
      authStore.stashedPathDetails.path &&
      !authStore.stashedPathDetails.path.contains('addsponsor')
    ) {
      uiStore.openModal('CONTEXT_SELECTOR');
    }
  }, [profileStore.hasFetchedInitial, authStore.isAuthenticated]);

  const loadingSignInBanner =
    !authStore.isAuthenticated && authStore.loadingTokenData;

  const showSignInBanner =
    !authStore.isAuthenticated &&
    !authStore.loadingTokenData &&
    authStore.companyInviteDetails &&
    !authStore.companyInviteDetails.redeemed;
  const showGiftBanner = Boolean(authStore.giftTokenDetails?.user);
  const amount = authStore.companyInviteDetails?.gift?.amount;
  const showGift = Boolean(amount) || showGiftBanner;
  const companyName =
    authStore.giftTokenDetails?.company?.name ||
    authStore.companyInviteDetails?.company?.name;

  const redeemerEmail = authStore.companyInviteDetails?.email;

  useEffect(() => {
    if (redeemerEmail) {
      setUsername(redeemerEmail);
    }
  }, [redeemerEmail]);

  if (authStore.isAuthenticated && profileStore.hasFetchedInitial) {
    if (authStore.stashedPathDetails.path) {
      return <Redirect to={`${authStore.unstashPathDetails()}`} noThrow />;
    } else {
      return <Redirect to="/donate" noThrow />;
    }
  }

  if (
    (authStore.isAuthenticated && !profileStore.hasFetchedInitial) ||
    loadingSignInBanner
  ) {
    return <CauzeSpinner />;
  }

  return (
    <div className="signup-view">
      <div className="background-image" />
      <div className="auth-form-body">
        <div className="signup-view-logos">
          <CauzeLogo />
          {showSignInBanner && (
            <>
              <FontAwesomeIcon icon={faTimes} size="lg" />
              <Avatar
                noBorder
                avatar={authStore.companyInviteDetails?.company?.avatar}
              />
            </>
          )}
        </div>
        {(showSignInBanner || showGiftBanner) && (
          <GiftBanner
            authStore={authStore}
            amount={amount}
            showGift={showGift}
            companyName={companyName}
          />
        )}
        <h1 className="signup-view-title">login</h1>
        <form
          className="signup-view-form"
          onSubmit={(e) => {
            e.preventDefault();
            onLoginClick({
              profileStore,
              authStore,
              username,
              password,
              uiStore,
            });
          }}
        >
          <LoginForm
            username={username}
            setUsername={setUsername}
            password={password}
            setPassword={setPassword}
          />
          <CauzeButton type="submit" isSubmitting={authStore.isLoggingIn}>
            LOGIN
          </CauzeButton>
        </form>
        <div className="login-button-container">
          <a href="/signup" className="login-button">
            SIGN UP
          </a>
        </div>
      </div>
    </div>
  );
};

export default inject(
  'authStore',
  'profileStore',
  'uiStore',
)(observer(AuthViewLogin));
