const stripeOptions = {
  elements: {
    fonts: [
      {
        cssSrc:
          'https://fonts.googleapis.com/css?family=Open+Sans&display=swap',
        family: 'open-sans',
        style: 'normal',
      },
    ],
  },
  cardElement: {
    fields: {
      billingDetails: {
        name: 'never',
        email: 'never',
        phone: 'never',
        address: 'never',
      },
    },
    wallets: {
      applePay: 'never',
      googlePay: 'never',
    },
  },
};

export default stripeOptions;
