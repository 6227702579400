import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { DateRangePicker } from 'react-dates';
import { OPEN_UP } from 'react-dates/constants';

const DatePicker = ({ startDate, endDate, onDatesChange, ...props }) => {
  const [focusedInput, setFocusedInput] = useState();

  return (
    <DateRangePicker
      startDate={startDate}
      startDateId="startDateId"
      endDate={endDate}
      endDateId="endDateId"
      onDatesChange={onDatesChange}
      focusedInput={focusedInput}
      onFocusChange={focusedInput => setFocusedInput(focusedInput)}
      openDirection={OPEN_UP}
      numberOfMonths={1}
      showClearDates
      showDefaultInputIcon
      {...props}
    />
  );
};

DatePicker.propTypes = {
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  onDatesChange: PropTypes.func.isRequired,
};

export default DatePicker;
