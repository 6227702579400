import React from 'react';

import PaymentMethodSelect from 'components/PaymentMethodSelect/PaymentMethodSelect';
import CheckoutSummary from 'components/checkout/CheckoutSummary';
import SubmitButton from './SubmitButton';

import useCheckoutStore from 'stores/CheckoutStore';

const ConfirmationContent = ({
  currentCheckout,
  uiStore,
  setFrame,
  loading,
  onSubmit,
}) => {
  const addFundsGiftCheckoutStore = useCheckoutStore();

  return (
    <div className="flex-1 w-full px-5 flex flex-col justify-between">
      <div>
        <div className="flex justify-center w-full">
          <div className="font-agenda-bold text-[20px] mx-auto py-2">
            Checkout Summary
          </div>
        </div>
      </div>
      <div>
        <div>
          <PaymentMethodSelect
            store={addFundsGiftCheckoutStore}
            currentCheckout={currentCheckout}
            activePaymentMethod={addFundsGiftCheckoutStore.activePaymentMethod}
            onClick={() => {
              setFrame(1);
            }}
            disabled={loading}
          />
          <hr className="my-2 h-[2px] bg-lightgray-b2b" />
          <CheckoutSummary
            store={addFundsGiftCheckoutStore}
            currentCheckout={currentCheckout}
            uiStore={uiStore}
            loading={loading}
            activePaymentMethod={addFundsGiftCheckoutStore.activePaymentMethod}
          />
          <SubmitButton onSubmit={onSubmit} isMobile />
        </div>
      </div>
    </div>
  );
};

export default ConfirmationContent;
