import Button from 'components/Button/ButtonNew';
import Currency from 'components/Currency/Currency';
import CauzeSpinner from 'components/CauzeSpinner/CauzeSpinner';

function UserLedgerRow({
  description,
  createdAt,
  amount,
  balanceType,
  endingBalance,
  isMobile,
  title,
  subtitle,
}) {
  let isNegativeTransaction = true;
  const descriptionPreface = description?.split(' ').slice(0, 2).join(' ');

  if (balanceType === 'CREDIT') {
    isNegativeTransaction = false;
  }

  if (descriptionPreface === 'Payroll deduction') {
    subtitle = '';
  }

  if (isMobile) {
    return (
      <div className="user-activity-table-row">
        <div className="description">
          <div className="desc-title">{title}</div>
          <div className="desc-type">{createdAt.format('MMM D, YYYY')}</div>
          <div className="desc-type">{subtitle}</div>
        </div>
        <div>
          <div className="amount">
            <Currency
              showCents
              showDollarSign
              amount={isNegativeTransaction ? -Math.abs(amount) : amount}
              className={!isNegativeTransaction ? 'positive' : undefined}
            />
          </div>
          <div className="balance">
            <Currency showCents showDollarSign amount={endingBalance} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="user-activity-table-row">
      <div className="date">
        <div className="month">{createdAt.format('MMM')}</div>
        <div className="day">{createdAt.format('D')}</div>
        <div className="year">{createdAt.year()}</div>
      </div>
      <div className="description">
        <div className="desc-title">{title}</div>
        <div className="desc-type">{subtitle}</div>
      </div>
      <div className="amount">
        <Currency
          showCents
          showDollarSign
          amount={amount}
          className={!isNegativeTransaction ? 'positive' : undefined}
        />
      </div>
      <div className="balance">
        <Currency showCents showDollarSign amount={endingBalance} />
      </div>
    </div>
  );
}

export default function UserLedgerTable({
  ledger,
  isMobile,
  loadMore,
  isFetching,
  loading,
}) {
  if (loading) {
    return (
      <div className="user-activity-no-activity">
        <CauzeSpinner />
      </div>
    );
  }

  if (!ledger?.length) {
    return (
      <div className="user-activity-no-activity">You have no activity.</div>
    );
  }

  return (
    <div className="user-activity-table">
      <div className="user-activity-table-columns">
        {isMobile ? (
          <div className="col-text">DESCRIPTION</div>
        ) : (
          <div className="col-text">DATE</div>
        )}
        {isMobile ? (
          <div className="col-text right">AMOUNT & BAL.</div>
        ) : (
          <div className="col-text">DESCRIPTION</div>
        )}
        {!isMobile && (
          <>
            <div className="col-text right">AMOUNT</div>
            <div className="col-text right">BALANCE</div>{' '}
          </>
        )}
      </div>
      {ledger.map((transaction) => (
        <UserLedgerRow
          isMobile={isMobile}
          key={transaction.key}
          {...transaction}
        />
      ))}
      {Boolean(loadMore) && (
        <div className="load-more-container">
          <Button variant="primary" onClick={loadMore} loading={isFetching}>
            Load More
          </Button>
        </div>
      )}
    </div>
  );
}
