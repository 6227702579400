import { useEffect } from 'react';
import influencerDashboardStore from '../stores/InfluencerDashboardStore';

const useInfluencerDashboard = userId => {
  useEffect(() => {
    influencerDashboardStore.onUnmount();
    if (userId) {
      influencerDashboardStore.getInitial({ userId });
    }
  }, [userId]);
};

export default useInfluencerDashboard;
