import { useEffect } from 'react';
import charityStore from '../stores/CharityStore';
import { normalizeContext } from '../util/contextUtils';

const useCharityProfileById = ({ id, userContext }) => {
  useEffect(() => {
    charityStore.getCharity({ id, userContext: normalizeContext(userContext) });
    charityStore.getCharityFeed({
      id,
      userContext: normalizeContext(userContext),
    });
    charityStore.getEvents({ id });
  }, [id, userContext]);
};

export default useCharityProfileById;
