import React, { useState, useEffect } from 'react';

import { emailValidate, zipValidate } from '../../../util/formFieldValidators';

const CreateAccountForm = ({
  user,
  setUser,
  fieldIsValid,
  setFieldIsValid,
  showTerms,
  uiStore,
}) => {
  const [isMobile, setIsMobile] = useState(document.body.clientWidth < 425);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 725 && !isMobile) {
        setIsMobile(true);
      } else if (window.innerWidth > 725 && isMobile) {
        setIsMobile(false);
      }
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isMobile]);

  return (
    <fieldset>
      <legend>Create an Account</legend>
      <div className="form-row create-account-first-name">
        <input
          id="first-name"
          placeholder="First Name"
          type="text"
          value={user.firstName}
          onChange={(e) => {
            if (e.target.value.length > 0) {
              setFieldIsValid({ ...fieldIsValid, firstName: true });
            }
            setUser({ ...user, firstName: e.target.value });
          }}
          style={!fieldIsValid.firstName ? { backgroundColor: 'pink' } : {}}
        />
      </div>
      <div className="form-row create-account-last-name">
        <input
          id="last-name"
          placeholder="Last Name"
          type="text"
          value={user.lastName}
          onChange={(e) => {
            if (e.target.value.length > 0) {
              setFieldIsValid({ ...fieldIsValid, lastName: true });
            }
            setUser({ ...user, lastName: e.target.value });
          }}
          style={!fieldIsValid.lastName ? { backgroundColor: 'pink' } : {}}
        />
      </div>
      <div className="form-row create-account-email">
        <input
          id="email"
          placeholder="Email"
          type="text"
          value={user.email}
          onChange={(e) => {
            if (emailValidate(e.target.value)) {
              setFieldIsValid({ ...fieldIsValid, email: true });
            }
            setUser({ ...user, email: e.target.value });
          }}
          style={!fieldIsValid.email ? { backgroundColor: 'pink' } : {}}
        />
      </div>
      <div className="form-row flex-row zip-container">
        <input
          className="zip"
          id="zip"
          placeholder="Zip Code"
          type="text"
          value={user.zip}
          onChange={(e) => {
            if (zipValidate(e.target.value)) {
              setFieldIsValid({ ...fieldIsValid, zip: true });
            }
            setUser({ ...user, zip: e.target.value });
          }}
          style={!fieldIsValid.zip ? { backgroundColor: 'pink' } : {}}
        />
      </div>
      {!isMobile && <div />}
      <div className="create-account-password">
        <input
          id="password"
          placeholder="Password"
          type="password"
          value={user.password}
          onChange={(e) => {
            if (e.target.value.length > 7) {
              setFieldIsValid({ ...fieldIsValid, password: true });
            }
            setUser({ ...user, password: e.target.value });
          }}
          style={!fieldIsValid.password ? { backgroundColor: 'pink' } : {}}
        />
        <div className="password-helper">Must be at least 8 characters.</div>
      </div>
      {showTerms && (
        <div className="terms-and-service">
          <div>
            <input
              type="checkbox"
              value={user.hasAcceptedTerms}
              onChange={(e) => {
                if (e.target.checked) {
                  setFieldIsValid({ ...fieldIsValid, hasAcceptedTerms: true });
                }

                setUser({ ...user, hasAcceptedTerms: e.target.checked });
              }}
            />
          </div>
          <span style={!fieldIsValid.hasAcceptedTerms ? { color: 'red' } : {}}>
            I agree to the{' '}
            <b
              onClick={() => {
                uiStore.openModal('TERMS_OF_USE');
              }}
            >
              terms of service
            </b>{' '}
            and{' '}
            <b
              onClick={() => {
                uiStore.openModal('PRIVACY_POLICY');
              }}
            >
              privacy policy.
            </b>
          </span>
        </div>
      )}
    </fieldset>
  );
};

export default CreateAccountForm;
