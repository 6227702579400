import { useEffect } from 'react';
import userDetailsStore from '../stores/UserDetailsStore';

const useUserDetails = ({ userId, userContext }) => {
  useEffect(() => {
    userDetailsStore.onUnmount();
    userDetailsStore.fetchUserDetails({ userId });
  }, [userId, userContext]);
};

export default useUserDetails;
