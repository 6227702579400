import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';

import useEntityGifts from '../../hooks/useEntityGifts';

import withUserContext from '../../behaviors/withUserContext';
import withAuthRequired from '../../behaviors/withAuthRequired';

import GiftsTable from 'components/GiftsTable/GiftsTable';
import ConfirmModal from 'components/modals/ConfirmModal/ConfirmModal';
import Navbar from 'components/page/Navbar/Navbar';
import Footer from 'components/page/Footer/Footer';
import Button from 'components/Button/Button';
import CauzeSpinner from 'components/CauzeSpinner/CauzeSpinner';

const InfluencerGiftsView = ({ entityGiftsStore, influencerId, uiStore }) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [confirmModalAction, setConfirmModalAction] = useState(() => {});

  const giftActions = [
    {
      name: 'Refund Gift',
      action: (ids) => {
        setConfirmModalAction(() => () => {
          let inviteIds = [];
          let giftIds = [];
          ids.forEach((id) => {
            if (id.includes('unredeemed')) {
              inviteIds.push(id.replace('unredeemed-', ''));
            } else {
              giftIds.push(id);
            }
          });
          if (inviteIds.length > 0) {
            entityGiftsStore.revokeUserTokens({ ids: inviteIds });
          }
          if (giftIds.length > 0) {
            entityGiftsStore.refundGifts({
              giftIds,
            });
          }
        });
        setShowConfirmModal(true);
      },
    },
  ];

  useEntityGifts({ userId: influencerId });

  return (
    <div className="company-admin flex-column">
      <Navbar />
      {entityGiftsStore.ledgerLoading ? (
        <CauzeSpinner fullscreen />
      ) : (
        <div>
          {showConfirmModal && (
            <ConfirmModal
              isOpen
              message="Are you sure you want to refund selected gifts?"
              action={confirmModalAction}
              onToggleClose={() => setShowConfirmModal(false)}
            />
          )}
          <section>
            <div className="page-header container flex-row">
              <h1 className="title">Gifts</h1>
              <Button
                className="is-small"
                onClick={() =>
                  uiStore.openModal('SEND_GIFTS', {
                    giftType: 'USER_GIFT',
                    userId: influencerId,
                    onSuccess: () =>
                      entityGiftsStore.getLedger({
                        userContext: { userId: influencerId },
                      }),
                  })
                }
              >
                Send Gift
              </Button>
            </div>
          </section>
          <section>
            <div className="container">
              <GiftsTable
                giftsList={entityGiftsStore.ledgerData}
                actions={giftActions}
                totalGifted={entityGiftsStore.totalGifted}
                remainingGifted={entityGiftsStore.remainingGifted}
              />
            </div>
          </section>
        </div>
      )}
      <Footer />
    </div>
  );
};

InfluencerGiftsView.propTypes = {
  profileStore: PropTypes.object.isRequired,
  entityGiftsStore: PropTypes.object.isRequired,
  influencerId: PropTypes.string,
  uiStore: PropTypes.object,
};

export default withAuthRequired(
  withUserContext(
    inject(
      'profileStore',
      'uiStore',
      'entityGiftsStore',
    )(observer(InfluencerGiftsView)),
  ),
  { entityType: 'INFLUENCER' },
);
