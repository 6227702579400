import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Currency from '../../../Currency/Currency';
import moment from 'moment';
import classnames from 'classnames';

const Summary = ({ store, balance, setConfirmButton, isGroup = false }) => {
  const match = store.currentMatch;
  const isEditing = !!store.editingMatchId;
  const isAnnual = match.matchCharities?.length === 0;
  const isEditingAnnual = isEditing && isAnnual;
  let remainingBalance = 0;

  if (!isEditing) {
    remainingBalance = balance.total - match.matchTotal;
  } else if (!isAnnual) {
    remainingBalance =
      balance.total - (match.matchTotal - store.previousMatchTotal);
  }
  let matchTotalDiff = null;

  if (isEditing && !isAnnual) { matchTotalDiff = match.matchTotal - store.previousMatchTotal; }

  const showBalanceSummary =
    !isEditing || (isEditing && !isAnnual && matchTotalDiff > 0);

  const showAddFunds =
    remainingBalance < 0 && !balance.allowNegative && !isEditingAnnual;

  useEffect(() => {
    if (showAddFunds) {
      setConfirmButton('Add Funds');
    }
  }, [showAddFunds, setConfirmButton]);

  return (
    <div className="modal-summary">
      <p>Match Name: {match.name}</p>
      {match.startDate && match.endDate && (
        <p>
          Dates: {moment(match.startDate).format('MMMM Do YYYY')} through{' '}
          {moment(match.endDate).format('MMMM Do YYYY')}
        </p>
      )}
      <p>
        Maxiumum Per {isGroup ? 'Member' : 'Employee'}: Match {match.multiplier}
        x <Currency amount={match.matchLimit} /> = up to{' '}
        <Currency amount={match.matchLimit * match.multiplier} /> per{' '}
        {isGroup ? 'Member' : 'Employee'}
      </p>
      {!match.matchCharities && (
        <p>
          {isGroup ? 'Member' : 'Employee'} Gifting + Match:{' '}
          {match.restrictMatchAfterBalance
            ? `Match ${
              isGroup ? 'member' : 'employee'
            } donations that are from any source`
            : `Only match ${
              isGroup ? 'member' : 'employee'
            } donations made from their personal funds`}
        </p>
      )}
      <p>
        Maximum Total Amount: Match up to <Currency amount={match.matchTotal} />{' '}
        Total
      </p>
      {showBalanceSummary && (
        <div className="summary-balance">
          <p className="balance-header">
            Funding Your Match: Fund <Currency amount={match.matchTotal} />{' '}
            Today
          </p>
          <div className="flex-row flex-expand flex-space-between">
            <p className="summary-title">Current Cauze Balance: </p>
            <Currency amount={balance.total} />
          </div>
          <div className="flex-row flex-expand flex-space-between summary-funding">
            <p className="summary-title">Match Funding:</p>
            <Currency amount={isEditing ? matchTotalDiff : match.matchTotal} />
          </div>
          <div
            className={classnames(
              'flex-row flex-expand flex-space-between flex-align-center',
              {
                'summary-error': showAddFunds,
              },
            )}
          >
            <p className="summary-title">Remaining Balance: </p>
            <Currency amount={remainingBalance} />
          </div>
        </div>
      )}
    </div>
  );
};

Summary.propTypes = {
  store: PropTypes.object.isRequired,
  balance: PropTypes.object.isRequired,
  setConfirmButton: PropTypes.func,
  isGroup: PropTypes.bool,
};

export default Summary;
