import React from 'react';
import Currency from '../Currency/Currency';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const ColumnHeader = ({ title, subtitle, amount, classNames }) => (
  <div className={classnames('flex-column', classNames)}>
    <div className="flex-row flex-center">{title}</div>
    <div className="col-total flex-row flex-center">
      {amount && <Currency amount={amount} />}
      {subtitle}
    </div>
  </div>
);

ColumnHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  amount: PropTypes.number,
  classNames: PropTypes.string,
};

export default ColumnHeader;
