import { useEffect, useState } from 'react';
import { useSpring, animated } from '@react-spring/web';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import { inject, observer } from 'mobx-react';

import SliderModal from '../SliderModal/SliderModal';

import withUserContext from 'behaviors/withUserContext';
import AddFundsContent from './AddFundsContent';
import PaymentMethodsContent from './PaymentMethodsContent';
import ConfirmationContent from './ConfirmationContent';
import AddNewCardContent from './AddNewCardContent';
import ElementsWrapper from 'components/ElementsWrapper';

import useCheckoutStore from 'stores/CheckoutStore';
import CauzeSpinner from 'components/CauzeSpinner/CauzeSpinner';

const FRAME = {
  ADD_FUNDS: 0,
  PAYMENT_METHOD: 1,
  CHECKOUT_SUMMARY: 2,
  ADD_NEW_CARD: 3,
};

const AddFundsModal = (props) => {
  const {
    activeEntity,
    userId,
    defaultAmount,
    uiStore,
    walletStore,
    initialFrame,
    onSuccess,
  } = props;

  const [closed, setClosed] = useState(false);
  const [frame, setFrame] = useState(initialFrame);
  const [isMobile, setIsMobile] = useState(document.body.clientWidth <= 425);
  const addFundsGiftCheckoutStore = useCheckoutStore();
  const currentCheckout = addFundsGiftCheckoutStore.currentCheckout;
  const stripe = useStripe();
  const elements = useElements();

  const onToggleCancel = () => {
    props.onToggleClose();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (isMobile && frame !== FRAME.CHECKOUT_SUMMARY) {
      setFrame(
        addFundsGiftCheckoutStore.activePaymentMethod
          ? FRAME.CHECKOUT_SUMMARY
          : FRAME.PAYMENT_METHOD,
      );
      return;
    }

    if (currentCheckout.amount === 0) {
      uiStore.showNotification({
        body: 'Please select an amount.',
        type: 'ERROR',
      });
      return;
    }

    if (!stripe || !elements) {
      return;
    }

    await addFundsGiftCheckoutStore.completeCheckout();
  };

  const [asideProps] = useSpring(
    () => ({
      from: { right: isMobile ? 0 : -376, bottom: isMobile ? -376 : 0 },
      to: { right: 0, bottom: 0 },
      reverse: closed,
      config: {
        duration: 200,
      },
      onResolve: () => {
        if (closed) {
          onToggleCancel();
        }
      },
    }),
    [props.isOpen, closed],
  );

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 425 && !isMobile) {
        setIsMobile(true);
      } else if (window.innerWidth > 425 && isMobile) {
        setIsMobile(false);
      }
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isMobile]);

  useEffect(() => {
    addFundsGiftCheckoutStore.initialize({
      onCheckoutSuccess: () => {
        addFundsGiftCheckoutStore.uiStore.showNotification({
          body: `$${
            addFundsGiftCheckoutStore.currentCheckout.amount / 100
          } was added to your account`,
          type: 'SUCCESS',
        });

        addFundsGiftCheckoutStore.profileStore.getProfile();

        onSuccess();
        onToggleCancel();
      },
    });
  }, [currentCheckout]);

  return (
    <SliderModal {...props} isOpen={!closed} isActive={props.isOpen}>
      <animated.aside
        style={asideProps}
        className="absolute h-auto md:h-screen flex flex-col w-screen md:w-[376px] bg-white rounded-t-[20px] md:rounded-l-[20px] md:rounded-tr-none add-funds-sidebar pb-5 pt-5"
      >
        <div
          className="delete self-end !absolute top-0"
          onClick={() => setClosed(true)}
        />
        {addFundsGiftCheckoutStore.loading ? (
          <CauzeSpinner />
        ) : (
          <>
            {frame === FRAME.ADD_FUNDS && (
              <AddFundsContent
                defaultAmount={defaultAmount}
                updateCheckout={(body) => {
                  addFundsGiftCheckoutStore.updateCheckout(body);
                  addFundsGiftCheckoutStore.startCheckout();
                }}
                uiStore={uiStore}
                setFrame={setFrame}
                loading={addFundsGiftCheckoutStore.checkoutLoading}
                onSubmit={handleSubmit}
                isMobile={isMobile}
              />
            )}
            {frame === FRAME.PAYMENT_METHOD && (
              <ElementsWrapper
                elementOptions={{
                  mode: 'setup',
                  setupFutureUsage: 'off_session',
                  amount: undefined,
                }}
              >
                <PaymentMethodsContent
                  walletStore={walletStore}
                  userId={userId}
                  cards={walletStore.cards}
                  setFrame={setFrame}
                  isMobile={isMobile}
                  onAddCard={async ({ elements, stripe }) => {
                    const newCard = await walletStore.registerNewCard({
                      elements: elements,
                      stripe: stripe,
                      userContext: {
                        userId,
                      },
                    });
                    const stripeCardId = newCard.setupIntent.payment_method;
                    const foundCard = walletStore.cards.find(
                      (card) => card.stripeCardId === stripeCardId,
                    );

                    return foundCard;
                  }}
                />
              </ElementsWrapper>
            )}
            {frame === FRAME.CHECKOUT_SUMMARY && (
              <ConfirmationContent
                currentCheckout={currentCheckout}
                uiStore={uiStore}
                setFrame={setFrame}
                loading={addFundsGiftCheckoutStore.checkoutLoading}
                onSubmit={handleSubmit}
              />
            )}
            {frame === FRAME.ADD_NEW_CARD && (
              <ElementsWrapper
                elementOptions={{
                  mode: 'setup',
                  setupFutureUsage: 'off_session',
                  amount: undefined,
                }}
              >
                <AddNewCardContent
                  onSubmit={async ({
                    elements: addElements,
                    stripe: addStripe,
                  }) => {
                    await walletStore.registerNewCard({
                      userContext: activeEntity.userContext,
                      elements: addElements,
                      stripe: addStripe,
                    });

                    setFrame(FRAME.PAYMENT_METHOD);
                  }}
                />
              </ElementsWrapper>
            )}
          </>
        )}
      </animated.aside>
    </SliderModal>
  );
};

export default withUserContext(
  inject(
    'profileStore',
    'authStore',
    'uiStore',
    'walletStore',
  )(observer(AddFundsModal)),
);
