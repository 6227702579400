import { useEffect } from 'react';
import influencersStore from '../stores/InfluencersStore';

const useInfluencers = () => {
  useEffect(() => {
    influencersStore.onUnmount();
    influencersStore.getInitial();
  }, []);
};

export default useInfluencers;
