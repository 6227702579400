import React from 'react';
import Button from 'components/Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWallet } from '@fortawesome/free-solid-svg-icons';

const AddFunds = ({ onClickAddFunds, title = 'Add Funds' }) => (
  <div className="dashboard-item flex-row">
    <div className="dashboard-icon">
      <FontAwesomeIcon icon={faWallet} />
    </div>
    <div className="dashboard-content">
      <div className="dashboard-details flex-column">
        <div className="dashboard-item-title">{title}</div>
        <div className="dashboard-item-body">
          Add funds to your account for gifting, matching and donating.
        </div>
      </div>
      <div className="dashboard-item-actions flex-row">
        <Button className="is-small" onClick={onClickAddFunds}>
          Add Funds
        </Button>
      </div>
    </div>
  </div>
);

export default AddFunds;
