import ReactGA from 'react-ga';
import mixpanel from 'mixpanel-browser';
import categories from './categories';

const cauzeReadMore = eventId => {
  mixpanel.track('Cauze Description Read More', {
    eventId,
  });
  ReactGA.event({
    category: categories.interaction,
    action: 'cauzeReadMore',
    label: 'Cauze description read more',
    value: eventId && parseInt(eventId),
  });
};

const charityReadMore = charityId => {
  mixpanel.track('Cauze Charity Description Read More', {
    charityId,
  });
  ReactGA.event({
    category: categories.interaction,
    action: 'charityReadMore',
    label: 'Cauze charity description read more',
    value: charityId && parseInt(charityId),
  });
};

const cauzeCheckoutClick = eventId => {
  mixpanel.track('Navigate to Donation Checkout from Event View', {
    eventId,
  });
  ReactGA.event({
    category: categories.interaction,
    action: 'checkoutFromCauze',
    label: 'Navigate to checkout from the cauze view',
    value: eventId && parseInt(eventId),
  });
};

export { cauzeReadMore, charityReadMore, cauzeCheckoutClick };
