/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ChartKeys from './ChartKeys';
import CauzePieChart from '../CauzePieChart';

const ChartCard = ({
  data,
  renderLabel,
  title,
  subTitle,
  showKeys = false,
}) => {
  const [showHighlightKey, setShowHighlightKey] = useState(false);
  const [highlightData, setHighlightData] = useState({});

  const onMouseOver = (text, value, color) => {
    setHighlightData({ text, value, color });
    setShowHighlightKey(true);
  };
  const onMouseOut = () => {
    setHighlightData({});
    setShowHighlightKey(false);
  };

  const highLightText = `${highlightData.text} ${highlightData.value}`;

  return (
    <div className="dashboard-item flex-column flex-center">
      <div className="dashboard-item-header">{title}</div>
      <div className="engagement-chart flex-row flex-expand flex-justify-center">
        <CauzePieChart
          data={data}
          renderLabel={renderLabel}
          onMouseOver={onMouseOver}
          onMouseOut={onMouseOut}
        />
      </div>
      <div className="dashboard-chart-key flex-column">
        <div className="flex-row flex-align-center flex-justify-center">
          {showHighlightKey && (
            <span
              className="dashboard-chart-dot"
              style={{ backgroundColor: highlightData.color }}
            />
          )}
          <p className="text-align-center">
            {showHighlightKey ? highLightText : 'Hover to Inspect'}
          </p>
        </div>
      </div>

      <div className="flex-row dashboard-item-body">
        <p className="text-align-center">{subTitle}</p>
      </div>
      {showKeys && <ChartKeys data={data} />}
    </div>
  );
};
ChartCard.propTypes = {
  data: PropTypes.array.isRequired,
  renderLabel: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  title: PropTypes.string,
  subTitle: PropTypes.string,
  showKeys: PropTypes.bool,
};

export default ChartCard;
