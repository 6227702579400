import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from '@reach/router';
import { observer, inject } from 'mobx-react';
import { emailValidate } from '../../util/formFieldValidators';
import Navbar from 'components/page/Navbar/Navbar';
import FooterNew from 'components/page/Footer/FooterNew';
import CauzeButton from 'components/CauzeButton/CauzeButton';

const onResetPassword = async (
  authStore,
  email,
  setIsSubmitting,
  setIsSubmitted,
  setIsValidEmail,
) => {
  setIsSubmitting(true);
  if (emailValidate(email)) {
    setIsValidEmail(true);
    await authStore.sendPasswordReset(email);
    setIsSubmitted(true);
  } else {
    setIsValidEmail(false);
  }
  setIsSubmitting(false);
};

const ForgotPasswordView = ({ authStore, profileStore }) => {
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(true);

  if (
    authStore.isAuthenticated &&
    profileStore.availableUserEntities.length > 0
  ) {
    return <Redirect from="resetpassword" to="/" noThrow />;
  }

  return (
    <div className="login-view forgot-password-view">
      <Navbar />
      <div className="form-container section flex-expand">
        {isSubmitted ? (
          <div className="notification">
            {`Please check your email for instructions. If you do not recieve an
            email in short time, `}
            <a
              href="mailto:hello@cauze.com"
              target="_blank"
              className="support-email"
            >
              contact support.
            </a>
          </div>
        ) : (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              onResetPassword(
                authStore,
                email,
                setIsSubmitting,
                setIsSubmitted,
                setIsValidEmail,
              );
            }}
          >
            <div className="forgot-header">
              <div className="forgot-title">Forgot your password?</div>
              <div className="forgot-subtitle">
                Enter your email address and we'll send you a link to set your
                password.{' '}
              </div>
            </div>
            <div className="forgot-body">
              <fieldset>
                <legend>partner login</legend>
                <div className="form-row">
                  <input
                    id="email"
                    placeholder="Email"
                    type="text"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setIsValidEmail(true);
                    }}
                  />
                </div>
              </fieldset>

              <div className="error-message">
                {!isValidEmail && `Please enter a valid email address.`}
              </div>

              <div className="form-row flex-center">
                <div className="login-button flex-expand">
                  <CauzeButton
                    large
                    type="submit"
                    isSubmitting={isSubmitting && !authStore.authError}
                  >
                    Submit
                  </CauzeButton>
                </div>
              </div>
              <div className="form-row flex-center">
                <div className="sign-in">
                  {`Know your password? `}
                  <a className="sign-in-anchor" href="/login">
                    Sign In
                  </a>
                </div>
              </div>
            </div>
          </form>
        )}
      </div>
      <FooterNew />
    </div>
  );
};

ForgotPasswordView.propTypes = {
  authStore: PropTypes.object.isRequired,
  profileStore: PropTypes.object.isRequired,
};

export default inject(
  'authStore',
  'profileStore',
)(observer(ForgotPasswordView));
