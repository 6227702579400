import React from 'react';
import PropTypes from 'prop-types';

import CreateCauzeView from './CreateCauzeView';
import useCreateCauze from '../../hooks/useCreateCauze';
import withAuthRequired from '../../behaviors/withAuthRequired';

const CompanyCreateCauzeView = ({ companyId, eventId }) => {
  useCreateCauze({ userContext: { companyId }, eventId });

  return <CreateCauzeView userContext={{ companyId }} eventId={eventId} />;
};

CompanyCreateCauzeView.propTypes = {
  companyId: PropTypes.string,
  eventId: PropTypes.string,
};

export default withAuthRequired(CompanyCreateCauzeView, {
  entityType: 'COMPANY',
});
