import moment from 'moment';

const getPrettyDate = date => {
  const now = moment().utc();
  const _date = moment(date).utc();

  if (moment(_date).isBefore(moment(now).subtract(1, 'day'))) {
    return moment(_date).format('MMM DD, YYYY');
  }

  return moment(date).fromNow();
};

export {
  getPrettyDate,
};
