import React from 'react';
import PropTypes from 'prop-types';

import CreateCauzeView from './CreateCauzeView';
import withAuthRequired from '../../behaviors/withAuthRequired';
import useCreateCauze from '../../hooks/useCreateCauze';

const InfluencerCreateCauzeView = ({ influencerId, eventId }) => {
  useCreateCauze({ userContext: { userId: influencerId }, eventId });

  return (
    <CreateCauzeView userContext={{ userId: influencerId }} eventId={eventId} />
  );
};

InfluencerCreateCauzeView.propTypes = {
  influencerId: PropTypes.string,
  eventId: PropTypes.string,
};

export default withAuthRequired(InfluencerCreateCauzeView, {
  entityType: 'INFLUENCER',
});
