function downloadLink(link, filename = null) {
  var element = document.createElement('a');
  element.setAttribute('href', link);

  if (filename) {
    element.setAttribute('download', filename);
  }

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

export default downloadLink;
