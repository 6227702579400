import React from 'react';
import PropTypes from 'prop-types';
import companyPlaceholder from '../../assets/images/placeholders/placeholder-business.png';
import charityPlaceholder from '../../assets/images/placeholders/placeholder-charity.png';
import userPlaceholder from '../../assets/images/placeholders/placeholder-user.png';
import sprocket from '../../assets/images/icons/sprocket/sprocket.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const getImageUri = ({ entityType, avatar }) => {
  if (avatar && avatar.md) {
    return avatar.md;
  }

  if (entityType === 'CHARITY') {
    return charityPlaceholder;
  }

  if (entityType === 'COMPANY') {
    return companyPlaceholder;
  }
  return userPlaceholder;
};

const ProfileAvatar = ({
  entityType,
  avatar,
  isVerified = true,
  hasRecurringDeposit,
}) => (
  <div className="position-relative">
    <img className="profile-avatar" src={getImageUri({ entityType, avatar })} />
    {isVerified && <FontAwesomeIcon className="check-icon" icon={faCheck} /> }
    {hasRecurringDeposit && !isVerified && (
      <img className="recurring-deposit-icon" src={sprocket} />
    )}
  </div>
);

ProfileAvatar.propTypes = {
  entityType: PropTypes.string,
  avatar: PropTypes.object,
  isVerified: PropTypes.bool,
  hasRecurringDeposit: PropTypes.bool,
};

export default ProfileAvatar;
