import React, { useState } from 'react';
import ChevronDown from '../../../assets/images/icons/chevron/down-chevron-2.svg';
import IphoneX from '../../../assets/images/Phone-hi.png';
import Paper from '../../../assets/images/icons/paper/paper.svg';
import OneFinger from '../../../assets/images/icons/one-finger/one-finger.svg';
import HeartHands from '../../../assets/images/icons/heart-hands/heart-hands.svg';
import ThinArrowRight from '../../../assets/images/icons/arrows/thin-arrow-right.svg';
import Detlef from '../../../assets/images/mock-avatars/detlef.png';
import RaceToZero from '../../../assets/images/race_to_zero_hi_res.png';
import MockAvatar1 from '../../../assets/images/mock-avatars/mock-avatar-1.png';
import MockAvatar2 from '../../../assets/images/mock-avatars/mock-avatar-2.png';
import MockAvatar3 from '../../../assets/images/mock-avatars/mock-avatar-3.png';
import MockAvatar4 from '../../../assets/images/mock-avatars/mock-avatar-4.png';

const FundraiserContentOverView = () => {
  const [sectionsOpen, setSectionsOpen] = useState({
    opportunity: true,
    approach: true,
    howItWorks: true,
    doesItMatter: true,
    traction: true,
    useOfFunds: true,
    supportWorks: true,
  });

  const charityMapUrl = 'https://www.google.com/maps/d/u/0/edit?mid=1UDMFKVGV3psfHtnGz0B7zSz3D6dvF8nv&usp=sharing';

  return (
    <div className='content-overview-container' id='content-overview-container'>
      <div className="content-overview-section">
        <div className={`content-overview-section-header`}>
        The Opportunity
          <img
            className={`down-chevron ${!sectionsOpen.opportunity && 'is-closed'}`}
            src={ChevronDown} onClick={() => {
              setSectionsOpen(sectionsOpen => ({ ...sectionsOpen, opportunity: !sectionsOpen.opportunity }));
            }} />
        </div>
        <div className={`content-overview-section-body ${sectionsOpen.opportunity ? 'is-open' : 'is-closed'}`}>
          <div className="opportunity-paragraph">
            {`The past few years have been challenging (to say the least). Navigating a global pandemic amid social unrest, 
          environmental catastrophes, and violent conflicts has made us all more aware of how much work is still left to be 
          done to create a more inclusive & safe world for the next generation.`}
          </div>
          <div className="opportunity-paragraph">
            {`We're leaning forward, wanting to make a positive change, 
            and yet feeling like we're capable of doing more. We're more 
            aware now than ever before that the path to happiness is not how 
            quickly we climb the corporate ladder or how many cars we own, but 
            in strong connections to our community and the people we love.`}
          </div>
          <div className="opportunity-paragraph">
            {`Our kids are growing up in a world that is becoming more polarized and 
            disconnected and it's difficult to model generosity & stewardship of the 
            organizations leading the efforts to create the necessary change with so much uncertainty and noise.`}
          </div>
          <div className="opportunity-paragraph">
            {`The problem is that giving to charity when inspired, is high friction and unrewarding. It's a financial 
            transaction vs. a social experience. This results in a lot of money left on the table that could be going 
            to nonprofits impacting our communities.`}
          </div>
          <div className="opportunity-paragraph">
            {`The act of giving is broken.`}
          </div>
          <div className='opportunity-tagline'>
            {`Giving should be:`}
          </div>
          <div className='opportunity-signature'>
            {`SIMPLE`} <br/> {`COLLECTIVE & AMPLIFIED`} <br/> {`ENABLE SOCIAL CONNECTION & EXPRESSION`}
          </div>

        </div>
      </div>
      <div className="content-overview-section">
        <div className={`content-overview-section-header ${!sectionsOpen.approach && 'is-closed'}`}>
        Our Approach
          <img
            className={`down-chevron ${!sectionsOpen.approach && 'is-closed'}`}
            src={ChevronDown} onClick={() => {
              setSectionsOpen(sectionsOpen => ({ ...sectionsOpen, approach: !sectionsOpen.approach }));
            }} />
        </div>
        <div className={`content-overview-section-body ${sectionsOpen.approach ? 'is-open' : 'is-closed'}`}>
          <div className="approach-wrapper">
            <div className='approach-text'>
            The Social Giving Account
            For the Social Generation! ☝️
              <div className="approach-block">
              Cauze is a social network for social good that empowers anyone to feel like a philanthropist.
              Cauze enables anyone to turn their influence into impact with a Cauze personal giving account and
              to unleash your good in the world.

              </div>
            </div>
            <img src={IphoneX} className="approach-image"/>
          </div>
        </div>
      </div>
      <div className="content-overview-section">
        <div className={`content-overview-section-header ${!sectionsOpen.howItWorks && 'is-closed'}`}>
        How it Works
          <img
            className={`down-chevron ${!sectionsOpen.howItWorks && 'is-closed'}`}
            src={ChevronDown} onClick={() => {
              setSectionsOpen(sectionsOpen => ({ ...sectionsOpen, howItWorks: !sectionsOpen.howItWorks }));
            }} />
        </div>
        <div className={`content-overview-section-body ${sectionsOpen.howItWorks ? 'is-open' : 'is-closed'}`}>
          <div className='works-section'>
            <div className='works-section-title'>
                1. A foundation in your pocket
            </div>
            <div className='works-section-body'>
              <div className="section-body-item">
                <img src={Paper} className='body-item-image'/>
                <div className='body-item-title'>
                  Your Foundation
                </div>
                <div className='body-item-message'>
                  Cauze provides every giver with the power of  a
                  Donor Advised Fund.
                </div>
              </div>
              <div className="section-body-item">
                <img src={OneFinger} className='body-item-image'/>
                <div className='body-item-title'>
                  One-touch giving
                </div>
                <div className='body-item-message'>
                  Give at any moment of inspiration, in any amount, with the touch of a button.
                </div>
              </div>
              <div className="section-body-item">
                <img src={HeartHands} className='body-item-image'/>
                <div className='body-item-title'>
                  Every Non-profit
                </div>
                <div className='body-item-message'>
                  Every 501c3 in the United States is in Cauze + 300k intl. 1.5M+ nonprofits..
                </div>
              </div>
            </div>
          </div>
          <div className='works-section'>
            <div className='works-section-title'>
                2. Commit monthly
            </div>
            <div className='works-section-body-commit'>
              <div className="race-to-zero-image-wrapper">
                <img className='race-to-zero-image' src={RaceToZero}/>
              </div>
              <br/>
              <div className='body-content'>
                <div className='content-paragraph'>
                It's simple, really. Set a goal for how
                much you want to commit to make your impact, fund your foundation monthly, and then give it away.
                </div>
              </div>
            </div>
          </div>
          <div className='works-section'>
            <div className='works-section-title'>
                3. See your influence grow
            </div>
            <div className='works-section-body'>
              <div className='section-body-avatars-container'>
                <div className='avatar-main'>
                  <img src={Detlef} className='avatar-main-image'/>
                  <img src={ThinArrowRight} className='thin-arrow-right'/>
                </div>
                <div className='avatar-minor-container'>
                  <div className='minor-avatar'>
                    <img src={MockAvatar1} className='minor-avatar-image'/>
                    <div className='minor-avatar-subtitle'>+40</div>
                  </div>
                  <div className='minor-avatar'>
                    <img src={MockAvatar2} className='minor-avatar-image'/>
                    <div className='minor-avatar-subtitle'>+15</div>
                  </div>
                  <div className='minor-avatar'>
                    <img src={MockAvatar3} className='minor-avatar-image'/>
                    <div className='minor-avatar-subtitle'>+5</div>
                  </div>
                  <div className='minor-avatar'>
                    <img src={MockAvatar4} className='minor-avatar-image'/>
                    <div className='minor-avatar-subtitle'>+25</div>
                  </div>
                </div>
              </div>
            </div>

            <div className='body-content'>
              <div className='content-paragraph'>
              When a donation is made in Cauze, it creates a social post.
              Others can "join" by adding $ with the touch of a button.
              This creates a ripple effect where a single donation has the
              opportunity to inspire others and grow exponentially. It's the
              antithesis of top down fundraising. In Cauze, everyone involved
              can see who they've influenced and their collective impact.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="content-overview-section">
        <div className={`content-overview-section-header ${!sectionsOpen.doesItMatter && 'is-closed'}`}>
        Why Does It Matter?
          <img
            className={`down-chevron ${!sectionsOpen.doesItMatter && 'is-closed'}`}
            src={ChevronDown} onClick={() => {
              setSectionsOpen(sectionsOpen => ({ ...sectionsOpen, doesItMatter: !sectionsOpen.doesItMatter }));
            }} />
        </div>
        <div className={`content-overview-section-body ${sectionsOpen.doesItMatter ? 'is-open' : 'is-closed'}`}>
          <div className='give-a-damn-section'>
            <div className='section-content-block'>
              <div className='content-block-paragraph'>
                Americans gave $450 Billion to U.S. Charities in 2019. Nearly 70% of that was from individuals.
                Those same individuals say they could have given $291B more.
              </div>
              <div className='content-block-paragraph'>
                Cauze is empowering givers to:
                <ol className='content-block-list'>
                  <li className='content-block-list-item'>
                   Give more, give effectively, and give instantly at any moment of inspiration
                  </li>
                  <li className='content-block-list-item'>
                    Feel connected to their local charities and communities
                  </li>
                  <li className='content-block-list-item'>
                    Create a happier, more engaged society
                  </li>
                </ol>
              </div>
                Over half of the donations made on Cauze are social. For every donation made on Cauze, 5 people join.
                For every $1 donated through Cauze, the donation is amplified 2.5x. <br/>
                Cauze users are growing the pie and because of their commitment, more money is flowing to charities
                that are creating lasting impact that ricochets across the world.

            </div>
          </div>
        </div>
      </div>
      <div className="content-overview-section">
        <div className={`content-overview-section-header ${!sectionsOpen.traction && 'is-closed'}`}>
        Traction
          <img
            className={`down-chevron ${!sectionsOpen.traction && 'is-closed'}`}
            src={ChevronDown} onClick={() => {
              setSectionsOpen(sectionsOpen => ({ ...sectionsOpen, traction: !sectionsOpen.traction }));
            }} />
        </div>
        <div className={`content-overview-section-body ${sectionsOpen.traction ? 'is-open' : 'is-closed'}`}>
          <div className='give-a-damn-section'>
            <div className='impact-item'><span className='impact-stat'>{`8,000 `}</span>account holders have donated</div>
            <div className='impact-item'><span className='impact-stat'>{`$3.1 million `}</span>to over</div>
            <div className='impact-item'><span className='impact-stat'>{`6,000 charities `}</span>{`around the world. `}
              <a className='charity-map-link' href={charityMapUrl} target='_blank'>{`Check it out`}</a>
            </div>
            <div className='impact-item'><span className='impact-stat'>{`$1.5 million `}</span>
            has moved from traditional gifts (amazon, starbucks, etc.) to local nonprofits via Cauze rewards.</div>
          </div>
        </div>
      </div>
      <div className="content-overview-section">
        <div className={`content-overview-section-header ${!sectionsOpen.useOfFunds && 'is-closed'}`}>
        Use of Funds
          <img
            className={`down-chevron ${!sectionsOpen.useOfFunds && 'is-closed'}`}
            src={ChevronDown} onClick={() => {
              setSectionsOpen(sectionsOpen => ({ ...sectionsOpen, useOfFunds: !sectionsOpen.useOfFunds }));
            }} />
        </div>
        <div className={`content-overview-section-body ${sectionsOpen.useOfFunds ? 'is-open' : 'is-closed'}`}>
          <div className='body-content'>
            <div className='content-paragraph'>
            We've created the Cauze platform with a small team of five.
            Needless to say, we're ready to grow our team. This round of
            funding is focused on growing our product and engineering teams,
            and making Cauze much cooler for our account holders. We want y'all
            to love your Cauze account. As "shareholders", we'd love to add you
            to our user testing cohorts and to provide feedback on new features
            and overall improvements.
            </div>
          </div>
        </div>
      </div>
      <div className="content-overview-section">
        <div className={`content-overview-section-header ${!sectionsOpen.supportWorks && 'is-closed'}`}>
        How Your Support Works
          <img
            className={`down-chevron ${!sectionsOpen.supportWorks && 'is-closed'}`}
            src={ChevronDown} onClick={() => {
              setSectionsOpen(sectionsOpen => ({ ...sectionsOpen, supportWorks: !sectionsOpen.supportWorks }));
            }} />
        </div>
        <div className={`content-overview-section-body ${sectionsOpen.supportWorks ? 'is-open' : 'is-closed'}`}>
          <div className='body-content'>
            <div className='content-paragraph'>
              {`This is a pooled donor fund to be hosted at `}
              <a className='impact-assets-link' href='https://impactassets.org' target='_blank'>ImpactAssets</a>
              {` to raise operating capital for Cauze, Inc., `}
              a profit + purpose social enterprise.
            </div>
            <div className='content-paragraph'>
              Charitable dollars donated will be directed to ImpactAssets and recommended as an investment into Cauze, Inc.
            </div>
            <div className='content-paragraph'>
              In the event Cauze, Inc. has a liquidity event in the future (or an investment is not made),
               proceeds will be allocated back to your donor account at Cauze for your recommendation as distributions
               to nonprofits.
            </div>
            <div className='content-paragraph page-end'>
              This is not a solicitation of an investment; this is a charitable donation.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FundraiserContentOverView;
