import React from 'react';

import AmountSelector from 'components/AmountSelector/AmountSelectorMini';
import PaymentMethodSelect from 'components/PaymentMethodSelect/PaymentMethodSelect';
import CheckoutSummary from 'components/checkout/CheckoutSummary';
import SubmitButton from './SubmitButton';

import useCheckoutStore from 'stores/CheckoutStore';

const AddFundsContent = ({
  defaultAmount,
  updateCheckout,
  uiStore,
  setFrame,
  loading,
  onSubmit,
  isMobile,
}) => {
  const addFundsGiftCheckoutStore = useCheckoutStore();
  const currentCheckout = addFundsGiftCheckoutStore.currentCheckout;

  return (
    <div className="flex-1 w-full px-5 flex flex-col justify-between">
      <div>
        <div className="flex justify-center w-full">
          <div className="font-agenda-bold text-[20px] mx-auto py-2">
            Add Funds
          </div>
        </div>
        <div className="text-center font-agenda text-[15px] leading-tight pb-4">
          Adding funds to your account makes it easy to provide gifts, matches,
          or direct donations in the name of your account.
        </div>
        <AmountSelector
          placeholder="$ custom amount"
          defaultAmount={currentCheckout.amount || defaultAmount || 500}
          amounts={[100, 500, 1000, 2000]}
          selectedAmount={currentCheckout.amount}
          onAmountUpdate={(amt) =>
            updateCheckout({
              amount: amt,
            })
          }
          onEnter={isMobile ? onSubmit : null}
        />
      </div>
      <div>
        <div>
          {!isMobile && (
            <>
              <PaymentMethodSelect
                store={addFundsGiftCheckoutStore}
                currentCheckout={currentCheckout}
                activePaymentMethod={
                  addFundsGiftCheckoutStore.activePaymentMethod
                }
                onClick={() => {
                  setFrame(1);
                }}
                disabled={loading}
              />
              <hr className="my-2 h-[2px] bg-lightgray-b2b" />
              <CheckoutSummary
                currentCheckout={currentCheckout}
                uiStore={uiStore}
                loading={loading}
                store={addFundsGiftCheckoutStore}
                activePaymentMethod={
                  addFundsGiftCheckoutStore.activePaymentMethod
                }
              />
            </>
          )}
          <SubmitButton
            isMobile={isMobile}
            onSubmit={onSubmit}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
};

export default AddFundsContent;
