import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripVertical } from '@fortawesome/free-solid-svg-icons';

import CharityListItem from './CharityListItem/CharityListItem';

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 8;

const getItemStyle = (_isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  margin: `0 0 ${grid}px 0`,
  display: 'flex',

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (_isDraggingOver) => ({
  padding: grid,
});

const CharityList = ({ charities = [], onRearrange }) => {
  const [items, setItems] = useState(charities);
  const [isMobile] = useState(document.body.clientWidth <= 768);

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const newItems = reorder(
      items,
      result.source.index,
      result.destination.index,
    );

    onRearrange?.(newItems);

    setItems(newItems);
  };

  return (
    <div className="draggable-charity-list">
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="favorite-nonprofit-droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {items.map((item, index) => (
                <Draggable
                  key={item.id}
                  draggableId={item.id.toString()}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style,
                      )}
                    >
                      <div className="handle" {...provided.dragHandleProps}>
                        <FontAwesomeIcon
                          icon={faGripVertical}
                          size={isMobile ? '2x' : undefined}
                        />
                      </div>
                      <CharityListItem charity={item} />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default CharityList;
