import React from 'react';
import classnames from 'classnames';
import { observer } from 'mobx-react';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import CharityList from './CharityList/CharityList';
import CauzeSpinner from 'components/CauzeSpinner/CauzeSpinner';

import useKeyboardEvent from 'hooks/useKeyboardEvent.js';

const FavoriteNonprofitModal = ({
  isOpen,
  isLoading,
  onToggleClose,
  charities,
  onRearrange,
}) => {
  useKeyboardEvent('Escape', () => {
    onToggleClose(false);
  });

  return (
    <div
      className={classnames('modal favorite-nonprofit-modal', {
        'is-active': isOpen,
      })}
    >
      <div
        className="modal-background"
        onClick={() => {
          onToggleClose(false);
        }}
      ></div>
      <div className="modal-card">
        <section className="modal-card-body">
          <div className="favorite-nonprofit-modal-title">
            <div
              className="favorite-nonprofit-modal-close"
              onClick={onToggleClose}
            >
              <FontAwesomeIcon icon={faArrowLeft} />
            </div>
            <div className="favorite-nonprofit-modal-title-text">
              Favorite Nonprofits
            </div>
          </div>
          <div className="favorite-nonprofit-modal-content">
            {isLoading ? (
              <CauzeSpinner style={{ marginTop: '5rem' }} large />
            ) : (
              <CharityList charities={charities} onRearrange={onRearrange} />
            )}
          </div>
        </section>
      </div>
    </div>
  );
};

export default observer(FavoriteNonprofitModal);
