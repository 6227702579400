import ReactGA from 'react-ga';
import categories from './categories';

const cauzeFromCheckout = eventId =>
  ReactGA.event({
    category: categories.interaction,
    action: 'leaveCheckoutToCauze',
    label: 'Nav back to cauze from checkout',
    value: eventId && parseInt(eventId),
  });

const selectCharityAtCheckout = charityId =>
  ReactGA.event({
    category: categories.interaction,
    action: 'selectCharityAtCheckout',
    label: 'Select a charity at checkout',
    value: charityId && parseInt(charityId),
  });

const removeCharityAtCheckout = charityId =>
  ReactGA.event({
    category: categories.interaction,
    action: 'removeCharityAtCheckout',
    label: 'Remove a charity at checkout',
    value: charityId && parseInt(charityId),
  });

const checkoutValidationError = () =>
  ReactGA.event({
    category: categories.validation,
    action: 'checkoutValidationError',
    label: 'User experienced a checkout Validation error',
    nonInteraction: true,
  });

const checkoutWithCard = () =>
  ReactGA.event({
    category: categories.interaction,
    action: 'checkoutWithCard',
    label: 'User payed With card',
  });

const checkoutWithPaypal = () =>
  ReactGA.event({
    category: categories.interaction,
    action: 'checkoutWithPaypal',
    label: 'User payed With paypal',
  });

const confirmCardCheckout = () => {
  ReactGA.event({
    category: categories.interaction,
    action: 'confirmCardCheckout',
    label: 'User confirmed card checkout',
  });
};

const returnToCauzeFromConfirm = () =>
  ReactGA.event({
    category: categories.interaction,
    action: 'cauzeFromCheckoutConfirm',
    label: 'Return to cauze from checkout confirmation',
  });

const downloadFromCheckoutConfirm = () =>
  ReactGA.event({
    category: categories.interaction,
    action: 'downloadFromCheckoutConfirm',
    label: 'Download app link from checkout confirmationpPage',
  });

const downloadWithReward = () =>
  ReactGA.event({
    category: categories.interaction,
    action: 'downloadWithReward',
    label: 'Download app link with reward from checkout confirmation page',
  });

const openShareModal = () =>
  ReactGA.event({
    category: categories.interaction,
    action: 'openCheckoutShareModal',
    label: 'Open the checkout success page share modal',
  });

const copyShareLink = () =>
  ReactGA.event({
    category: categories.interaction,
    action: 'copyShareLink',
    label: 'Copy the share link to the clipboard from share modal',
  });

export {
  cauzeFromCheckout,
  selectCharityAtCheckout,
  removeCharityAtCheckout,
  checkoutValidationError,
  checkoutWithCard,
  checkoutWithPaypal,
  confirmCardCheckout,
  returnToCauzeFromConfirm,
  downloadFromCheckoutConfirm,
  downloadWithReward,
  openShareModal,
  copyShareLink,
};
