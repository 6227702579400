import { isEmpty, isEqual } from 'lodash';

const convertEntityTypeToId = ({ id, entityType }) => {
  if (!id || !entityType) {
    return {};
  }
  if (entityType === 'USER') {
    return { userId: id };
  }
  if (entityType === 'COMPANY') {
    return { companyId: id };
  }
  if (entityType === 'CHARITY' || entityType === 'NONPROFIT') {
    return { charityId: id };
  }
  if (entityType === 'INFLUENCER') {
    return { influencerId: id };
  }
};

const convertIdToEntityType = props => {
  if (Object.prototype.hasOwnProperty.call(props, 'userId')) {
    return { id: props.userId, entityType: 'USER' };
  }
  if (Object.prototype.hasOwnProperty.call(props, 'companyId')) {
    return { id: props.companyId, entityType: 'COMPANY' };
  }
  if (Object.prototype.hasOwnProperty.call(props, 'charityId')) {
    return { id: props.charityId, entityType: 'CHARITY' };
  }
  if (Object.prototype.hasOwnProperty.call(props, 'influencerId')) {
    return { id: props.charityId, entityType: 'INFLUENCER' };
  }
  return {};
};

const matchEntityToContext = ({ entity, context }) => {
  if (!entity || !context) {
    return false;
  }

  if (context.charityId) {
    return entity.id === context.charityId && entity.entityType === 'CHARITY';
  }

  if (context.userId) {
    return entity.id === context.userId && entity.entityType === 'USER';
  }

  if (context.companyId) {
    return entity.id === context.companyId && entity.entityType === 'COMPANY';
  }
};

const normalizeEntity = entity => {
  if (entity.entityType) {
    return convertEntityTypeToId(entity);
  }
  return entity;
};

const entitiesMatch = (entity1, entity2) => {
  if (entity1 == null || isEmpty(entity1)) return false;
  if (entity2 == null || isEmpty(entity2)) return false;
  // supports context in form of userId, companyId,
  return isEqual(normalizeEntity(entity1), normalizeEntity(entity2));
};

// For server context consumption. influencerId is a web-only concept
const normalizeContext = context => {
  if (context?.influencerId) return { userId: context?.influencerId };
  return context;
};

const getEntity = ({ companyId, charityId, userId, influencerId }) => {
  if (!companyId && !charityId && !userId && !influencerId) return {};

  if (companyId) {
    return { entityType: 'COMPANY', id: companyId };
  }
  if (charityId) {
    return { entityType: 'CHARITY', id: charityId };
  }
  if (influencerId) {
    return { entityType: 'INFLUENCER', id: influencerId };
  }
  if (userId) {
    return { entityType: 'USER', id: userId };
  }
};

export {
  entitiesMatch,
  convertEntityTypeToId,
  convertIdToEntityType,
  normalizeContext,
  getEntity,
  matchEntityToContext,
};
