// USAGE:
// export default function Component(props) {
//   useKeyboardEvent('Escape', () => {
//     alert('Escaped')
//   })
//   return <span />
// }

import { useEffect } from 'react';
function useKeyboardEvent (key, callback, preventDefault = false) {
  useEffect(() => {
    const handler = function (event) {
      if (event.key === key) {
        if (preventDefault) {
          event.preventDefault();
        }
        callback();
      }
    };
    window.addEventListener('keydown', handler);
    return () => {
      window.removeEventListener('keydown', handler);
    };
  }, [callback, key, preventDefault]);
}

export default useKeyboardEvent;
