import React from 'react';
import { Redirect } from '@reach/router';

const getEntityUrl = ({ id, entityType }) => {
  if (entityType === 'USER') {
    return `/profile/${id}`;
  }
  if (entityType === 'COMPANY') {
    return `/company/${id}`;
  }

  if (entityType === 'CHARITY') {
    return `/charity/${id}`;
  }
};

const redirectEntity = activeEntity => {
  if (activeEntity.entityType === 'CHARITY') {
    let url = `/admin/charity/${activeEntity.id}`;
    return <Redirect to={url} noThrow />;
  }

  if (activeEntity.entityType === 'COMPANY') {
    let url = `/admin/company/${activeEntity.id}`;
    return <Redirect to={url} noThrow />;
  }

  if (activeEntity.entityType === 'INFLUENCER') {
    let url = `/admin/influencer/${activeEntity.id}`;
    return <Redirect to={url} noThrow />;
  }

  if (activeEntity.entityType === 'ADMIN') {
    return <Redirect to="/admin/companies" noThrow />;
  }
  return <Redirect to="/donate" noThrow />;
};

const getAdminUrl = activeEntity => {
  if (activeEntity.entityType === 'CHARITY') {
    return `/admin/charity/${activeEntity.id}`;
  }

  if (activeEntity.entityType === 'COMPANY') {
    return `/admin/company/${activeEntity.id}`;
  }

  if (activeEntity.entityType === 'INFLUENCER') {
    return `/admin/influencer/${activeEntity.id}`;
  } else return '/';
};

export { redirectEntity, getEntityUrl, getAdminUrl };
