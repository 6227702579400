import { useEffect } from 'react';
import usersStore from '../stores/UsersStore';

const useUsers = () => {
  useEffect(() => {
    usersStore.onUnmount();
    usersStore.getInitial();
  }, []);
};

export default useUsers;
