import cx from 'classnames';

import applePayBtn from 'assets/images/icons/apple/apple-pay-btn.png';

const AppleButton = ({ children, className, ...props }) => {
  const btnClassName = cx(
    '!p-0 bg-black border-none rounded-md cursor-pointer flex justify-center h-10',
    {
      [className]: className,
    },
  );

  return (
    <button className={btnClassName} {...props}>
      {children} <img className="my-auto" src={applePayBtn} />
    </button>
  );
};

export default AppleButton;
