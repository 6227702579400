import React from 'react';
import Glide from '@glidejs/glide';
import PropTypes from 'prop-types';

const Carousel = ({ sliderItems, sliderConfig }) => {
  React.useEffect(() => {
    new Glide('.glide', {
      ...sliderConfig,
    }).mount();
  }, [sliderItems, sliderConfig]);
  return (
    <>
      <div className="glide">
        <div className=" glide__track" data-glide-el="track">
          <ul className=" glide__slides">
            {sliderItems.map((item, index) => (
              <li className=" glide__slide" key={index}>
                {item}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

Carousel.propTypes = {
  sliderItems: PropTypes.array.isRequired,
  sliderConfig: PropTypes.object.isRequired,
};

export default Carousel;
