import React from 'react';
import cx from 'classnames';

const ErrorCard = ({ message, className, support }) => (
  <div
    className={cx('error-card-container', { [className]: Boolean(className) })}
  >
    <div className={cx('error-card', { support: support })}>
      <div className="error-card-message">{message}</div>
    </div>
  </div>
);

export default ErrorCard;
