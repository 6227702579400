import gql from 'graphql-tag';

const giftTokenQuery = gql`
  query giftToken($token: String!) {
    giftToken(token: $token) {
      userId
      user {
        name
      }
      company {
        name
      }
    }
  }
`;

export { giftTokenQuery };
