import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Button/Button';
import useKeyboardEvent from '../../../hooks/useKeyboardEvent.js';

import classnames from 'classnames';

const VerifyAchModal = ({
  isOpen,
  onToggleClose,
  cardId,
  cardName,
  userContext,
  verifyAch = () => {},
}) => {
  const [amount1, setAmount1] = useState('00');
  const [amount2, setAmount2] = useState('00');
  const [isSubmitting, setIsSubmitting] = useState();

  useKeyboardEvent('Escape', () => {
    onToggleClose(false);
  });

  return (
    <div
      className={classnames('modal', {
        'is-active': isOpen,
      })}
    >
      <div
        className="modal-background"
        onClick={() => {
          onToggleClose(false);
        }}
      ></div>
      <div className="modal-card send-gift-modal">
        <header className="modal-card-head">
          <p className="modal-card-title">Verify ACH - {cardName}</p>
          <button
            className="delete"
            aria-label="close"
            onClick={onToggleClose}
          ></button>
        </header>
        <section className="modal-card-body">
          <div className="option-group">
            <p className="option-group-subhead is-size-5">
              Two small deposits were made in your account under the name of
              &nbsp;<strong>ONE4ALL CHARITAB AMTS</strong>. Verify your account
              by entering the deposit amounts below.
            </p>
          </div>
          <div className="option-group">
            <div className="control">
              <label className="label">Amount 1</label>
              <div className="flex-row flex-align-center">
                <span className="zero-dollar">$0.</span>
                <input
                  onFocus={() => setAmount1('')}
                  type="number"
                  placeholder="00"
                  className="input cents"
                  onChange={(ev) => {
                    setAmount1(ev.target.value);
                  }}
                  value={amount1}
                />
              </div>
              <label className="label">Amount 2</label>
              <div className="flex-row flex-align-center">
                <span className="zero-dollar">$0.</span>
                <input
                  onFocus={() => setAmount2('')}
                  type="number"
                  placeholder="00"
                  className="input cents"
                  onChange={(ev) => {
                    setAmount2(ev.target.value);
                  }}
                  value={amount2}
                />
              </div>
            </div>
          </div>
        </section>
        <footer className="modal-card-foot">
          <button className="button is-small" onClick={onToggleClose}>
            cancel
          </button>
          <Button
            disabled={!amount1 || !amount2}
            isSubmitting={isSubmitting}
            className="button is-primary is-small"
            onClick={() => {
              setIsSubmitting(true);
              verifyAch({
                cardId,
                amount1,
                amount2,
                userContext,
              }).then((success) => {
                if (success) {
                  onToggleClose(false);
                } else {
                  setIsSubmitting(false);
                }
              });
            }}
          >
            Verify Account
          </Button>
        </footer>
      </div>
    </div>
  );
};

VerifyAchModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onToggleClose: PropTypes.func.isRequired,
  cardId: PropTypes.string.isRequired,
  cardName: PropTypes.string.isRequired,
  userContext: PropTypes.object,
  verifyAch: PropTypes.func.isRequired,
};

export default VerifyAchModal;
