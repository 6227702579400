import ReactGA from 'react-ga';
import categories from './categories';
import mixpanel from 'mixpanel-browser';

const headerDownloadLinkPress = () => {
  mixpanel.track('Header Download App link click');

  ReactGA.event({
    category: categories.interaction,
    action: 'headerDownloadClick',
    label: 'Header Download App link click',
  });
};

const footerDownloadLinkPress = () => {
  mixpanel.track('Footer Download App link click');

  ReactGA.event({
    category: categories.interaction,
    action: 'footerDownloadClick',
    label: 'Footer Download App link click',
  });
};

const footerHomeLinkPress = () => {
  mixpanel.track('Footer Cauze Home link click');

  ReactGA.event({
    category: categories.interaction,
    action: 'cauzeHomeLinkClick',
    label: 'Footer Cauze Home link click',
  });
};

const footerEmployersLinkPress = () => {
  mixpanel.track('Footer Employers link click');

  ReactGA.event({
    category: categories.interaction,
    action: 'employersLinkClick',
    label: 'Footer Employers link click',
  });
};

const footerNonprofitsLinkPress = () => {
  mixpanel.track('Footer Nonprofits link click');

  ReactGA.event({
    category: categories.interaction,
    action: 'nonprofitsLinkClick',
    label: 'Footer Nonprofits link click',
  });
};

const footerAboutLinkPress = () => {
  mixpanel.track('Footer About Us link click');

  ReactGA.event({
    category: categories.interaction,
    action: 'aboutLinkClick',
    label: 'Footer About Us link click',
  });
};

const footerHelpLinkPress = () => {
  mixpanel.track('Footer Help link click');

  ReactGA.event({
    category: categories.interaction,
    action: 'helpLinkClick',
    label: 'Footer Help link click',
  });
};

const footerInstragramPress = () => {
  mixpanel.track('Footer Instagram icon click');

  ReactGA.event({
    category: categories.interaction,
    action: 'instagramIconClick',
    label: 'Footer Instagram icon click',
  });
};

const footerTwitterPress = () => {
  mixpanel.track('Footer Twitter icon click');

  ReactGA.event({
    category: categories.interaction,
    action: 'twitterIconClick',
    label: 'Footer Twitter icon click',
  });
};

const footerFacebookPress = () => {
  mixpanel.track('Footer Facebook icon click');

  ReactGA.event({
    category: categories.interaction,
    action: 'facebookIconClick',
    label: 'Footer Facebook icon click',
  });
};

const footerTermsLinkPress = () => {
  mixpanel.track('Footer Terms link click');

  ReactGA.event({
    category: categories.interaction,
    action: 'termsLinkClick',
    label: 'Footer Terms link click',
  });
};

const footerPrivacyLinkPress = () => {
  mixpanel.track('Footer Privacy Policy link click');

  ReactGA.event({
    category: categories.interaction,
    action: 'privacyPolicyLinkClick',
    label: 'Footer Privacy Policy link click',
  });
};

const footerDonorAgreementPress = () => {
  mixpanel.track('Footer Donor Agreement link click');

  ReactGA.event({
    category: categories.interaction,
    action: 'donorAgreementLinkClick',
    label: 'Footer Donor Agreement link click',
  });
};

const appStoreDownloadPress = () => {
  mixpanel.track('App Store download link click');

  ReactGA.event({
    category: categories.interaction,
    action: 'appStoreDownloadClick',
    label: 'App Store download link click',
  });
};

const playStoreDownloadPress = () => {
  mixpanel.track('Play Store download link click');

  ReactGA.event({
    category: categories.interaction,
    action: 'playStoreDownloadClick',
    label: 'Play Store download link click',
  });
};

export {
  headerDownloadLinkPress,
  footerDownloadLinkPress,
  footerHomeLinkPress,
  footerEmployersLinkPress,
  footerNonprofitsLinkPress,
  footerAboutLinkPress,
  footerHelpLinkPress,
  footerInstragramPress,
  footerTwitterPress,
  footerFacebookPress,
  footerTermsLinkPress,
  footerPrivacyLinkPress,
  footerDonorAgreementPress,
  appStoreDownloadPress,
  playStoreDownloadPress,
};
