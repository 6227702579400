import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { navigate } from '@reach/router';

import userImagePlaceholder0 from '../../assets/images/icons/placeholder/placeholder-avatar-0.svg';
import userImagePlaceholder1 from '../../assets/images/icons/placeholder/placeholder-avatar-1.svg';
import userImagePlaceholder2 from '../../assets/images/icons/placeholder/placeholder-avatar-2.svg';
import userImagePlaceholder3 from '../../assets/images/icons/placeholder/placeholder-avatar-3.svg';
import companyPlaceholder from '../../assets/images/icons/placeholder/placeholder-business.svg';
import charityPlaceholder from '../../assets/images/icons/placeholder/cauze-placeholder-charity.svg';
import mixpanel from 'mixpanel-browser';

const getImageSource = ({
  avatar = null,
  avatarUrls = null,
  type = null,
  size,
  entityType,
  src = null,
  index,
}) => {
  if (src) {
    return src;
  }
  if (avatar) {
    if (avatar[size]) {
      return avatar[size];
    }
    if (avatar['md']) {
      return avatar.md;
    }
    if (avatar['sm']) {
      return avatar.sm;
    }
  }

  if (avatarUrls) {
    if (avatarUrls[size]) {
      return avatarUrls[size];
    }
    if (avatarUrls['md']) {
      return avatarUrls.md;
    }
    if (avatarUrls['sm']) {
      return avatarUrls.sm;
    }
  }

  if (type === 'company' || entityType === 'COMPANY') {
    return companyPlaceholder;
  }
  if (type === 'charity' || entityType === 'CHARITY') {
    return charityPlaceholder;
  }
  let avatarIndex = index % 4;

  if (index !== null) {
    if (avatarIndex === 0) {
      return userImagePlaceholder0;
    }
    if (avatarIndex === 1) {
      return userImagePlaceholder1;
    }
    if (avatarIndex === 2) {
      return userImagePlaceholder2;
    }
    if (avatarIndex === 3) {
      return userImagePlaceholder3;
    } else return userImagePlaceholder2;
  } else {
    return userImagePlaceholder2;
  }
};

const navToProfile = ({ entityType, id }) => {
  mixpanel.track('Avatar Nav Press', {
    entityType,
    id,
  });

  if (entityType && id) {
    if (entityType === 'COMPANY') {
      navigate(`/company/${id}`);
      return;
    }
    if (entityType === 'USER') {
      navigate(`/profile/${id}`);
    }
  }
};

const Avatar = ({
  xl,
  lg,
  md,
  xsm,
  entityType,
  id,
  style,
  dropShadow,
  square,
  className,
  avatar,
  avatarUrls,
  type,
  src,
  onClick = () => navToProfile({ entityType, id }),
  index,
  noBorder = false,
}) => {
  let size =
    (xl && 'xl') || (lg && 'lg') || (md && 'md') || (xsm && 'xsm') || 'sm';

  return (
    <img
      onClick={onClick}
      alt=""
      style={{ ...style, ...(onClick ? { cursor: 'pointer' } : {}) }}
      className={classnames('avatar', size, className, {
        'shadow-light': dropShadow,
        'is-square': square,
        'orange-border': avatar && !noBorder, // only show the orange border when there isn't an avatar placeholder
      })}
      src={getImageSource({
        avatar,
        avatarUrls,
        type,
        size,
        entityType,
        src,
        index,
      })}
    />
  );
};

Avatar.propTypes = {
  md: PropTypes.bool,
  lg: PropTypes.bool,
  xl: PropTypes.bool,
  xsm: PropTypes.bool,

  onClick: PropTypes.func,
  dropShadow: PropTypes.bool,
  className: PropTypes.string,
  type: PropTypes.string,
  avatar: PropTypes.object,
  square: PropTypes.bool,
  style: PropTypes.object,
  entityType: PropTypes.string,
  id: PropTypes.string,
  src: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Avatar;
