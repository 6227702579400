/* eslint-disable no-irregular-whitespace */
import React from 'react';
import PropTypes from 'prop-types';
import ChartCard from 'components/ChartCard';

const pendingColor = '#B3B3B3';
const smallColorSet = [pendingColor, '#5CA793', '#0C3C55'];
const largeColorSet = [
  '#5D6C89',
  '#0C3C55',
  '#165A78',
  '#1E7899',
  '#2896B9',
  '#5CA793',
  '#A3B86C',
  '#EAC845',
  '#F7BA5E',
  '#FD8D4C',
];

const Charts = ({ engagementData, categoryData, isGroup }) => {
  const { pending, created, donated } = engagementData;
  const total = pending + created + donated;
  const percentEngaged = Math.floor(((donated + created) / total) * 100);

  let engagementPayload = [
    {
      title: 'Pending',
      value: pending,
      color: smallColorSet[0],
    },
    {
      title: 'Created',
      value: created,
      color: smallColorSet[1],
    },
    {
      title: 'Donated',
      value: donated,
      color: smallColorSet[2],
    },
  ];

  engagementPayload.sort((item1, item2) =>
    item1.value > item2.value ? 1 : -1,
  );

  let categoryTotal = 0;
  const categoryPayload = categoryData
    .slice() // for mobx
    .sort((item1, item2) => (item1.value > item2.value ? 1 : -1))
    .map((item, index) => {
      categoryTotal = categoryTotal + item.value;

      let temp = item;
      temp.color = largeColorSet[index];

      if (item.title.includes('Unknown')) {
        temp.color = pendingColor;
      }
      return temp;
    });

  const showEngagement =
    engagementPayload.filter((item) => item.value !== 0).length > 0;

  return (
    <div className="dashboard flex-row flex-space-around">
      {showEngagement && (
        <ChartCard
          data={engagementPayload}
          renderLabel={() => `${percentEngaged}%`}
          title={isGroup ? 'Group Engagement' : 'Company Engagement'}
          subTitle={
            isGroup
              ? `${donated + created} / ${total} Members Engaged`
              : `${donated + created} / ${total} Employees Engaged`
          }
        />
      )}
      {categoryPayload.length > 0 && (
        <ChartCard
          data={categoryPayload}
          renderLabel={() => categoryTotal}
          title="Donations by Category"
          subTitle={`${categoryTotal} Total Donations`}
        />
      )}
    </div>
  );
};

Charts.propTypes = {
  engagementData: PropTypes.object.isRequired,
  categoryData: PropTypes.array.isRequired,
  isGroup: PropTypes.bool,
};

export default Charts;
