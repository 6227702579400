import React from 'react';
import PropTypes from 'prop-types';

const TopicPill = ({ topic, index, onClick, isSelected }) =>
  (
    <a
      onClick={onClick}
      key={`${topic}-${index}`}
      className={`topic ${isSelected && 'is-selected'}`}

    >
      {topic}
    </a>
  )
;

TopicPill.propTypes = {
  topic: PropTypes.string.isRequired,
  searchStore: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};

export default TopicPill;
