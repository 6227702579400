import React from 'react';
import { navigate } from '@reach/router';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { getEntityUrl } from 'util/navHelpers';

const getSubjectsText = ({ subjects }) => {
  if (!subjects) return;
  return ` + ${subjects.length - 1} other${subjects?.length > 2 ? 's' : ''}`;
};

const SubjectText = ({ children }) => {
  return (
    <span className="actor-verb-subject">
      <span className="subject-text">{children}</span>
    </span>
  );
};

const ActorVerbSubjectText = ({
  actor,
  subjects,
  projects,
  purchaseCount,
  type,
  initiator,
  joinedEntity,
  eventType,
  isMatchSponsor,
  entity,
  activityPurchase,
}) => {
  const isActorAlsoInitiator =
    actor.id === initiator.id && actor.entityType === initiator.entityType;
  const isJoinedAlsoInitiator =
    joinedEntity?.id === initiator.id &&
    joinedEntity?.type === initiator.entityType;
  const isActorAlsoJoined =
    actor?.id === joinedEntity?.id && actor?.entityType === joinedEntity?.type;
  const isInactiveUser =
    actor.entityType === 'USER' &&
    (actor.user?.unclaimed || !actor.user?.isActive);
  const entityUrl = isInactiveUser
    ? undefined
    : getEntityUrl({ id: actor.id, entityType: actor.entityType });
  const matchCount = activityPurchase?.matchPurchases
    ?.filter((mp) => `${mp?.matchSponsor?.id}` === `${entity?.id}`)
    ?.reduce((a, b) => {
      return a + (b?.match?.donorCount || 0);
    }, 0);

  const handleLinkClick = (e, href) => {
    if (href) {
      e.preventDefault();
      e.stopPropagation();

      navigate(href);
    }
  };

  const renderActorDisplay = () => {
    if (isMatchSponsor) {
      const NameWrapper = !entityUrl ? 'span' : 'a';
      const matcherUrl = getEntityUrl({
        id: entity.id,
        entityType: entity.groupType,
      });
      return (
        <NameWrapper
          className={classNames('actor-name', {
            'brand-hover': Boolean(matcherUrl),
          })}
          href={matcherUrl}
          onClick={(e) => {
            handleLinkClick(e, matcherUrl);
          }}
        >
          {entity.name}{' '}
        </NameWrapper>
      );
    }

    if (actor.isSelf) {
      return <span className="actor-name">You</span>;
    }

    const NameWrapper = !entityUrl ? 'span' : 'a';

    return (
      <NameWrapper
        className={classNames('actor-name', {
          'brand-hover': Boolean(entityUrl),
        })}
        href={entityUrl}
        onClick={(e) => {
          handleLinkClick(e, entityUrl);
        }}
      >
        {actor.name}{' '}
      </NameWrapper>
    );
  };

  const renderAdditionalActors = () => {
    if (isMatchSponsor) return <></>;

    if (type?.toUpperCase() === 'INITIATED_ACTIVITY' || purchaseCount < 3) {
      return <></>;
    }

    return (
      <span>
        {` and ${purchaseCount - 1} other${purchaseCount > 2 ? 's' : ''}`}
      </span>
    );
  };

  const renderJoined = () => {
    if (
      isJoinedAlsoInitiator ||
      type?.toUpperCase() === 'INITIATED_ACTIVITY' ||
      purchaseCount < 1 ||
      !joinedEntity
    ) {
      return <></>;
    }

    if (isMatchSponsor) {
      const NameWrapper = !entityUrl ? 'span' : 'a';

      return (
        <span>
          {' '}
          has matched{' '}
          <NameWrapper
            className={classNames('actor-name', {
              'brand-hover': Boolean(entityUrl),
            })}
            href={entityUrl}
            onClick={(e) => {
              handleLinkClick(e, entityUrl);
            }}
          >
            {actor.name}{' '}
          </NameWrapper>
          {matchCount > 2 && (
            <span>
              {` and ${matchCount - 1} other${matchCount > 2 ? 's' : ''}`}
            </span>
          )}
        </span>
      );
    }

    if (initiator.isSelf && !isActorAlsoInitiator) {
      return <span> joined you </span>;
    }

    if (joinedEntity?.name && !isActorAlsoJoined) {
      const joinedUrl = getEntityUrl({
        id: joinedEntity.id,
        entityType: joinedEntity.type,
      });

      const NameWrapper = !joinedUrl ? 'span' : 'a';

      return (
        <>
          {' '}
          joined{' '}
          <NameWrapper
            className={classNames('actor-name', {
              'brand-hover': Boolean(joinedUrl),
            })}
            href={joinedUrl}
            onClick={(e) => {
              handleLinkClick(e, joinedUrl);
            }}
          >
            {joinedEntity?.name}{' '}
          </NameWrapper>
        </>
      );
    }

    return <></>;
  };

  const renderSupportOrStarted = () => {
    if (type === 'JOINED_ACTIVITY' && !isActorAlsoJoined) {
      return joinedEntity && !isJoinedAlsoInitiator
        ? ' in supporting '
        : ' supported ';
    } else if (type === 'INITIATED_ACTIVITY') {
      if (subjects?.[0] && eventType?.toUpperCase() === 'PLANNED') {
        return ' started ';
      } else {
        return ' supported ';
      }
    } else {
      return ' supported ';
    }
  };

  const renderEventOrCharitiesTitle = () => {
    const event = subjects?.[0];
    const eventUrl = event ? `/event/${event?.id}` : undefined;
    const projectUrl = projects?.[0]?.id
      ? `/charity/${projects?.[0]?.id}`
      : undefined;
    const NameWrapper = !eventUrl ? 'span' : 'a';
    const ProjectNameWrapper = !projectUrl ? 'span' : 'a';

    if (event?.eventType?.toLowerCase() === 'planned') {
      return (
        <NameWrapper
          className={classNames('actor-name', {
            'brand-hover': Boolean(eventUrl),
          })}
          href={eventUrl}
          onClick={(e) => {
            handleLinkClick(e, eventUrl);
          }}
        >
          {event.name}{' '}
        </NameWrapper>
      );
    } else if (projectUrl) {
      return (
        <ProjectNameWrapper
          className={classNames('actor-name', {
            'brand-hover': Boolean(projectUrl),
          })}
          href={projectUrl}
          onClick={(e) => {
            handleLinkClick(e, projectUrl);
          }}
        >
          <span>{projects?.[0]?.name}</span>
          <span>
            {projects?.length > 1 && (
              <span>{getSubjectsText({ subjects: projects })}</span>
            )}
          </span>
        </ProjectNameWrapper>
      );
    } else if (event) {
      return (
        <NameWrapper
          className={classNames('actor-name', {
            'brand-hover': Boolean(eventUrl),
          })}
          href={eventUrl}
          onClick={(e) => {
            handleLinkClick(e, eventUrl);
          }}
        >
          {event.name}{' '}
        </NameWrapper>
      );
    } else {
      return <></>;
    }
  };

  return (
    <SubjectText>
      {renderActorDisplay()}
      {renderAdditionalActors()}
      {renderJoined()}
      {renderSupportOrStarted()}
      {renderEventOrCharitiesTitle()}
    </SubjectText>
  );
};

ActorVerbSubjectText.propTypes = {
  purchaseCount: PropTypes.number,
  subjects: PropTypes.array.isRequired,
  actor: PropTypes.object.isRequired,
  hasJoined: PropTypes.bool,
  type: PropTypes.string,
  userContext: PropTypes.object,
  initiator: PropTypes.object.isRequired,
  eventTitle: PropTypes.string,
  purchaseId: PropTypes.string,
  eventId: PropTypes.string,
  isMatchSponsor: PropTypes.bool,
  eventType: PropTypes.oneOf(['planned', 'adhoc', 'group']),
};

export default ActorVerbSubjectText;
