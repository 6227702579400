/* eslint-disable no-console */
import { observable, action, makeObservable } from 'mobx';
import { client as apolloClient } from '../util/apolloClient';
import { client as api2Client } from '../util/api2Client';
import {
  giftsLedgerQuery,
  refundCompanyGiftsMutation,
} from '../graphql/companyGifts';
import {
  revokeUserTokensMutation,
  sendGiftsMutation,
} from '../graphql/companyAdmin';

class CompanyGiftsStore {
  @observable loading = true;
  @observable ledgerLoading = false;
  @observable isError = false;
  @observable ledgerItems = [];
  @observable totalGifted = 0;
  @observable remainingGifted = 0;
  @observable limit = 25;
  @observable offset = 0;
  @observable totalRows = 0;
  @observable searchTerm = '';
  @observable filters = {
    status: null,
    type: null,
  };
  @observable sort = 'insertedAt';
  @observable sortDirection = 'desc';
  @observable companyId = null;

  @action getInitial = companyId => {
    this.companyId = companyId;
    this.resetSearchParameters();
    this.queryRows();
  };

  @action resetSearchParameters = async () => {
    this.limit = 25;
    this.offset = 0;
    this.totalRows = 0;
    this.searchTerm = '';
    this.filters = {
      status: null,
      type: null,
    };
    this.sort = 'inserted_at';
    this.sortDirection = 'desc';
  };

  @action setSearchParameters = async ({ limit, offset, searchTerm, filters, sort, sortDirection }) => {
    this.limit = limit !== undefined ? limit : this.limit;
    this.offset = offset !== undefined ? offset : this.offset;
    this.searchTerm = searchTerm !== undefined ? searchTerm : this.searchTerm;
    this.filters = filters !== undefined ? filters : this.filters;
    this.sort = sort !== undefined ? sort : this.sort;
    this.sortDirection = sortDirection !== undefined ? sortDirection : this.sortDirection;
    await this.queryRows();
  };

  @action queryRows = async () => {
    this.ledgerLoading = true;
    try {
      const res = await api2Client
        .query({
          variables: {
            limit: this.limit,
            offset: this.offset,
            searchTerm: this.searchTerm,
            filters: this.filters,
            sort: this.sort,
            sortDirection: this.sortDirection,
            companyId: this.companyId ? +this.companyId : null,
          },
          query: giftsLedgerQuery,
          fetchPolicy: 'no-cache',
          errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
        });
      this.ledgerItems = res.data.giftsLedger.ledgerItems;
      this.totalGifted = res.data.giftsLedger.totalGifted;
      this.remainingGifted = res.data.giftsLedger.remainingGifted;
      this.totalRows = res.data.giftsLedger.totalRows;
    } catch (err) {
      this.isError = true;
      this.error = err;
      if (global.IS_LOCAL_OR_DEV) {
        console.log(err);
      }
    }
    this.ledgerLoading = false;
  };

  @action sendUserGifts = async ({ emails, comment, companyId, amount }) => {
    try {
      await apolloClient
        .mutate({
          variables: {
            emails,
            comment,
            amount,
            pending: false,
            giftType: 'USER_GIFT',
            paymentType: 'BALANCE',
            UserContext: { companyId },
          },
          mutation: sendGiftsMutation,
          errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
        })
        .then(() => this.getInitial(this.companyId));
    } catch (err) {
      console.log(err);
    }
  };

  @action revokeUserTokens = async ({ ids }) => {
    try {
      await apolloClient.mutate({
        variables: { ids },
        mutation: revokeUserTokensMutation,
        errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
      });

      this.ledgerItems = this.ledgerItems.map(gift => {
        if (ids.includes(gift.userTokenId)) {
          return { ...gift, remaining: 0, status: 'Revoked' };
        } else {
          return gift;
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  @action refundCompanyGifts = async ({ giftIds }) => {
    try {
      await apolloClient.mutate({
        variables: { giftIds },
        mutation: refundCompanyGiftsMutation,
        errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
      });
    } catch (err) {
      console.log(err);
    }
  };

  constructor () {
    makeObservable(this);
  }

  @action onUnmount () {
    this.loading = true;
    this.ledgerLoading = false;
    this.isError = false;
    this.ledgerItems = [];
    this.totalGifted = 0;
    this.remainingGifted = 0;
  }
}

const companyGiftsStore = new CompanyGiftsStore();

export default companyGiftsStore;
