import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faXTwitter,
  faInstagram,
  faFacebookF,
  faYoutube,
  faTiktok,
} from '@fortawesome/free-brands-svg-icons';

import { validateLink } from 'util/formFieldValidators';

const checkHttp = (uri) => {
  if (uri.startsWith('https://') || uri.startsWith('http://')) {
    return uri;
  } else {
    return `https://${uri}`;
  }
};

const ProfileSocial = ({ socialType, url }) => {
  let icon = null;

  if (socialType === 'instagram') {
    icon = faInstagram;
  }

  if (socialType === 'facebook') {
    icon = faFacebookF;
  }

  if (socialType === 'x') {
    icon = faXTwitter;
  }

  if (socialType === 'youtube') {
    icon = faYoutube;
  }

  if (socialType === 'tiktok') {
    icon = faTiktok;
  }

  if (
    !icon ||
    !url ||
    !validateLink(url, socialType === 'x' ? 'twitter' : socialType)
  ) {
    return <></>;
  }

  return (
    <a target="_blank" className="profile-social" href={checkHttp(url)}>
      <FontAwesomeIcon icon={icon} size="xs" color="white" />
    </a>
  );
};

ProfileSocial.propTypes = {
  socialType: PropTypes.oneOf([
    'facebook',
    'instagram',
    'x',
    'youtube',
    'tiktok',
    'website',
  ]),
  url: PropTypes.string,
};

export default ProfileSocial;
