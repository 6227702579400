import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { debounce } from 'lodash';

import useCompanyAdmin from '../../hooks/useCompanyAdmin';
import useCompanyGifts from '../../hooks/useCompanyGifts';

import withUserContext from '../../behaviors/withUserContext';
import withAuthRequired from '../../behaviors/withAuthRequired';

import {
  TableCurrency,
  DateWithNullText,
  User,
} from 'components/UserTable/components';
import ColumnHeader from 'components/ColumnHeader/ColumnHeader';
import classnames from 'classnames';

import ServerTable from 'components/ServerTable/ServerTable';
import ConfirmModal from 'components/modals/ConfirmModal/ConfirmModal';
import Navbar from 'components/page/Navbar/Navbar';
import Footer from 'components/page/Footer/Footer';
import Button from 'components/Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { getThnksCompanyId } from 'util/cobrandUtils';

const CompanyGiftsView = ({
  profileStore,
  companyAdminStore,
  companyGiftsStore,
  companyId,
  uiStore,
}) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [confirmModalAction, setConfirmModalAction] = useState(() => {});
  const isGroup = profileStore.getActiveEntity().groupType !== 'COMPANY';
  const [searchTermImmediate, setSearchTermImmediate] = useState('');
  const [showFilterModal, setShowFilterModal] = useState(false);
  const isThnks = companyId === getThnksCompanyId();

  const companyGiftActions = [
    {
      name: 'Refund Gift',
      action: (ids) => {
        setConfirmModalAction(() => async () => {
          let inviteIds = [];
          let giftIds = [];
          ids.forEach((id) => {
            if (id.includes('utid')) {
              inviteIds.push(id.replace('utid-', ''));
            } else {
              giftIds.push(id.replace('gid-', ''));
            }
          });
          if (inviteIds.length > 0) {
            await companyGiftsStore.revokeUserTokens({ ids: inviteIds });
          }
          if (giftIds.length > 0) {
            await companyGiftsStore.refundCompanyGifts({
              giftIds,
            });
          }
          companyGiftsStore.getInitial(companyId);
          companyAdminStore.getInitial(companyId);
        });
        setShowConfirmModal(true);
      },
    },
  ];

  useCompanyAdmin(companyId);
  useCompanyGifts(companyId);

  const setSearchTermDebounced = debounce((searchTerm) => {
    companyGiftsStore.setSearchParameters({ searchTerm, offset: 0 });
  }, 1500);

  return (
    <div className="company-admin flex-column">
      <Navbar />
      <div>
        {showConfirmModal && (
          <ConfirmModal
            isOpen
            message="Are you sure you want to refund selected gifts?"
            action={confirmModalAction}
            onToggleClose={() => setShowConfirmModal(false)}
          />
        )}
        <section>
          <div className="page-header container flex-row">
            <h1 className="title">Gifts</h1>
            <Button
              className="is-small"
              onClick={() =>
                uiStore.openModal('SEND_GIFTS', {
                  giftType: 'USER_GIFT',
                  companyId,
                  onSuccess: () => companyGiftsStore.getInitial(companyId),
                })
              }
            >
              Send Gift
            </Button>
          </div>
        </section>
        <section>
          <div className="container">
            <div
              className={classnames('modal', {
                'is-active': showFilterModal,
              })}
            >
              <div className="modal-background"></div>
              <div className="modal-card search-options-modal">
                <header className="modal-card-head">
                  <p className="modal-card-title">
                    <FontAwesomeIcon icon={faFilter} />
                    Filters
                  </p>
                  <button
                    className="delete"
                    aria-label="close"
                    onClick={() => setShowFilterModal(false)}
                  ></button>
                </header>
                <section className="modal-card-body">
                  <div className="option-group">
                    <p className="option-group-subhead is-size-5">Status</p>
                    <div className="control flex-column">
                      <label className="radio" htmlFor="statusRadio1">
                        <input
                          type="radio"
                          value={null}
                          name="status"
                          checked={!companyGiftsStore.filters.status}
                          onChange={(_) =>
                            companyGiftsStore.setSearchParameters({
                              filters: {
                                status: null,
                                type: companyGiftsStore.filters.type,
                              },
                            })
                          }
                        />
                        All
                      </label>
                      <label className="radio" htmlFor="statusRadio2">
                        <input
                          type="radio"
                          value="Redeemed"
                          id="statusRadio2"
                          name="status"
                          checked={
                            companyGiftsStore.filters.status === 'Redeemed'
                          }
                          onChange={(e) =>
                            companyGiftsStore.setSearchParameters({
                              filters: {
                                status: e.target.value,
                                type: companyGiftsStore.filters.type,
                              },
                            })
                          }
                        />
                        Redeemed
                      </label>
                      <label className="radio" htmlFor="statusRadio3">
                        <input
                          type="radio"
                          value="Waiting"
                          id="statusRadio5"
                          name="status"
                          checked={
                            companyGiftsStore.filters.status === 'Waiting'
                          }
                          onChange={(e) =>
                            companyGiftsStore.setSearchParameters({
                              filters: {
                                status: e.target.value,
                                type: companyGiftsStore.filters.type,
                              },
                            })
                          }
                        />
                        Waiting
                      </label>
                      <label className="radio" htmlFor="statusRadio4">
                        <input
                          type="radio"
                          value="Revoked"
                          id="statusRadio4"
                          name="status"
                          checked={
                            companyGiftsStore.filters.status === 'Revoked'
                          }
                          onChange={(e) =>
                            companyGiftsStore.setSearchParameters({
                              filters: {
                                status: e.target.value,
                                type: companyGiftsStore.filters.type,
                              },
                            })
                          }
                        />
                        Revoked
                      </label>
                    </div>
                  </div>
                  <div className="option-group">
                    <p className="option-group-subhead is-size-5">Type</p>
                    <div className="control flex-column">
                      <label className="radio" htmlFor="eligibleGiversRadio1">
                        <input
                          type="radio"
                          value={null}
                          name="eligibleGivers"
                          id="typeRadio1"
                          checked={!companyGiftsStore.filters.type}
                          onChange={(_) =>
                            companyGiftsStore.setSearchParameters({
                              filters: {
                                status: companyGiftsStore.filters.status,
                                type: null,
                              },
                            })
                          }
                        />
                        All
                      </label>
                      <label className="radio" htmlFor="eligibleGiversRadio2">
                        <input
                          type="radio"
                          value="Invite & Gift"
                          name="type"
                          id="typeRadio2"
                          checked={
                            companyGiftsStore.filters.type === 'Invite & Gift'
                          }
                          onChange={(e) =>
                            companyGiftsStore.setSearchParameters({
                              filters: {
                                status: companyGiftsStore.filters.status,
                                type: e.target.value,
                              },
                            })
                          }
                        />
                        Invite & Gift
                      </label>
                      <label className="radio" htmlFor="eligibleGiversRadio3">
                        <input
                          type="radio"
                          value="Employee Gift"
                          name="type"
                          id="typeRadio3"
                          checked={
                            companyGiftsStore.filters.type === 'Employee Gift'
                          }
                          onChange={(e) =>
                            companyGiftsStore.setSearchParameters({
                              filters: {
                                status: companyGiftsStore.filters.status,
                                type: e.target.value,
                              },
                            })
                          }
                        />
                        {isGroup ? 'Member' : 'Employee'} Gift
                      </label>
                      <label className="radio" htmlFor="eligibleGiversRadio3">
                        <input
                          type="radio"
                          value="Customer Gift"
                          name="type"
                          id="typesRadio3"
                          checked={
                            companyGiftsStore.filters.type === 'Customer Gift'
                          }
                          onChange={(e) =>
                            companyGiftsStore.setSearchParameters({
                              filters: {
                                status: companyGiftsStore.filters.status,
                                type: e.target.value,
                              },
                            })
                          }
                        />
                        Customer Gift
                      </label>
                    </div>
                  </div>
                </section>
                <footer className="modal-card-foot">
                  <Button
                    className="button is-primary"
                    onClick={() => setShowFilterModal(false)}
                  >
                    Done
                  </Button>
                  <Button
                    white
                    className="button"
                    onClick={() => {
                      companyGiftsStore.setSearchParameters({
                        filters: {
                          status: null,
                          type: null,
                        },
                      });
                      setShowFilterModal(false);
                    }}
                  >
                    Clear All
                  </Button>
                </footer>
              </div>
            </div>
            <ServerTable
              id="company-gifts"
              loading={companyGiftsStore.ledgerLoading}
              limit={companyGiftsStore.limit}
              offset={companyGiftsStore.offset}
              offsetChanged={async (offset) => {
                await companyGiftsStore.setSearchParameters({ offset });
              }}
              totalRows={companyGiftsStore.totalRows}
              rows={companyGiftsStore.ledgerItems}
              searchTerm={searchTermImmediate}
              searchTermChanged={(searchTerm) => {
                setSearchTermImmediate(searchTerm);
                setSearchTermDebounced(searchTerm);
              }}
              sort={companyGiftsStore.sort}
              sortChanged={(sort) => {
                companyGiftsStore.setSearchParameters({ sort });
              }}
              sortDirection={companyGiftsStore.sortDirection}
              sortDirectionChanged={(sortDirection) => {
                companyGiftsStore.setSearchParameters({ sortDirection });
              }}
              filterClicked={() => {
                setShowFilterModal(true);
              }}
              canBeSelected={(row, selectedAction) => {
                if (
                  companyGiftActions[selectedAction]?.name === 'Refund Gift' &&
                  (row.revoked === true || row.remaining === 0)
                ) {
                  return false;
                } else {
                  return true;
                }
              }}
              menuActions={companyGiftActions}
              getItemKey={(row) =>
                row.userTokenId
                  ? `utid-${row.userTokenId}`
                  : `gid-${row.giftId}`
              }
              columnDefs={
                isThnks
                  ? [
                      {
                        id: 'recipient',
                        Header: 'Recipient',
                        className: 'col-left col-user-email',
                        headerClassName: 'col-left',
                        style: { minWidth: 120 },
                        Cell: (row) => (
                          <span
                            style={{
                              fontSize: '0.9rem',
                            }}
                          >
                            {row.email}
                          </span>
                        ),
                        sortKey: 'email',
                      },
                      {
                        id: 'date',
                        Header: 'Date',
                        Cell: (row) => (
                          <DateWithNullText
                            value={{ date: row.insertedAt, nullText: '' }}
                          />
                        ),
                        className: 'col-left col-wrap col-match-name',
                        headerClassName: 'col-wrap',
                        minWidth: 45,
                        sortKey: 'inserted_at',
                      },
                      {
                        id: 'amount',
                        Header: () => (
                          <ColumnHeader
                            title="Amount"
                            amount={companyGiftsStore.totalGifted}
                          />
                        ),
                        style: { minWidth: 35, textAlign: 'center' },
                        Cell: (row) => <TableCurrency value={row.amount} />,
                        sortKey: 'amount',
                      },
                      {
                        id: 'projectName',
                        Header: 'Charity',
                        headerClassName: 'col-left',
                        Cell: (row) => (
                          <span
                            style={{
                              fontSize: '0.9rem',
                            }}
                          >
                            {row.projectName}
                          </span>
                        ),
                        sortKey: 'project_name',
                      },
                    ]
                  : [
                      {
                        id: 'recipient',
                        Header: 'Recipient',
                        className: 'col-left col-user-email',
                        headerClassName: 'col-left',
                        style: { minWidth: 120 },
                        Cell: (row) =>
                          row.recipient ? (
                            <User original={row.recipient} />
                          ) : (
                            <span
                              style={{
                                fontSize: '0.9rem',
                              }}
                            >
                              {row.email || row.phoneNumber}
                            </span>
                          ),
                        sortKey: 'email',
                      },
                      {
                        id: 'date',
                        Header: 'Date',
                        Cell: (row) => (
                          <DateWithNullText
                            value={{ date: row.insertedAt, nullText: '' }}
                          />
                        ),
                        className: 'col-left col-wrap col-match-name',
                        headerClassName: 'col-wrap',
                        minWidth: 45,
                        sortKey: 'inserted_at',
                      },
                      {
                        id: 'resent',
                        Header: 'Resent Date',
                        Cell: (row) => (
                          <DateWithNullText
                            value={{ date: row.resentAt, nullText: '' }}
                          />
                        ),
                        className: 'is-hidden-mobile is-hidden-tablet-only',
                        headerClassName:
                          'is-hidden-mobile is-hidden-tablet-only',
                        minWidth: 45,
                        sortKey: 'resent_at',
                      },
                      {
                        id: 'type',
                        Header: 'Type',
                        className:
                          'col-wrap is-hidden-mobile is-hidden-tablet-only',
                        headerClassName:
                          'is-hidden-mobile is-hidden-tablet-only',
                        style: { minWidth: 45, textAlign: 'center' },
                        Cell: (row) => {
                          if (row.type === 'Employee Gift' && isGroup) {
                            return 'Member Gift';
                          } else {
                            return row.type;
                          }
                        },
                        sortKey: 'type',
                      },
                      {
                        id: 'desc',
                        Header: 'Description',
                        style: { minWidth: 100, textAlign: 'left' },
                        className:
                          'col-wrap is-hidden-mobile is-hidden-tablet-only',
                        headerClassName:
                          ' is-hidden-mobile is-hidden-tablet-only',
                        Cell: (row) => row.comment,
                        sortKey: 'comment',
                      },
                      {
                        id: 'status',
                        Header: 'Status',
                        style: { minWidth: 35, textAlign: 'left' },
                        Cell: (row) => (
                          <span
                            className={classnames({
                              'col-waiting-highlight': row.status === 'Waiting',
                              'col-revoked-highlight': row.status === 'Revoked',
                            })}
                          >
                            {row.status}
                          </span>
                        ),
                      },
                      {
                        id: 'amount',
                        Header: () => (
                          <ColumnHeader
                            title="Amount"
                            amount={companyGiftsStore.totalGifted}
                          />
                        ),
                        style: { minWidth: 35, textAlign: 'center' },
                        Cell: (row) => <TableCurrency value={row.amount} />,
                        sortKey: 'amount',
                      },
                      {
                        id: 'remaining',
                        Header: () => (
                          <ColumnHeader
                            title="Remaining"
                            amount={companyGiftsStore.remainingGifted}
                          />
                        ),
                        style: { minWidth: 35, textAlign: 'center' },
                        Cell: (row) => <TableCurrency value={row.remaining} />,
                        sortKey: 'remaining',
                      },
                    ]
              }
            />
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

CompanyGiftsView.propTypes = {
  profileStore: PropTypes.object.isRequired,
  companyAdminStore: PropTypes.object.isRequired,
  companyGiftsStore: PropTypes.object.isRequired,
  companyId: PropTypes.string.isRequired,
  uiStore: PropTypes.object,
};

export default withAuthRequired(
  withUserContext(
    inject(
      'profileStore',
      'uiStore',
      'companyAdminStore',
      'companyGiftsStore',
    )(observer(CompanyGiftsView)),
  ),
  { entityType: 'COMPANY' },
);
