import cx from 'classnames';

import googlePayBtn from 'assets/images/icons/google/Google_Pay_Logo.png';

const GoogleButton = ({ children, className, ...props }) => {
  const btnClassName = cx(
    '!p-0 h-10 bg-black border-none rounded-md cursor-pointer flex justify-center',
    {
      [className]: className,
    },
  );

  return (
    <button className={btnClassName} {...props}>
      {children} <img className="h-6 my-auto" src={googlePayBtn} />
    </button>
  );
};

export default GoogleButton;
