import { create } from 'zustand';
import mixpanel from 'mixpanel-browser';
import { navigate } from '@reach/router';
import { client as apolloClient } from '../util/apolloClient';

import {
  completePaymentProcess,
  confirmStripePayment,
  startGiftPaymentProcess,
} from 'util/checkoutUtils';

import { sendGiftsMutation } from '../graphql/giftCheckout';

const initialState = {
  currentCheckout: {
    amount: 0,
    firstName: '',
    lastName: '',
    emails: [],
  },
  paymentOptions: [],
  activeEntity: null,
  checkoutLoading: false,
  loading: false,
  activePaymentMethod: null,
  lastUsedPaymentMethod: {}, // TODO
  userProfile: null,
  paymentRequest: null,
};

const useCheckoutStore = create((set, get) => ({
  ...initialState,
  initialize: (initState) => {
    set(initState);
  },
  updateCheckout: (updatedCheckout) => {
    set({ currentCheckout: { ...get().currentCheckout, ...updatedCheckout } });
  },
  resetCheckout: () => {
    set({
      currentCheckout: {
        amount: 0,
        firstName: '',
        lastName: '',
        emails: [],
      },
    });
  },
  setPaymentRequest: (paymentRequest) => {
    set({ paymentRequest });
  },
  getPaymentRequest: async () => {
    const state = get();

    let { stripe, paymentRequest } = state;

    try {
      const orderDetails = await state.onApplePayOrderCreate();

      if (!paymentRequest) {
        paymentRequest = stripe.paymentRequest({
          ...orderDetails,
          requestPayerName: true,
          requestPayerEmail: true,
        });

        paymentRequest.on('paymentmethod', async (ev) => {
          // Confirm the PaymentIntent without handling potential next actions (yet).
          const { paymentIntent, error: confirmError } =
            await stripe.confirmCardPayment(
              state.currentCheckout.clientSecret,
              { payment_method: ev.paymentMethod.id },
              { handleActions: false },
            );

          let firstName = '';
          let lastName = '';
          let email = ev.payerEmail || '';

          try {
            const payerName = ev.payerName || '';
            if (payerName) {
              firstName = payerName.split(' ')[0];
              lastName = payerName.split(' ').slice(1).join(' ');
            }
          } catch (err) {
            // dont set values
          }

          if (confirmError) {
            state.popErrorModal(
              'Unable to verify card payment. Try again later.',
            );
            ev.complete('fail');
          } else {
            ev.complete('success');
            if (paymentIntent.status === 'requires_action') {
              // Let Stripe.js handle the rest of the payment flow.
              const { error } = await stripe.confirmCardPayment(
                state.currentCheckout.clientSecret,
              );
              if (error) {
                state.popErrorModal(
                  'The payment failed. Try a different payment method.',
                );
              } else {
                state.updateCheckout({
                  paymentIntentId: paymentIntent.id,
                  paymentMethodId: ev.paymentMethod.id,
                  firstName,
                  lastName,
                  email,
                });

                await state.onApplePayOrderComplete(paymentIntent);
              }
            } else {
              state.updateCheckout({
                paymentIntentId: paymentIntent.id,
                paymentMethodId: ev.paymentMethod.id,
                firstName,
                lastName,
                email,
              });

              await state.onApplePayOrderComplete(paymentIntent);
            }
          }
        });

        let canMakePayment = false;
        let result = await paymentRequest.canMakePayment();

        if (state.getPaymentType().toUpperCase() === 'GOOGLE_PAY') {
          canMakePayment = result?.googlePay;
        } else if (state.getPaymentType().toUpperCase() === 'APPLE_PAY') {
          canMakePayment = result?.applePay;
        }

        if (canMakePayment) {
          state.setPaymentRequest(paymentRequest);
        } else {
          throw new Error('Cannot make payment request');
        }
      } else {
        paymentRequest.update(orderDetails);
      }
    } catch (err) {
      state.setPaymentRequest(null);
    }
  },
  setPaymentOptions: (paymentOptions) => {
    set({ paymentOptions });
  },
  setLoading: (loading) => {
    set({ loading });
  },
  setActiveEntity: (activeEntity) => {
    set({ activeEntity });
  },
  chooseDefaultPaymentMethod: (defaults = {}) => {
    const state = get();

    const { activeEntity, paymentOptions } = state;

    if (!paymentOptions.length) {
      return null;
    }

    let balance = 0;
    let giftType = defaults.giftType || state.currentCheckout.giftType;
    let defaultCard = defaults.defaultCard || state.currentCheckout.defaultCard;
    let balancePm = null;

    paymentOptions.forEach((paymentOption) => {
      if (defaultCard) return;

      const paymentType = paymentOption.paymentType?.toUpperCase();

      if (paymentType === 'BALANCE') {
        balance = paymentOptions[0].balance?.total;
        balancePm = paymentOptions[0];
      }

      // Bank cards are payment options with paymentType 'CARD' and accountType 'ACH'
      if (
        ['STRIPE_ACH', 'STRIPE_CC'].includes(paymentType) &&
        paymentOption.paymentMethods.length > 0
      ) {
        defaultCard =
          paymentOption.paymentMethods.find((card) => card.is_default) ||
          paymentOption.paymentMethods[0];
      }
    });

    if (
      giftType !== 'SELF_GIFT' &&
      (balance > 0 || activeEntity?.balance?.allowNegative)
    ) {
      defaultCard = balancePm;
    }

    set({
      activePaymentMethod: defaultCard,
    });

    return defaultCard;
  },
  setActivePaymentMethod: (activePaymentMethod) => {
    set({ activePaymentMethod });
  },
  getPaymentType: () => {
    const state = get();

    if (!state.activePaymentMethod) {
      return '';
    }

    return (
      state.activePaymentMethod.paymentType ||
      state.activePaymentMethod.accountType ||
      ''
    );
  },
  startCheckout: async () => {
    set({ checkoutLoading: true });

    const state = get();
    let paymentType = state.getPaymentType().toUpperCase();

    if (paymentType === 'VENMO') {
      paymentType = 'PAYPAL';
    }

    const variables = {
      delayBalanceCheckout: true,
      amount: state.currentCheckout.amount,
      giftType: state.currentCheckout.giftType,
      paymentType,
      userContext: state.activeEntity.userContext,
      cardBrand: state.activePaymentMethod?.brand,
    };

    if (paymentType === 'APPLE_PAY' || paymentType === 'GOOGLE_PAY') {
      variables.paymentType = 'CARD';
    }

    await startGiftPaymentProcess({
      store: state,
      variables,
      activeCard: state.activePaymentMethod,
      paymentType: paymentType?.toLowerCase(),
    });

    await state.getPaymentRequest();

    set({ checkoutLoading: false, loading: false });
  },
  completeCheckout: async () => {
    const state = get();
    const activePaymentMethod = state.activePaymentMethod;
    const paymentType = activePaymentMethod?.paymentType?.toUpperCase();

    try {
      const variables = {
        uuid: state.currentCheckout.token,
      };

      if (paymentType === 'PAYPAL') {
        variables.paypalData = state.currentCheckout.paypalData;
      } else if (['APPLE_PAY', 'GOOGLE_PAY'].includes(paymentType)) {
        variables.paymentData = {
          paymentToken: state.currentCheckout.paymentIntentId,
          paymentMethodId: state.currentCheckout.paymentMethodId,
          paymentTokenType: 'INTENT',
          savePaymentMethod: false,
        };
        variables.paymentType = 'STRIPE_CC';
      } else if (activePaymentMethod?.stripeCardId?.slice(0, 3) === 'pm_') {
        const result = await confirmStripePayment({
          stripe: state.stripe,
          elements: state.elements,
          cardId: activePaymentMethod.id,
          clientSecret: state.currentCheckout.clientSecret,
          firstName:
            state.currentCheckout.firstName ||
            state.userProfile?.firstName ||
            '',
          lastName:
            state.currentCheckout.lastName || state.userProfile?.lastName || '',
          email: state.currentCheckout.email || state.userProfile?.email || '',
        });

        variables.paymentData = {
          paymentToken: result.paymentIntent.id,
          paymentMethodId: result.paymentIntent.payment_method,
          paymentTokenType: 'INTENT',
          savePaymentMethod: !Boolean(result.paymentIntent.payment_method),
        };
      } else {
        variables.cardId = activePaymentMethod.id;
      }

      let result = null;
      try {
        result = await completePaymentProcess({
          store: state,
          variables: variables,
          checkoutType: 'gift',
        });
      } catch (err) {
        // ignore errors from api
      }

      if (state.onCheckoutSuccess) {
        state.onCheckoutSuccess();
      }

      if (result.errors && result.errors.length > 0) {
        throw result.errors;
      }

      return false;
    } catch (errors) {
      return errors;
    } finally {
      state.resetCheckout();
    }
  },
  popErrorModal: (message) => {
    const state = get();
    state.uiStore.showNotification({
      body: message,
      type: 'ERROR',
    });
  },

  // paypal functions
  paypalValidate: async () => {
    const state = get();
    if (state.currentCheckout.amount === 0) {
      state.popErrorModal('Please select an amount.');
      return false;
    }
    return true;
  },
  onPaypalSuccess: (orderDetails) => {
    const state = get();
    set({ checkoutLoading: true });

    try {
      state.updateCheckout({
        paypalData: {
          payerId: orderDetails.payer.payer_id,
          paymentId: orderDetails.id,
          paymentToken: orderDetails.purchase_units[0].payments.captures[0]?.id,
        },
      });

      state.completeCheckout().then((checkoutComplete) => {
        set({ checkoutLoading: false });
        if (checkoutComplete === true) {
          navigate('/checkout/success');
        }

        mixpanel.track('Donation Checkout Completed with Paypal');
      });
    } catch (err) {
      set({ checkoutLoading: false });
    }
  },
  onPaypalSuccessRelegated: (orderDetails) => {
    const state = get();
    set({ checkoutLoading: true });

    try {
      state.updateCheckout({
        paypalData: {
          payerId: orderDetails.payer.payer_id,
          paymentId: orderDetails.id,
          paymentToken: orderDetails.purchase_units[0].payments.captures[0]?.id,
        },
      });
      state.setActivePaymentMethod({ paymentType: 'paypal' });
    } catch (err) {
      // ignore
    }

    set({ checkoutLoading: false });
  },
  onPaypalError: (error) => {
    const state = get();
    set({ checkoutLoading: false });
    state.popErrorModal(
      'There was an issue processing your paypal transaction. Please try again with card payment. Support has been notified.',
    );

    mixpanel.track('Donation Checkout Error with Paypal', { error });
  },
  onPaypalCancel: () => {
    set({ checkoutLoading: false });

    mixpanel.track('Donation Checkout Cancelled with Paypal');
  },
  onPaypalOrderCreate: async () => {
    const state = get();
    state.setActivePaymentMethod({ paymentType: 'PAYPAL' });
    state.updateCheckout({ stripeToken: null });
    await state.startCheckout();
    return state.getPaypalOrder();
  },
  getPaypalOrder: () => {
    const state = get();

    if (state.currentCheckout.splitAmount) {
      // paypal uses decimal money representation
      let donationTotal = state.currentCheckout.splitAmount.forCauze / 100;
      let fee = state.currentCheckout.splitAmount.fee / 100;
      let orderTotal = state.currentCheckout.splitAmount.total / 100;

      return {
        intent: 'CAPTURE',
        purchase_units: [
          {
            items: [
              {
                name: `${
                  state.currentCheckout.giftType === 'SELF_GIFT'
                    ? 'Cauze Funding'
                    : 'Cauze Gift'
                }`,
                quantity: 1,
                unit_amount: { value: donationTotal, currency_code: 'USD' },
              },
              {
                name: 'Handling Fee',
                // description: `This charge does not go to Cauze`,
                quantity: 1,
                unit_amount: { value: fee, currency_code: 'USD' },
              },
            ],
            amount: {
              currency_code: 'USD',
              value: orderTotal,
              breakdown: {
                item_total: { value: orderTotal, currency_code: 'USD' },
              },
            },
            description: `${
              state.currentCheckout.giftType === 'SELF_GIFT'
                ? 'Cauze Funding'
                : 'Cauze Gift'
            }`,
          },
        ],
        application_context: {
          shipping_preference: 'NO_SHIPPING', // default is "GET_FROM_FILE"
        },
      };
    }
  },
  getPaypalVars: () => {
    const state = get();

    return {
      currency: 'USD',
      commit: true,
      onSuccess: state.onPaypalSuccess,
      onError: state.onPaypalError,
      onCancel: state.onPaypalCancel,
      onOrderCreate: state.onPaypalOrderCreate,
      validateFunc: state.paypalValidate,
    };
  },
  getAmount: async () => {
    const state = get();
    let paymentType = state.getPaymentType().toLowerCase();

    if (paymentType === 'venmo') {
      paymentType = 'paypal';
    }

    const variables = {
      delayBalanceCheckout: true,
      amount: state.currentCheckout.amount,
      giftType: state.currentCheckout.giftType,
      paymentType: paymentType.toUpperCase(),
      userContext: state.activeEntity.userContext,
      cardBrand: state.activePaymentMethod?.brand,
    };

    if (paymentType === 'apple_pay' || paymentType === 'google_pay') {
      variables.paymentType = 'CARD';
    }

    const options = {
      variables: variables,
      mutation: sendGiftsMutation,
      fetchPolicy: 'no-cache',
      errorPolicy: global.IS_DEV ? 'all' : 'none',
    };

    let result = await apolloClient.mutate(options);
    return result.data.sendGifts.splitAmount;
  },

  // apple pay functions
  onApplePayOrderCreate: async () => {
    const state = get();
    const splitAmount = await state.getAmount();
    state.updateCheckout({
      stripeToken: null,
      splitAmount,
    });
    return state.getApplePayOrder(splitAmount.total);
  },
  getApplePayOrder: (total = null) => {
    const state = get();

    mixpanel.track('Gift Checkout Started with Apple Pay');

    const amount = total || state.currentCheckout.splitAmount?.total;

    if (amount) {
      return {
        country: 'US',
        currency: 'usd',
        total: {
          label: 'Cauze',
          amount,
        },
      };
    }

    return null;
  },
  onApplePayOrderComplete: async (paymentIntent) => {
    const state = get();

    set({ checkoutLoading: true });

    try {
      state.updateCheckout({
        paymentIntentId: paymentIntent.id,
      });

      const checkoutComplete = await state.completeCheckout();
      set({ checkoutLoading: false });
      state.uiStore.closeModal();

      if (checkoutComplete) {
        mixpanel.track('Donation Checkout Completed with Apple Pay');
      }
    } catch (err) {
      set({ checkoutLoading: false });
      state.uiStore.showNotification({
        body: 'There was an issue processing your apple pay transaction. Please try again with card payment. Support has been notified.',
        type: 'ERROR',
      });
    }
  },
}));

export default useCheckoutStore;
