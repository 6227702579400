/* eslint-disable no-console */
import { observable, action, makeObservable } from 'mobx';
import { client as apolloClient } from '../util/apolloClient';

import {
  companySupportedCharitiesQuery,
  companyByIdQuery,
  createDownloadTokenMutation,
} from '../graphql/companyReporting';

class CompanyReportingStore {
  @observable isError = false;
  @observable engagementData = {};
  @observable categoryData = [];
  @observable locationData = [];
  @observable supportedCharities = [];
  @observable chartsLoading = true;
  @observable supportedCharitiesLoading = true;
  @observable reportResponse = 'waiting';
  @observable donationReportResponse = 'waiting';
  @observable totalGood = 0;
  @observable totalMatchedPortion = 0;
  @observable totalDonations = 0;

  @action getInitial = companyId => {
    this.isError = false;
    this.getCompanyCounts(companyId);
    this.getSupportedCharities(companyId);
  };

  @action getCompanyCounts = async companyId => {
    this.chartsLoading = true;
    try {
      const result = await apolloClient
        .query({
          query: companyByIdQuery,
          variables: { id: companyId },
          fetchPolicy: 'no-cache',
          errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
        })
        .then(res => res.data.companyById);

      this.engagementData = {
        pending: result.invitedCount,
        created: result.employeeCount - result.employeeDonatedCount,
        donated: result.employeeDonatedCount,
      };

      this.categoryData = result.donationCountByCategory.map(item => ({
        title: item.category,
        value: item.count,
      }));
      this.locationData = result.donationCountByLocation.map(item => ({
        title: item.state,
        value: item.count,
      }));

      this.chartsLoading = false;
    } catch (err) {
      this.isError = true;
    }
  };

  @action getSupportedCharities = async companyId => {
    this.supportedCharitiesLoading = true;
    const options = {
      query: companySupportedCharitiesQuery,
      variables: { companyId },
      fetchPolicy: 'network-only',
      errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
    };

    try {
      const queryResult = await apolloClient
        .query(options)
        .then(result => result.data.companySupportedCharities);

      queryResult.forEach(charity => {
        this.totalGood = this.totalGood + charity.donationAmount;
        this.totalMatchedPortion =
          this.totalMatchedPortion + charity.matchAmount;
        this.totalDonations = this.totalDonations + charity.donationCount;
      });

      this.supportedCharities = queryResult;
      this.supportedCharitiesLoading = false;
    } catch (err) {
      this.error = err;
      this.supportedCharitiesLoading = false;
    }
  };

  @action getDownloadToken = async (companyId, handlerName) => {
    const options = {
      mutation: createDownloadTokenMutation,
      variables: { userContext: { companyId }, handlerName },
      errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
    };

    try {
      const token = await apolloClient
        .mutate(options)
        .then(result => result.data.createDownloadToken.token);

      return token;
    } catch (err) {
      this.error = err;
    }
  };

  @action onUnmount = () => {
    this.loading = false;
    this.isError = false;

    this.totalGood = 0;
    this.totalMatchedPortion = 0;
    this.totalDonations = 0;
    this.downloadToken = null;

    this.reportResponse = 'waiting';
  };

  constructor () {
    makeObservable(this);
  }
}

const companyReportingStore = new CompanyReportingStore();

export default companyReportingStore;
