import { ReactComponent as SortIcon } from 'assets/images/icons/sort/sort-neutral.svg';
import { ReactComponent as SortIconAsc } from 'assets/images/icons/sort/sort-asc.svg';
import { ReactComponent as SortIconDesc } from 'assets/images/icons/sort/sort-desc.svg';
import Button from 'components/Button/ButtonNew';

/**
 * Sortable Table Component
 * columns is an array of objects that take the form { id, name, sortable }
 * rows is an array of jsx elements to render
 */

const DynamicSortIcon = ({ sortDir }) => {
  if (sortDir === 'asc') {
    return <SortIconAsc />;
  }

  if (sortDir === 'desc') {
    return <SortIconDesc />;
  }

  return <SortIcon />;
};

const SortableTableColumn = ({
  id,
  name,
  sortable,
  style,
  sort = [],
  setSort,
}) => {
  const handleClick = () => {
    if (id !== sort[0]) {
      setSort([id, 'asc']);
      return;
    }

    if (sort[1] === 'asc') {
      setSort([id, 'desc']);
      return;
    }

    if (sort[1] === 'desc') {
      setSort([]);
    }
  };

  return (
    <div className="sortable-table-col" style={style} onClick={handleClick}>
      <span>{name}</span>
      {sortable && <DynamicSortIcon sortDir={sort[0] === id && sort[1]} />}
    </div>
  );
};

const SortableTable = ({
  columns,
  rows,
  canLoadMore,
  loadingMore,
  loadMore,
  sort,
  setSort,
}) => {
  return (
    <div
      className="sortable-table"
      style={{
        display: 'grid',
        gridTemplateColumns: `repeat(${columns.length}, auto)`,
      }}
    >
      {columns.map((col) => (
        <SortableTableColumn
          key={col.id}
          {...col}
          sort={sort}
          setSort={setSort}
        />
      ))}
      {rows}
      {canLoadMore && (
        <div
          style={{
            gridColumn: `1 / ${columns.length}`,
            padding: '20px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Button loading={loadingMore} onClick={loadMore}>
            Load More
          </Button>
        </div>
      )}
    </div>
  );
};

export default SortableTable;
