import WrappedPlaidButton from 'components/WrappedPlaidButton';

const AddACH = ({ store }) => {
  if (!store.plaidLinkToken) return <></>;

  return (
    <div>
      <WrappedPlaidButton
        hideIcon
        linkToken={store.plaidLinkToken}
        validateFunc={store.plaidValidate}
        onLinkStart={store.onPlaidStart}
        onLinkSuccess={store.onPlaidSuccess}
        onLinkError={store.onPlaidError}
        onExitEvent={store.onPlaidExitEvent}
        id="plaid-button"
        text="ADD AN ACH ACCOUNT"
      />
    </div>
  );
};

export default AddACH;
