/* eslint-disable no-console */
import { observable, action, makeObservable } from 'mobx';
import { client as apolloClient } from '../util/apolloClient';
import moment from 'moment';
import {
  influencerAdminsQuery,
  revokeUserRoleMutation,
  assignUserRoleMutation,
  inviteInfluencerAdminMutation,
  listInfluencerAdminInvitesQuery,
} from '../graphql/influencers';

import { revokeUserTokensMutation } from '../graphql/companyAdmin';

class InfluencerDashboardStore {
  @observable loading = true;
  @observable isError = false;
  @observable revokingAdmins = false;

  @observable influencerAdmins = [];

  getInitial = ({ userId }) => {
    this.getInfluencerAdmins({ userId });
  };

  @action getInfluencerAdmins = async ({ userId }) => {
    const getOptions = query => ({
      query: query,
      variables: { userId },
      fetchPolicy: 'network-only',
      errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
    });

    this.loading = true;

    try {
      const adminResult = await apolloClient
        .query(getOptions(influencerAdminsQuery))
        .then(res => {
          if (res?.data?.influencerAdmins) {
            return res.data.influencerAdmins;
          }
          return [];
        });

      const unredeemedResult = await apolloClient
        .query(getOptions(listInfluencerAdminInvitesQuery))
        .then(res => {
          if (res.data?.listInfluencerAdminInvites) {
            return res.data.listInfluencerAdminInvites
              .filter(adminToken => !adminToken.revoked)
              .map(adminToken => ({
                ...adminToken,
                email: adminToken.email,
                username: '',
                name: '',
                balance: {
                  total: 0,
                },
                firstName: null,
                lastName: null,
                tokenId: adminToken.id,
                id: `unredeemed-${adminToken.id}`,
                invitedDate: adminToken.updatedAt,
                redeemed: false,
                status: adminToken.pending ? 'Pending' : 'Waiting',
              }));
          }
          return [];
        });

      this.influencerAdmins = adminResult.concat(unredeemedResult);

      this.loading = false;
      this.isError = false;
    } catch (err) {
      global.IS_LOCAL_OR_DEV && console.log(err);
      this.loading = false;
      this.isError = true;
      this.error = err;
    }
  };

  // userId is the influencer admin who'se role is being revoked, targetId is the influencer
  @action revokeInfluencerAdmin = async ({ userId, targetId }) => {
    console.log('revokeInfluencerAdmin', userId, targetId);
    this.revokingAdmins = true;

    const options = {
      variables: {
        userId,
        targetId,
        roleType: 'INFLUENCER_ADMIN',
      },
      mutation: revokeUserRoleMutation,
      fetchPolicy: 'no-cache',
      errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
    };

    try {
      await apolloClient.mutate(options);

      // removes the user from memory for ui update
      this.influencerAdmins = this.influencerAdmins.filter(
        admin => admin.id !== userId,
      );
    } catch (err) {
      global.IS_LOCAL_OR_DEV && console.log(err);
    }
    this.revokingAdmins = false;
  };

  @action addInfluencerAdmin = async ({ userId, email, targetId }) => {
    try {
      if (email) {
        const options = {
          variables: {
            userContext: { userId: targetId },
            emails: [email],
          },
          query: inviteInfluencerAdminMutation,
          fetchPolicy: 'no-cache',
          errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
        };
        const res = await apolloClient.query(options);
        this.influencerAdmins.push({
          id: res.data.sendGifts.token,
          firstName: email,
          lastName: '',
          invitedDate: moment().utc(),
        });
      } else {
        const options = {
          variables: {
            roleType: 'INFLUENCER_ADMIN',
            targetId: targetId,
            userId,
          },
          query: assignUserRoleMutation,
          fetchPolicy: 'no-cache',
          errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
        };
        await apolloClient.query(options);
        this.getInfluencerAdmins({ userId: targetId });
      }
    } catch (err) {
      console.log(err);
    }
  };

  @action revokeAdminInvites = async ({ ids }) => {
    console.log('revoke admin invites', ids);
    try {
      await apolloClient.mutate({
        variables: { ids },
        mutation: revokeUserTokensMutation,
        errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
      });
      this.influencerAdmins = this.influencerAdmins.filter(admin => {
        console.log(admin);
        return !ids.includes(admin.tokenId);
      });
    } catch (err) {
      console.log(err);
    }
  };

  @action onUnmount = () => {
    this.loading = true;
    this.revokingAdmins = false;
    this.isError = false;
    this.influencerAdmins = [];
  };

  constructor () {
    makeObservable(this);
  }
}

const influencerDashboardStore = new InfluencerDashboardStore();

export default influencerDashboardStore;
