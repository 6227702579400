import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { navigate } from '@reach/router';

import useUsers from '../../hooks/useUsers';
import withAuthRequired from '../../behaviors/withAuthRequired';
import withUserContext from '../../behaviors/withUserContext';

import Navbar from 'components/page/Navbar/Navbar';
import Footer from 'components/page/Footer/Footer';
import CauzeSpinner from 'components/CauzeSpinner/CauzeSpinner';
import Button from 'components/Button/Button';

import UsersTable from 'components/Admin/UsersTable';

const UsersView = ({ usersStore, uiStore }) => {
  const goToUser = (_state, rowInfo, _column, _instance) => ({
    onClick: (_e, _handleOriginal) => {
      navigate(`/admin/user/${rowInfo.original.id}/profile`);
    },
  });

  useUsers();
  return (
    <div className="company-reporting flex-column">
      <Navbar />
      {usersStore.loading ? (
        <CauzeSpinner fullscreen />
      ) : (
        <div>
          <section>
            <div className="page-header container flex-row">
              <h1 className="title">Users</h1>

              <Button
                style={{ marginLeft: 200 }}
                className="is-small"
                onClick={() =>
                  uiStore.openModal('USER_SEARCH', {
                    title: 'Add a User',
                    buttonText: 'Add User',
                    onConfirm: ({ email, userId }) =>
                      usersStore.addUser({ email, userId }),
                  })
                }
              >
                Add User
              </Button>
            </div>
          </section>
          <section>
            <div className="container flex-justify-center">
              {usersStore.users.length > 0 && (
                <UsersTable goToUser={goToUser} />
              )}
            </div>
          </section>
        </div>
      )}
      <Footer />
    </div>
  );
};

UsersView.propTypes = {
  usersStore: PropTypes.object.isRequired,
  profileStore: PropTypes.object.isRequired,
  uiStore: PropTypes.object.isRequired,
};

export default withAuthRequired(
  withUserContext(
    inject('profileStore', 'usersStore', 'uiStore')(observer(UsersView)),
  ),
  { entityType: 'ADMIN' },
);
