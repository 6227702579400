import React, { useState } from 'react';
import DownChevron from '../../assets/images/icons/chevron/down-chevron-2.svg';

const NewsCollapseConatiner = ({ date, children }) => {
  const [isPostOpen, setIsPostOpen] = useState(true);
  return (
    <div className={`news-post-container ${!isPostOpen && 'is-closed'}`}>
      <div className='news-post-dropdown' >
        {date}
        <img
          src={DownChevron}
          className={`chevron-down ${!isPostOpen && 'is-closed'}`}
          onClick={() => setIsPostOpen(!isPostOpen)}
        />
      </div>
      <div className={`post-child-container ${isPostOpen ? 'is-open' : 'is-closed'}`}>
        {children}
      </div>
    </div>
  );
};

const FundraiserNews = () => (
  <div className="fundraiser-news-container">
    <NewsCollapseConatiner date='April 7, 2022'>
      <div className='news-post-content-container'>
        <div className='post-title'>
          Welcome to the Cauze Fundraiser
        </div>
        <div className='post-content'>
          <div className='content-paragraph'>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            Proin auctor non purus at ornare. Integer eget purus lacinia,
            lobortis est ac, dictum magna. Sed dictum interdum posuere. Proin
            pharetra sit amet quam et scelerisque.
          </div>
          <div className='content-paragraph'>
            Nunc posuere ac nulla tincidunt sodales. Phasellus in augue lacus.
            Quisque eget dapibus risus. Morbi tristique lectus et est hendrerit,
            in ultrices turpis tempus. Sed laoreet, eros et tincidunt lacinia,
            neque dui ullamcorper felis, in condimentum dolor nulla ut ante.
            Etiam semper vehicula odio, non porta ex tincidunt a.
          </div>
          <div className='content-paragraph'>
            Curabitur sit amet convallis elit. Nulla lorem nisl,
            cursus et vehicula eu, consectetur a nibh. Vivamus lacus ante,
            molestie a lacus sit amet, porta fringilla quam. Interdum et
            malesuada fames ac ante ipsum primis in faucibus. Ut placerat
            suscipit risus, eu pretium tellus fringilla eget. Mauris a laoreet dui.
            Aenean dolor metus, luctus nec blandit eget, vulputate vitae felis. Vestibulum vel magna arcu.
          </div>
        </div>
      </div>
    </NewsCollapseConatiner>
    <NewsCollapseConatiner date='December 31, 2021'>
      <div className='news-post-content-container'>
        <div className='post-title'>
        This is an example of a super long headlines that takes up space
        </div>
        <div className='post-content'>
          <div className='content-paragraph'>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            Proin auctor non purus at ornare. Integer eget purus lacinia,
            lobortis est ac, dictum magna. Sed dictum interdum posuere. Proin
            pharetra sit amet quam et scelerisque.
          </div>
          <div className='content-paragraph'>
            Nunc posuere ac nulla tincidunt sodales. Phasellus in augue lacus.
            Quisque eget dapibus risus. Morbi tristique lectus et est hendrerit,
            in ultrices turpis tempus. Sed laoreet, eros et tincidunt lacinia,
            neque dui ullamcorper felis, in condimentum dolor nulla ut ante.
            Etiam semper vehicula odio, non porta ex tincidunt a.
          </div>
          <div className='content-paragraph'>
            Curabitur sit amet convallis elit. Nulla lorem nisl,
            cursus et vehicula eu, consectetur a nibh. Vivamus lacus ante,
            molestie a lacus sit amet, porta fringilla quam. Interdum et
            malesuada fames ac ante ipsum primis in faucibus. Ut placerat
            suscipit risus, eu pretium tellus fringilla eget. Mauris a laoreet dui.
            Aenean dolor metus, luctus nec blandit eget, vulputate vitae felis. Vestibulum vel magna arcu.
          </div>
        </div>
      </div>
    </NewsCollapseConatiner>
  </div>
);

export default FundraiserNews;
