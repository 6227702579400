import React, { useEffect, useMemo, useRef, useState } from 'react';
import { observer, inject } from 'mobx-react';
import moment from 'moment';
import { navigate } from '@reach/router';
import cx from 'classnames';
import useClipboard from 'react-use-clipboard';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import set from 'lodash/set';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ReactComponent as CopyIcon } from 'assets/images/icons/svgs/copy-squares.svg';
import { ReactComponent as SearchIcon } from 'assets/images/icons/search/search-dark.svg';
import { ReactComponent as FilterIcon } from 'assets/images/icons/filter/filter.svg';
import { ReactComponent as DownloadIcon } from 'assets/images/icons/download/download.svg';
import { ReactComponent as ArrowLeftIcon } from 'assets/images/icons/arrows/arrow-left.svg';
import { ReactComponent as ShareIcon } from 'assets/images/icons/share/share-box.svg';
import { ReactComponent as DownChevron } from 'assets/images/icons/chevron/down-chevron-small.svg';

import { Navbar, Footer } from 'components/page';
import UserLedgerTable from 'components/UserLedgerTable/UserLedgerTable';
import Currency from 'components/Currency/Currency';
import Avatar from 'components/Avatar/Avatar';
import SavedPaymentMethods from 'components/SavedPaymentMethods/SavedPaymentMethods';
import ElementsWrapper from 'components/ElementsWrapper';

import useCheckoutStore from 'stores/CheckoutStore';

const CREDIT = 'CREDIT';
const DEBIT = 'DEBIT';

const getTitleAndSubtitle = ({
  description,
  eventId,
  subscriptionChargeId,
}) => {
  let title = description;
  let subtitle = '';
  const descriptionPreface = description?.split(' ').slice(0, 2).join(' ');
  const descriptionRemainder = description?.split(' ').slice(2).join(' ');
  const isEvent = Boolean(eventId);

  if (descriptionPreface === 'Payroll deduction') {
    title = 'Payroll Deposit';
    subtitle = 'payroll';
  } else if (subscriptionChargeId) {
    title = 'Monthly Recur Donation';
    subtitle = 'Taxable Contribution';
  } else if (descriptionPreface === 'Donation to') {
    title = descriptionRemainder;

    if (isEvent) {
      title = (
        <a href={`/event/${eventId}`}>
          Cauze: {title.replace('and others', '')}
        </a>
      );
    }

    subtitle = 'Grant';
  } else if (descriptionPreface === 'Gift to') {
    title = `Gift to: ${descriptionRemainder}`;
    subtitle = 'Gift';
  } else if (descriptionPreface === 'Gift from') {
    title = `From: ${descriptionRemainder}`;
    subtitle = 'Gift Received';
  } else if (descriptionPreface === 'Payment processing') {
    title = 'Transaction Fee';
    subtitle = 'Bank Fee';
  } else if (
    descriptionPreface === 'Refund of' &&
    descriptionRemainder?.includes('gift')
  ) {
    const email = descriptionRemainder.split(' ').at(-1);
    title = `Gift to: ${email}`;

    if (descriptionRemainder.includes('expired')) {
      subtitle = 'Gifts Unused';
    } else {
      subtitle = 'Gifts Revoked';
    }
  } else if (descriptionPreface === 'Transfer to') {
    title = `Serving ${descriptionRemainder}`;
    subtitle = 'Grant';
  } else if (
    descriptionPreface === 'Fund own' ||
    descriptionPreface === 'Fund donation' ||
    descriptionPreface === 'Reward for' ||
    descriptionPreface === 'Fund user'
  ) {
    title = 'Contribution';
    subtitle = 'Taxable Contribution';
  }

  return { title, subtitle };
};

const transformBalanceDebitsToLedger = (userBalanceCreditsAndDebits) => {
  const mapped = userBalanceCreditsAndDebits
    .map((data) => ({
      ...data,
      createdAt: moment(
        data.createdAt?.replace(/\.\d+/, '') ||
          data.insertedAt?.replace(/\.\d+/, ''),
      ),
    }))
    .map((data) => ({
      ...data,
      key: `${data.type}${data.id}`,
      ...getTitleAndSubtitle(data),
    }))
    .sort((a, b) => {
      const unixA = a.createdAt.unix();
      const unixB = b.createdAt.unix();

      if (
        unixA === unixB &&
        a.balanceType === DEBIT &&
        b.balanceType === DEBIT
      ) {
        return a.endingBalance - b.endingBalance;
      }

      if (
        unixA === unixB &&
        a.balanceType === CREDIT &&
        b.balanceType === CREDIT
      ) {
        return b.endingBalance - a.endingBalance;
      }

      if (unixA === unixB && a.balanceType !== b.balanceType) {
        return a.balanceType === CREDIT ? 1 : -1;
      }

      return unixB - unixA;
    });
  return mapped;
};

const UserActivityFilterInput = ({
  type,
  name,
  label,
  filters,
  setFilters,
  options,
}) => {
  const inputRef = useRef();
  let value = get(filters, name);

  if (type.includes('date') && value) {
    value = moment(value, 'YYYY-MM-DD').format('MM/DD/YY');
  }

  if (type === 'select') {
    return (
      <div>
        <select
          value={value || ''}
          onChange={(e) => {
            setFilters({ ...set(filters, name, e.target.value) });
          }}
        >
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
    );
  }

  if (type === 'range') {
    return (
      <div className="range-input-container">
        <div className="dollar-sign">$</div>
        <input
          className="range-input"
          placeholder={label}
          onChange={(e) => {
            setFilters({ ...set(filters, name, e.target.value) });
          }}
          value={value || ''}
        />
      </div>
    );
  }

  return (
    <div>
      <button className="small" onClick={() => inputRef.current.showPicker()}>
        <span className={value ? 'has-value' : undefined}>
          {value || label}
        </span>
        <DownChevron />
      </button>
      <input
        style={{ visibility: 'hidden', position: 'absolute', bottom: 0 }}
        name={name}
        type="date"
        ref={inputRef}
        onChange={(e) => {
          setFilters({ ...set(filters, name, e.target.value) });
        }}
      />
    </div>
  );
};

const UserActivityFilter = ({
  name,
  label,
  type,
  filters,
  setFilters,
  options,
}) => {
  const inputs = useMemo(() => {
    if (type === 'date_range') {
      return (
        <>
          <UserActivityFilterInput
            type={type}
            label="From"
            name={`${name}.from`}
            filters={filters}
            setFilters={setFilters}
          />
          <UserActivityFilterInput
            type={type}
            label="To"
            name={`${name}.to`}
            filters={filters}
            setFilters={setFilters}
          />
        </>
      );
    }

    if (type === 'range') {
      return (
        <>
          <UserActivityFilterInput
            type={type}
            label="From"
            name={`${name}.from`}
            filters={filters}
            setFilters={setFilters}
          />
          <UserActivityFilterInput
            type={type}
            label="To"
            name={`${name}.to`}
            filters={filters}
            setFilters={setFilters}
          />
        </>
      );
    }

    if (type === 'select') {
      return (
        <UserActivityFilterInput
          type={type}
          options={options}
          name={name}
          filters={filters}
          setFilters={setFilters}
        />
      );
    }
  }, [type, filters, setFilters, name]);

  return (
    <div className="user-activity-filter">
      <div className="user-activity-filter-name">{label}</div>
      <div className="user-activity-filter-inputs">{inputs}</div>
    </div>
  );
};

const UserActivityFilterGroup = ({
  filters,
  setFilters,
  clearFilters,
  _setFilters = { _setFilters },
}) => {
  return (
    <div className="user-activity-filter-group">
      <UserActivityFilter
        name="date_range"
        label="date range"
        type="date_range"
        filters={filters}
        setFilters={setFilters}
      />
      <UserActivityFilter
        name="credit_debit"
        label="credit / debit"
        type="select"
        options={[
          {
            label: 'Any',
            value: '',
          },
          {
            label: 'Credit',
            value: CREDIT,
          },
          {
            label: 'Debit',
            value: DEBIT,
          },
        ]}
        filters={filters}
        setFilters={setFilters}
      />
      <UserActivityFilter
        name="amount"
        label="amount"
        type="range"
        filters={filters}
        setFilters={setFilters}
      />
      <UserActivityFilter
        name="type"
        label="type"
        type="select"
        options={[
          {
            label: 'Any',
            value: '',
          },
          {
            label: 'Grant',
            value: 'Grant',
          },
          {
            label: 'Taxable Contribution',
            value: 'Taxable Contribution',
          },
          {
            label: 'Gifts Given',
            value: 'Gifts Given',
          },
          {
            label: 'Bank Fee',
            value: 'Bank Fee',
          },
          {
            label: 'Payroll',
            value: 'payroll',
          },
        ]}
        filters={filters}
        setFilters={setFilters}
      />
      <div className="btn-container">
        <button
          className="clear-filters-btn"
          onClick={() => {
            clearFilters();
          }}
        >
          Clear Filters
        </button>
        <button
          className="see-results-btn"
          onClick={() => {
            _setFilters(filters);
          }}
        >
          See Results
        </button>
      </div>
    </div>
  );
};

const UserActivityFilters = ({
  isMobile,
  filters: _filters,
  setFilters: _setFilters,
  activeTab,
}) => {
  const inputRef = useRef();
  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState({});
  const [showSearch, setShowSearch] = useState(false);

  const clearFilters = () => {
    setFilters({
      search: filters.search,
    });

    if (!isEqual(filters, _filters)) {
      _setFilters({
        search: _filters.search,
      });
    }
  };

  useEffect(() => {
    if (showSearch && inputRef.current) {
      inputRef.current.focus();
    }
  }, [showSearch]);

  if (isMobile) {
    return (
      <div>
        <div
          className={cx('user-activity-search-mobile', {
            hidden: activeTab !== 0,
          })}
        >
          <button
            className="search-mobile"
            onClick={() => {
              if (!showSearch === false) {
                setFilters({ ...filters, search: '' });
                _setFilters({ ...filters, search: '' });
              }

              setShowSearch(!showSearch);
            }}
          >
            <span>Search</span>
            <SearchIcon width={15} height={15} />
          </button>
          {false && (
            <button className="download-mobile">
              <span>Download</span>
              <DownloadIcon height={15} width={15} />
            </button>
          )}
        </div>
        {showSearch && (
          <div className="user-activity-search-filters">
            <div className="user-activity-search">
              <div className="user-activity-search-bar">
                <SearchIcon
                  width={15}
                  height={15}
                  onClick={() => {
                    _setFilters(filters);
                  }}
                />
                <form action="." onSubmit={(e) => e.preventDefault()}>
                  <input
                    type="search"
                    autoComplete="off"
                    ref={inputRef}
                    placeholder="Search transactions"
                    onChange={(e) =>
                      setFilters({ ...filters, search: e.target.value })
                    }
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        _setFilters(filters);
                        inputRef.current.blur();
                      }
                    }}
                    value={filters.search || ''}
                  />
                </form>
                {Boolean(filters.search) && (
                  <button
                    className="close-button"
                    onClick={() => {
                      setFilters({ ...filters, search: '' });
                      _setFilters({ ...filters, search: '' });
                    }}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </button>
                )}
              </div>
            </div>
            <UserActivityFilterGroup
              filters={filters}
              setFilters={setFilters}
              _setFilters={_setFilters}
              clearFilters={clearFilters}
            />
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="user-activity-filters">
      <div className="user-activity-search">
        <div className="user-activity-search-bar">
          <SearchIcon
            width={15}
            height={15}
            onClick={() => {
              _setFilters(filters);
            }}
          />
          <input
            type="search"
            ref={inputRef}
            placeholder="Search transactions"
            onChange={(e) => setFilters({ ...filters, search: e.target.value })}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                _setFilters(filters);
              }
            }}
            value={filters.search || ''}
          />
          {Boolean(filters.search) && (
            <button
              className="close-button"
              onClick={() => {
                setFilters({ ...filters, search: '' });
                _setFilters({ ...filters, search: '' });
              }}
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
          )}
        </div>
        <button
          className={cx('user-activity-filter-btn', {
            active: showFilters,
          })}
          onClick={() => {
            if (!showFilters === false) {
              clearFilters();
            }

            setShowFilters(!showFilters);
          }}
        >
          <FilterIcon />
        </button>
        {false && (
          <button className="user-activity-download-btn">
            <span>Download</span>
            <DownloadIcon height={15} width={15} />
          </button>
        )}
      </div>
      {showFilters && (
        <UserActivityFilterGroup
          filters={filters}
          setFilters={setFilters}
          _setFilters={_setFilters}
          clearFilters={clearFilters}
        />
      )}
    </div>
  );
};

const UserActivitySection = ({ children, title, className }) => {
  return (
    <div className={`user-activity-section ${className}`}>
      <div className="user-activity-section-title">{title}</div>
      {children}
    </div>
  );
};

const UserActivityAccountDetails = ({
  matches,
  userId,
  walletStore,
  isMobile,
  addFundsGiftCheckoutStore,
}) => {
  const shareUrl = `${window.location.origin}/checkout/user/${userId}`;

  const [isCopied, setCopied] = useClipboard(shareUrl, {
    successDuration: 2000,
  });

  const handleShare = () => {
    if (isMobile && navigator.share) {
      navigator.share({
        title: 'Share your Cauze Payment Link',
        url: shareUrl,
      });
    } else {
      setCopied();
    }
  };

  const filteredMatches = matches.filter(
    (match) => match.active && match.currentEntityRemaining > 0,
  );

  return (
    <div className="user-activity-details">
      <UserActivitySection
        className="user-activity-payment-details"
        title="Saved Payment Methods"
      >
        <ElementsWrapper
          elementOptions={{
            mode: 'setup',
            setupFutureUsage: 'off_session',
            amount: undefined,
          }}
        >
          <SavedPaymentMethods
            walletStore={walletStore}
            cards={walletStore.cards}
            userId={userId}
            addFundsGiftCheckoutStore={addFundsGiftCheckoutStore}
          />
        </ElementsWrapper>
      </UserActivitySection>
      {filteredMatches.length > 0 && (
        <UserActivitySection
          className="user-activity-eligible-matches"
          title="Eligible Matches"
        >
          <div className="user-activity-matches-list">
            {filteredMatches.map((match, index) => (
              <div
                className={cx('user-activity-match', {
                  'has-border': index <= matches.length - 1,
                })}
              >
                <Avatar avatarUrls={match.matchAdmin.avatarUrls} />
                <div className="user-activity-match-container">
                  <div className="user-activity-match-remaining">
                    <span className="user-activity-match-current">
                      <Currency amount={match.currentEntityRemaining} />
                    </span>{' '}
                    of{' '}
                    <span>
                      <Currency amount={match.matchLimit} />
                    </span>
                  </div>
                  <div className="user-activity-match-name">
                    {match.matchAdmin.name}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </UserActivitySection>
      )}
      <UserActivitySection
        className="user-activity-payment-link"
        title="Your Payment Link"
      >
        <div className="user-activity-payment-link-text">
          Share this link with anyone who wants to donate directly to your fund.
        </div>
        <div className="user-activity-payment-link-container">
          <div className="user-activity-payment-link-url">{shareUrl}</div>
          {isMobile ? (
            <>
              <button
                className="user-activity-payment-link-btn"
                onClick={setCopied}
              >
                <CopyIcon />
                <div>Copy</div>
              </button>
              <button
                className="user-activity-payment-link-btn"
                onClick={handleShare}
              >
                <ShareIcon />
                <div>Share</div>
              </button>
              {isCopied && <div>Copied!</div>}
            </>
          ) : (
            <div>
              <button
                className="user-activity-payment-link-btn"
                onClick={setCopied}
              >
                <span>Copy</span>
                <CopyIcon />
                {isCopied && <span>Copied!</span>}
              </button>
            </div>
          )}
        </div>
      </UserActivitySection>
    </div>
  );
};

const UserActivityView = ({
  profileStore,
  uiStore,
  userProfileStore,
  walletStore,
}) => {
  const [ledger, setLedger] = useState([]);
  const [isMobile, setIsMobile] = useState(document.body.clientWidth <= 768);
  const [receiptRequested, setReceiptRequested] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [filters, setFilters] = useState({});

  const addFundsGiftCheckoutStore = useCheckoutStore();
  const activeEntity = profileStore.activeEntity;
  const userProfile = userProfileStore.users.get(activeEntity.id);

  const stripe = useStripe();
  const elements = useElements();
  walletStore.setStripeObject(stripe);

  useEffect(() => {
    userProfileStore.getUser({
      id: activeEntity.id,
      userContext: { userId: activeEntity.id },
    });
  }, [activeEntity]);

  const fetchLedger = async (loadMore = false) => {
    const data = await userProfileStore.getUserActivity({
      id: activeEntity.id,
      loadMore,
      filters: userProfileStore.ledgerFilters,
    });
    const balanceLedger = transformBalanceDebitsToLedger(data);
    return balanceLedger;
  };

  const handleAddFundsClick = () => {
    let userId;

    userId = activeEntity.id;

    // initialize hook store
    addFundsGiftCheckoutStore.updateCheckout({
      giftType: 'SELF_GIFT',
      amount: 500,
    });
    addFundsGiftCheckoutStore.initialize({
      stripe,
      elements,
      activeEntity,
      uiStore,
      paymentOptions: walletStore.paymentOptions,
      loading: true,
      userProfile,
      profileStore,
    });
    const defaultPaymentMethod =
      addFundsGiftCheckoutStore.chooseDefaultPaymentMethod();

    let initialFrame = 0;

    if (defaultPaymentMethod) {
      addFundsGiftCheckoutStore.startCheckout();
    } else {
      initialFrame = isMobile ? 0 : 1;
      addFundsGiftCheckoutStore.setLoading(false);
    }

    uiStore.openModal('SIDEBAR_ADD_FUNDS', {
      giftType: 'SELF_GIFT',
      companyId: undefined,
      userId,
      onSuccess: () => {
        walletStore.getPaymentMethods({ userContext: { userId } });
        fetchLedger().then((newLedger) => {
          setLedger(newLedger);
        });
      },
      currentCheckout: addFundsGiftCheckoutStore.currentCheckout,
      updateCheckout: addFundsGiftCheckoutStore.updateCheckout,
      initialFrame,
    });
  };

  const handleGetReceiptClick = () => {
    profileStore
      .getAnnualReciept({
        year: parseInt(moment().format('YYYY'), 10) - 1,
      })
      .then(() => {
        uiStore.showNotification({
          body: 'Receipt Emailed',
          type: 'SUCCESS',
        });
        setReceiptRequested(true);
      });
  };

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 425 && !isMobile) {
        setIsMobile(true);
      } else if (window.innerWidth > 425 && isMobile) {
        setIsMobile(false);
      }
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isMobile]);

  useEffect(() => {
    if (!ledger?.length) {
      fetchLedger().then((newLedger) => {
        setLedger(newLedger);
      });
    }
  }, [userProfileStore]);

  useEffect(() => {
    walletStore.getInitial({
      userContext: { userId: activeEntity.id },
    });
    walletStore.generatePlaidLinkToken();
  }, []);

  useEffect(() => {
    const ledgerFilters = {};

    if (filters.date_range) {
      const { from, to } = filters.date_range;

      if (from) {
        ledgerFilters['date_from'] = moment(from, 'YYYY-MM-DD').format(
          'YYYY-MM-DD 00:00:00',
        );
      }

      if (to) {
        ledgerFilters['date_to'] = moment(to, 'YYYY-MM-DD').format(
          'YYYY-MM-DD 23:59:59',
        );
      }
    }

    if (filters.credit_debit) {
      ledgerFilters['balance_type'] = filters.credit_debit;
    }

    if (filters.type) {
      ledgerFilters['item_type'] = filters.type;
    }

    if (filters.amount) {
      if (filters.amount.from) {
        const fromAmount = parseFloat(filters.amount.from) * 100;
        ledgerFilters['amount_from'] = fromAmount;
      }

      if (filters.amount.to) {
        const toAmount = parseFloat(filters.amount.to) * 100;
        ledgerFilters['amount_to'] = toAmount;
      }
    }

    if (filters.search) {
      ledgerFilters.search = filters.search;
    }

    userProfileStore.setLedgerFilters(ledgerFilters);
    fetchLedger(false).then((newLedger) => {
      setLedger(newLedger);
    });
  }, [filters]);

  useEffect(() => {
    // load payment methods and choose a default payment method
    walletStore
      .getPaymentMethods({ userContext: activeEntity.userContext })
      .then((paymentOptions) => {
        addFundsGiftCheckoutStore.setPaymentOptions(paymentOptions);
        addFundsGiftCheckoutStore.chooseDefaultPaymentMethod();
      });
  }, []);

  return (
    <>
      <div className="user-activity-view">
        <Navbar />
        <div className="user-activity-body">
          <div className="user-activity-title">
            {isMobile && (
              <span onClick={() => navigate(-1)}>
                <ArrowLeftIcon />
              </span>
            )}
            <span>Account Activity</span>
          </div>
          <div className="user-activity-funds">
            <div className="user-activity-current-balance">
              <div className="user-activity-current-balance-amount">
                <Currency
                  showCents
                  showDollarSign
                  amount={activeEntity.balance.total}
                />
              </div>
              <div className="user-activity-current-balance-text">
                Current Balance
              </div>
            </div>
            <div className="user-activity-fund-buttons">
              <button
                type="button"
                className="black"
                onClick={handleAddFundsClick}
              >
                ADD FUNDS
              </button>
              <button
                type="button"
                className="outlined"
                onClick={handleGetReceiptClick}
                disabled={receiptRequested}
              >
                {receiptRequested ? 'RECEIPT SENT!' : 'ANNUAL TAX RECEIPT'}
              </button>
            </div>
          </div>
          {isMobile && (
            <UserActivityFilters
              isMobile={isMobile}
              filters={filters}
              setFilters={setFilters}
              activeTab={activeTab}
            />
          )}
          <div className="user-activity-tabs">
            <button
              className={cx('', {
                active: activeTab === 0,
              })}
              onClick={() => setActiveTab(0)}
            >
              Transactions
            </button>
            <button
              className={cx('', {
                active: activeTab === 1,
              })}
              onClick={() => setActiveTab(1)}
            >
              Account Details
            </button>
          </div>
          {!isMobile && activeTab === 0 && (
            <UserActivityFilters
              isMobile={isMobile}
              filters={filters}
              setFilters={setFilters}
            />
          )}
          <div style={{ display: activeTab === 0 ? 'block' : 'none' }}>
            <UserLedgerTable
              ledger={ledger}
              isMobile={isMobile}
              loadMore={
                userProfileStore.ledgerHasMore &&
                (async () => {
                  const moreLedgers = await fetchLedger(true);
                  setLedger(ledger.concat(moreLedgers));
                })
              }
              isFetching={userProfileStore.ledgerLoadingMore}
              loading={userProfileStore.ledgerLoading}
            />
          </div>
          <div style={{ display: activeTab === 1 ? 'block' : 'none' }}>
            <UserActivityAccountDetails
              cards={walletStore.cards}
              matches={[userProfile?.profileData?.companyMatch].filter(
                (m) => m,
              )}
              userId={activeEntity.id}
              elements={elements}
              walletStore={walletStore}
              isMobile={isMobile}
              addFundsGiftCheckoutStore={addFundsGiftCheckoutStore}
            />
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default inject(
  'profileStore',
  'uiStore',
  'userProfileStore',
  'walletStore',
)(observer(UserActivityView));
