import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import CauzeSpinner from '../CauzeSpinner/CauzeSpinner';

const Button = ({
  children,
  onClick,
  _sm,
  outline,
  white,
  purple,
  href,
  className,
  disabled,
  isSubmitting,
  style,
  type = 'button',
}) =>
  href ? (
    <a
      className={classnames(
        'button',
        {
          'is-primary': !white && !purple,
          'is-secondary': purple,
          'is-outlined': outline,
        },
        className,
      )}
      onClick={onClick}
      href={href}
    >
      <span>{children}</span>
    </a>
  ) : (
    <button
      style={style}
      className={classnames(
        'button',
        {
          'is-primary': !white && !purple,
          'is-secondary': purple,
          'is-outlined': outline,
        },
        className,
      )}
      disabled={disabled}
      onClick={onClick}
      type={type}
    >
      {isSubmitting && (
        <CauzeSpinner
          style={{
            margin: 'auto',
            position: 'absolute',
            width: '20px',
            height: '20px',
          }}
        />
      )}
      <span className={classnames({ 'is-submitting': isSubmitting })}>
        {children}
      </span>
    </button>
  );

Button.defaultProps = {
  onClick: () => {},
  type: 'button',
};

Button.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
  sm: PropTypes.bool,
  outline: PropTypes.bool,
  white: PropTypes.bool,
  purple: PropTypes.bool,
  href: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  style: PropTypes.object,
  isSubmitting: PropTypes.bool,
};

export default Button;
