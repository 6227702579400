const TOKEN_KEY = 'TOKEN';
const USERNAME_KEY = 'USERNAME';
const UI_KEY = 'UI';
const USER_CONTEXT_KEY = 'USERCONTEXT';
const SEEN_THANKS_REMINDER_KEY = 'SEEN_THANKS_REMINDER';

const _setItem = (key, value) => {
  if (window && window.localStorage) {
    return Promise.resolve(window.localStorage.setItem(key, value));
  }
};

const _getItem = key => {
  if (window && window.localStorage) {
    return Promise.resolve(window.localStorage.getItem(key));
  }
};

const _removeItem = key => Promise.resolve(window.localStorage.removeItem(key));

const getToken = async () => _getItem(TOKEN_KEY);

const setToken = async token => {
  global.AUTH_TOKEN = token;
  return _setItem(TOKEN_KEY, token);
};

const removeToken = async () => _removeItem(TOKEN_KEY);

const getUsername = async () => _getItem(USERNAME_KEY);

const setUsername = async username => _setItem(USERNAME_KEY, username);

const getHasSeenThanksReminder = async () => _getItem(SEEN_THANKS_REMINDER_KEY);

const setHasSeenThanksReminder = async () =>
  _setItem(SEEN_THANKS_REMINDER_KEY, true);

const removeUsername = async () => _removeItem(USERNAME_KEY);

const getUiState = async () => _getItem(UI_KEY).then(res => JSON.parse(res));

const setUiState = async uiState => _setItem(UI_KEY, JSON.stringify(uiState));

const setActiveEntity = async userEntity =>
  _setItem(USER_CONTEXT_KEY, JSON.stringify(userEntity));
const getActiveEntity = async () =>
  _getItem(USER_CONTEXT_KEY).then(res => JSON.parse(res));

export {
  getToken,
  setToken,
  removeToken,
  getUiState,
  setUiState,
  getUsername,
  setUsername,
  getHasSeenThanksReminder,
  setHasSeenThanksReminder,
  setActiveEntity,
  getActiveEntity,
  removeUsername,
};
