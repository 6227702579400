import React, { useEffect } from 'react';
import CauzeMatchListItem from './CauzeMatchListItem/CauzeMatchListItem';
import useGrabSlider from '../../util/useGrabSlider';
import PropTypes from 'prop-types';

const CauzeMatchList = ({
  matches,
  selected = [],
  onSelect,
  donationAmount,
  currentPaymentType = 'CARD',
}) => {
  useGrabSlider('cauze-match-list-container');

  useEffect(() => {
    if (currentPaymentType) {
      matches.forEach(match => {
        if (!match.paymentTypes) {
          onSelect(match.id);
        } else if (
          selected.includes(match.id) &&
          !match.paymentTypes.includes(currentPaymentType.toUpperCase())
        ) {
          onSelect(match.id);
        } else if (
          !selected.includes(match.id) &&
          match.paymentTypes.includes(currentPaymentType.toUpperCase())
        ) {
          onSelect(match.id);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPaymentType]);

  if (matches.length === 0) {
    return null;
  }

  return (
    <div className="cauze-match-list-container">
      {matches.map((match, index) => (
        <CauzeMatchListItem
          match={match}
          key={index}
          index={index}
          donationAmount={donationAmount}
          onSelect={onSelect}
          selected={selected}
          isSelected={selected.includes(match.id)}
          currentPaymentType={currentPaymentType}
        />
      ))}
    </div>
  );
};

CauzeMatchList.propTypes = {
  matches: PropTypes.array,
  selected: PropTypes.array,
  onSelect: PropTypes.func.isRequired,
  donationAmount: PropTypes.number.isRequired,
  currentPaymentType: PropTypes.string,
};

export default CauzeMatchList;
