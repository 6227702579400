/* eslint-disable no-console */
import { observable, action, toJS, makeObservable } from 'mobx';
import mixpanel from 'mixpanel-browser';

import uiStore from './UiStore';
import profileStore from './ProfileStore';

import { plaidLinkTokenQuery } from '../graphql/giftCheckout';
import { paymentOptionsQuery } from '../graphql/paymentOptions';

import { client as apolloClient } from '../util/apolloClient';
import {
  completePaymentProcess,
  startPaymentProcess,
  getCheckoutVars,
} from '../util/checkoutUtils';

class AddFundsGiftCheckoutStore {
  @observable checkoutDetailsFail = false;
  @observable checkoutLoading = true;
  @observable awaitTokenCreation = false;
  @observable awaitCompleteCheckout = false;
  @observable awaitWebCheckout = false;
  @observable newCardToken = '';
  @observable checkoutDetails = {};

  @observable currentCheckout = {
    amount: 0,
    firstName: '',
    lastName: '',
    emails: [],
  };
  @observable checkoutSuccess = {};
  @observable showConfirmationModal = false;
  @observable showRewardModal = false;
  @observable showErrorModal = false;
  @observable errorModalText = '';
  @observable errorModalButtonText = '';
  @observable supportsApplePay = false;
  @observable applePaySetup = false;
  @observable paypalEnabled = false;
  @observable returnPath;

  @observable plaidLinkToken;

  setStripeObject = (stripe) => {
    this.stripe = stripe;
  };

  setStripeElements = (elements) => {
    this.elements = elements;
  };

  setOrderDetails = (orderDetails) => {
    this.orderDetails = orderDetails;
  };

  @action updateCheckout = (props) => {
    this.currentCheckout = { ...this.currentCheckout, ...props };
  };

  @action resetCheckout = () => {
    this.checkoutLoading = false;
    this.checkoutDetails = {};
    this.currentCheckout = {
      amount: 0,
      firstName: '',
      lastName: '',
      email: '',
      emails: [],
    };
    this.newCardToken = '';
    this.checkoutDetailsFail = false;
    this.awaitTokenCreation = false;
    this.awaitCompleteCheckout = false;
    this.showConfirmationModal = false;
    this.plaidLinkToken = '';
  };

  @action getCheckoutDetails = async ({
    userContext,
    activeEntity,
    giftType,
  }) => {
    let paymentType;
    let balance = 0;
    let cardId;
    let cardName;
    let cardBrand;
    let cardNickname;
    let cardStripeId;
    this.checkoutLoading = true;
    this.checkoutDetailsFail = false;

    const options = {
      variables: { userContext },
      query: paymentOptionsQuery,
      fetchPolicy: 'no-cache',
      errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
    };
    try {
      const result = await apolloClient.query(options);
      this.paymentOptions = result.data.paymentOptions.filter((paymentOption) =>
        ['BALANCE', 'CARD', 'ACH', 'PAYPAL', 'APPLE_PAY'].includes(
          paymentOption.paymentType,
        ),
      );
      if (result.data.paymentOptions.length > 0) {
        let defaultCardIsBankCard = false;
        result.data.paymentOptions.forEach((paymentOption) => {
          if (paymentOption.paymentType === 'BALANCE') {
            balance = result.data.paymentOptions[0].balance?.total;
          }
          if (paymentOption.paymentType === 'PAYPAL') {
            this.paypalEnabled = true;
          }
          // Bank cards are payment options with paymentType 'CARD' and accountType 'ACH'
          if (
            paymentOption.paymentType === 'CARD' &&
            paymentOption.cards.length > 0
          ) {
            const defaultCard =
              paymentOption.cards.find((card) => card.is_default) ||
              paymentOption.cards[0];

            cardId = defaultCard.id;
            cardName = defaultCard.last4;
            cardBrand = defaultCard.brand;
            cardNickname = defaultCard.nickname;
            cardStripeId = defaultCard.stripeCardId;
            if (defaultCard.accountType?.toLowerCase() === 'ach') {
              defaultCardIsBankCard = true;
            }
          }
        });
        if (
          giftType !== 'SELF_GIFT' &&
          (balance > 0 || activeEntity?.balance?.allowNegative)
        ) {
          paymentType = 'BALANCE';
        } else if (cardId) {
          paymentType = defaultCardIsBankCard ? 'ACH' : 'CARD';
        }
      }
      this.updateCheckout({
        paymentType,
        balance,
        cardId,
        cardName,
        cardNickname,
        cardStripeId,
        cardBrand,
        userContext,
        giftType,
      });
      this.checkoutLoading = false;
    } catch (err) {
      this.checkoutDetailsFail = true;
    }
  };

  @action startCheckout = async () => {
    this.awaitTokenCreation = true;

    const variables = {
      delayBalanceCheckout: true,
      ...toJS(this.currentCheckout),
      ...(this.currentCheckout.amount === 0
        ? { paymentType: 'BALANCE' }
        : { paymentType: toJS(this.currentCheckout.paymentType) }),
      ...(this.currentCheckout.emails.length > 0
        ? { emails: toJS(this.currentCheckout.emails) }
        : { emails: null }),
    };

    if (variables.paymentType?.toLowerCase() === 'apple_pay') {
      variables.paymentType = 'CARD';
    }

    await startPaymentProcess({
      store: this,
      checkoutType: 'gift',
      variables: variables,
    });
  };

  @action cancelCheckoutConfirmation = () => {
    this.showConfirmationModal = false;
  };

  @action completeCheckout = async () => {
    this.awaitCompleteCheckout = true;

    try {
      const variables = await getCheckoutVars({
        store: this,
        checkoutType: 'gift',
      });
      const result = await completePaymentProcess({
        store: this,
        variables: variables,
        checkoutType: 'gift',
      });

      if (result.errors && result.errors.length > 0) {
        throw result.errors;
      }

      if (this.currentCheckout.giftType === 'SELF_GIFT') {
        uiStore.showNotification({
          body: `$${
            this.currentCheckout.amount / 100
          } was added to your account`,
          type: 'SUCCESS',
        });
      } else {
        uiStore.showNotification({
          body: `${
            this.currentCheckout.amount === 0 ? 'Invites' : 'Gifts'
          } Sent`,
          type: 'SUCCESS',
        });
      }

      profileStore.getProfile();

      return true;
    } catch (errors) {
      this.showConfirmationModal = false;
      this.awaitCompleteCheckout = false;

      if (Array.isArray(errors)) {
        if (errors[0].message) {
          this.popErrorModal(errors[0].message);
          return false;
        }
      } else {
        this.popErrorModal(
          'There was a problem with your transaction. Support has been notified.',
        );
      }

      return false;
    }
  };

  getPaypalVars = () => ({
    currency: 'USD',
    commit: true,
    onSuccess: this.onPaypalSuccess,
    onError: this.onPaypalError,
    onCancel: this.onPaypalCancel,
    onOrderCreate: this.onPaypalOrderCreate,
  });

  @action onPaypalError = (error) => {
    this.checkoutLoading = false;
    this.popErrorModal(
      'There was an issue processing your paypal transaction. Support has been notified.',
    );
    global.IS_LOCAL_OR_DEV &&
      console.log('PayPal: Erroneous payment OR failed to load script!', error);
  };

  @action onPaypalCancel = (data) => {
    this.checkoutLoading = false;
    global.IS_LOCAL_OR_DEV && console.log('Paypal: Cancelled payment!', data);
  };

  @action onPaypalSuccess = (orderDetails) => {
    this.checkoutLoading = true;
    try {
      this.updateCheckout({
        paypalData: {
          payerId: orderDetails.payer.payer_id,
          paymentId: orderDetails.id,
          paymentToken: orderDetails.purchase_units[0].payments.captures[0]?.id,
        },
      });

      this.completeCheckout().then((_checkoutComplete) => {
        this.checkoutLoading = false;
        uiStore.closeModal();
      });
    } catch (err) {
      this.checkoutLoading = false;
      this.popErrorModal(
        'There was an issue processing your paypal transaction. Support has been notified.',
      );
    }
  };

  roundToTwo = (num) => Math.round((num + Number.EPSILON) * 100) / 100;

  @action onPaypalOrderCreate = async () => {
    this.updateCheckout({ paymentType: 'PAYPAL', stripeToken: null });
    await this.startCheckout();
    return this.getPaypalOrder();
  };

  getPaypalOrder = () => {
    if (this.currentCheckout.splitAmount) {
      // paypal uses decimal money representation
      let donationTotal = this.currentCheckout.splitAmount.forCauze / 100;
      let fee = this.currentCheckout.splitAmount.fee / 100;
      let orderTotal = this.currentCheckout.splitAmount.total / 100;

      return {
        intent: 'CAPTURE',
        purchase_units: [
          {
            items: [
              {
                name: `${
                  this.currentCheckout.giftType === 'SELF_GIFT'
                    ? 'Cauze Funding'
                    : 'Cauze Gift'
                }`,
                quantity: 1,
                unit_amount: { value: donationTotal, currency_code: 'USD' },
              },
              {
                name: 'Handling Fee',
                // description: `This charge does not go to Cauze`,
                quantity: 1,
                unit_amount: { value: fee, currency_code: 'USD' },
              },
            ],
            amount: {
              currency_code: 'USD',
              value: orderTotal,
              breakdown: {
                item_total: { value: orderTotal, currency_code: 'USD' },
              },
            },
            description: `${
              this.currentCheckout.giftType === 'SELF_GIFT'
                ? 'Cauze Funding'
                : 'Cauze Gift'
            }`,
          },
        ],
        application_context: {
          shipping_preference: 'NO_SHIPPING', // default is "GET_FROM_FILE"
        },
      };
    }
  };

  @action generatePlaidLinkToken = async () => {
    const options = {
      query: plaidLinkTokenQuery,
      fetchPolicy: 'no-cache',
      errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
    };
    try {
      this.plaidLinkToken = await apolloClient
        .query(options)
        .then((res) => res.data.plaidLinkToken);
    } catch (err) {
      console.log(err);
    }
  };

  @action onPlaidStart = () => {
    this.updateCheckout({ paymentType: 'ACH', stripeToken: null });
  };

  // token is the public token returned from the modal, which we send as part of completeCheckout
  // for the server to validate against stripe for the transaction.
  //
  // Very similar behavior to paypal
  @action onPlaidSuccess = (token, metadata) => {
    this.checkoutLoading = true;

    try {
      this.updateCheckout({
        paymentData: {
          paymentTokenType: 'PLAID',
          paymentToken: token,
          paymentId: metadata.accounts[0]?.id,
        },
      });

      this.startCheckout().then(() => {
        this.completeCheckout()
          .then((checkoutComplete) => {
            if (checkoutComplete) {
              uiStore.closeModal();
              if (this.currentCheckout.emails?.length > 0) {
                uiStore.showNotification({
                  body: `$${
                    this.currentCheckout.amount / 100
                  } in gifts were sent.`,
                  type: 'SUCCESS',
                });
              } else {
                uiStore.showNotification({
                  body: `$${
                    this.currentCheckout.amount / 100
                  } was added from your bank account`,
                  type: 'SUCCESS',
                });
              }
            }
            this.checkoutLoading = false;
          })
          .catch((err) => {
            this.popAchErrorModal(err);
          });
      });
    } catch (error) {
      this.checkoutLoading = false;
      this.onPlaidError(error);
    }
  };

  @action onPlaidError = (error, _metadata) => {
    this.checkoutLoading = false;
    this.popAchErrorModal(error);
  };

  popAchErrorModal = (error) => {
    this.popErrorModal(
      'There was an issue processing your Plaid ACH transaction. Support has been notified.',
    );
    global.IS_LOCAL_OR_DEV && console.log('Plaid ACH Error', error);
  };

  @action onApplePayOrderCreate = async () => {
    this.updateCheckout({
      paymentType: 'APPLE_PAY',
      stripeToken: null,
    });
    await this.startCheckout();
    return this.getApplePayOrder();
  };

  getApplePayOrder = () => {
    mixpanel.track('Gift Checkout Started with Apple Pay');

    if (this.currentCheckout.splitAmount) {
      return {
        country: 'US',
        currency: 'usd',
        total: {
          label: 'Cauze',
          amount: this.currentCheckout.splitAmount.total,
        },
      };
    }

    return null;
  };

  @action onApplePayOrderComplete = async (paymentIntent) => {
    this.checkoutLoading = true;
    try {
      this.updateCheckout({
        paymentIntentId: paymentIntent.id,
        paymentType: 'APPLE_PAY',
      });

      const checkoutComplete = await this.completeCheckout();
      this.checkoutLoading = false;
      uiStore.closeModal();

      if (checkoutComplete) {
        mixpanel.track('Donation Checkout Completed with Apple Pay');
      }
    } catch (err) {
      this.checkoutLoading = false;
      this.popErrorModal(
        'There was an issue processing your apple pay transaction. Please try again with card payment. Support has been notified.',
      );
    }
  };

  @action getApplePaymentRequest = async () => {
    const orderDetails = await this.onApplePayOrderCreate();

    const pr = this.stripe.paymentRequest({
      ...orderDetails,
      requestPayerName: true,
      requestPayerEmail: true,
    });

    pr.on('paymentmethod', async (ev) => {
      // Confirm the PaymentIntent without handling potential next actions (yet).
      const { paymentIntent, error: confirmError } =
        await this.stripe.confirmCardPayment(
          this.currentCheckout.clientSecret,
          { payment_method: ev.paymentMethod.id },
          { handleActions: false },
        );

      if (confirmError) {
        this.popErrorModal('Unable to verify card payment. Try again later.');
        ev.complete('fail');
      } else {
        ev.complete('success');
        if (paymentIntent.status === 'requires_action') {
          // Let Stripe.js handle the rest of the payment flow.
          const { error } = await this.stripe.confirmCardPayment(
            this.currentCheckout.clientSecret,
          );
          if (error) {
            this.popErrorModal(
              'The payment failed. Try a different payment method.',
            );
          } else {
            this.updateCheckout({ paymentMethodId: ev.paymentMethod.id });
            await this.onApplePayOrderComplete(paymentIntent);
          }
        } else {
          this.updateCheckout({ paymentMethodId: ev.paymentMethod.id });
          await this.onApplePayOrderComplete(paymentIntent);
        }
      }
    });

    // Check the availability of the Payment Request API.
    const result = await pr.canMakePayment();

    if (result) {
      return pr;
    }
  };

  @action popErrorModal = (errorText, buttonText) => {
    this.errorModalText = errorText;
    this.errorModalButtonText = buttonText;
    this.toggleErrorModal();
  };

  @action onErrorModalClose = () => {
    this.toggleErrorModal();
    this.errorModalText = '';
    this.errorModalButtonText = '';
  };

  @action toggleErrorModal = () => {
    this.showErrorModal = !this.showErrorModal;
  };

  constructor() {
    makeObservable(this);
  }
}

const addFundsGiftCheckoutStore = new AddFundsGiftCheckoutStore();
export default addFundsGiftCheckoutStore;
