import gql from 'graphql-tag';

const influencersQuery = gql`
  query influencers {
    influencers {
      id
      firstName
      lastName
      username
      email
      followerCount
      followingCount
      avatar {
        sm
      }
      balance {
        total
      }
      shareLink
      zip
    }
  }
`;

const influencerAdminsQuery = gql`
  query influencerAdmins($user_id: ID!) {
    influencerAdmins(userId: $user_id) {
      id
      firstName
      lastName
      username
      email
      followerCount
      followingCount
      invitedDate
      redeemedDate
      avatar {
        sm
      }
      balance {
        total
        gifts {
          amount
          remaining
        }
      }
      zip
    }
  }
`;

const assignUserRoleMutation = gql`
  mutation assignUserRole(
    $role_type: UserRoleType!
    $target_id: ID
    $user_id: ID!
  ) {
    assignUserRole(
      roleType: $role_type
      targetId: $target_id
      userId: $user_id
    ) {
      active
      id
    }
  }
`;

const revokeUserRoleMutation = gql`
  mutation revokeUserRole(
    $role_type: UserRoleType!
    $target_id: ID
    $user_id: ID!
  ) {
    revokeUserRole(
      roleType: $role_type
      targetId: $target_id
      userId: $user_id
    ) {
      active
      id
    }
  }
`;

const inviteInfluencerAdminMutation = gql`
  mutation sendGifts($user_context: UserContext, $emails: [String]) {
    sendGifts(
      userContext: $user_context
      emails: $emails
      amount: 0
      paymentType: BALANCE
      userRole: INFLUENCER_ADMIN
      giftType: USER_GIFT
    ) {
      token
    }
  }
`;

const inviteInfluencerMutation = gql`
  mutation sendGifts($emails: [String]) {
    sendGifts(
      emails: $emails
      amount: 0
      paymentType: BALANCE
      userRole: INFLUENCER
      giftType: USER_GIFT
    ) {
      token
    }
  }
`;

const listInfluencerAdminInvitesQuery = gql`
  query listInfluencerAdminInvites($user_id: ID!) {
    listInfluencerAdminInvites(userId: $user_id) {
      id
      email
      updatedAt
      pending
      revoked
      gift {
        amount
        comment
      }
    }
  }
`;

export {
  influencersQuery,
  influencerAdminsQuery,
  assignUserRoleMutation,
  revokeUserRoleMutation,
  inviteInfluencerMutation,
  inviteInfluencerAdminMutation,
  listInfluencerAdminInvitesQuery,
};
