import Avatar from 'components/Avatar/Avatar';
import CheckoutSection from './CheckoutSection';
import Currency from 'components/Currency/Currency';
import CauzeAmountSelector from 'components/CauzeAmountSelector/CauzeAmountSelector';

const ChooseAmountSectionUser = ({
  user,
  minimumDonationAmount,
  currentCheckout,
  checkoutDetails,
  userGiftStore,
  location,
}) => {
  const showMinDonation = minimumDonationAmount && minimumDonationAmount > 100;
  const customDonationAmounts = location?.state?.donationAmountPresets;

  return (
    <CheckoutSection title="1. Choose Amount">
      {showMinDonation && (
        <h4>
          Minimum <Currency amount={minimumDonationAmount} />
        </h4>
      )}
      <CauzeAmountSelector
        defaultAmount={
          customDonationAmounts?.[0] || currentCheckout.donationAmount
        }
        amounts={customDonationAmounts || checkoutDetails.donationAmounts}
        selectedAmount={currentCheckout.donationAmount}
        minimumAmount={minimumDonationAmount}
        onAmountUpdate={(donationAmount) => {
          userGiftStore.updateCheckout({
            donationAmount,
          });

          // remove payment type if balance is selected and amount is greater than balance
          if (
            currentCheckout.paymentType === 'balance' &&
            donationAmount > currentCheckout.balance
          ) {
            userGiftStore.updateCheckout({
              paymentType: null,
              cardId: null,
              cardBrand: null,
              cardName: null,
              cardNickname: null,
            });
          }
        }}
      />

      <div className="avatar-container">
        <div className="user-recipients">Recipients</div>
        <div className="user-sub">
          each recipient will receive ${currentCheckout.donationAmount / 100}
        </div>
        <div className="user-avatar">
          <Avatar md avatarUrls={user?.avatarUrls} />
        </div>
        <div className="user-name">
          {user?.firstName || ''} {user?.lastName || ''}
        </div>
      </div>

      <div className="message-container">
        <div>
          <p>Send a personal message!</p>
          <textarea
            onInput={(input) =>
              userGiftStore.updateCheckout({
                comment: input.currentTarget.value,
              })
            }
            className="message-input"
            id="message"
            placeholder="Hey there rockstar..."
            rows="7"
            cols="50"
          />
        </div>
      </div>
    </CheckoutSection>
  );
};

export default ChooseAmountSectionUser;
