import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import CauzeButton from 'components/CauzeButton/CauzeButton';
import useKeyboardEvent from 'hooks/useKeyboardEvent.js';

const ConfirmModal = ({
  message = 'Are you sure?',
  bodyMessage = null,
  onToggleClose,
  action,
  isOpen,
  confirmText = 'ok',
}) => {
  useKeyboardEvent('Escape', () => {
    onToggleClose(false);
  });

  return (
    <div
      className={cx('modal confirm-modal', {
        'is-active': isOpen,
      })}
    >
      <div
        className="modal-background"
        onClick={() => {
          onToggleClose(false);
        }}
      />
      <div className="modal-card ">
        <header className="confirm-modal-head">
          <div className="modal-card-title">
            <div
              className="close-button flex-row flex-justify-end"
              onClick={() => {
                onToggleClose(false);
              }}
            >
              <FontAwesomeIcon icon={faTimes} />
            </div>
            <div className="modal-card-title-text">{message}</div>
          </div>
        </header>
        {bodyMessage && (
          <section className="confirm-modal-body">
            <p className="confirm-modal-body-message">{bodyMessage}</p>
          </section>
        )}
        <footer className="modal-card-foot">
          <CauzeButton
            className="button is-primary is-small"
            onClick={() => {
              action();
              onToggleClose(false);
            }}
          >
            {confirmText}
          </CauzeButton>
        </footer>
      </div>
    </div>
  );
};

ConfirmModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onToggleClose: PropTypes.func.isRequired,
  bodyMessage: PropTypes.string,
  action: PropTypes.func,
  message: PropTypes.string,
  confirmOnly: PropTypes.bool,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
};

export default ConfirmModal;
