/* eslint-disable no-console */
import { observable, action, makeObservable } from 'mobx';
import { client as apolloClient } from '../util/apolloClient';

import {
  companiesQuery,
  addToCompanyBalanceMutation,
} from '../graphql/companies';

import { createDownloadTokenMutation } from '../graphql/companyReporting';

class CompaniesStore {
  @observable loading = true;
  @observable isError = false;

  @observable companies = [];

  // id
  // name
  // url
  // description
  // shareLink
  // avatar { sm }
  // balance
  // employeeCount
  // invitedCount

  @observable activeCompanyId = null;

  getInitial = () => {
    this.getCompanies();
  };

  @action getCompanies = async () => {
    this.loading = true;

    const options = {
      variables: {},
      query: companiesQuery,
      fetchPolicy: 'no-cache',
      errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
    };

    try {
      this.companies = await apolloClient.query(options).then(res => {
        if (res.data?.companies) {
          return res.data.companies.map(company => ({
            ...company,
            balance: company.balance.total,
            entityType: 'COMPANY',
          }));
        }
        return [];
      });

      this.loading = false;
      this.isError = false;
    } catch (err) {
      console.log(err);
      this.loading = false;
      this.isError = true;
      this.error = err;
    }
  };

  @action setActiveCompany = companyId => {
    this.activeCompanyId = companyId;
  };

  @action addToBalance = async ({ amount, description }) => {
    try {
      await apolloClient.mutate({
        variables: {
          amount,
          description,
          id: this.activeCompanyId,
        },
        mutation: addToCompanyBalanceMutation,
        errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
      });
    } catch (err) {
      console.error(err);
    }

    this.activeCompanyId = null;
    this.getCompanies();
  };

  @action getAdminDownloadToken = async handlerName => {
    const options = {
      mutation: createDownloadTokenMutation,
      variables: { handlerName },
      errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
    };

    try {
      const token = await apolloClient
        .mutate(options)
        .then(result => result.data.createDownloadToken.token);

      return token;
    } catch (err) {
      this.error = err;
    }
  };

  @action onUnmount = () => {
    this.isError = false;
    this.companies = [];
  };

  constructor () {
    makeObservable(this);
  }
}

const companiesStore = new CompaniesStore();

export default companiesStore;
