/* eslint-disable no-console */
import { observable, action, makeObservable } from 'mobx';
import { client as apolloClient } from '../util/apolloClient';
import moment from 'moment';
import {
  revokeUserRoleMutation,
  assignUserRoleMutation,
  inviteUserAdminMutation,
  listUserAdminInvitesQuery,
  userAdminsQuery,
} from '../graphql/users';

import { revokeUserTokensMutation } from '../graphql/companyAdmin';

class UserDashboardStore {
  @observable loading = true;
  @observable isError = false;
  @observable revokingAdmins = false;

  @observable userAdmins = [];

  getInitial = ({ userId }) => {
    this.getUserAdmins({ userId });
  };

  @action getUserAdmins = async ({ userId }) => {
    const getOptions = query => ({
      query: query,
      variables: { userId },
      fetchPolicy: 'network-only',
      errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
    });

    this.loading = true;

    try {
      const adminResult = await apolloClient
        .query(getOptions(userAdminsQuery))
        .then(res => {
          if (res?.data?.userAdmins) {
            return res.data.userAdmins;
          }
          return [];
        });

      const unredeemedResult = await apolloClient
        .query(getOptions(listUserAdminInvitesQuery))
        .then(res => {
          if (res.data?.listUserAdminInvites) {
            return res.data.listUserAdminInvites
              .filter(adminToken => !adminToken.revoked)
              .map(adminToken => ({
                ...adminToken,
                email: adminToken.email,
                username: '',
                name: '',
                balance: {
                  total: 0,
                },
                firstName: null,
                lastName: null,
                tokenId: adminToken.id,
                id: `unredeemed-${adminToken.id}`,
                invitedDate: adminToken.updatedAt,
                redeemed: false,
                status: adminToken.pending ? 'Pending' : 'Waiting',
              }));
          }
          return [];
        });

      this.userAdmins = adminResult.concat(unredeemedResult);

      this.loading = false;
      this.isError = false;
    } catch (err) {
      global.IS_LOCAL_OR_DEV && console.log(err);
      this.loading = false;
      this.isError = true;
      this.error = err;
    }
  };

  // userId is the influencer admin who'se role is being revoked, targetId is the influencer
  @action revokeUserAdmin = async ({ userId, targetId }) => {
    console.log('revokeUserAdmin', userId, targetId);
    this.revokingAdmins = true;

    const options = {
      variables: {
        userId,
        targetId,
        roleType: 'USER_ADMIN',
      },
      mutation: revokeUserRoleMutation,
      fetchPolicy: 'no-cache',
      errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
    };

    try {
      await apolloClient.mutate(options);

      // removes the user from memory for ui update
      this.userAdmins = this.userAdmins.filter(admin => admin.id !== userId);
    } catch (err) {
      global.IS_LOCAL_OR_DEV && console.log(err);
    }
    this.revokingAdmins = false;
  };

  @action addUserAdmin = async ({ userId, email, targetId }) => {
    try {
      if (email) {
        const options = {
          variables: {
            userContext: { userId: targetId },
            emails: [email],
          },
          query: inviteUserAdminMutation,
          fetchPolicy: 'no-cache',
          errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
        };
        const res = await apolloClient.query(options);
        this.userAdmins.push({
          id: res.data.sendGifts.token,
          firstName: email,
          lastName: '',
          invitedDate: moment().utc(),
        });
      } else {
        const options = {
          variables: {
            roleType: 'USER_ADMIN',
            targetId: targetId,
            userId,
          },
          query: assignUserRoleMutation,
          fetchPolicy: 'no-cache',
          errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
        };
        await apolloClient.query(options);
        this.getUserAdmins({ userId: targetId });
      }
    } catch (err) {
      console.log(err);
    }
  };

  @action revokeUserInvites = async ({ ids }) => {
    console.log('revoke user invites', ids);
    try {
      await apolloClient.mutate({
        variables: { ids },
        mutation: revokeUserTokensMutation,
        errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
      });
      this.userAdmins = this.userAdmins.filter(admin => {
        console.log(admin);
        return !ids.includes(admin.tokenId);
      });
    } catch (err) {
      console.log(err);
    }
  };

  @action onUnmount = () => {
    this.loading = true;
    this.revokingAdmins = false;
    this.isError = false;
    this.userAdmins = [];
  };

  constructor () {
    makeObservable(this);
  }
}

const userDashboardStore = new UserDashboardStore();

export default userDashboardStore;
