import gql from 'graphql-tag';

const createAnnualMatchMutation = gql`
  mutation(
    $company_id: ID!
    $start_date: Date
    $end_date: Date
    $match_limit: Int!
    $match_total: Int
  ) {
    createCompanyMatch(
      companyId: $company_id
      startDate: $start_date
      endDate: $end_date
      matchLimit: $match_limit
      matchTotal: $match_total
    ) {
      active
      id
    }
  }
`;

const createCompanyMatchMutation = gql`
  mutation(
    $user_context: UserContext!
    $start_date: Date
    $end_date: Date
    $name: String
    $match_limit: Int!
    $match_total: Int
    $match_type: MatchType
    $multiplier: Float
    $restrict_match_after_balance: Boolean
  ) {
    createMatch(
      userContext: $user_context
      name: $name
      startDate: $start_date
      endDate: $end_date
      matchLimit: $match_limit
      matchTotal: $match_total
      matchType: $match_type
      multiplier: $multiplier
      restrictMatchAfterBalance: $restrict_match_after_balance
    ) {
      active
      id
    }
  }
`;

const getMatchDetailsQuery = gql`
  query($match_id: ID!) {
    matchDetails(matchId: $match_id) {
      id
      name
      matchType
      matchLimit
      matchTotal
      startDate
      restrictMatchAfterBalance
      matchCharities {
        id
        name
      }
      endDate
      multiplier
      matchSponsors {
        id
        matchTotal
      }
      matchAdmin {
        id
      }
    }
  }
`;

const editCompanyMatchMutation = gql`
  mutation editMatch(
    $id: ID!
    $start_date: Date
    $end_date: Date
    $name: String
    $match_limit: Int
    $match_total: Int
    $multiplier: Float
    $restrict_match_after_balance: Boolean
    $active: Boolean
  ) {
    editMatch(
      id: $id
      name: $name
      startDate: $start_date
      endDate: $end_date
      matchLimit: $match_limit
      matchTotal: $match_total
      restrictMatchAfterBalance: $restrict_match_after_balance
      multiplier: $multiplier
      active: $active
    ) {
      active
      id
    }
  }
`;

const createDownloadTokenMutation = gql`
  mutation($handler_name: String, $user_context: UserContext) {
    createDownloadToken(
      handlerName: $handler_name
      userContext: $user_context
    ) {
      token
    }
  }
`;

export {
  createAnnualMatchMutation,
  createCompanyMatchMutation,
  getMatchDetailsQuery,
  editCompanyMatchMutation,
  createDownloadTokenMutation,
};
