import { useEffect } from 'react';
import eventStore from '../stores/EventStore';
import mixpanel from 'mixpanel-browser';

const useEvent = ({ eventId, purchaseId, userContext }) => {
  useEffect(() => {
    eventStore.onUnmount();
    eventStore.getEventApi2({
      id: +eventId,
      purchaseId: +purchaseId,
    });
    mixpanel.track('screenViewEvent', {
      eventId,
      purchaseId,
    });
  }, [eventId, purchaseId, userContext]);
};

export default useEvent;
