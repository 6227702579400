import { useEffect } from 'react';
import companyReportingStore from '../stores/CompanyReportingStore';

const useCompanyReporting = companyId => {
  useEffect(() => {
    if (companyId) {
      companyReportingStore.getInitial(companyId);
    }

    return () => {
      companyReportingStore.onUnmount();
    };
  }, [companyId]);
};

export default useCompanyReporting;
