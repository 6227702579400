import React from 'react';
import CauzeCharityListItem from './CauzeCharityListItem/CauzeCharityListItem';

const CauzeCharityList = ({
  charities,
  onLearnMore,
  onSelect,
  selected = [],
  showLink,
  disabled = false,
  noCheckBox = false,
}) => {
  const selectValidate = (charityId) => {
    if (selected.length > 1) {
      onSelect(charityId);
    }
    if (selected.length === 1 && !selected.includes(charityId)) {
      onSelect(charityId);
    }
  };

  return (
    <div className="cauze-charity-list-container">
      {charities.map((charity, index) => {
        const isSelected = selected.includes(charity.id);
        let notSelectable = false;
        if (selected.length <= 1 && isSelected) {
          notSelectable = true;
        }
        return (
          <CauzeCharityListItem
            key={`${charity.id}`}
            index={index}
            charity={charity}
            onSelect={selectValidate}
            isSelected={isSelected}
            onLearnMore={onLearnMore}
            disabled={disabled}
            showLink={showLink}
            noCheckBox={noCheckBox}
            notSelectable={notSelectable}
          />
        );
      })}
    </div>
  );
};

export default CauzeCharityList;
