/* eslint-disable no-console */
import { observable, action, toJS, makeObservable } from 'mobx';
import { reject } from 'lodash';
import mixpanel from 'mixpanel-browser';
import { navigate } from '@reach/router';

import { startPaymentProcess } from '../util/checkoutUtils';

import { client as apolloClient } from '../util/apolloClient';
import { client as api2Client } from '../util/api2Client';

import uiStore from './UiStore';
import authStore from './AuthStore';
import profileStore from './ProfileStore';

import charityPlaceholder from '../assets/images/icons/placeholder/placeholder-charity.svg';
import eventPlaceholder from '../assets/images/placeholders/placeholder-event.png';

import {
  unauthedCheckoutDetailsQuery,
  unauthCompleteDonationCheckout,
  unauthedCompleteDonationCheckout,
  unauthedStartDonationCheckoutMutation,
} from '../graphql/donationCheckout';
import { pendingInvitesQuery } from '../graphql/user';
import { plaidLinkTokenQuery } from '../graphql/giftCheckout';

class DonationUnauthedCheckoutStore {
  @observable checkoutDetailsFail = false;
  @observable checkoutLoading = true;
  @observable awaitTokenCreation = false;
  @observable plaidLinkToken;
  @observable awaitCompleteCheckout = false;
  @observable awaitWebCheckout = false;
  @observable newCardToken = '';
  @observable checkoutDetails = {};
  @observable paymentOptions = [];
  @observable checkoutTitle = 'Donation Checkout';
  @observable checkoutImage = charityPlaceholder;
  @observable backgroundCategory = '';
  @observable charityBackground;
  @observable selectAllCharities = true;
  @observable showMarketingOption = true;
  @observable currentCheckout = {
    donationAmount: 2500,
    firstName: '',
    lastName: '',
    email: '',
    comment: '',
    emailIsPrivate: false,
  };
  @observable checkoutSuccess = {};
  @observable showConfirmationModal = false;
  @observable showPendingInvitesMessage = false;
  @observable pendingInvites;
  @observable showRewardModal = false;
  @observable showErrorModal = false;
  @observable errorModalText = '';
  @observable errorModalButtonText = '';
  @observable supportsApplePay = false;
  @observable applePaySetup = false;
  @observable paypalEnabled = false;
  @observable cardInputError = false;
  @observable returnPath;
  referrerContext = {};
  stashedCheckoutState = {};
  isAuthenticated = false;

  setStripeObject = (stripe) => {
    this.stripe = stripe;
  };

  setStripeElements = (elements) => {
    this.elements = elements;
  };

  @action updateCheckout = (props) => {
    this.currentCheckout = { ...this.currentCheckout, ...props };
  };

  @action resetCheckout = () => {
    this.checkoutLoading = true;
    this.checkoutDetails = {};
    this.currentCheckout = {
      donationAmount: 2500,
      firstName: '',
      lastName: '',
      email: '',
      comment: '',
    };
    this.newCardToken = '';
    this.checkoutDetailsFail = false;
    this.awaitTokenCreation = false;
    this.awaitCompleteCheckout = false;
    this.showConfirmationModal = false;
    this.charityBackground = null;
    this.backgroundCategory = null;
    this.checkoutTitle = 'Donation Checkout';
    this.checkoutImage = charityPlaceholder;
    this.showMarketingOption = true;
    this.cardInputError = false;
    this.showPendingInvitesMessage = false;
    this.pendingInvites = null;
    this.clearStashedCheckoutState();
    // referrerContext is not reset here since this function runs at the beginning of checkout,
    // and referrerContext is saved ahead of time. referrerContext is cleared out manually after a
    // successful checkout in this.completeDonationCheckout()
  };

  @action getCheckoutDetails = async ({
    _gift,
    charityId,
    charityIds,
    eventId,
    userContext,
    joinPurchaseId,
    tokenId,
    emailIsPrivate = false,
    giftToken,
    giftAmount,
    firstName,
    lastName,
    email,
  }) => {
    if (eventId) {
      this.returnPath = `/event/${eventId}`;
    }
    mixpanel.track('Donation Checkout Page Load', {
      eventId,
      charityId,
      ...userContext,
      giftToken,
      giftAmount,
    });
    let paymentType;
    let balance = 0;
    let onlyCharity;
    let cardId;
    let cardName;
    let cardBrand;
    let cardNickname;
    let matchIds = [];
    let referrerContext;
    this.checkoutLoading = true;
    this.checkoutDetailsFail = false;
    this.selectAllCharities = true;
    let _emailIsPrivate = emailIsPrivate;
    // prevents other contexts from being referrer for now,
    // remove `&& uiStore.savedUiState.checkoutReferrer.referrerContext?.userId` in the future
    if (
      uiStore.savedUiState.checkoutReferrer &&
      uiStore.savedUiState.checkoutReferrer.referrerContext?.userId
    ) {
      if (
        uiStore.savedUiState.checkoutReferrer.eventId === eventId ||
        uiStore.savedUiState.checkoutReferrer.charityId === charityId ||
        uiStore.savedUiState.checkoutReferrer.routeId === eventId ||
        uiStore.savedUiState.checkoutReferrer.routeId === charityId
      ) {
        referrerContext = toJS(
          uiStore.savedUiState.checkoutReferrer.referrerContext,
        );
      } else {
        referrerContext = {};
      }
    }
    const options = {
      variables: {
        charityIds,
        eventId,
        userContext,
        tokenId,
        userToken: giftToken,
      },
      query: unauthedCheckoutDetailsQuery,
      fetchPolicy: 'no-cache',
      errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
    };
    try {
      const checkoutDetails = await apolloClient
        .query(options)
        .then((result) => result.data.checkoutDetails);
      this.checkoutDetails = checkoutDetails;
      this.setCharityTitleAndImage();
      this.backgroundCategory = checkoutDetails.charities[0]?.category;
      // Process paymentOptions and set defaults
      if (giftToken) {
        paymentType = 'GIFT';
      } else {
        if (checkoutDetails.paymentOptions.length > 0) {
          this.paymentOptions = checkoutDetails.paymentOptions.filter(
            (paymentOption) =>
              paymentOption.paymentType === 'BALANCE' ||
              paymentOption.paymentType === 'CARD' ||
              paymentOption.paymentType === 'ACH' ||
              paymentOption.paymentType === 'PAYPAL',
          );
          checkoutDetails.paymentOptions.forEach((paymentOption) => {
            if (paymentOption.paymentType === 'BALANCE') {
              balance = checkoutDetails.paymentOptions[0].balance.total;
            }
            if (paymentOption.paymentType === 'PAYPAL') {
              this.paypalEnabled = true;
            }
            if (
              paymentOption.paymentType === 'CARD' &&
              paymentOption.cards.length > 0
            ) {
              cardId = paymentOption.cards[0].id;
              cardName = paymentOption.cards[0].last4;
              cardBrand = paymentOption.cards[0].brand;
              cardNickname = paymentOption.cards[0].nickname;
            }
          });
          if (balance > 0) {
            paymentType = 'BALANCE';
          } else if (cardId) {
            paymentType = 'CARD';
          }
        }
      }
      if (checkoutDetails.matches.length > 0) {
        matchIds = checkoutDetails.matches.map((match) => match.id);
      }
      if (checkoutDetails.charities.length === 1) {
        onlyCharity = checkoutDetails.charities[0]?.id;
      }
      charityIds = checkoutDetails.charities.map((charity) => charity.id);
      if (
        checkoutDetails.charities[0]?.image &&
        checkoutDetails.charities[0]?.image.full
      ) {
        this.charityBackground = checkoutDetails.charities[0]?.image.full;
      }
      // Check if they or their company has marketing notifications turned off
      const marketingPreference = checkoutDetails.notificationPreferences?.find(
        (np) => np.channel === 'EMAIL' && np.category === 'MARKETING',
      );
      if (marketingPreference) {
        this.showMarketingOption = marketingPreference.active;
        if (!this.showMarketingOption) {
          _emailIsPrivate = true;
        }
      }
      // Default donation amount is custom for some balance amounts, and gifts
      let defaultDonationAmount = 2500;
      if (giftAmount) {
        defaultDonationAmount = giftAmount;
      } else if (paymentType === 'BALANCE' && balance > 0 && balance <= 2500) {
        defaultDonationAmount = balance;
      }
      this.updateCheckout({
        donationAmount: defaultDonationAmount,
        firstName,
        lastName,
        email,
        userToken: giftToken,
        charityId: onlyCharity,
        charityIds,
        eventId,
        eventName: checkoutDetails?.event?.name,
        eventImage:
          checkoutDetails?.event?.image?.sm ||
          checkoutDetails?.event?.image?.md,
        joinPurchaseId,
        paymentType,
        balance,
        cardId,
        cardName,
        cardNickname,
        matchIds,
        cardBrand,
        referrerContext,
        emailIsPrivate: _emailIsPrivate,
        userContext,
      });
      this.checkoutLoading = false;
    } catch (err) {
      this.checkoutDetailsFail = true;
    }
  };

  @action startCheckout = async () => {
    this.awaitTokenCreation = true;
    const userDetails = {
      firstName: this.currentCheckout.firstName,
      lastName: this.currentCheckout.lastName,
      email: this.currentCheckout.email,
      zip: this.currentCheckout.zip || '',
    };
    if (!userDetails.firstName && userDetails.lastName) {
      userDetails.firstName = 'Giver';
    }

    const validateReferrerContext = () => {
      if (this.currentCheckout.referrerContext) {
        if (
          this.activeEntity?.type === 'USER' &&
          parseInt(this.activeEntity?.id) ===
            parseInt(this.currentCheckout.referrerContext.userId)
        ) {
          return null;
        } else {
          return parseInt(this.currentCheckout?.referrerContext?.userId);
        }
      } else return null;
    };

    if (this.currentCheckout.paymentType === 'BRAINTREE') {
      this.currentCheckout.paymentType = 'braintree';
    }

    const charityIdsToInt = this.currentCheckout?.charityIds
      ? this.currentCheckout?.charityIds?.map((id) => parseInt(id))
      : [this.currentCheckout?.charityId];
    const matchIdsToInt = this.currentCheckout?.matchIds?.map((id) =>
      parseInt(id),
    );

    const platformType = authStore.isAuthenticated ? 'WEB' : 'WEB_UNCLAIMED';
    // TODO: get joinPurchaseId from updated feed card
    const variables = {
      donationAmount: this.currentCheckout.donationAmount,
      charityIds: charityIdsToInt,
      platform: platformType,
      paymentType: this.currentCheckout.paymentType.toUpperCase(),
      comment: this.currentCheckout.comment,
      commentImageId: parseInt(this.currentCheckout.commentImageId),
      eventId: parseInt(this.currentCheckout.eventId),
      joinPurchaseId: parseInt(this.currentCheckout.joinPurchaseId),
      matchIds: matchIdsToInt,
      previewUrl: this.currentCheckout.previewUrl,
      referralLink: this.currentCheckout.referralLink,
      referralPath: this.currentCheckout.referralPath,
      referrerId: validateReferrerContext(),
      referrerType: 'USER',
      emailIsPrivate: this.currentCheckout.emailIsPrivate,
      cardBrand: this.currentCheckout.cardBrand,
      createEvent: false,
      eventName: this.currentCheckout.eventName,
      externalImageUrl: this.currentCheckout.externalImageUrl,
      userDetails: userDetails,
    };

    await startPaymentProcess({
      store: this,
      checkoutType: 'donation',
      variables,
    });
  };

  executeRecaptcha = () => {
    const grecaptchaPromise = new Promise((resolve, reject) => {
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute('6Lc-e08jAAAAAEZ7BSXi10cVWgb2G_K2FoxSGsBD', {
            action: 'donationCheckout',
          })
          .then((recaptchaToken) => {
            resolve(recaptchaToken);
          }, reject);
      });
    })
      .then((token) => token)
      .catch((err) => {
        console.err(err);
        reject(err);
      });
    return grecaptchaPromise;
  };

  @action completeCheckout = async () => {
    this.awaitCompleteCheckout = true;
    const { token } = toJS(this.currentCheckout);
    let variables = {
      checkoutTokenUuid: token,
      paymentType: this.currentCheckout.paymentType.toUpperCase(),
    };

    if (
      ['stripe_cc', 'stripe_ach'].includes(this.currentCheckout.paymentType)
    ) {
      if (!this.currentCheckout.stripeToken) {
        const result = await this.stripe.confirmPayment({
          elements: this.elements,
          clientSecret: this.currentCheckout.clientSecret,
          redirect: 'if_required',
          confirmParams: {
            return_url: `${window.location.origin}/checkout/success`,
            payment_method_data: {
              billing_details: {
                name: `${this.currentCheckout.firstName} ${this.currentCheckout.lastName}`,
                email: this.currentCheckout.email,
                phone: '',
                address: {
                  city: '',
                  country: 'US',
                  state: '',
                  postal_code: '',
                  line1: '',
                  line2: '',
                },
              },
            },
          },
        });

        if (result.error) {
          this.popErrorModal(result.error.message);
          return;
        } else {
          this.updateCheckout({
            paymentIntentId: result.paymentIntent.id,
            paymentMethodId: result.paymentIntent.payment_method,
          });
          variables = {
            ...variables,
            stripeOnetimePaymentData: {
              paymentIntentId: this.currentCheckout.paymentIntentId,
              paymentMethodId: this.currentCheckout.paymentMethodId,
            },
            paymentType: 'STRIPE_CC',
          };
        }
      }
    }

    if (
      this.currentCheckout.paymentToken &&
      this.currentCheckout.paymentType === 'braintree'
    ) {
      variables = {
        ...variables,
        braintreePaymentData: {
          braintreeNonce: this.currentCheckout.paymentToken,
        },
      };
    }

    if (this.currentCheckout.paymentType === 'PAYPAL') {
      variables = {
        ...variables,
        paypalPaymentData: this.currentCheckout.paypalData,
      };
    }

    if (this.currentCheckout.paymentType?.toUpperCase() === 'APPLE_PAY') {
      variables = {
        ...variables,
        stripeOnetimePaymentData: {
          paymentIntentId: this.currentCheckout.paymentIntentId,
          paymentMethodId: this.currentCheckout.paymentMethodId,
        },
        paymentType: 'STRIPE_CC',
      };
    }

    if (this.currentCheckout.paymentType?.toUpperCase() === 'GIFT') {
      variables.giftPaymentData = {
        userTokenUuid: this.currentCheckout.userToken,
      };
    }

    const options = {
      variables: { request: variables },
      mutation: unauthCompleteDonationCheckout,
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      onError: (err) => console.log(err),
    };

    try {
      const result = await api2Client.mutate(options);
      this.checkoutSuccess = result.data.completeDonationCheckout;

      mixpanel.track('Donation Checkout Transaction Completed');

      this.updateCheckout({
        cardName: this.checkoutSuccess.cardLast4,
        cardBrand: this.checkoutSuccess.cardBrand,
      });

      if (result.data?.completeDonationCheckout?.rewardAmount) {
        this.showRewardModal = true;
      }
      profileStore.getProfile();

      this.awaitCompleteCheckout = false;
      this.showConfirmationModal = false;
      uiStore.saveUiState({ checkoutReferrer: {} });

      if (result.errors && result.errors.length > 0) {
        throw result.errors;
      }

      return true;
    } catch (errors) {
      this.showConfirmationModal = false;
      this.awaitCompleteCheckout = false;

      if (Array.isArray(errors)) {
        if (errors[0].message) {
          this.popErrorModal(errors[0].message);
          return;
        }
      } else {
        this.popErrorModal(
          'There was a problem with your transaction. Support has been notified.',
        );
      }

      return false;
    }
  };

  @action startGiftCheckout = async () => {
    this.awaitTokenCreation = true;
    const userDetails = {
      firstName: this.currentCheckout.firstName,
      lastName: this.currentCheckout.lastName,
      email: this.currentCheckout.email,
      zip: this.currentCheckout.zip,
    };
    /*
      In some cases we allow the user to not provide a firstName/lastName (thnks), but in those cases,
      if there's a lastName and not a firstName, we need to send something so the server has a
      full name to process confirmation emails and the like
    */
    if (!userDetails.firstName && userDetails.lastName) {
      userDetails.firstName = 'Giver';
    }

    const options = {
      variables: {
        ...toJS(this.currentCheckout),
        ...(authStore.isAuthenticated
          ? { platform: 'WEB' }
          : { userDetails, platform: 'WEB_UNCLAIMED' }),
      },
      mutation: unauthedStartDonationCheckoutMutation,
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    };

    try {
      const result = await apolloClient.mutate(options);
      this.awaitTokenCreation = false;
      if (result.errors) {
        throw result.errors;
      }

      mixpanel.track('Donation Checkout Transaction Started');

      this.updateCheckout({
        token: result.data.startDonationCheckout.token,
        splitAmount: result.data.startDonationCheckout.splitAmount,
      });

      if (
        this.currentCheckout.paymentType === 'ACH' &&
        !this.currentCheckout.cardId
      ) {
        return;
      }
      if (
        this.currentCheckout.paymentType === 'ACH' &&
        this.currentCheckout.cardId
      ) {
        this.showConfirmationModal = true;
      }
      if (
        this.currentCheckout.paymentType === 'BALANCE' ||
        this.currentCheckout.paymentType === 'APPLE_PAY'
      ) {
        this.showConfirmationModal = true;
      }
      if (this.currentCheckout.paymentType === 'CARD') {
        if (!this.currentCheckout.cardId && !this.currentCheckout.stripeToken) {
          // navigation.navigate('addcard', { onAddCard: this.registerNewCard });
        } else {
          this.showConfirmationModal = true;
        }
      }
    } catch (err) {
      if (err[0].message === 'Insufficient balance to complete donation') {
        this.popErrorModal(
          'Insufficient balance to complete donation, please select a lesser amount.',
        );
      }
      this.awaitTokenCreation = false;
    }
  };

  @action completeGiftCheckout = async () => {
    const recaptchaToken = await this.executeRecaptcha();
    this.awaitCompleteCheckout = true;
    const { token, stripeToken, cardId, userToken } = toJS(
      this.currentCheckout,
    );
    const options = {
      variables: {
        token,
        stripeToken,
        userToken,
        ...(this.currentCheckout.paymentType === 'CARD' ||
        (this.currentCheckout.paymentType === 'ACH' &&
          !this.currentCheckout.stripeToken)
          ? { cardId }
          : {}),
        ...(this.currentCheckout.paymentType === 'ACH' ? { cardId } : {}),
        ...(this.currentCheckout.paypalData
          ? { paypalData: this.currentCheckout.paypalData }
          : {}),
        paymentData: this.currentCheckout.paymentData // This structure is only used for Plaid right now
          ? this.currentCheckout.paymentData
          : null,
        nickname: this.currentCheckout.cardNickname,
        recaptchaToken,
      },
      mutation: unauthedCompleteDonationCheckout,
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      onError: (err) => console.log(err),
    };
    try {
      const result = await apolloClient.mutate(options);
      this.checkoutSuccess = result.data.completeDonationCheckout;
      mixpanel.track('Donation Checkout Transaction Completed');
      if (result.data?.completeDonationCheckout?.rewardAmount) {
        this.showRewardModal = true;
      }
      profileStore.getProfile();
      this.awaitCompleteCheckout = false;
      this.showConfirmationModal = false;
      uiStore.saveUiState({ checkoutReferrer: {} });
      if (result.errors && result.errors.length > 0) {
        throw result.errors;
      }
      return true;
    } catch (errors) {
      this.showConfirmationModal = false;
      this.awaitCompleteCheckout = false;
      if (Array.isArray(errors)) {
        if (errors[0].message) {
          // this could be handled better if completeDonationCheckout
          // returns purchase detail of original transaction
          // navigation.dispatch(resetAction);
          this.popErrorModal(errors[0].message);
          return;
        }
      } else {
        this.popErrorModal(
          'There was a problem with your transaction. Support has been notified.',
        );
      }
      return false;
    }
  };

  @action toggleAllCharities = () => {
    this.selectAllCharities = !this.selectAllCharities;
    if (this.selectAllCharities) {
      this.updateCheckout({
        charityIds: this.checkoutDetails.charities.map((charity) => charity.id),
      });
    } else {
      this.updateCheckout({ charityIds: [] });
    }
  };

  @action setCharityTitleAndImage = () => {
    let image;
    let title;
    if (this.checkoutDetails.event) {
      if (this.checkoutDetails.event.name) {
        title = this.checkoutDetails.event.name;
      }
      if (this.checkoutDetails.event.image) {
        image = this.checkoutDetails.event.image.md;
      } else {
        if (this.checkoutDetails.charities.length === 1) {
          if (
            this.checkoutDetails.charities[0]?.avatar &&
            this.checkoutDetails.charities[0]?.avatar.md
          ) {
            image = this.checkoutDetails.charities[0]?.avatar.md;
          } else {
            image = charityPlaceholder;
          }
        } else {
          image = eventPlaceholder;
        }
      }
      // else not an event
    } else {
      title = this.checkoutDetails.charities[0]?.name;
      if (
        this.checkoutDetails.charities.length === 1 &&
        this.checkoutDetails.charities[0]?.avatar &&
        this.checkoutDetails.charities[0]?.avatar.md
      ) {
        image = this.checkoutDetails.charities[0]?.avatar.md;
      } else {
        image = charityPlaceholder;
      }
    }
    this.checkoutTitle = title;
    this.checkoutImage = image;
  };

  @action cancelCheckoutConfirmation = () => {
    this.showConfirmationModal = false;
  };

  @action cancelPendingInvitesMessage = () => {
    this.showPendingInvitesMessage = false;
  };

  @action hideRewardSuccessModal = () => {
    this.showRewardModal = false;
  };

  getPaypalVars = () => ({
    currency: 'USD',
    commit: true,
    onSuccess: this.onPaypalSuccess,
    onError: this.onPaypalError,
    onCancel: this.onPaypalCancel,
    onOrderCreate: this.onPaypalOrderCreate,
  });

  @action onPaypalError = (error) => {
    this.checkoutLoading = false;
    this.popErrorModal(
      'There was an issue processing your paypal transaction. Please try again with card payment. Support has been notified.',
    );
    mixpanel.track('Donation Checkout Error with Paypal', { error });
  };

  @action onPaypalCancel = () => {
    this.checkoutLoading = false;
    this.hydrateCheckout();
    mixpanel.track('Donation Checkout Cancelled with Paypal');
  };

  @action onPaypalSuccess = async (orderDetails) => {
    this.checkoutLoading = true;
    try {
      const { name, email_address } = orderDetails.payer;
      this.updateCheckout({
        paypalData: {
          payerId: orderDetails.payer.payer_id,
          paymentId: orderDetails.id,
          paymentToken: orderDetails.purchase_units[0].payments.captures[0]?.id,
        },
        firstName: name.given_name,
        lastName: name.surname,
        email: email_address,
      });
      await this.startCheckout();
      const checkoutComplete = await this.completeCheckout();
      this.clearStashedCheckoutState();
      this.checkoutLoading = false;
      if (checkoutComplete === true) {
        navigate('/checkout/success');
      }
      mixpanel.track('Donation Checkout Completed with Paypal');
    } catch (err) {
      this.checkoutLoading = false;
      this.hydrateCheckout();
      this.popErrorModal(
        'There was an issue processing your paypal transaction. Please try again with card payment. Support has been notified.',
      );
    }
  };

  @action onPaypalOrderCreate = async () => {
    this.stashCheckoutState();
    this.updateCheckout({ paymentType: 'PAYPAL', stripeToken: null });
    await this.startCheckout();
    return this.getPaypalOrder();
  };

  getPaypalOrder = () => {
    mixpanel.track('Donation Checkout Started with Paypal');

    if (this.currentCheckout.splitAmount) {
      // paypal uses decimal money representation
      let donationTotal = this.currentCheckout.splitAmount.remaining / 100;
      let fee = this.currentCheckout.splitAmount.fee / 100;
      let orderTotal = this.currentCheckout.splitAmount.total / 100;

      return {
        intent: 'CAPTURE',
        purchase_units: [
          {
            items: [
              {
                name: 'Cauze Charitable Donation',
                description: `Cauze Charitable Donation to ${this.checkoutTitle}`,
                quantity: 1,
                unit_amount: { value: donationTotal, currency_code: 'USD' },
              },
              {
                name: 'Handling Fee',
                // description: `This charge does not go to Cauze`,
                quantity: 1,
                unit_amount: { value: fee, currency_code: 'USD' },
              },
            ],
            amount: {
              currency_code: 'USD',
              value: orderTotal,
              breakdown: {
                item_total: { value: orderTotal, currency_code: 'USD' },
              },
            },
            description: `Cauze Charitable Donation to ${this.checkoutTitle}`,
          },
        ],
        application_context: {
          shipping_preference: 'NO_SHIPPING', // default is "GET_FROM_FILE"
        },
      };
    }
  };

  @action checkEmailTokens = async () => {
    if (!this.currentCheckout.email || this.currentCheckout.email === '') {
      return;
    }
    const options = {
      variables: {
        email: this.currentCheckout.email.trim(),
      },
      mutation: pendingInvitesQuery,
      fetchPolicy: 'no-cache',
      errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
    };

    try {
      const result = await apolloClient.mutate(options);
      if (result.data.pendingInvites) {
        this.pendingInvites = {
          userIsUnclaimed: result.data.pendingInvites.userIsUnclaimed,
          hasMatches: result.data.pendingInvites.matches.find(
            (match) => match.active,
          ),
          hasGiftToken: result.data.pendingInvites.tokens.find(
            (token) =>
              token.type === 'COMPANY_INVITE_WITH_GIFT' ||
              token.type === 'GIFT',
          ),
          hasGroupInvite: result.data.pendingInvites.tokens.find(
            (token) =>
              token.type === 'COMPANY_INVITE_WITH_GIFT' ||
              token.type === 'COMPANY_INVITE',
          ),
        };
        this.showPendingInvitesMessage = true;
      }
    } catch (err) {
      this.showPendingInvitesMessage = false;
    }
  };

  @action resetPendingInvites = () => {
    this.pendingInvites = {};
    this.showPendingInvitesMessage = false;
  };

  @action generatePlaidLinkToken = async () => {
    if (!authStore.isAuthenticated) {
      return;
    }
    const options = {
      query: plaidLinkTokenQuery,
      fetchPolicy: 'no-cache',
      errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
    };
    try {
      this.plaidLinkToken = await apolloClient
        .query(options)
        .then((res) => res.data.plaidLinkToken);
    } catch (err) {
      // no handling
    }
  };

  @action onPlaidStart = () => {
    mixpanel.track('Donation Checkout Started with Plaid');

    // Stashing the checkout state so that paymentOptions can be rehydrated if the user or an
    // error closes the modal preemptively
    this.stashCheckoutState();

    this.updateCheckout({
      paymentType: 'ACH',
      stripeToken: null,
      cardBrand: null,
      cardId: null,
      cardName: null,
    });
    this.startCheckout();
  };

  // token is the public token returned from the modal, which we send as part of completeCheckout
  // for the server to validate against stripe for the transaction.
  //
  // Very similar behavior to paypal
  @action onPlaidSuccess = (token, metadata) => {
    this.checkoutLoading = true;
    try {
      this.updateCheckout({
        paymentData: {
          paymentTokenType: 'PLAID',
          paymentToken: token,
          paymentId: metadata.accounts[0]?.id, // or metadata.accountId but keeping same as app
        },
      });

      this.completeCheckout()
        .then((checkoutComplete) => {
          if (checkoutComplete) {
            uiStore.closeModal();
            navigate('/checkout/success');
          }

          this.clearStashedCheckoutState();
          mixpanel.track('Donation Checkout Completed with Plaid');
          this.checkoutLoading = false;
        })
        .catch((err) => {
          this.popAchErrorModal(err);
        });
    } catch (error) {
      this.checkoutLoading = false;
      this.onPlaidError(error);
      this.hydrateCheckout();
    }
  };

  @action onPlaidError = (error, _metadata) => {
    mixpanel.track('Donation Checkout Error with Plaid');
    this.checkoutLoading = false;
    this.popAchErrorModal(error);
    this.hydrateCheckout();
  };

  @action onPlaidExitEvent = (_eventName, _metadata) => {
    mixpanel.track('Donation Checkout User Closed Plaid Modal');
    this.hydrateCheckout();
    this.checkoutLoading = false;
  };

  popAchErrorModal = (error) => {
    this.popErrorModal(
      'There was an issue processing your Plaid ACH transaction. Support has been notified.',
    );
    global.IS_LOCAL_OR_DEV && console.log('Plaid ACH Error', error);
  };

  @action onApplePayOrderCreate = async () => {
    if (this.checkoutLoading) return;

    this.stashCheckoutState();
    this.updateCheckout({
      paymentType: 'apple_pay',
      stripeToken: null,
    });
    await this.startCheckout();
    this.hydrateCheckout();
    return this.getApplePayOrder();
  };

  getApplePayOrder = () => {
    mixpanel.track('Donation Checkout Started with Apple Pay');

    if (this.currentCheckout.splitAmount) {
      return {
        country: 'US',
        currency: 'usd',
        total: {
          label: 'Cauze',
          amount: this.currentCheckout.splitAmount.total,
        },
      };
    }

    return null;
  };

  @action onApplePayOrderComplete = async (paymentIntent) => {
    this.checkoutLoading = true;
    try {
      this.updateCheckout({
        paymentIntentId: paymentIntent.id,
        paymentType: 'APPLE_PAY',
      });

      await this.startCheckout();
      const checkoutComplete = await this.completeCheckout();
      if (checkoutComplete === true) {
        navigate('/checkout/success');
      }
      this.clearStashedCheckoutState();
      this.checkoutLoading = false;
      mixpanel.track('Donation Checkout Completed with Apple Pay');
      return true;
    } catch (err) {
      this.checkoutLoading = false;
      this.hydrateCheckout();
      this.popErrorModal(
        'There was an issue processing your apple pay transaction. Please try again with card payment. Support has been notified.',
      );
    }

    return false;
  };

  @action onNoCharitySelected = () => {
    this.popErrorModal('Please select a nonprofit.');
  };

  @action popErrorModal = (errorText, buttonText) => {
    this.errorModalText = errorText;
    this.errorModalButtonText = buttonText;
    this.toggleErrorModal();
  };

  @action onErrorModalClose = () => {
    this.toggleErrorModal();
    this.errorModalText = '';
    this.errorModalButtonText = '';
  };

  @action toggleErrorModal = () => {
    this.showErrorModal = !this.showErrorModal;
  };

  @action raiseCardInputError = () => {
    this.cardInputError = true;
  };

  @action dismissCardInputError = () => {
    this.cardInputError = false;
  };

  hydrateCheckout = () => {
    this.updateCheckout(this.stashedCheckoutState);
    this.clearStashedCheckoutState();
  };

  @action stashCheckoutState = () => {
    this.stashedCheckoutState = this.currentCheckout;
  };

  @action clearStashedCheckoutState = () => {
    this.stashedCheckoutState = {};
  };

  constructor() {
    makeObservable(this);
  }
}

const donationUnauthedCheckoutStore = new DonationUnauthedCheckoutStore();
export default donationUnauthedCheckoutStore;
