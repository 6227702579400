/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import donationLandingStore from '../stores/DonationLandingStore';
import mixpanel from 'mixpanel-browser';

const useDonationLanding = () => {
  useEffect(() => {
    if (document.referrer) {
      mixpanel.track('Landed on Webpage From External Site', {
        referrer: document.referrer,
      });
    }
    donationLandingStore.getInitial();
  }, []);
};

export default useDonationLanding;
