import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import classnames from 'classnames';
import Button from 'components/Button/Button';
import Currency from 'components/Currency/Currency';
import useKeyboardEvent from '../../hooks/useKeyboardEvent.js';
import columns from './MatchTableColumns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSearch,
  faTimes,
  faSortUp,
  faSortDown,
  faFilter,
} from '@fortawesome/free-solid-svg-icons';

const MatchTable = ({
  matchList,
  keyField = 'id',
  onEdit,
  onDisable,
  onDownloadMatchLedger,
  userContext,
  isGroup,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [pageSize, setPageSize] = useState(matchList.length);
  const [searchActive, setSearchActive] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [statusFilter, setStatusFilter] = useState('all');
  const [eligibleGiversFilter, setEligibleGiversFilter] = useState('all');
  const [mobileSortActive, setMobileSortActive] = useState(false);
  const [mobileSort, setMobileSort] = useState({
    id: 'status',
    desc: true,
  });
  const [showSponsorModal, setShowSponsorModal] = useState(false);
  const [sponsorDetails, setSponsorDetails] = useState([]);

  const MAX_PAGE_SIZE = 200;

  let userTableRef = useRef(null);
  let searchInputRef = useRef(null);

  useEffect(() => {
    if (searchActive) {
      searchInputRef.current.focus();
    }
  }, [searchActive]);

  useKeyboardEvent('Escape', () => {
    toggleSearch(false);
  });

  const onViewSponsors = (sponsorList) => {
    setSponsorDetails(sponsorList);
    setShowSponsorModal(true);
  };

  const closeSponsorModal = () => {
    setSponsorDetails([]);
    setShowSponsorModal(false);
  };

  const matchColumns = columns(
    onEdit,
    onDisable,
    onDownloadMatchLedger,
    userContext,
    isGroup,
    onViewSponsors,
  );

  const clearSearch = () => {
    setSearchTerm('');
    searchInputRef.current.value = '';
  };

  const clearAllFilters = () => {
    setEligibleGiversFilter('all');
    setStatusFilter('all');
  };

  const toggleOptionsModal = (isOpen) => {
    if (!isOpen) {
      document.documentElement.classList.remove('is-clipped');
      setShowOptions(false);
      return;
    }
    document.documentElement.classList.add('is-clipped');
    setShowOptions(true);
  };

  const toggleSearch = (isOpen = true) => {
    if (isOpen) {
      setMobileSortActive(false);
      setSearchActive(true);
      searchInputRef.current.focus();
      return;
    }
    setSearchActive(false);
    searchInputRef.current.blur();
    clearSearch();
  };

  const toggleMobileSort = (isOpen = true) => {
    if (isOpen) {
      setMobileSortActive(true);
      return;
    }
    setMobileSortActive(false);
  };

  const rowFn = (state, rowInfo, column, instance) => ({
    onClick: (e, handleOriginal) => {
      const { expanded } = state;
      const path = rowInfo.nestingPath[0];
      const diff = { [path]: !expanded[path] };

      instance.setState({
        expanded: { ...expanded, ...diff },
      });
      if (handleOriginal) {
        handleOriginal();
      }
    },
  });

  return (
    <div className="rt-container">
      <div
        className={classnames('modal', {
          'is-active': showSponsorModal,
        })}
      >
        <div className="modal-background"></div>
        <div className="modal-card sponsor-info-modal">
          <header className="modal-card-head">
            <p className="modal-card-title"> Sponsor Info</p>
            <button
              className="delete"
              aria-label="close"
              onClick={closeSponsorModal}
            ></button>
          </header>
          <section className="modal-card-body">
            <div className="flex-row">
              <table>
                <tr className="option-info">
                  <td className="sponsor-header-item">Sponsor</td>
                  <td className="sponsor-header-item">Used Amount</td>
                  <td className="sponsor-header-item">Remaining Match</td>
                  <td className="sponsor-header-item">Total Match</td>
                </tr>
                {sponsorDetails &&
                  sponsorDetails.map((sponsor) => (
                    <tr className="option-info">
                      <td>{sponsor.name}</td>
                      <td>
                        <Currency
                          amount={sponsor.matchTotal - sponsor.remaining}
                        />
                      </td>
                      <td>
                        <Currency amount={sponsor.remaining} />
                      </td>
                      <td>
                        <Currency amount={sponsor.matchTotal} />
                      </td>
                    </tr>
                  ))}
              </table>
            </div>
          </section>
          <footer className="modal-card-foot">
            <Button className="button is-primary" onClick={closeSponsorModal}>
              Close
            </Button>
          </footer>
        </div>
      </div>
      <div
        className={classnames('modal', {
          'is-active': showOptions,
        })}
      >
        <div className="modal-background"></div>
        <div className="modal-card search-options-modal">
          <header className="modal-card-head">
            <p className="modal-card-title">
              <FontAwesomeIcon icon={faFilter} />
              Filters
            </p>
            <button
              className="delete"
              aria-label="close"
              onClick={() => toggleOptionsModal(false)}
            ></button>
          </header>
          <section className="modal-card-body">
            <div className="option-group">
              <p className="option-group-subhead is-size-5">Status</p>
              <div className="control flex-column">
                <label className="radio" htmlFor="statusRadio1">
                  <input
                    type="radio"
                    value="all"
                    name="status"
                    id="statusRadio1"
                    checked={statusFilter === 'all'}
                    onChange={(e) => setStatusFilter(e.target.value)}
                  />
                  All
                </label>
                <label className="radio" htmlFor="statusRadio2">
                  <input
                    type="radio"
                    value="live"
                    id="statusRadio2"
                    name="status"
                    checked={statusFilter === 'live'}
                    onChange={(e) => setStatusFilter(e.target.value)}
                  />
                  Live
                </label>
                <label className="radio" htmlFor="statusRadio3">
                  <input
                    type="radio"
                    value="future"
                    id="statusRadio3"
                    name="status"
                    checked={statusFilter === 'future'}
                    onChange={(e) => setStatusFilter(e.target.value)}
                  />
                  Future
                </label>
                <label className="radio" htmlFor="statusRadio4">
                  <input
                    type="radio"
                    value="ended"
                    id="statusRadio4"
                    name="status"
                    checked={statusFilter === 'ended'}
                    onChange={(e) => setStatusFilter(e.target.value)}
                  />
                  Ended
                </label>
                <label className="radio" htmlFor="statusRadio5">
                  <input
                    type="radio"
                    value="disabled"
                    id="statusRadio5"
                    name="status"
                    checked={statusFilter === 'disabled'}
                    onChange={(e) => setStatusFilter(e.target.value)}
                  />
                  Disabled
                </label>
              </div>
            </div>
            <div className="option-group">
              <p className="option-group-subhead is-size-5">Eligible Givers</p>
              <div className="control flex-column">
                <label className="radio" htmlFor="eligibleGiversRadio1">
                  <input
                    type="radio"
                    value="all"
                    name="eligibleGivers"
                    id="eligibleGiversRadio1"
                    checked={eligibleGiversFilter === 'all'}
                    onChange={(e) => setEligibleGiversFilter(e.target.value)}
                  />
                  All
                </label>
                <label className="radio" htmlFor="eligibleGiversRadio2">
                  <input
                    type="radio"
                    value="anyone"
                    name="eligibleGivers"
                    id="eligibleGiversRadio2"
                    checked={eligibleGiversFilter === 'anyone'}
                    onChange={(e) => setEligibleGiversFilter(e.target.value)}
                  />
                  Anyone
                </label>
                <label className="radio" htmlFor="eligibleGiversRadio3">
                  <input
                    type="radio"
                    value="employees"
                    name="eligibleGivers"
                    id="eligibleGiversRadio3"
                    checked={eligibleGiversFilter === 'employees'}
                    onChange={(e) => setEligibleGiversFilter(e.target.value)}
                  />
                  {isGroup ? 'Members' : 'Employees'}
                </label>
              </div>
            </div>
          </section>
          <footer className="modal-card-foot">
            <Button
              className="button is-primary"
              onClick={() => toggleOptionsModal(false)}
            >
              Apply
            </Button>
            <Button
              white
              className="button"
              onClick={() => {
                clearAllFilters();
                toggleOptionsModal(false);
              }}
            >
              Clear All
            </Button>
          </footer>
        </div>
      </div>
      <div className="action-bar flex-row">
        <div className="responsive-row">
          <div className={classnames('search-input')}>
            <div
              tabIndex="0"
              onFocus={() => toggleSearch(true)}
              onClick={searchTerm !== '' ? clearSearch : toggleSearch}
              className="action-button"
            >
              <FontAwesomeIcon
                icon={faSearch}
                className={classnames('', {
                  orange: searchTerm !== '',
                })}
              />
            </div>
            <input
              tabIndex={0}
              autoFocus={searchActive}
              className="input"
              placeholder="Search matches&#8230;"
              ref={searchInputRef}
              onChange={(e) => setSearchTerm(e.target.value)}
              onFocus={toggleSearch}
              onBlur={() => setSearchActive(false)}
            />
            <div
              alt="clear all"
              className="action-button close-button flex-column"
              disabled={
                !(
                  mobileSortActive ||
                  searchActive ||
                  searchTerm !== '' ||
                  eligibleGiversFilter !== 'all' ||
                  statusFilter !== 'all'
                )
              }
              onClick={() => {
                toggleSearch(false);
                toggleMobileSort(false);
                setStatusFilter('all');
                setEligibleGiversFilter('all');
              }}
            >
              <FontAwesomeIcon icon={faTimes} />
            </div>
          </div>
        </div>
        <div className="responsive-row flex-expand flex-justify-end">
          <div
            className={classnames('flex-row flex-expand is-hidden-tablet', {
              'is-hidden-mobile': searchActive,
            })}
          />
          <div className="flex-row">
            <div
              className={classnames(
                'action-button sort-action flex-column is-hidden-tablet',
              )}
              onClick={
                mobileSortActive
                  ? () =>
                      setMobileSort({ ...mobileSort, desc: !mobileSort.desc })
                  : () => {
                      setMobileSort({ ...mobileSort, desc: !mobileSort.desc });
                      toggleMobileSort(true);
                    }
              }
            >
              <FontAwesomeIcon
                icon={faSortUp}
                className={classnames('', {
                  orange: mobileSort.desc,
                })}
              />
              <FontAwesomeIcon
                icon={faSortDown}
                className={classnames('', {
                  orange: !mobileSort.desc,
                })}
              />
            </div>
            <div
              className={classnames('action-button')}
              onClick={() => toggleOptionsModal(!showOptions)}
            >
              <FontAwesomeIcon
                icon={faFilter}
                className={classnames('', {
                  orange:
                    statusFilter !== 'all' || eligibleGiversFilter !== 'all',
                })}
              />
            </div>
          </div>
        </div>
      </div>
      <ReactTable
        filterable
        className="match-table"
        resizable={false}
        keyField={keyField}
        ref={userTableRef}
        selectType="checkbox"
        columns={matchColumns}
        data={matchList}
        getTrProps={rowFn}
        getTheadProps={() => ({ className: 'is-hidden-mobile' })}
        pageSize={MAX_PAGE_SIZE}
        minRows={0}
        filtered={[
          { id: 'name', value: searchTerm },
          { id: 'status', value: statusFilter },
          { id: 'eligibleGivers', value: eligibleGiversFilter },
        ]}
        defaultSorted={[{ id: 'status', desc: true }]}
        showPaginationBottom={pageSize > MAX_PAGE_SIZE}
        defaultPageSize={MAX_PAGE_SIZE}
        onFilterUpdateLength={(len) => setPageSize(len)}
        {...(mobileSortActive > 0 && { sorted: [mobileSort] })}
      />
    </div>
  );
};

MatchTable.propTypes = {
  matchList: PropTypes.array,
  keyField: PropTypes.string,
  onEdit: PropTypes.func.isRequired,
  onDisable: PropTypes.func.isRequired,
  onDownloadMatchLedger: PropTypes.func.isRequired,
  userContext: PropTypes.object.isRequired,
  isGroup: PropTypes.bool,
};

export default MatchTable;
