/* eslint-disable no-console */
import { observable, action, makeObservable } from 'mobx';
import { client as apolloClient } from '../util/apolloClient';
import uiStore from './UiStore';

import {
  usersQuery,
  // revokeUserRoleMutation,
  assignUserRoleMutation,
  inviteUserMutation,
} from '../graphql/users';

class UsersStore {
  @observable loading = true;
  @observable isError = false;

  @observable balanceTotal = 0;
  @observable followersCount = 0;
  @observable followingCount = 0;

  @observable users = [];

  getInitial = () => {
    this.getUsers();
  };

  @action getUsers = async () => {
    this.loading = true;

    const options = {
      variables: {},
      query: usersQuery,
      fetchPolicy: 'no-cache',
      errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
    };

    let _balanceTotal = 0;
    let _followersCount = 0;
    let _followingCount = 0;

    try {
      this.users = await apolloClient.query(options).then(res => {
        console.log(res.data);
        if (res?.data?.users) {
          return res.data.users.map(user => {
            _balanceTotal += user.balance?.total;
            _followersCount += user.followerCount;
            _followingCount += user.followingCount;
            return {
              ...user,
              balance: user.balance?.total,
              entityType: 'USER',
              shareLink: user.shareLink,
              name: `${user.firstName} ${user.lastName}`,
            };
          });
        }
        return [];
      });

      this.balanceTotal = _balanceTotal;
      this.followersCount = _followersCount;
      this.followingCount = _followingCount;

      this.loading = false;
      this.isError = false;
    } catch (err) {
      console.log(err);
      this.loading = false;
      this.isError = true;
      this.error = err;
    }
  };

  @action addUser = async ({ userId, email }) => {
    try {
      if (email) {
        const options = {
          variables: {
            emails: [email],
          },
          query: inviteUserMutation,
          fetchPolicy: 'no-cache',
          errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
        };
        const res = await apolloClient.query(options);
        this.users.push({
          id: res.data.sendGifts.token,
          firstName: email,
          lastName: '',
        });
      } else {
        const options = {
          variables: {
            roleType: 'USER',
            userId,
          },
          query: assignUserRoleMutation,
          fetchPolicy: 'no-cache',
          errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
        };
        await apolloClient.query(options);
        this.getUsers();
      }
      uiStore.showNotification({ body: 'User added.', type: 'SUCCESS' });
    } catch (err) {
      console.log(err);
    }
  };

  @action onUnmount = () => {
    this.isError = false;
    this.users = [];
    this.balanceTotal = 0;
    this.followersCount = 0;
    this.followingCount = 0;
  };

  constructor () {
    makeObservable(this);
  }
}

const usersStore = new UsersStore();

export default usersStore;
