import React from 'react';
import Carousel from '../Carousel/Carousel';
import WorldMap from '../../assets/images/backgrounds/world-map.svg';
import Thnks from '../../assets/images/brand/externalBrands/thnksLogo.svg';
import Lovevery from '../../assets/images/brand/externalBrands/lovevery.svg';
import Limeade from '../../assets/images/brand/externalBrands/limeade.svg';
import Pattern from '../../assets/images/brand/externalBrands/Pattern_RGB_logo_2C_pos.png';
import GQG from '../../assets/images/brand/externalBrands/GQG_Logo_White@2x.png';
import WTIA from '../../assets/images/brand/externalBrands/wtia.png';
import Via from '../../assets/images/brand/externalBrands/via.png';

const bannerIcons = [
  <img className="banner-icon" src={Thnks} />,
  <img className="banner-icon" src={Lovevery} />,
  <img className="banner-icon" src={Limeade} />,
  <img className="banner-icon" src={Pattern} />,
  <img className="banner-icon" src={GQG} />,
  <img className="banner-icon" src={WTIA} />,
  <img className="banner-icon" src={Via} />,
];

const sliderConfig = {
  type: 'carousel',
  startAt: 1,
  animationDuration: 3000,
  animationTimingFunc: 'ease-in-out',
  autoplay: true,
  breakpoints: {
    4000: {
      perView: 5,
    },
    1024: {
      perView: 3,
      peek: { before: 100, after: 100 },
    },
    716: {
      perView: 3,
      peek: { before: 50, after: 50 },
    },
    500: {
      perView: 1,
      peek: { before: 150, after: 150 },
    },
    400: {
      perView: 1,
      peek: { before: 100, after: 100 },
    },
    350: {
      perView: 1,
      peek: { before: 75, after: 75 },
    },
  },
};

function BrandedBanner () {
  return (
    <div className="branded-banner-wrapper">
      <img className="banner-background-image" src={WorldMap} />
      <h4 className="banner-headline">We're Trusted By</h4>
      <div className="container">
        <div className="container banner-icon-wrapper">
          <Carousel sliderItems={bannerIcons} sliderConfig={sliderConfig} />
        </div>
      </div>
    </div>
  );
}

export default BrandedBanner;
