import gql from 'graphql-tag';

const companyUsersByIdQuery = gql`
  query companyUsersById($id: ID!) {
    companyUsersById(id: $id) {
      id
      username
      firstName
      lastName
      avatar {
        sm
        md
      }
      email
      balance {
        total
      }
      companyRoles
      employerGiftTotal
      employerGiftRemaining
      donationCount
      remainingMatch
      redeemedDate
      invitedDate
      eligibleMatch
      companyGiftTotal
      insertedAt
      lastDonationDate
      groupDonationTotal
      groupDonationCount
      lastGroupDonationDate
      isSelfFollowing
    }
  }
`;

const matchListForCompanyQuery = gql`
  query matchListForCompany($id: ID!) {
    matchListForCompany(id: $id) {
      sponsorCount
      matchTotal
      sponsorMatchRemaining
      matchType
      matchAdmin {
        id
        entityType
      }
      matchSponsors {
        name
        matchTotal
        remaining
      }
      active
      name
      charityNames
      description
      startDate
      endDate
      charityCount
      id
      userMatchLimit
      events {
        id
      }
    }
  }
`;

const listCompanyUnredeemedInvitesQuery = gql`
  query listCompanyUnredeemedInvites($id: ID!) {
    listCompanyUnredeemedInvites(id: $id) {
      id
      email
      updatedAt
      pending
      revoked
      gift {
        amount
        comment
      }
    }
  }
`;

const revokeUserTokensMutation = gql`
  mutation ($ids: [ID!]!, $revoke_all: Boolean) {
    revokeUserTokens(ids: $ids, revokeAll: $revoke_all) {
      email
    }
  }
`;

const removeCompanyUsersMutation = gql`
  mutation ($company_id: ID!, $user_ids: [ID!]!) {
    removeCompanyUsers(companyId: $company_id, userIds: $user_ids) {
      id
    }
  }
`;

const sendGiftsMutation = gql`
  mutation (
    $amount: Int
    $comment: String
    $emails: [String!]
    $gift_type: GiftType!
    $payment_type: PaymentType!
    $pending: Boolean!
    $phone_numbers: [String!]
    $user_context: UserContext
    $user_ids: [ID!]
  ) {
    sendGifts(
      amount: $amount
      comment: $comment
      emails: $emails
      giftType: $gift_type
      paymentType: $payment_type
      pending: $pending
      phoneNumbers: $phone_numbers
      userContext: $user_context
      userIds: $user_ids
    ) {
      gifts {
        id
      }
    }
  }
`;

const sendGroupDonationReminderMutation = gql`
  mutation ($user_ids: [ID!]!, $company_id: ID!) {
    sendGroupDonationReminder(companyId: $company_id, userIds: $user_ids)
  }
`;

const resendCompanyUserTokens = gql`
  mutation ($ids: [ID!]!) {
    resendCompanyUserTokens(ids: $ids) {
      id
    }
  }
`;

const updateCompanyInvitesMutation = gql`
  mutation ($ids: [ID!]!, $amount: Int, $comment: String) {
    updateCompanyInvites(ids: $ids, amount: $amount, comment: $comment) {
      id
    }
  }
`;

const updatePayrollRowMutation = gql`
  mutation ($payroll_id: ID!, $user_id: ID) {
    updatePayrollRow(payroll_id: $payroll_id, user_id: $user_id) {
      id
    }
  }
`;

const listPayrollForCompanyQuery = gql`
  query listPayrollForCompany($company_id: ID!) {
    listPayrollForCompany(companyId: $company_id) {
      amount
      id
      error
      fileRow
      filename
      payrollDate
      uploadedAt
      user {
        id
        firstName
        email
        lastName
        avatar {
          sm
        }
      }
    }
  }
`;

export {
  companyUsersByIdQuery,
  listCompanyUnredeemedInvitesQuery,
  revokeUserTokensMutation,
  removeCompanyUsersMutation,
  matchListForCompanyQuery,
  resendCompanyUserTokens,
  updateCompanyInvitesMutation,
  sendGiftsMutation,
  listPayrollForCompanyQuery,
  sendGroupDonationReminderMutation,
  updatePayrollRowMutation,
};
