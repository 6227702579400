import React from 'react';
import PropTypes from 'prop-types';
import SectionHeader from '../SectionHeader/SectionHeader';
import SponsoredCard from './SponsoredCard/SponsoredCard';
import CauzeLogo from '../../assets/images/brand/cauze-logotype-accent.png';

const cauzeDefault = {
  logo: {
    original: CauzeLogo,
  },
  description: 'Cauze is a social network for social good that empowers anyone to feel like a philanthropist. Turn your influence into impact with a Cauze personal giving account and #unleashyourgood ',
};

const SponsoredCards = ({ sponsor }) => (
  <div className="sponsored-cards-wrapper">
    <SectionHeader
      title="Brought to You By"
      lineColor="Orange"
    />
    <div className="sponsored-cards-card-container">
      <SponsoredCard sponsor={sponsor} key={0} />
      <SponsoredCard sponsor={cauzeDefault} key={1} />
    </div>
  </div>
);

SponsoredCards.propTypes = {
  sponsor: PropTypes.array.isRequired,
};

export default SponsoredCards;
