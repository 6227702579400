import ReactPlayer from 'react-player';

import LinkPreview from 'components/LinkPreview/LinkPreview';

const FeedCardPreview = ({ previewUrl }) => {
  const isVideo = previewUrl.includes('youtu') || previewUrl.includes('vimeo');

  if (isVideo) {
    return (
      <div className="video-container">
        <div className="event-video">
          <ReactPlayer width="100%" height="100%" controls url={previewUrl} />
        </div>
      </div>
    );
  }

  return <LinkPreview uri={previewUrl} />;
};

export default FeedCardPreview;
