import React from 'react';
import Currency from 'components/Currency/Currency';
import PropTypes from 'prop-types';

const TableCurrency = ({ value, showCents }) => (
  <Currency amount={value || 0} showCents={showCents || false} />
);

TableCurrency.propTypes = {
  value: PropTypes.number,
  showCents: PropTypes.bool,
};

export default TableCurrency;
