import React, { Fragment, useState } from 'react';
import classNames from 'classnames';
import { PaymentElement } from '@stripe/react-stripe-js';

import WrappedPaypalButton from 'components/WrappedPaypalButton';
import WrappedPlaidButton from 'components/WrappedPlaidButton';
import Currency from 'components/Currency/Currency';
import CauzeButton from 'components/CauzeButton/CauzeButton';
import CauzeCheckbox from 'components/CauzeCheckbox/CauzeCheckbox';
import DonationLegalInfo from 'components/legal/DonationLegalInfo';
import ErrorCard from 'components/ErrorCard/ErrorCard';
import PaymentMeta from 'components/PaymentMeta/PaymentMeta';
import AddPaymentModal from 'components/AddPaymentModal';
import WrappedApplePayButton from 'components/WrappedApplePayButton';

import stripeOptions from 'config/stripe';
import uiStore from 'stores/UiStore';
import DropdownChevron from 'assets/images/icons/chevron/down-chevron-2.svg';
import WrappedGooglePayButton from 'components/WrappedGooglePayButton';

const savedOptionsEnum = ['balance', 'stripe_cc', 'stripe_ach', 'card', 'ach'];
const cardsEnum = ['stripe_ach', 'stripe_cc', 'card', 'ach'];
const digitalWalletEnum = ['paypal', 'plaid', 'apple_pay', 'google_pay'];

const SavedOptions = ({
  store,
  activeEntity,
  allowNegativeBalance = false,
  excludedPaymentTypes = [],
  dismissCardInputError,
}) => {
  const [addPaymentType, setAddPaymentType] = useState('');

  if (!store.paymentOptions) return [];

  let savedOptions = store.checkoutDetails.paymentOptions.filter(
    (paymentOption) =>
      !excludedPaymentTypes.includes(paymentOption.paymentType.toUpperCase()) &&
      savedOptionsEnum.includes(paymentOption.paymentType.toLowerCase()),
  );

  const currentCheckout = store.currentCheckout;
  const isAddNewCard =
    `${currentCheckout.paymentType}`.toLowerCase() === 'card' &&
    !currentCheckout.cardId;
  const isAddNewACH =
    `${currentCheckout.paymentType}`.toLowerCase() === 'ach' &&
    !currentCheckout.cardId;
  const isDisabled = (paymentOption) => {
    if (paymentOption.paymentType === 'balance') {
      return (
        paymentOption.balance.total - currentCheckout.donationAmount < 0 &&
        !allowNegativeBalance
      );
    }

    return false;
  };

  const plaidValidate = () => {
    if (currentCheckout.amount === 0) {
      store.popErrorModal('Please select an amount.');
      return false;
    }

    if (
      currentCheckout.giftType === 'USER_GIFT' &&
      currentCheckout.emails?.length === 0
    ) {
      store.popErrorModal('Please enter at least one email address.');
      return false;
    }
    return true;
  };

  const handlePaymentOptionClick = (paymentOption, card) => {
    if (isDisabled(paymentOption)) return;

    const paymentType = (
      paymentOption.paymentType || paymentOption.accountType
    ).toLowerCase();

    if (paymentType === 'balance') {
      store.updateCheckout({
        paymentType: 'balance',
        cardId: null,
        cardBrand: null,
        cardName: null,
        cardNickname: null,
        cardStripeId: null,
      });
      return;
    }

    if (card && cardsEnum.includes(paymentType)) {
      store.updateCheckout({
        paymentType: paymentOption.paymentType,
        cardBrand: card.brand,
        cardId: card.id,
        cardName: card.last4,
        cardStripeId: card.stripeCardId,
      });
    }
  };

  const getPaymentMethods = (paymentOption) => {
    return paymentOption?.paymentMethods || paymentOption?.cards || [];
  };

  return (
    <>
      <AddPaymentModal
        isOpen={Boolean(addPaymentType)}
        onToggleClose={() => {
          setAddPaymentType('');
        }}
        paymentType={addPaymentType}
        activeEntity={activeEntity}
      />
      <div className="flex-expand flex-column">
        {savedOptions.map((paymentOption, index) => {
          const paymentType = paymentOption.paymentType.toLowerCase();

          return (
            <Fragment key={`${paymentOption}-${index}`}>
              {paymentType === 'balance' && (
                <div
                  key={`balance`}
                  className={`
                flex-expand flex-row flex-align-center
                ${
                  isDisabled(paymentOption)
                    ? 'payment-option-disabled'
                    : 'payment-option'
                }`}
                  onClick={() => handlePaymentOptionClick(paymentOption)}
                >
                  <CauzeCheckbox
                    isSelected={currentCheckout.paymentType === 'balance'}
                    id="balance-checkbox"
                  />
                  <p
                    style={{ marginTop: '2px' }}
                    className={classNames('', {
                      selected: currentCheckout.paymentType === 'balance',
                    })}
                  >
                    From{' '}
                    <Currency showCents amount={paymentOption.balance.total} />{' '}
                    {activeEntity.entityType === 'INFLUENCER' && 'Influencer '}
                    {activeEntity.entityType === 'COMPANY' && 'Company '}
                    Balance
                  </p>
                </div>
              )}
              {cardsEnum.includes(paymentType) &&
                getPaymentMethods(paymentOption)?.length > 0 &&
                getPaymentMethods(paymentOption).map((card, index) => {
                  const isSelected =
                    currentCheckout.cardId === card.id &&
                    currentCheckout.paymentType !== 'balance';
                  return (
                    <div
                      key={`CARD-${index}`}
                      className="flex-expand flex-row flex-align-center payment-option"
                      onClick={(_ev) => {
                        handlePaymentOptionClick(paymentOption, card);
                      }}
                    >
                      <CauzeCheckbox isSelected={isSelected} />
                      <p className={classNames('', { selected: isSelected })}>
                        {card.nickname || `${card.brand} ${card.last4}`}
                      </p>
                    </div>
                  );
                })}
            </Fragment>
          );
        })}
        {/*
          ACH
          - Is not returned from the server so is standalone option on the client.
          - Is active when paymentType === "ACH" but theres is no active acc (!cardId)
        */}
        <div
          key={`NEW-ACH`}
          className="flex-expand flex-column payment-option new-card"
        >
          <div className="flex-expand flex-row flex-align-center">
            <CauzeCheckbox
              isSelected={isAddNewACH}
              onClick={() => {
                document.querySelector('#plaid-button')?.click();
              }}
            />
            <p className="mr-1">ACH</p>
            <WrappedPlaidButton
              linkToken={store.plaidLinkToken}
              validateFunc={plaidValidate}
              onLinkStart={store.onPlaidStart}
              onLinkSuccess={store.onPlaidSuccess}
              onLinkError={store.onPlaidError}
              onExitEvent={store.onPlaidExitEvent}
              id="plaid-button"
            />
          </div>
        </div>
        {/*
          Add New Card
          - Is not returned from the server so is standalone option on the client.
          - Is active when paymentType === "CARD" but theres is no active card (!cardId)
        */}
        <div
          key={`NEW-CARD`}
          className="flex-expand flex-column payment-option new-card"
          onClick={(_ev) => {
            store.updateCheckout({
              paymentType: 'CARD',
              cardId: null,
              cardBrand: null,
              cardName: null,
              cardNickname: null,
              savedPaymentMethod: true,
            });
          }}
        >
          <div className="flex-expand flex-row flex-align-center">
            <CauzeCheckbox isSelected={isAddNewCard} />
            <p>Add New Card</p>
          </div>
          {isAddNewCard && (
            <div className="stripe-element-wrapper">
              <PaymentElement
                options={stripeOptions.cardElement}
                onClick={dismissCardInputError}
                onFocus={dismissCardInputError}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const DigitalWalletProcessors = ({
  store,
  excludedPaymentTypes,
  _activeEntity,
}) => {
  if (!store.paymentOptions) return [];

  const options = store.checkoutDetails.paymentOptions.filter(
    (paymentOption) => {
      const paymentType = paymentOption.paymentType.toLowerCase();

      return (
        !excludedPaymentTypes.includes(paymentType) &&
        digitalWalletEnum.includes(paymentType)
      );
    },
  );

  // These validation functions should probably live in the store and get passed down, but not
  // worrying about it for now
  const paypalValidate = async () => {
    if (currentCheckout.amount === 0) {
      store.popErrorModal('Please select an amount.');
      return false;
    }
    return true;
  };

  const currentCheckout = store.currentCheckout;
  return (
    <div className="digital-wallet">
      {options.map((option, index) => {
        const paymentType = option.paymentType.toLowerCase();

        return (
          <Fragment key={`${option}-${index}`}>
            {paymentType === 'paypal' && (
              <WrappedPaypalButton
                {...store.getPaypalVars()}
                validateFunc={paypalValidate}
              />
            )}
            {paymentType === 'apple_pay' && (
              <WrappedApplePayButton store={store} />
            )}
            {paymentType === 'google_pay' && (
              <WrappedGooglePayButton store={store} />
            )}
          </Fragment>
        );
      })}
    </div>
  );
};

const PaymentHeader = ({
  title,
  onToggle,
  toggleState,
  activeEntity,
  showBalance,
}) => (
  <div className="payment-header">
    <p>{title}</p>
    <div>
      {showBalance && (
        <span className="payment-header-balance">
          Balance:{' '}
          {activeEntity && (
            <Currency amount={activeEntity.balance.total} showCents />
          )}
        </span>
      )}
      <div
        className="dropdown-cheveron-container"
        onClick={() => onToggle(!toggleState)}
      >
        <img
          className={`dropdown-chevron ${toggleState ? 'open' : 'closed'}`}
          src={DropdownChevron}
        />
      </div>
    </div>
  </div>
);

const NewPaymentOptions = ({
  store,
  activeEntity,
  allowNegativeBalance = false,
  excludedPaymentTypes = [], // filter balance and apple pay for selfgift
  onDonate,
  donateText,
  showCardInputError,
  dismissCardInputError,
  isUser,
}) => {
  const balance = store.checkoutDetails.paymentOptions.find(
    (option) => option.paymentType === 'balance',
  )?.balance;

  const disableBalance =
    balance?.total - store.currentCheckout.donationAmount < 0 &&
    !allowNegativeBalance;

  const [isPaymentWindowOpen, setIsPaymentWindowOpen] = useState(true);
  const [isDigitalPaymentWindowOpen, setIsDigitalPaymentWindowOpen] =
    useState(true);

  return (
    <div className="new-payment-options">
      <div className="option-section">
        <PaymentHeader
          title="Pay with Balance / Card"
          onToggle={setIsPaymentWindowOpen}
          toggleState={isPaymentWindowOpen}
          activeEntity={activeEntity}
          showBalance
        />
        <div
          className={`options ${
            isPaymentWindowOpen ? 'window-open' : 'window-closed'
          }`}
        >
          {SavedOptions({
            store,
            activeEntity,
            allowNegativeBalance,
            excludedPaymentTypes,
            dismissCardInputError,
          })}
          {showCardInputError && (
            <ErrorCard
              message="Oops. The card information you entered is incorrect. Please check,
               change the highlighted areas and you’ll be all set!"
            />
          )}
          {!isUser && (
            <div className="legal-text">
              <div className="flex-expand flex-row share-option">
                <CauzeCheckbox
                  isSelected={!store.currentCheckout.emailIsPrivate}
                  onClick={() =>
                    store.updateCheckout({
                      emailIsPrivate: !store.currentCheckout.emailIsPrivate,
                    })
                  }
                />
                <p className="share-message">
                  Yes, please share my email with this nonprofit.
                </p>
              </div>
              <DonationLegalInfo uiStore={uiStore} />
            </div>
          )}
          <CauzeButton
            onClick={onDonate}
            className="donate"
            large
            disabled={
              !store.currentCheckout.paymentType ||
              (store.currentCheckout.paymentType === 'balance' &&
                disableBalance)
            }
          >
            {donateText}
          </CauzeButton>
          <PaymentMeta
            currentCheckout={store.currentCheckout}
            balance={balance}
          />
        </div>
      </div>
      <div className="option-section">
        <PaymentHeader
          title="Pay with Digital Wallet"
          onToggle={setIsDigitalPaymentWindowOpen}
          toggleState={isDigitalPaymentWindowOpen}
        />
        <div
          className={`digital-wallet-options ${
            isDigitalPaymentWindowOpen ? 'window-open' : 'window-closed'
          }`}
        >
          {DigitalWalletProcessors({
            store,
            activeEntity,
            excludedPaymentTypes,
          })}
        </div>
      </div>
    </div>
  );
};

export default NewPaymentOptions;
