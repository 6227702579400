import Avatar from 'components/Avatar/Avatar';

const CharityListItem = ({ charity }) => {
  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
      }}
    >
      <Avatar entityType="CHARITY" avatarUrls={charity.avatarUrls} />
      <div
        style={{
          fontWeight: 'bold',
          marginTop: 'auto',
          marginBottom: 'auto',
          marginLeft: '1rem',
        }}
      >
        {charity.name}
      </div>
    </div>
  );
};

export default CharityListItem;
