/* eslint-disable no-console */
import { observable, action, makeObservable } from 'mobx';

import { client as apolloClient } from '../util/apolloClient';
import {
  followMutation,
  unfollowMutation,
  getFollowersQuery,
  getFollowingQuery,
  getSuggestedEntitiesQuery,
} from '../graphql/follow';
import { trackFollow, trackUnfollow } from '../util/tracking/follow';

class FollowFollowingStore {
  @observable entitiesList = [];
  @observable isSelf = false;

  @observable loadingEntities = false;
  @observable loadingSuggestions = false;

  @action getInitial = ({ userContext, pageContext, isSelf, isFollowers }) => {
    this.isSelf = isSelf;
    this.getEntities(
      userContext,
      pageContext || userContext,
      pageContext || userContext,
      isFollowers,
    );
    this.getSuggestions(userContext);
  };

  @action getEntities = async (
    userContext,
    targetContext,
    actorContext,
    isFollowers,
  ) => {
    this.loadingEntities = true;

    let queryType = isFollowers ? getFollowersQuery : getFollowingQuery;
    let variableContext = isFollowers
      ? { userContext, targetContext }
      : { userContext, actorContext };

    const options = {
      variables: variableContext,
      query: queryType,
      errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
      fetchPolicy: 'no-cache',
    };

    try {
      const res = await apolloClient.query(options);
      this.entitiesList = isFollowers
        ? res.data.getFollowers
        : res.data.getFollowing;
      this.entityCount = this.entitiesList?.length;
      this.loadingEntities = false;
    } catch (err) {
      global.IS_LOCAL_OR_DEV && console.log('getEntities error', err);
    }
  };

  @action getSuggestions = (userContext) => {
    this.loadingSuggestions = true;

    const options = {
      variables: { userContext },
      query: getSuggestedEntitiesQuery,
      errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
      fetchPolicy: 'network-only',
    };

    try {
      apolloClient.query(options).then((res) => {
        this.suggestionsList = res.data.getSuggestedEntities;
        this.loadingSuggestions = false;
      });
    } catch (err) {
      global.IS_LOCAL_OR_DEV && console.log(err);
    }
  };

  @action follow = ({
    follow = true,
    targetContext,
    actorContext,
    targetId,
    pageContext,
  }) => {
    if (follow) {
      trackFollow({ pageContext, actorContext, targetContext });
    } else {
      trackUnfollow({ pageContext, actorContext, targetContext });
    }
    this.toggleLocalFollow(targetId, follow);

    const options = {
      variables: {
        targetContext,
        actorContext,
      },
      mutation: follow ? followMutation : unfollowMutation,
      errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
    };

    apolloClient.mutate(options).catch((err) => {
      this.toggleLocalFollow(targetId, !follow);

      if (global.IS_LOCAL_OR_DEV) {
        console.log(err);
      }
    });
  };

  @action toggleLocalFollow = (id, follow) => {
    this.entitiesList
      .filter((entity) => entity.id === id)
      .map((entity) => (entity.isSelfFollowing = follow));
  };

  @action onUnmount = () => {
    this.entitiesList = [];
    this.isSelf = false;
    this.loadingEntities = false;
    this.loadingSuggestions = false;
  };

  constructor() {
    makeObservable(this);
  }
}

const followFollowingStore = new FollowFollowingStore();

export default followFollowingStore;
