import gql from 'graphql-tag';

const listCountriesQuery = gql`
  query listCountries {
    listCountries {
      id
      name
      code
    }
  }
`;

const searchCharitiesQuery = gql`
  query searchCharity($query: String!, $filters: SearchFilters, $zip: String) {
    searchCharity(query: $query, filters: $filters, zip: $zip) {
      id
      name
      avatar {
        sm
        md
        lg
      }
      ein
      location
    }
  }
`;

const donationCharitySearchQuery = gql`
  query searchCharityReturningCharities(
    $query: String!
    $filters: SearchFilters
    $zip: String
  ) {
    searchCharityReturningCharities(
      query: $query
      filters: $filters
      zip: $zip
    ) {
      id
      name
      description
      avatar {
        sm
        md
        lg
      }
      ein
      city
      state
      isSelfFollowing
      followerCount
      featuredFollowers {
        name
        avatarUrl
      }
    }
  }
`;

const searchCharitiesReturningCharitiesQuery = gql`
  query searchCharityReturningCharities($query: String) {
    searchCharityReturningCharities(query: $query) {
      id
      name
      description
      shareLink
      avatar {
        sm
      }
      balance {
        total
      }
      ein
      street
      city
      state
      zip
    }
  }
`;

const usersSearchQuery = gql`
  query usersSearch($query: String) {
    usersSearch(query: $query) {
      id
      firstName
      lastName
      username
      isVerified
      avatar {
        sm
      }
    }
  }
`;

const listActiveTopicsQuery = gql`
  query listActiveTopics {
    listActiveTopics
  }
`;

const trendingTopicsQuery = gql`
  query trendingTopics {
    trendingTopics {
      id
      name
    }
  }
`;

const projectCategoriesQuery = gql`
  query projectCategories {
    projectCategories
  }
`;

export {
  listActiveTopicsQuery,
  listCountriesQuery,
  searchCharitiesQuery,
  searchCharitiesReturningCharitiesQuery,
  usersSearchQuery,
  donationCharitySearchQuery,
  projectCategoriesQuery,
  trendingTopicsQuery,
};
