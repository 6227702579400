import cx from 'classnames';

import paypalBtn from 'assets/images/icons/paypal/PayPal-Logo.png';

const PaypalButton = ({ children, className, ...props }) => {
  const btnClassName = cx(
    '!p-0 bg-paypal-yellow border-none rounded-md cursor-pointer flex justify-center h-10',
    {
      [className]: className,
    },
  );

  return (
    <button className={btnClassName} {...props}>
      {children} <img className="h-10" src={paypalBtn} />
    </button>
  );
};

export default PaypalButton;
