import React, { useState, useRef, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { navigate } from '@reach/router';
import { observer, inject } from 'mobx-react';
import { isEmpty } from 'lodash';
import classnames from 'classnames';
import mixpanel from 'mixpanel-browser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';

import config from '../../../config';
import { getAdminUrl } from 'util/navHelpers';
import useKeyboardEvent from 'hooks/useKeyboardEvent.js';
import { shorten } from 'util/stringUtils';
import Currency from 'components/Currency/Currency';
import Avatar from 'components/Avatar/Avatar';

import { useCauzeRoutingContext } from 'hooks/useCauzeRoutingContext';

const onLogoutClick = async (authStore, profileStore, eventStore) => {
  mixpanel.track('User Drop Down Logout');
  await eventStore.onUnmount();
  await profileStore.onUnmount();
  await authStore.changeAuthentication();
  navigate(`/login`);
};

const UserDropDown = ({
  authStore,
  uiStore,
  profileStore,
  eventStore,
  className,
  companyReportingStore,
  activeEntity,
  id,
  hideContextSelector = false,
  redirectAfterContextSelect = true,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { navigateWithConfirm } = useCauzeRoutingContext();

  useKeyboardEvent('Escape', () => {
    setIsOpen(false);
  });
  const activeCompanyId =
    activeEntity.entityType === 'COMPANY' && activeEntity.id;
  const activeCharityId =
    activeEntity.entityType === 'CHARITY' && activeEntity.id;
  const activeInfluencerId =
    activeEntity.entityType === 'INFLUENCER' && activeEntity.id;
  const activeUserId = ['USER', 'INFLUENCER'].includes(activeEntity.entityType) && activeEntity.id;

  let triggerRef = useRef(null);

  useEffect(() => {
    if (!isOpen && triggerRef.current) {
      triggerRef.current.blur();
    }
  }, [isOpen]);

  if (!profileStore.hasFetchedInitial) {
    return null;
  }

  const _setIsOpen = (open) => {
    mixpanel.track(`User Drop Down ${open ? 'opened' : 'closed'}`);
    setIsOpen(open);
  };

  const isGroup = activeEntity.groupType !== 'COMPANY';

  return (
    <div
      id={id}
      className={classnames(
        'navbar-item',
        'dropdown',
        'user-dropdown',
        'is-right',
        { 'is-active': isOpen },
        className,
      )}
    >
      <div className="dropdown-trigger">
        <button
          ref={triggerRef}
          onBlur={() => _setIsOpen(false)}
          onClick={() => _setIsOpen(!isOpen)}
          className="button"
          aria-haspopup="true"
          aria-controls="user-dropdown-menu"
        >
          <div className="user-container">
            <span className="user-name">{shorten(activeEntity.name, 30)}</span>
            <span className="user-balance">
              Balance:{' '}
              <Currency
                amount={activeEntity.balance.total}
                superCents={activeEntity.balance.total > 0}
              />
            </span>
          </div>
          <Avatar
            sm
            avatar={
              activeEntity.avatar
                ? activeEntity.avatar
                : activeEntity?.id === profileStore?.data?.id &&
                profileStore?.data?.avatar
            }
          />
          <span className="icon is-small">
            <FontAwesomeIcon icon={faAngleDown} />
          </span>
        </button>
      </div>
      <div className="dropdown-menu" id="dropdown-menu" role="menu">
        <div
          className={classnames('backdrop has-navbar-fixed-top', {
            'backdrop-lowered': !!activeUserId,
          })}
          onTouchEnd={() => _setIsOpen(false)}
        />
        <div className="dropdown-content">
          <div>
            <div className="dropdown-status">
              <div className="dropdown-status-title">{activeEntity.name}</div>
              <div className="balance flex-row">
                <div className="dropdown-status-subhead">
                  Balance: <Currency amount={activeEntity.balance.total} />
                </div>
              </div>
              {activeCompanyId && (
                <div>
                  <div className="balance flex-row">
                    <button
                      className="button is-small user-action-button"
                      onMouseDown={(ev) => {
                        ev.preventDefault();
                      }}
                      onClick={(ev) => {
                        ev.preventDefault();
                        _setIsOpen(false);
                        uiStore.openModal('SEND_GIFTS', {
                          giftType: 'SELF_GIFT',
                          companyId: activeCompanyId,
                        });
                      }}
                    >
                      Add Funds
                    </button>
                    <button
                      className="button is-small user-action-button"
                      onMouseDown={(ev) => {
                        ev.preventDefault();
                      }}
                      onClick={() => {
                        companyReportingStore
                          .getDownloadToken(activeCompanyId, 'company/ledger')
                          .then((token) =>
                            navigateWithConfirm(
                              `${config.API_ROOT}/company/${activeCompanyId}/ledger/${token}`,
                            ),
                          );
                      }}
                    >
                      Download Ledger
                    </button>
                  </div>
                  <div className="balance flex-row">
                    <button
                      className="button is-small user-action-button"
                      onMouseDown={(ev) => {
                        ev.preventDefault();
                      }}
                      onClick={() => {
                        companyReportingStore
                          .getDownloadToken(
                            activeCompanyId,
                            'company/yearly_receipt',
                          )
                          .then((token) =>
                            navigateWithConfirm(
                              `${config.API_ROOT}/company/${activeCompanyId}/yearly_receipt/${token}`,
                            ),
                          );
                      }}
                    >
                      Download Tax Receipt
                    </button>
                  </div>
                </div>
              )}
            </div>
            {(!activeCharityId ||
              (activeCharityId && activeEntity.canDonate)) && (
                <div>
                  <a
                    href="#"
                    onMouseDown={() => navigateWithConfirm(`/donate`)}
                    className="dropdown-item dropdown-item-highlight"
                  >
                    Donate
                  </a>
                </div>
              )}
            {activeCompanyId && (
              <div>
                <div>
                  <a
                    href="#"
                    onMouseDown={() =>
                      navigateWithConfirm(`/company/${activeCompanyId}`)
                    }
                    className="dropdown-item"
                  >
                    View Profile
                  </a>
                </div>
                <a
                  href="#"
                  onMouseDown={() =>
                    navigateWithConfirm(
                      `/admin/company/${activeCompanyId}/profile`,
                    )
                  }
                  className="dropdown-item"
                >
                  Edit Profile
                </a>
                {activeEntity.modules?.includes('EMPLOYEE') && (
                  <a
                    href="#"
                    onMouseDown={() =>
                      navigateWithConfirm(`/admin/company/${activeCompanyId}`)
                    }
                    className="dropdown-item"
                  >
                    {isGroup ? 'Members' : 'Employees'}
                  </a>
                )}
                {activeEntity.modules?.includes('REPORTING') && (
                  <a
                    onMouseDown={() =>
                      navigateWithConfirm(
                        `/admin/company/${activeCompanyId}/reporting`,
                      )
                    }
                    className="dropdown-item"
                  >
                    Reporting
                  </a>
                )}
                {activeEntity.modules?.includes('MATCH') && (
                  <a
                    onMouseDown={() =>
                      navigateWithConfirm(
                        `/admin/company/${activeCompanyId}/matching`,
                      )
                    }
                    className="dropdown-item"
                  >
                    Matching
                  </a>
                )}
                {activeEntity.modules?.includes('GIFT') && (
                  <a
                    onMouseDown={() =>
                      navigateWithConfirm(
                        `/admin/company/${activeCompanyId}/gifts`,
                      )
                    }
                    href="#"
                    className="dropdown-item"
                  >
                    Gifts
                  </a>
                )}
                {activeEntity.modules?.includes('EVENT') && (
                  <a
                    onMouseDown={() =>
                      navigateWithConfirm(
                        `/admin/company/${activeCompanyId}/cauzes`,
                      )
                    }
                    className="dropdown-item"
                  >
                    Cauzes
                  </a>
                )}
                {activeEntity.modules?.includes('REPORTING') && (
                  <a
                    onMouseDown={() =>
                      navigateWithConfirm(
                        `/admin/company/${activeCompanyId}/payroll`,
                      )
                    }
                    href="#"
                    className="dropdown-item"
                  >
                    Payroll
                  </a>
                )}
                <a
                  onMouseDown={() =>
                    navigateWithConfirm(
                      `/admin/company/${activeCompanyId}/wallet`,
                    )
                  }
                  href="#"
                  className="dropdown-item wallet"
                >
                  Wallet
                </a>
              </div>
            )}
            {activeCharityId && (
              <div>
                <a
                  href="#"
                  onMouseDown={() =>
                    navigateWithConfirm(`/admin/charity/${activeCharityId}`)
                  }
                  className="dropdown-item"
                >
                  Donations
                </a>
                <a
                  href="#"
                  onMouseDown={() =>
                    navigateWithConfirm(`/charity/${activeCharityId}`)
                  }
                  className="dropdown-item"
                >
                  View Profile
                </a>
                <a
                  onMouseDown={() =>
                    navigateWithConfirm(
                      `/admin/charity/${activeCharityId}/profile`,
                    )
                  }
                  className="dropdown-item"
                >
                  Edit Profile
                </a>
                <a
                  onMouseDown={() =>
                    navigateWithConfirm(
                      `/admin/charity/${activeCharityId}/cauzes`,
                    )
                  }
                  className="dropdown-item"
                >
                  Cauzes
                </a>
                <a
                  onMouseDown={() =>
                    navigateWithConfirm(`/admin/charity/${activeCharityId}/faq`)
                  }
                  className="dropdown-item"
                >
                  FAQ
                </a>
              </div>
            )}
            {activeInfluencerId && (
              <div>
                <a
                  href="#"
                  onMouseDown={() =>
                    navigateWithConfirm(
                      `/admin/influencer/${activeInfluencerId}`,
                    )
                  }
                  className="dropdown-item"
                >
                  Dashboard
                </a>
                <a
                  href="#"
                  onMouseDown={() =>
                    navigateWithConfirm(`/profile/${activeInfluencerId}`)
                  }
                  className="dropdown-item"
                >
                  View Profile
                </a>
                <a
                  onMouseDown={() =>
                    navigateWithConfirm(
                      `/admin/user/${activeInfluencerId}/profile`,
                    )
                  }
                  className="dropdown-item"
                >
                  Edit Profile
                </a>
                <a
                  href="#"
                  onMouseDown={() =>
                    navigateWithConfirm(
                      `/admin/influencer/${activeInfluencerId}/cauzes`,
                    )
                  }
                  className="dropdown-item"
                >
                  Cauzes
                </a>
                <a
                  href="#"
                  onMouseDown={() =>
                    navigateWithConfirm(
                      `/admin/influencer/${activeInfluencerId}/gifts`,
                    )
                  }
                  className="dropdown-item"
                >
                  Gifts
                </a>
                <a
                  onMouseDown={() =>
                    navigateWithConfirm(
                      `/admin/user/${activeInfluencerId}/wallet`,
                    )
                  }
                  href="#"
                  className="dropdown-item wallet"
                >
                  Wallet
                </a>
              </div>
            )}
            {activeUserId && (
              <div>
                <div>
                  <a
                    href="#"
                    onMouseDown={() =>
                      navigateWithConfirm(`/profile/${activeUserId}`)
                    }
                    className="dropdown-item"
                  >
                    View Profile
                  </a>
                </div>
                <div>
                  <a
                    href="#"
                    onMouseDown={() =>
                      navigateWithConfirm(
                        `/admin/user/${activeUserId}/activity`,
                      )
                    }
                    className="dropdown-item"
                  >
                    View Activity
                  </a>
                </div>
                <a
                  href="#"
                  onMouseDown={(ev) => {
                    ev.preventDefault();
                    _setIsOpen(false);
                    uiStore.openModal('SEND_GIFTS', {
                      giftType: 'USER_GIFT',
                      userId: activeUserId,
                    });
                  }}
                  className="dropdown-item"
                >
                  Send Gifts
                </a>
                <a
                  onMouseDown={() =>
                    navigateWithConfirm(`/admin/user/${activeUserId}/wallet`)
                  }
                  href="#"
                  className="dropdown-item wallet"
                >
                  Wallet
                </a>
              </div>
            )}
            {profileStore.data.sysAdmin && (
              <div>
                <a
                  href="#"
                  onMouseDown={() => {
                    navigateWithConfirm(`/admin/companies`);
                    profileStore.clearActiveEntity();
                  }}
                  className="dropdown-item"
                >
                  Companies
                </a>
                <a
                  href="#"
                  onMouseDown={() => {
                    navigateWithConfirm(`/admin/charities`);
                    profileStore.clearActiveEntity();
                  }}
                  className="dropdown-item"
                >
                  Charities
                </a>
                <a
                  href="#"
                  onMouseDown={() => {
                    navigateWithConfirm(`/admin/influencers`);
                    profileStore.clearActiveEntity();
                  }}
                  className="dropdown-item"
                >
                  Influencers
                </a>
                <a
                  href="#"
                  onMouseDown={() => {
                    navigateWithConfirm(`/admin/users`);
                    profileStore.clearActiveEntity();
                  }}
                  className="dropdown-item"
                >
                  Users
                </a>
              </div>
            )}
          </div>
          {!hideContextSelector &&
            (profileStore.availableUserEntities.length >= 1 ||
              profileStore.groupRoles.length >= 1) && (
              <div>
                {!isEmpty(
                  profileStore.activeEntity &&
                  profileStore.availableUserEntities.length > 1,
                ) && <hr className="dropdown-divider" />}
                {profileStore.availableUserEntities.length > 1 && (
                  <Fragment>
                    <hr className="dropdown-divider" />
                    <a
                      className="dropdown-title flex-row"
                      style={{ fontSize: '1.0rem' }}
                    >
                      My Accounts
                    </a>
                  </Fragment>
                )}
                {profileStore.availableUserEntities.map((context, index) =>
                  index === 0 ? null : (
                    <a
                      key={`${index}-${context.id}`}
                      style={{ paddingLeft: '2rem' }}
                      className="dropdown-item flex-row"
                      onMouseDown={(_e) => {
                        if (redirectAfterContextSelect) {
                          navigateWithConfirm(
                            getAdminUrl(
                              profileStore.availableUserEntities[index],
                            ),
                          );
                        } else {
                          profileStore.setActiveEntity(index, true);
                        }
                      }}
                      onTouchEnd={(_e) => {
                        if (redirectAfterContextSelect) {
                          navigateWithConfirm(
                            getAdminUrl(
                              profileStore.availableUserEntities[index],
                            ),
                          );
                        } else {
                          profileStore.setActiveEntity(index, true);
                        }
                      }}
                    >
                      <Avatar sm avatar={context.avatar} />
                      {context.name}
                    </a>
                  ),
                )}
                {profileStore.groupRoles.length >= 1 && (
                  <Fragment>
                    <hr className="dropdown-divider" />
                    <a
                      className="dropdown-title flex-row"
                      style={{ fontSize: '1.0rem' }}
                    >
                      My Groups
                    </a>
                  </Fragment>
                )}
                {profileStore.groupRoles.map((entity, index) => (
                  <a
                    key={`${index}-${entity.id}`}
                    className="dropdown-item flex-row"
                    style={{ paddingLeft: '2rem' }}
                    onMouseDown={(_e) => {
                      navigateWithConfirm(`/company/${entity.id}`);
                    }}
                    onTouchEnd={(_e) => {
                      navigateWithConfirm(`/company/${entity.id}`);
                    }}
                  >
                    <Avatar sm avatar={entity.avatar} /> {entity.name}
                  </a>
                ))}
                <hr className="dropdown-divider" />
                {profileStore.availableUserEntities.length > 1 && (
                  <a
                    className="dropdown-item flex-row"
                    onMouseDown={(_e) => {
                      if (profileStore.data.sysAdmin) {
                        navigateWithConfirm('/admin/companies').then(() =>
                          profileStore.clearActiveEntity(),
                        );
                      } else {
                        navigateWithConfirm('/donate').then(() =>
                          profileStore.clearActiveEntity(),
                        );
                      }
                    }}
                  >
                    <Avatar sm avatar={profileStore.data.avatar} />
                    Use as {profileStore.data.firstName}
                  </a>
                )}
              </div>
            )}
          <a
            className="dropdown-item logout"
            onMouseDown={(_e) => {
              navigateWithConfirm('/logout', {}, () =>
                onLogoutClick(authStore, profileStore, eventStore),
              );
            }}
          >
            Log Out
          </a>
        </div>
      </div>
    </div>
  );
};

UserDropDown.propTypes = {
  className: PropTypes.string,
  authStore: PropTypes.shape({
    isAuthenticated: PropTypes.bool.isRequired,
    username: PropTypes.string.isRequired,
  }).isRequired,
  profileStore: PropTypes.shape({
    activeEntity: PropTypes.object,
    availableUserEntities: PropTypes.array,
    setActiveEntity: PropTypes.func,
  }).isRequired,
  eventStore: PropTypes.shape({
    onUnmount: PropTypes.func.isRequired,
  }).isRequired,
  uiStore: PropTypes.object.isRequired,
  companyReportingStore: PropTypes.object,
  activeEntity: PropTypes.object,
  lowerBackdrop: PropTypes.bool,
  hideContextSelector: PropTypes.bool,
  redirectAfterContextSelect: PropTypes.bool,
};

export default inject(
  'authStore',
  'uiStore',
  'profileStore',
  'eventStore',
  'companyReportingStore',
)(observer(UserDropDown));
