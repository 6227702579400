import { useEffect } from 'react';

const toggleSelection = (key, shift, row, selectedItems, setSelectedItems) => {
  let selection = [...selectedItems];
  const keyIndex = selection.indexOf(key);
  if (keyIndex >= 0) {
    // remove it using destructing
    selection = [
      ...selection.slice(0, keyIndex),
      ...selection.slice(keyIndex + 1),
    ];
  } else {
    selection.push(key);
  }
  setSelectedItems(selection);
};

const toggleAll = (
  tableData,
  tableRef,
  selectedItems,
  setSelected,
  keyField,
) => {
  // we need to get at the internals of ReactTable
  // the 'sortedData' property contains the currently accessible records based on the filter and sort
  const currentRecords = getWrappedFilteredRows(tableRef);

  const selectAll =
    tableData.length !== selectedItems.length &&
    currentRecords.length !== selectedItems.length;
  // This catches the case where the user is selectingAll, then deselectingAll, from a filter

  const selection = [];

  if (selectAll) {
    currentRecords.forEach(item => {
      selection.push(`select-${item._original[keyField]}`);
    });
  }
  setSelected(selection);
};

const useMultiSelect = (
  selectAllRef,
  selectAllMobileRef,
  selectedItems,
  tableData,
) => {
  useEffect(() => {
    selectAllRef.current.indeterminate =
      selectedItems.length > 0 && selectedItems.length < tableData.length;
    selectAllMobileRef.current.indeterminate =
      selectedItems.length > 0 && selectedItems.length < tableData.length;
  }, [
    selectAllMobileRef,
    selectAllRef,
    selectedItems.length,
    tableData.length,
  ]);
};

const getWrappedFilteredRows = tableRef => {
  if (tableRef.current) {
    // we need to get at the internals of ReactTable
    const wrappedInstance = tableRef?.current?.getWrappedInstance();
    // the 'sortedData' property contains the currently accessible records based on the filter and sort
    return wrappedInstance.getResolvedState().sortedData;
  }
  return [];
};

const getUnwrappedFilteredRows = tableRef => {
  if (tableRef.current) {
    return tableRef.current.getResolvedState().sortedData;
  }
  return [];
};

const getGiftTotals = rows => {
  let total = 0;
  let remaining = 0;
  rows.forEach(row => {
    if (row.status === 'Redeemed') {
      total += row.amount;
      remaining += row.remaining;
    } else if (row.status === 'Waiting') {
      total += row.amount;
    }
  });

  return {
    filteredTotalGifted: total,
    filteredRemainingGifted: remaining,
  };
};

const getCharityTotals = rows => {
  let filteredTotalGood = 0;
  let filteredMatched = 0;
  let filteredDonations = 0;

  rows.forEach(row => {
    filteredTotalGood += row.totalDonated;
    filteredMatched += row.matched;
    filteredDonations += row.donationCount;
  });

  return {
    filteredTotalGood,
    filteredMatched,
    filteredDonations,
  };
};

export {
  toggleSelection,
  toggleAll,
  useMultiSelect,
  getWrappedFilteredRows,
  getUnwrappedFilteredRows,
  getGiftTotals,
  getCharityTotals,
};
