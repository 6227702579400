import React from 'react';
import { observer, inject } from 'mobx-react';

import withUserContext from 'behaviors/withUserContext';
import useCompanyProfileById from 'hooks/useCompanyProfileById';
import { normalizeContext } from 'util/contextUtils';

import EntityProfile from 'components/EntityProfile/EntityProfile';
import convertFeedToV1 from 'util/feed/convertFeedToV1';
import mergeFeedItems from 'util/feed/mergeFeedItems';

const CompanyProfileView = ({
  profileStore,
  companyStore,
  uiStore,
  authStore,
  id,
  activeEntity,
}) => {
  useCompanyProfileById({ id, userContext: activeEntity.userContext });
  const companyData = companyStore.companies.get(id) || {};
  const feed = companyStore.feeds.get(id);
  const members = companyStore.members.get(id) || [];
  const events = companyStore.events.get(id);
  const charities = companyStore.charities.get(id);
  const groupMember =
    companyData &&
    activeEntity.entityType === 'USER' &&
    (companyData.groupType === 'EXTERNAL_GROUP' ||
      companyData.groupType === 'INTERNAL_GROUP') &&
    profileStore.data.roles.find(
      (role) =>
        role.entity.id === companyData.id &&
        role.entity.entityType === 'COMPANY',
    );
  const isSelf =
    companyData.isSelf ||
    (activeEntity?.entityType === 'COMPANY' &&
      activeEntity?.id?.toString() === companyData?.id?.toString());
  const isMember = members.find(
    (companyMember) =>
      companyMember.id.toString() === activeEntity?.id?.toString() &&
      activeEntity?.entityType === 'USER',
  );

  const showMembers = Boolean(
    members &&
      members?.length &&
      (isMember || companyData.groupType === 'EXTERNAL_GROUP' || isSelf),
  );

  const onFollow = () => {
    companyStore.follow({
      follow: !companyData.isSelfFollowing,
      actorContext: normalizeContext(activeEntity.userContext),
      companyId: id,
    });
  };

  const onLike = ({ feedId }) => {
    const feedItem = feed?.find(
      (feedItem) => feedItem?.activityPurchase?.id === feedId,
    );

    if (feedItem) {
      companyStore.like({
        like: !feedItem.currentEntityLiked,
        id: feedId,
        purchaseId: feedItem.activityPurchase?.id,
        companyId: id,
        userContext: normalizeContext(profileStore.activeEntity.userContext),
      });
    }
  };

  const feedLoadMore = () => {
    companyStore.loadMore({ id });
  };

  const onRearrange = async (newCharities) => {
    if (id) {
      companyStore.setCharities({ followerId: id, charities: newCharities });
    }

    await Promise.all(
      newCharities.map(async (charity, index) => {
        companyStore.updateFollow({
          charityId: charity.id,
          priority: index,
        });
      }),
    );
  };

  return (
    <EntityProfile
      featuredProjects={charities}
      loading={companyData?.loading}
      entityData={{
        ...companyData,
        bio: companyData.description,
      }}
      isAuthenticated={authStore.isAuthenticated}
      onFollow={onFollow}
      onLike={onLike}
      entityId={id}
      onRearrange={onRearrange}
      isSelf={isSelf}
      groupMember={groupMember}
      uiStore={uiStore}
      events={events}
      eventsLoading={companyStore.eventsLoading}
      feedLoading={companyStore.feedLoading}
      feedLoadMore={feedLoadMore}
      feedLoadingMore={companyStore.feedLoadingMore}
      feed={mergeFeedItems(feed)?.map(convertFeedToV1)}
      companyMembers={members.map((member) => ({
        ...member,
        entityType: 'USER',
      }))}
      entityType="COMPANY"
      isVerified
      hideFollowerCount
      showMembers={showMembers}
      store={{
        ...companyStore,
        follow: ({ follow, targetContext, targetId }) => {
          companyStore.followEntity({
            follow,
            companyId: id,
            targetContext: {
              ...targetContext,
              userId: targetId,
            },
            actorContext: {
              ...activeEntity.userContext,
            },
          });
        },
      }}
    />
  );
};

export default withUserContext(
  inject(
    'profileStore',
    'companyStore',
    'authStore',
    'uiStore',
  )(observer(CompanyProfileView)),
);
