import React from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

const Key = ({ text, value, color, large }) => (
  <div
    className={classnames(
      'flex-column',
      large ? 'dashboard-chart-key-large' : 'dashboard-chart-key',
    )}
  >
    <div className="flex-row flex-align-center">
      <span
        className={classnames(
          large ? 'dashboard-chart-dot-large' : 'dashboard-chart-dot',
        )}
        style={{ backgroundColor: color }}
      />
      <p>
        {text} ({value})
      </p>
    </div>
  </div>
);

Key.propTypes = {
  text: PropTypes.string.isRequired,
  value: PropTypes.number,
  color: PropTypes.string,
  large: PropTypes.bool,
};

const ChartKeys = ({ data, column, large }) => (
  <div
    className={classnames(
      'dashboard-keys',
      'flex-expand',
      'flex-space-around',
      column ? 'flex-column' : 'flex-row',
    )}
  >
    {data.map(key => (
      <Key
        key={key.title}
        text={key.title}
        value={key.value}
        large={large}
        color={key.color}
      />
    ))}
  </div>
);

ChartKeys.propTypes = {
  data: PropTypes.array,
  column: PropTypes.bool,
  large: PropTypes.bool,
};

export default ChartKeys;
