import React from 'react';
import CauzeButton from 'components/CauzeButton/CauzeButton';
import useKeyboardEvent from '../../../hooks/useKeyboardEvent.js';
import classnames from 'classnames';

const TermsOfUseModal = ({ onToggleClose, action, isOpen }) => {
  useKeyboardEvent('Escape', () => {
    onToggleClose(false);
  });

  return (
    <div
      className={classnames('modal terms-of-use-modal', {
        'is-active': isOpen,
      })}
    >
      <div
        className="modal-background"
        onClick={() => {
          onToggleClose(false);
        }}
      ></div>
      <div className="modal-card ">
        <header className="modal-card-head">
          <p className="modal-card-title">TERMS OF SERVICE</p>
        </header>
        <section className="modal-card-body">
          <div>(Last updated: May 18, 2022)</div>
          <div>WELCOME TO THE CAUZE, INC. WEBSITE (THE "SITE")</div>
          <div>
            PLEASE READ THESE TERMS OF USE CAREFULLY. BY ACCESSING OR USING THE
            SITE, YOU REPRESENT THAT YOU ARE OF LEGAL AGE TO FORM A BINDING
            CONTRACT WITH CAUZE, INC. AND YOU AGREE TO BE BOUND BY THE TERMS AND
            CONDITIONS DESCRIBED BELOW (THE “TERMS”). IF YOU DO NOT AGREE TO ALL
            OF THESE TERMS, DO NOT USE THIS SITE. The Site is owned and operated
            by Cauze, Inc. (“we,” “us,” etc. or the “Company”). YOU ASSUME ALL
            RISKS AND ALL COSTS ASSOCIATED WITH YOUR USE OF THE SITE, INCLUDING,
            WITHOUT LIMITATION, ANY INTERNET ACCESS FEES, BACK-UP EXPENSES,
            COSTS INCURRED FOR THE USE OF YOUR DEVICE AND PERIPHERALS, AND ANY
            DAMAGE TO ANY EQUIPMENT, SOFTWARE, OR INFORMATION. The Company
            reserves the right to modify, suspend or remove the Site or any part
            of the Site, at any time or from time to time, with or without prior
            notice to you. You agree that we will not be liable to you or any
            third party for any modification or termination of the Site. You
            acknowledge that we have no express or implied obligation to provide
            or continue to provide the Site, or any part of the Site, nor to
            provide any maintenance, technical or other support for the Site.
            You acknowledge that we may, at any time, restrict, limit, suspend
            or terminate your access to the Site or commence charging fees for
            use of the Site.
          </div>
          <div className="subheader">SERVICES</div>
          <div>
            We provide administrative, supportive, and technical services to
            One4All Charitable Fund, a 501(c)(3) public charity ("One4All")
            through a platform that allows Site users to create a donor advised
            fund ("DAF"), access publicly-available information regarding
            charities, create and manage profiles in connection with their
            accounts, and post comments and use other communications tools
            provided on the Site (the "Services"). The Company assumes no
            responsibility or liability for the timeliness, accuracy, deletion,
            or mis-delivery of, or the failure to store, any Site content, user
            information or settings or communications tools. You bear all such
            risks. Likewise, we reserve the right at any time to modify or
            discontinue any Services, temporarily or permanently, with or
            without notice. You agree that we will not be liable to you or to
            any third party for any modification, suspension, or discontinuance
            of the Services.
          </div>
          <div className="subheader">PROPRIETARY RIGHTS</div>
          <div>
            Unless otherwise indicated, all of the content displayed on this
            Site, including, but not limited to, any and all text, graphics,
            data, images, illustrations, sound, video, audio, software, and the
            selection and arrangement of these items, are owned by the Company
            or its licensors and protected by copyright, trademark, trade dress,
            or other intellectual property rights. All user content and
            feedback, including messages posted to the Site, may be edited,
            published, or otherwise used (or removed) by us. We do not claim
            ownership of these items, but by submitting or posting them, you
            grant us a non-exclusive, royalty-free, perpetual, irrevocable, and
            sublicenseable right to copy, modify, publish, translate, create
            derivative works from, perform, display and otherwise use such
            content (including your submitted name, likeness, and/or voice) on
            this Site or in any other media for any purpose related to our
            services or products, and you waive any right of publicity or
            privacy you may have in connection with such uses.
          </div>
        </section>
        <footer className="modal-card-foot">
          <CauzeButton
            className="button is-primary is-small"
            onClick={() => {
              action && action();
              onToggleClose(false);
            }}
          >
            Close
          </CauzeButton>
        </footer>
      </div>
    </div>
  );
};

export default TermsOfUseModal;
