import React from 'react';
import { observer, inject } from 'mobx-react';
import { navigate } from '@reach/router';

const LoginForm = ({ username, setUsername, password, setPassword }) => (
  <fieldset>
    <legend>partner login</legend>
    <div className="form-row create-account-email">
      <input
        id="email"
        placeholder="Email or Username"
        type="text"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
      />
    </div>
    <div className="form-row create-account-password">
      <input
        id="password"
        placeholder="Password"
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
    </div>
    <p
      className="forgot-password flex-start"
      onClick={(e) => {
        e.preventDefault();
        navigate(`/resetpassword`);
      }}
    >
      Forgot Password
    </p>
  </fieldset>
);

export default inject(
  'authStore',
  'eventStore',
  'profileStore',
)(observer(LoginForm));
