import React from 'react';
import PropTypes from 'prop-types';

import EntityListItem from './EntityListItem/EntityListItem';
import { convertEntityTypeToId, normalizeContext } from 'util/contextUtils';

const EntityList = ({
  showFollow,
  entities,
  buttonLabel,
  store,
  userContext,
  pageContext,
  setUnfollow,
  isAuthenticated,
  confirmMessage,
}) => (
  <div className="entity-list-container">
    {entities &&
      entities.map((entity, index) => {
        const entityId = entity.id;
        const entityEntityType = entity.entityType;
        const targetContext = normalizeContext(
          convertEntityTypeToId({ id: entityId, entityType: entityEntityType }),
        );

        if (entity?.isActive === undefined && entity?.user) {
          entity.isActive = entity.user.isActive;
          entity.unclaimed = entity.user.unclaimed;
        }

        return (
          <EntityListItem
            entity={entity}
            showFollow={showFollow}
            buttonLabel={buttonLabel}
            store={store}
            userContext={userContext || { entityType: 'USER' }}
            pageContext={pageContext}
            targetContext={targetContext}
            targetId={entity.id}
            key={index}
            setUnfollow={setUnfollow}
            index={index}
            isAuthenticated={isAuthenticated}
            confirmMessage={confirmMessage}
          />
        );
      })}
  </div>
);

EntityList.propTypes = {
  entities: PropTypes.array,
  showFollow: PropTypes.bool,
  buttonLabel: PropTypes.string,
  store: PropTypes.object.isRequired,
  userContext: PropTypes.object.isRequired,
};

export default EntityList;
