import React, { useMemo, useState } from 'react';
import classnames from 'classnames';
import { useStripe } from '@stripe/react-stripe-js';

import CauzeButton from 'components/CauzeButton/CauzeButton';

import useKeyboardEvent from 'hooks/useKeyboardEvent.js';
import walletStore from 'stores/WalletStore';
import useWallet from 'hooks/useWallet';

const defaultAchData = {
  country: 'us',
  currency: 'usd',
  routing_number: '',
  account_number: '',
  account_holder_name: '',
  account_holder_type: 'individual',
};

const AddACHForm = ({ activeEntity, onToggleClose }) => {
  const companyId =
    activeEntity?.entityType !== 'USER' ? activeEntity.id : undefined;
  const userId =
    activeEntity?.entityType === 'USER' ? activeEntity.id : undefined;
  const [userContext] = useState({ companyId, userId });
  const [achData, setAchData] = useState(defaultAchData);
  const [achSubmitting, setAchSubmitting] = useState(false);

  const stripe = useStripe();
  walletStore.setStripeObject(stripe);
  useWallet({ userContext });

  const handleSubmit = (e) => {
    e.preventDefault();
    setAchSubmitting(true);
    walletStore.addAch({ achData, userContext }).then((success) => {
      if (success) {
        onToggleClose(false);
        setAchData(defaultAchData);
      }

      setAchSubmitting(false);
    });
  };

  return (
    <div>
      <form onSubmit={handleSubmit} autoComplete="off">
        <input type="hidden" name="source" />
        <div className="flex-column group">
          <label htmlFor="name">
            <span>Name on account</span>
          </label>
          <input
            id="name"
            className="input field"
            value={achData.account_holder_name}
            onChange={(e) =>
              setAchData({
                ...achData,
                account_holder_name: e.target.value,
              })
            }
          />
          <label htmlFor="type">
            <span>Type</span>
          </label>
          <select
            id="type"
            className="field"
            value={achData.account_holder_type}
            onChange={(e) =>
              setAchData({
                ...achData,
                account_holder_type: e.target.value,
              })
            }
          >
            <option value="individual">Individual</option>
            <option value="company">Company</option>
          </select>
          <label htmlFor="routing-number">
            <span>Routing number</span>
          </label>
          <input
            id="routing-number"
            className="input field"
            value={achData.routing_number}
            onChange={(e) =>
              setAchData({
                ...achData,
                routing_number: e.target.value,
              })
            }
          />
          <label htmlFor="account-number">
            <span>Account number</span>
          </label>
          <input
            id="account-number"
            className="input field"
            value={achData.account_number}
            onChange={(e) =>
              setAchData({
                ...achData,
                account_number: e.target.value,
              })
            }
          />
        </div>
        <div className="ach-detail mt-6 mb-4">
          Two small deposits will be made in your account under the name of{' '}
          ONE4ALL CHARITAB AMTS. When you see those two deposits, come back here
          to verify your account by entering the deposit amounts.
        </div>
        <div>
          <CauzeButton
            black
            tall={true}
            className="bg-black font-black is-medium is-fullwidth full-width border-none uppercase text-20 cursor-pointer"
            baseClass="cauze-button-donate"
            isSubmitting={achSubmitting}
            type="submit"
          >
            Send Verification
          </CauzeButton>
        </div>
        <div className="pt-2">
          <CauzeButton
            black
            tall={true}
            className="bg-black font-black is-medium is-fullwidth full-width border-none uppercase text-20 cursor-pointer"
            baseClass="cauze-button-donate"
            onClick={() => onToggleClose(false)}
          >
            Close
          </CauzeButton>
        </div>
      </form>
    </div>
  );
};

const AddPaymentModal = ({
  onToggleClose,
  isOpen,
  paymentType,
  activeEntity,
}) => {
  useKeyboardEvent('Escape', () => {
    onToggleClose(false);
  });

  const paymentTitle = useMemo(() => {
    if (`${paymentType}`.toLowerCase() === 'ach') {
      return 'ADD ACH PAYMENT DETAILS';
    }

    if (`${paymentType}`.toLowerCase() === 'cc') {
      return 'ADD CARD PAYMENT DETAILS';
    }

    return 'ADD PAYMENT DETAILS';
  }, [paymentType]);

  return (
    <div
      className={classnames('modal add-payment-modal', {
        'is-active': isOpen,
      })}
    >
      <div
        className="modal-background "
        onClick={() => {
          onToggleClose(false);
        }}
      ></div>
      <div className="modal-card ">
        <header className="modal-card-head">
          <p className="modal-card-title">{paymentTitle}</p>
        </header>
        <section className="modal-card-body">
          <AddACHForm
            activeEntity={activeEntity}
            onToggleClose={onToggleClose}
          />
        </section>
      </div>
    </div>
  );
};

export default AddPaymentModal;
