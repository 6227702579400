/* eslint-disable no-underscore-dangle */

global.NODE_ENV = Object.assign({}, process.env).NODE_ENV;
global.__DEV__ = process.env.REACT_APP_IS_DEV;

if (global.__DEV__) {
  try {
    console.warn('REACT_ENV: ', global.__DEV__);
    console.warn('NODE_ENV', Object.assign({}, process.env).NODE_ENV);
  } catch (e) {
    console.warn('Transform Env Variable Plugin Down!!', e);
  }
}

const baseUrl = process.env.REACT_APP_BASE_URL;
const api2BaseUrl = process.env.REACT_APP_API2_BASE_URL;
const frontendUrl = process.env.REACT_APP_FRONTEND_URL;
const isLocal = process.env.REACT_APP_IS_LOCAL === 'true';
const isDev = process.env.REACT_APP_IS_DEV === 'true';
const stripeKey = process.env.REACT_APP_STRIPE_KEY;
const paypalKey = process.env.REACT_APP_PAYPAL_KEY;
const GAKey = process.env.REACT_APP_GA_KEY;
const branchKey = process.env.REACT_APP_BRANCH_KEY;
const hotJarID = process.env.REACT_APP_HOTJAR_ID;
const hotJarSnippetVersion = process.env.REACT_APP_HOTJAR_SNIPPET_VERSION;
const mixPanelProjToken = process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN;

const config = {
  DEBUG: global.__DEV__,
  API_ROOT: `${baseUrl}`,
  UPLOAD_ROOT: `${baseUrl}/upload`,
  OAUTH_ROOT: `${baseUrl}/oauth`,
  GRAPH_ROOT: `${baseUrl}/graphql`,
  API2_GRAPH_ROOT: `${api2BaseUrl}/graphql`,
  WS_ROOT: `${api2BaseUrl.split('//')[1]}/ws`,
  FRONTEND_URL: frontendUrl,
  STRIPE_KEY: stripeKey,
  PAYPAL_KEY: paypalKey,
  GA_KEY: GAKey,
  IS_LOCAL: isLocal,
  IS_DEV: isDev,
  IS_LOCAL_OR_DEV: isLocal || isDev,
  BRANCH_KEY: branchKey,
  HOTJAR_ID: hotJarID,
  HOTJAR_SNIPPET_VERSION: hotJarSnippetVersion,
  MIXPANEL_PROJECT_TOKEN: mixPanelProjToken,
};

if (global.__DEV__) {
  console.log('config', config);
}

Object.keys(config).forEach(key => (global[key] = config[key]));

export default config;
