import uiStore from '../stores/UiStore';

const iosLink =
  'https://apps.apple.com/us/app/cauze-charitable-giving/id1297994796';
const playLink =
  'https://play.google.com/store/apps/details?id=com.cauze.Cauze';
const webLink = 'https://www.cauze.com/download';

const getMobileOperatingSystem = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/android/i.test(userAgent)) {
    return 'ANDROID';
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'IOS';
  }

  return null;
};

const getAppLink = () => {
  // if there is a userToken, and on mobile, use branch link, if on desktop,
  // and user is rdeeming usertoken take to /download page for custom message
  const tokenLink = uiStore.savedUiState?.userToken?.token
    ? `https://cauze.app.link/token/${uiStore.savedUiState.userToken.token}?` +
      `$fallback_url=https%3A%2F%2Fwww.cauze.com%2Fdownload%3Fpath%3Dtoken%2F` +
      `${uiStore.savedUiState.userToken.token}`
    : null;
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/android/i.test(userAgent)) {
    if (tokenLink) {
      return tokenLink;
    }
    return playLink;
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    if (tokenLink) {
      return tokenLink;
    }
    return iosLink;
  }
  if (tokenLink) {
    return '/download';
  }

  return webLink;
};

const getIosLink = () => {
  const tokenLink = uiStore.savedUiState?.userToken?.token
    ? `https://cauze.app.link/token/${uiStore.savedUiState.userToken.token}?` +
    `$fallback_url=https%3A%2F%2Fwww.cauze.com%2Fdownload%3Fpath%3Dtoken%2F` +
    `${uiStore.savedUiState.userToken.token}`
    : null;

  if (tokenLink) {
    return tokenLink;
  }

  return playLink;
};

const getAndroidLink = () => {
  const tokenLink = uiStore.savedUiState?.userToken?.token
    ? `https://cauze.app.link/token/${uiStore.savedUiState.userToken.token}?` +
    `$fallback_url=https%3A%2F%2Fwww.cauze.com%2Fdownload%3Fpath%3Dtoken%2F` +
    `${uiStore.savedUiState.userToken.token}`
    : null;

  if (tokenLink) {
    return tokenLink;
  }
  return iosLink;
};

const isMobile = () => {
  const mobileOs = getMobileOperatingSystem();
  if (mobileOs === 'ANDROID' || mobileOs === 'IOS') {
    return true;
  }
  return false;
};

export {
  isMobile,
  getMobileOperatingSystem,
  getAppLink,
  getIosLink,
  getAndroidLink,
};
