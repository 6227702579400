import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';

import useCharityAdmin from '../../hooks/useCharityAdmin';
import withUserContext from '../../behaviors/withUserContext';
import withAuthRequired from '../../behaviors/withAuthRequired';

import Navbar from 'components/page/Navbar/Navbar';
import Button from 'components/Button/Button';

import charityProfileImage from '../../assets/images/screens/charity-profile.png';
import charityPlaceholderImage from '../../assets/images/icons/placeholder/placeholder-charity@3x.png';

const handleCharityLogoChange = (e, store) => {
  e.preventDefault();

  let reader = new FileReader();
  let file = e.target.files[0];

  reader.onloadend = () => {
    store.setCharityLogoFile(file);
    store.setCharityLogoUrl(reader.result);
  };

  reader.readAsDataURL(file);
};

const handleCharityHeroChange = (e, store) => {
  e.preventDefault();

  let reader = new FileReader();
  let file = e.target.files[0];

  reader.onloadend = () => {
    store.setCharityHeroFile(file);
    store.setCharityHeroUrl(reader.result);
  };

  reader.readAsDataURL(file);
};

const CharityProfileView = ({ charityAdminStore, charityId }) => {
  useCharityAdmin(charityId);

  let charityLogoPreview = null;
  if (charityAdminStore.charityLogoUrl) {
    charityLogoPreview = (
      <img
        className="profile-preview-image"
        src={charityAdminStore.charityLogoUrl}
      />
    );
  } else {
    charityLogoPreview = (
      <img className="profile-preview-image" src={charityPlaceholderImage} />
    );
  }

  let charityHeroPreview = null;
  if (charityAdminStore.charityLogoUrl) {
    charityHeroPreview = (
      <img
        className="hero-preview-image"
        src={charityAdminStore.charityHeroUrl}
      />
    );
  } else {
    charityHeroPreview = (
      <img className="hero-preview-image" src={charityPlaceholderImage} />
    );
  }

  return (
    <div className="charity-admin charity-profile flex-column flex-expand">
      <Navbar />
      <section>
        <div className="page-header container flex-row">
          <h1 className="title">Update Profile</h1>
        </div>
      </section>
      <section className="page-body">
        <div className="container">
          <hr />
          <h2 className="subtitle is-size-3">Your Profile Page</h2>
          <p className="section-description" style={{ maxWidth: '45rem' }}>
            With every registered 501(c)3 in Cauze, it’s important to update
            your profile so givers know exactly why they should support your
            work! Start by adding a logo and mission statement.
          </p>
          <div className="columns">
            <div className="column">
              <h3 className="subtitle image-header">Logo</h3>
              <p className="section-description">
                Ideal image size is 500x500 pixels.
              </p>
              <div className="image-preview-container">
                {charityLogoPreview}
              </div>
              <div
                style={{ flex: 1, display: 'flex' }}
                className="flex-column flex-space-around"
              >
                <form
                  className="image-form"
                  onSubmit={(e) => {
                    e.preventDefault();
                    charityAdminStore.submitCharityLogo();
                  }}
                >
                  <input
                    className="fileInput"
                    type="file"
                    onChange={(e) =>
                      handleCharityLogoChange(e, charityAdminStore)
                    }
                  />
                  <div>
                    <Button
                      isSubmitting={charityAdminStore.isUploadingCharityLogo}
                      disabled={!charityAdminStore.charityLogoHasChanged}
                      className="is-small upload-button"
                      type="submit"
                      onClick={(e) => {
                        e.preventDefault();
                        charityAdminStore.submitCharityLogo();
                      }}
                    >
                      Save
                    </Button>
                  </div>
                </form>
              </div>
              <h3 className="subtitle image-header">Cover Image</h3>
              <p className="section-description">
                Ideal image size is 1600x900 pixels (16x9 aspect ratio).
              </p>
              <div className="image-preview-container">
                {charityHeroPreview}
              </div>
              <div
                style={{ flex: 1, display: 'flex' }}
                className="flex-column flex-space-around"
              >
                <form
                  className="image-form"
                  onSubmit={(e) => {
                    e.preventDefault();
                    charityAdminStore.submitCharityHero();
                  }}
                >
                  <input
                    className="fileInput"
                    type="file"
                    onChange={(e) =>
                      handleCharityHeroChange(e, charityAdminStore)
                    }
                  />
                  <div>
                    <Button
                      isSubmitting={charityAdminStore.isUploadingCharityHero}
                      disabled={!charityAdminStore.charityHeroHasChanged}
                      className="is-small upload-button"
                      type="submit"
                      onClick={(e) => {
                        e.preventDefault();
                        charityAdminStore.submitCharityHero();
                      }}
                    >
                      Save
                    </Button>
                  </div>
                </form>
              </div>
              <div className="flex-column flex-expand page-section">
                <h2 className="subtitle">Mission Statement</h2>
                <p className="section-description">
                  We encourage you to include information on impact (“$10
                  provides 1 free meal”) or highlight an area of need (“we’re
                  fundraising to fix the van!”). These calls to action mobilize
                  givers and help push them to get their communities involved!
                </p>
                <textarea
                  className="profile-textarea"
                  onChange={(ev) =>
                    charityAdminStore.setMissionText(ev.target.value)
                  }
                  placeholder="Describe your nonprofit's mission"
                  value={charityAdminStore.missionText}
                />
                <div>
                  <Button
                    isSubmitting={charityAdminStore.updatingMissionText}
                    disabled={!charityAdminStore.missionTextHasChanged}
                    className="button is-small save-button"
                    onClick={charityAdminStore.updateMissionText}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </div>
            <div className="column">
              <img src={charityProfileImage} className="app-preview-image" />
            </div>
          </div>
          <hr />
          <div
            className="flex-column page-section"
            style={{ maxWidth: '45rem' }}
          >
            <h2 className="subtitle">Website & Social Links</h2>
            <div className="flex-row flex-expand">
              <div className="url-name">Website:</div>
              <input
                className="url-textarea"
                onChange={(ev) =>
                  charityAdminStore.setSocialAccounts({
                    webUrl: ev.target.value,
                  })
                }
                placeholder="https://"
                value={charityAdminStore.socialAccounts.webUrl}
              />
            </div>
            <div className="flex-row flex-expand">
              <div className="url-name">Facebook:</div>
              <input
                style={
                  charityAdminStore.socialAccountsValid.facebookName === false
                    ? { borderColor: '#e60054' }
                    : {}
                }
                className="url-textarea"
                onChange={(ev) =>
                  charityAdminStore.setSocialAccounts({
                    facebookName: ev.target.value,
                  })
                }
                placeholder="username"
                value={charityAdminStore.socialAccounts.facebookName}
              />
            </div>
            <div className="flex-row flex-expand">
              <div className="url-name">Instagram:</div>
              <input
                style={
                  charityAdminStore.socialAccountsValid.instagramName === false
                    ? { borderColor: '#e60054' }
                    : {}
                }
                className="url-textarea"
                onChange={(ev) =>
                  charityAdminStore.setSocialAccounts({
                    instagramName: ev.target.value,
                  })
                }
                placeholder="username"
                value={charityAdminStore.socialAccounts.instagramName}
              />
            </div>
            <div className="flex-row flex-expand">
              <div className="url-name">Twitter:</div>
              <input
                className="url-textarea"
                style={
                  charityAdminStore.socialAccountsValid.twitterName === false
                    ? { borderColor: '#e60054' }
                    : {}
                }
                onChange={(ev) =>
                  charityAdminStore.setSocialAccounts({
                    twitterName: ev.target.value,
                  })
                }
                placeholder="username"
                value={charityAdminStore.socialAccounts.twitterName}
              />
            </div>
            <div className="flex-row flex-expand">
              <div className="url-name">YouTube Url:</div>
              <input
                style={
                  charityAdminStore.socialAccountsValid.youtubeUrl === false
                    ? { borderColor: '#e60054' }
                    : {}
                }
                className="url-textarea"
                onChange={(ev) =>
                  charityAdminStore.setSocialAccounts({
                    youtubeUrl: ev.target.value,
                  })
                }
                placeholder="https://"
                value={charityAdminStore.socialAccounts.youtubeUrl}
              />
            </div>
            <div>
              <Button
                isSubmitting={charityAdminStore.updatingSocialAccounts}
                disabled={
                  !charityAdminStore.socialAccountsHasChanged ||
                  !charityAdminStore.socialAccountsValid.instagramName ||
                  !charityAdminStore.socialAccountsValid.facebookName ||
                  !charityAdminStore.socialAccountsValid.twitterName ||
                  !charityAdminStore.socialAccountsValid.youtubeUrl
                }
                className="button is-small save-button"
                onClick={charityAdminStore.updateSocialAccounts}
              >
                Save
              </Button>
            </div>
          </div>
          <hr />
          <div className="flex-row flex-expand page-section">
            <div style={{ maxWidth: '45rem' }}>
              <h2 className="subtitle is-size-3">Donation</h2>
              <div className="columns">
                <div className="column">
                  <h3 className="subtitle">Custom Thank You - Post Donation</h3>
                  <p className="section-description">
                    Givers that give to your nonprofit will see this custom
                    message from you after they complete their donation. Seeing
                    a thank you message helps our givers feel the immediate
                    impact of their donation, gets them excited to share with
                    their friends, and they look forward to giving again.
                  </p>
                  <textarea
                    className="profile-textarea"
                    value={charityAdminStore.sayThanksText || ''}
                    placeholder="This will show to Cauze users when they give to you"
                    onChange={(ev) =>
                      charityAdminStore.setSayThanksText(ev.target.value)
                    }
                  />
                  <div>
                    <Button
                      isSubmitting={charityAdminStore.updatingSayThanksText}
                      disabled={!charityAdminStore.sayThanksTextHasChanged}
                      className="button is-small save-button"
                      onClick={charityAdminStore.updateSayThanksText}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

CharityProfileView.propTypes = {
  charityAdminStore: PropTypes.object.isRequired,
  charityId: PropTypes.object.isRequired,
};

export default withAuthRequired(
  withUserContext(inject('charityAdminStore')(observer(CharityProfileView))),
  { entityType: 'CHARITY' },
);
