import gql from 'graphql-tag';

const followMutation = gql`
  mutation follow($actor_context: UserContext, $target_context: UserContext) {
    follow(actorContext: $actor_context, targetContext: $target_context) {
      follower {
        id
        isSelf
        isSelfFollowing
        followerType
        followerTitle
      }
      target {
        id
        isSelf
        isSelfFollowing
        followerType
        followerTitle
      }
    }
  }
`;

const unfollowMutation = gql`
  mutation unfollow($actor_context: UserContext, $target_context: UserContext) {
    unfollow(actorContext: $actor_context, targetContext: $target_context) {
      follower {
        id
        isSelf
        isSelfFollowing
        followerType
        followerTitle
      }
      target {
        id
        isSelf
        isSelfFollowing
        followerType
        followerTitle
      }
    }
  }
`;

const getFollowersQuery = gql`
  query getFollowers($user_context: UserContext, $target_context: UserContext) {
    getFollowers(userContext: $user_context, targetContext: $target_context) {
      avatar {
        sm
        md
      }
      entityType
      id
      isSelf
      isSelfFollowing
      name
      subtitle
      isVerified
      hasRecurringDeposit
      foundationName
      isActive
      unclaimed
    }
  }
`;

const getFollowersQueryV2 = gql`
  query getFollowers($projectId: Int!) {
    projectFollowers(projectId: $projectId) {
      avatar: avatarUrls {
        sm
        md
      }
      entityType: type
      id
      isSelfFollowing: currentEntityIsFollowing
      name
      subtitle
      user {
        isActive
        unclaimed
        avatarUrls {
          sm
          md
        }
      }
    }
  }
`;

const getFollowingQuery = gql`
  query getFollowing($actor_context: UserContext, $user_context: UserContext) {
    getFollowing(actorContext: $actor_context, userContext: $user_context) {
      avatar {
        sm
        md
      }
      entityType
      id
      isSelf
      isSelfFollowing
      name
      subtitle
      isVerified
      hasRecurringDeposit
      foundationName
      isActive
      unclaimed
    }
  }
`;

const getSuggestedEntitiesQuery = gql`
  query getSuggestedEntities($user_context: UserContext) {
    getSuggestedEntities(userContext: $user_context) {
      avatar {
        sm
        md
      }
      entityType
      id
      isSelf
      isSelfFollowing
      name
      subtitle
      relationship
    }
  }
`;

const updateFollowerMutation = gql`
  mutation updateFollowerMutation($charityId: Int!, $priority: Int!) {
    updateCharityFollow(charityId: $charityId, priority: $priority) {
      status
    }
  }
`;

export {
  followMutation,
  unfollowMutation,
  getFollowersQuery,
  getFollowersQueryV2,
  getFollowingQuery,
  getSuggestedEntitiesQuery,
  updateFollowerMutation,
};
