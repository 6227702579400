import React from 'react';
import { observer, inject } from 'mobx-react';

const withUserContext = (WrappedComponent) =>
  inject('profileStore')(
    observer((props) => {
      const userContext = props.profileStore.getUserContext();
      const activeEntity = props.profileStore.getActiveEntity();

      return (
        <WrappedComponent
          {...props}
          {...userContext}
          activeEntity={activeEntity}
        />
      );
    }),
  );

export default withUserContext;
