import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';

import { observer } from 'mobx-react';

const ShowMatchDetails = ({ store, setValidator }) => {
  const match = store.currentMatch;

  setValidator(() => '');

  return (
    <div className="modal-match-height event-match-details">
      <div className="option-group">
        <div className="flex-column details-container">
          <div className="flex-expand flex-row flex-space-between details-item">
            <p className="match-title">Match Name: </p>
            <p className="match-title">{match.name}</p>
          </div>
          {match.startDate && match.endDate && (
            <Fragment>
              <div className="flex-expand flex-row flex-space-between flex-align-end details-item">
                <p>Start Date:</p>
                <p>{moment(match.startDate).format('MMMM Do YYYY')}</p>
              </div>
              <div className="flex-expand flex-row flex-space-between flex-align-end details-item">
                <p>End Date:</p>
                <p>{moment(match.endDate).format('MMMM Do YYYY')}</p>
              </div>
            </Fragment>
          )}
          <div className="flex-expand flex-row flex-space-between details-item">
            <p className="match-title">Match Charities:</p>
            <div className="flex-column flex-align-end">
              {match.matchCharities.map(charity => (
                <p
                  className="match-title"
                  style={{ marginLeft: `1rem` }}
                  key={charity.name}
                >
                  {charity.name}
                </p>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ShowMatchDetails.propTypes = {
  store: PropTypes.object.isRequired,
  setValidator: PropTypes.func,
};

export default observer(ShowMatchDetails);
