import React from 'react';
import PropTypes from 'prop-types';

import CauzesView from './CauzesView';
import withAuthRequired from '../../behaviors/withAuthRequired';
import useCauzes from '../../hooks/useCauzes';

const InfluencerCauzesView = ({ influencerId }) => {
  useCauzes({ userContext: { userId: influencerId } });
  return <CauzesView userContext={{ userId: influencerId }} />;
};

InfluencerCauzesView.propTypes = {
  influencerId: PropTypes.string,
};

export default withAuthRequired(InfluencerCauzesView, {
  entityType: 'INFLUENCER',
});
