import { ReactComponent as PinOutline } from 'assets/images/icons/svgs/pin-outline.svg';
import { ReactComponent as Pin } from 'assets/images/icons/svgs/pin.svg';

const CauzePinButton = ({ pinned, onPin }) => {
  return (
    <div
      className="pin-button"
      title={pinned ? 'Unpin this cauze?' : 'Pin this cauze?'}
      onClick={onPin}
    >
      {pinned ? <Pin /> : <PinOutline />}
    </div>
  );
};

export default CauzePinButton;
