import { useEffect } from 'react';
import walletStore from '../stores/WalletStore';

const useWallet = ({ userContext }) => {
  useEffect(() => {
    walletStore.getPaymentMethods({ userContext });
    return () => walletStore.onUnmount();
  }, [userContext]);
};

export default useWallet;
